import { Injectable } from '@angular/core';

const _window = (): any =>
  // return the global native browser window object
   window || {}
;

@Injectable({
  providedIn: 'root',
})
export class MessageBusNativeWindowReference {
  get nativeWindow(): any {
    return _window();
  }
}
