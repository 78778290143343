<div id="uiHeaderDefault">
  <ng-container *ngTemplateOutlet="headerTemplate; context: { title: title | translate, subTitle: subTitle | translate }"></ng-container>
</div>

<ng-template #headerTemplate let-title="title" let-subTitle="subTitle">
  <div title class="mt-xs mb-sm">
    <typography *ngIf="title" title-medium>{{ title }}</typography>
    <bw-icon *ngIf="icon" [size]="41.6" [iconName]="icon" color="var(--brand)" backgroundColor="var(--bg-blue)"></bw-icon>
  </div>
  <p *ngIf="subTitle" text-large-regular class="pt-xs" color="var(--secondary-text)" [innerHtml]="subTitle"></p>
</ng-template>
