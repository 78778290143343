import { Injectable } from '@angular/core';
import {forkJoin, from, map, Observable, of, switchMap} from 'rxjs';
import {
  AwsApiWrapperService,
  BsCacheService,
} from '@brightside-web/desktop/data-access/core-services';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MicroCoreApiCacheService {
  constructor(
    private awsApiWrapperService: AwsApiWrapperService,
    private bsCacheService: BsCacheService,
  ) {}

  get<T>(api: string, key: string, init?: { [key: string]: any }, extendCacheSecondsBy: number = 0): Observable<T> {
    return from(this.bsCacheService.getItem(key)).pipe(
      switchMap((cached: T) => {
        if (cached) {
          return of(cached);
        } else {
          return from(this.awsApiWrapperService.get(api, key, init)).pipe(
            tap((response) => {
              this.bsCacheService.setItem(key, response, {
                expires: moment()
                  .add(30 + extendCacheSecondsBy, 'seconds')
                  .valueOf(),
              });
            })
          );
        }
      })
    )
  }

  //Instead of adding an ambiguous boolean value, added new method. If we switch params to not be so static we should keep
  //it to one method though
  getForced<T>(api: string, key: string, init?: { [key: string]: any }): Observable<T> {
    //Remove cache before making API call
    this.bsCacheService.removeItem(key);

    return this.get<T>(api, key, init);
  }

  getItem<T>(key: string): Promise<T> {
    return this.bsCacheService.getItem(key);
  }

  setItem(key: string, value: any) {
    this.bsCacheService.setItem(key, value);
  }

  refreshItem(key: string) {
    this.bsCacheService.removeItem(key);
  }
}
