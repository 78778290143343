import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  _amplitudeInstance: any;

  get amplitudeInstance() {
    if (this._amplitudeInstance) return this._amplitudeInstance;
    this._amplitudeInstance = amplitude.getInstance();
    return this._amplitudeInstance;
  }

  logEvent(eventName: string, eventProperties?: { [key: string]: string }) {
    eventName = eventName.split('_').join(' ').toLowerCase();
    if (eventProperties && Object.keys(eventProperties).length > 0) {
      this.amplitudeInstance.logEvent(eventName, this.updateEventPropertiesKeys(eventProperties));
    } else {
      this.amplitudeInstance.logEvent(eventName);
    }
  }

  setUserId(userId: string) {
    this.amplitudeInstance.setUserId(userId);
  }

  clearProperties(): void {
    // this.amplitudeInstance.setUserId(null);
    // this clears previously stored user properties in Amplitude
    // this.amplitudeInstance.clearUserProperties();
  }

  setUserProperties(userProperties: { [key: string]: string }) {
    this.amplitudeInstance.setUserProperties(userProperties);
  }

  incrementErrorsViewed() {
    const identify = new amplitude.Identify().add('total errors viewed', 1);
    this.amplitudeInstance.identify(identify);
  }

  updateEventPropertiesKeys(eventProperties?: { [key: string]: string }): { [key: string]: string } | undefined {
    if (!eventProperties) {
      return undefined;
    }

    const updatedProperties: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(eventProperties)) {
      const updatedKey = key.replace(/_/g, ' ');
      updatedProperties[updatedKey] = value;
    }

    return updatedProperties;
  }

}

export const amplitudeWhitelist = [
  "app_opened",
  "app_review",
  "app_update",
  "argyle",
  "biometrics",
  "change_username",
  "chat",
  "create_account",
  "credit",
  "email",
  "error",
  "external_savings",
  "full_ssn",
  "help",
  "help_topic_list",
  "home",
  "home_shown",
  "invite",
  "loading_timed_out",
  "loan",
  "notifications",
  "onboarding",
  "profile",
  "push",
  "savings",
  "select_employer",
  "settings",
  "sign_in",
  "sign_up",
  "verify",
  "home_address",
  "employee_id",
  "web_loaded"
];
