import {inject, Injectable} from '@angular/core';
import {
  AwsApiWrapperService, BsAuthService
} from "@brightside-web/desktop/data-access/core-services";
import * as moment from "moment";
import {Observable, Subscription, BehaviorSubject} from "rxjs";
import {
  CacheStorageService,
  ProfileService
} from "@brightside-web/desktop/data-access/shared";
import {HttpClient} from "@angular/common/http";
import {Environment} from "@brightside-web/micro/core/environment";
import {TitleCasePipe} from "@angular/common";

interface CompanyObject {
  company: string;
  timeSet: number;
}

interface CompanyConfig {
  supportNumber: string;
}

interface RCCustomerInfo {
  code: string;
  displayName: string;
  supportNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private bsAuthService: BsAuthService = inject(BsAuthService);

  private _company: BehaviorSubject<string> = new BehaviorSubject<string>('')
  private _supportNumber: BehaviorSubject<string> = new BehaviorSubject<string>('')
  private sub = new Subscription();

  public readonly company: Observable<string> = this._company.asObservable();
  public readonly supportNumber: Observable<string> = this._supportNumber.asObservable();

  constructor(
    private profileService: ProfileService,
    private http: HttpClient,
    public env: Environment,
    private cacheStorageSvc: CacheStorageService,
    private titleCasePipe: TitleCasePipe,
    private awsApiWrapperService: AwsApiWrapperService,
  ) {

    // wait a second for app to check/set company in cache from subdomain/company param
    // before we start trying to grab it from places
    setTimeout(() => {
      this.cacheStorageSvc.getItem('company').then(
        company => {
          if (company) this.setCompany(company);
          if (this._company.getValue() === '') {
            this.sub.add(
              this.bsAuthService.fetchUserAttributes().subscribe({
                next: (value) => {
                  if (value && value.company) {
                    this.setCompany(value.company);
                  }
                }
              })
            );
          }
        }
      )
    }, 500);
  }

  setCompany(companyName: string) {
    if (companyName === '') {
      this._company.next('');
      this.cacheStorageSvc.removeItem('company');
    } else {
      this._company.next(companyName);
      const companyObj = { company: companyName};
      this.cacheStorageSvc.setItem('company', companyName, { expires: moment().add(6000, 'seconds').valueOf() });
    }

    this.updateSupportNumber();

  }

  getCompany() : string {
    return this._company.getValue();
  }

  // at this point supportNumber should just be 10 digits
  setSupportNumber(supportNumber: string) {
    supportNumber = this.phonePipeCopy(supportNumber);
    if (this._supportNumber.getValue() !== supportNumber && supportNumber !== '') {
      this._supportNumber.next(supportNumber);
      this.cacheStorageSvc.setItem('supportNumber', supportNumber, { expires: moment().add(6000, 'seconds').valueOf() });
    } else if (supportNumber === '') {
      this._supportNumber.next('');
      this.cacheStorageSvc.removeItem('supportNumber');
    }
  }

  getSupportNumber() : string {
    return this._supportNumber.getValue();
  }

  updateSupportNumber(file = ''): void {

    if (file === '') {
      if (this.getCompany()) {
        file = this.getCompany().toLowerCase();
      } else {
        file = 'app';
      }
    }

    this.awsApiWrapperService.get('api-mobile-noauth', `/cdn-resource/resources/configs/${file}.json`, {body: null})
      .then((data:any) => {
        this.setSupportNumber(data.supportNumber);
      })
      .catch((_:any) => {
        if (file !== 'app') {
          this.updateSupportNumber('app');
        } else {
          console.error(`Error, fallback failed~`);
        }
      });

  }

  private secondsSinceTime(startDate: number) {
    const millis = Date.now() - startDate;
    return Math.floor(millis/1000);
  }

  phonePipeCopy(value: string, mask = false) {
    if (value && value.length >= 10) {
      const normalized= value.replace(/[^0-9]/gi, '');
      const pt1 = normalized.slice(0,3);
      const pt2 = normalized.slice(3,6);
      const pt3 = normalized.slice(6,10);
      if (mask){
        const regex = /[0-9]/gi;
        return `${pt1.replace(regex, 'X')}-${pt2.replace(regex, 'X')}-${pt3}`;
      } else {
        return `${pt1}-${pt2}-${pt3}`;
      }
    }
    return '';
  }

  getCompanyDisplayName(value?: string): string {
    const companyKey = value ?? this.getCompany();
    try {
      if (companyKey.toLowerCase() === 'mckesson') {
        return 'McKesson';
      } else if (companyKey.toLowerCase() === 'airproducts') {
        return 'Air Products';
      } else if (companyKey.toLowerCase() === 'bigy') {
        return 'Big Y';
      } else {
        return this.titleCasePipe.transform(companyKey.toLowerCase());
      }
    } catch (e) {
      // if value and this._company are undefined/null/non-string
      return '';
    }
  }
}
