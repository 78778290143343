import { Pipe, PipeTransform } from '@angular/core';
import {ConfigEligType} from "./product-eligibility/product-eligibility.component";
import {Validators} from "@angular/forms";
import {CurrencyPipe, DatePipe} from "@angular/common";

@Pipe({
  name: 'genCellValue'
})
export class GenCellValuePipe implements PipeTransform {

  transform(value: ConfigEligType[]): string {
    const items: string[] = [];
    let missingRequired = false;
    let isSSN = false;
    let lastFour = '****';

    value.forEach( item => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (item.control.hasValidator(Validators.required) && !item.value) {
       missingRequired = true;
       return;
      }
      if (item.key === 'fullSSN') {
        isSSN = true;
        if (item.value) {
          const splitSSN = item.value.split('-');
          if (splitSSN.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lastFour = splitSSN.pop();
          }
            return;
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const itemToWorkOn = item.value;
      if (itemToWorkOn && itemToWorkOn !== 'middle') {
        items.push(itemToWorkOn);
      }

    });

    if (value[0].type.toLowerCase() === 'date' && value[0].value ) {
      const datePipe: DatePipe = new DatePipe('en-US');
      return datePipe.transform(value[0].value, 'longDate') as string;

    }

    if (value[0].key === 'annualSalary') {
     const currencyPipe: CurrencyPipe = new CurrencyPipe('en-US');
     return currencyPipe.transform(value[0].value ?? 0) as string;
    }

    return missingRequired ? '-' : isSSN ? `***-**-${lastFour}` : items.join(' ');
  }

}
