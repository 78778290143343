<div class="card-lt-wrapper" [ngClass]="{'elevated': isElevated}">
  <div class="card-lt">
    <div class="card-lt-header flex-row space-between" [ngClass]="{clickable: _isHeaderClickable}" (click)="handleHeaderClick($event)">
      <div class="card-lt-title"><typography text-large-semibold>{{cardTitle}}</typography></div>
      <div class="card-lt-header-icon" *ngIf="_isHeaderClickable"><bw-icon size="24" iconName="chevron_right"></bw-icon></div>
    </div>
    
    <div class="card-lt-content">
      <ng-content select="[card-lt-content]"></ng-content>
    </div>

    <div *ngIf="hasFooter" class="card-lt-footer flex-row space-between" [ngClass]="{clickable: _isFooterClickable}" [ngStyle]="{'background-color': _footerColor, 'color': _footerIconColor}" (click)="handleFooterClick($event)">
      <bw-icon size="24" [iconName]="footerLeftIcon" [color]="_footerIconColor" class="mr-xs"></bw-icon>
      <div style="flex: 1 1 auto;">
        <typography class="type-sm-bold">{{footerText}}</typography>
      </div>
      <bw-icon size="24" [iconName]="footerRightIcon" [color]="_footerIconColor" ></bw-icon>
    </div>
  </div>
</div>
