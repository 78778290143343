import { Injectable } from '@angular/core';

import {
  FinancialAccountStatementsResponse,
  GroomedMonthlyAccountStatements,
  FinancialAccountStatementsPayload,
  FinancialAccountStatementsDataPayload
} from "../models/financial-account-statements";

import {
  GenericResponseCode,
  GenericResponseResultItems
} from "@brightside-web/desktop/data-access/core-services";

import {BsApiService} from "@brightside-web/desktop/data-access/core-services";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FinancialAccountsStatementsService {

  apiName = 'api-mobile';

  private _statements = new Subject<GroomedMonthlyAccountStatements[]>();
  public readonly statements: Observable<GroomedMonthlyAccountStatements[]> = this._statements.asObservable();

  constructor(
    private bsApi: BsApiService
  ) { }

  groomMonthlyData(statementsResponse: FinancialAccountStatementsResponse[]) : GroomedMonthlyAccountStatements[]{
    const years:GroomedMonthlyAccountStatements[] = [];

    statementsResponse.forEach(statement => {
      let yearObject = years.find(y => y.year === statement.year);

      if (!yearObject) {
        yearObject = { year: statement.year, statements: [] };
        years.push(yearObject);
      }

      yearObject.statements.push(statement);
    });

    return years;
  }

  getMonthlyStatements(accountId?:number) : void {
    this.bsApi.get<FinancialAccountStatementsPayload>(
      this.apiName,
      '/statements'
    ).subscribe(
      resp => {
        if (resp.result.code === GenericResponseCode.SERVICE_OK) {
          const groomedStatements = this.groomMonthlyData(resp.payload.statements);
          this._statements.next(groomedStatements);
        }
      }
    )
  }

  getDateForStatement(statementId?:number) {
    this.bsApi.get<FinancialAccountStatementsDataPayload>(
      this.apiName,
      `/statements/${statementId}/download`
    ).subscribe(
      resp => {
        if (resp.result.code === GenericResponseCode.SERVICE_OK) {
          this.openBase64Pdf(resp.payload.content);
        }

      }
    )
  }
  openBase64Pdf(base64String: string, filename: string = 'document.pdf') {
    const byteString = atob(base64String);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'application/pdf' });

    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
  }
}
