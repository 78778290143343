import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';

import { MicroCoreUtilityModule, FirebaseAnalyticsService, FirebaseService } from '@micro-core/utility';

import { UiMediaComponent } from './media/media.component';
import { UiProductPromoCardComponent } from './product-promo-card/product-promo-card.component';

import { UiNavigationExitBackComponent } from './navigation/exit/back/exit-back.component';
import { UiNavigationExitCloseComponent } from './navigation/exit/close/exit-close.component';

import { UiHeaderDefaultComponent } from './header/default/header-default.component';
import { UiHeaderEyebrowComponent } from './header/eyebrow/header-eyebrow.component';
import { UiHeaderMenuComponent } from './header/menu/header-menu.component';

import { UiFooterDefaultComponent } from './footer/default/footer-default.component';

const Molecules = [
  UiFooterDefaultComponent,
  UiHeaderDefaultComponent,
  UiHeaderEyebrowComponent,
  UiHeaderMenuComponent,
  UiMediaComponent,
  UiNavigationExitBackComponent,
  UiNavigationExitCloseComponent,
  UiProductPromoCardComponent,
];

@NgModule({
  imports: [BrightsideUiModule, BrightsideUiServicesModule, CommonModule, MicroCoreUtilityModule, TranslateModule],
  exports: [...Molecules],
  providers: [{ provide: FirebaseService, useClass: FirebaseAnalyticsService }],
  declarations: [...Molecules],
})
export class MicroUiMoleculeModule {}
