import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router, Params
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';

import {RegistrationService} from "@brightside-web/desktop/data-access/onboarding";

@Injectable({
  providedIn: 'root'
})
export class ReferralGuard implements CanActivate {

  referrerConfigs : { [p:string]: string[] } = {
    'assistant' : ['referrerType', 'firstName', 'employmentType', 'company'],
    'client' : ['referrerType', 'firstName']
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private regSvc: RegistrationService ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const referrerType = this.whichReferrer(next.queryParams);
    const hasAllParams = this.allParamsPresent(next.queryParams, referrerType);
    if (hasAllParams && referrerType === 'assistant') {
      this.regSvc.storeReferralToSession();
      this.regSvc.skipAccountCreation = true;
    }

    switch (next.routeConfig?.path) {
      case 'create-account':
        if (hasAllParams) {
          this.router.navigate(['registration/referral'], {queryParams: next.queryParams});
          return of(false);
        } else {
          return of(true);
        }
        break;
      case 'referral':
        if (hasAllParams) {
          return of(true);
        } else {
          this.router.navigate(['home']);
          return of(false);
        }
        break;
      default:
        return of(true);
    }
  }

  whichReferrer(queryParams: Params) : string {
    if (queryParams.referrerType) {
      const configKeys = Object.keys(this.referrerConfigs);
      for (let i = 0; i < configKeys.length; i++) {
        if (queryParams.referrerType === configKeys[i]) {
          return configKeys[i];
          break;
        }
      }
    }
    return '';
  }

  allParamsPresent(params: Params, referrerType: string) {
    if (referrerType) {
      const referrerConfig = this.referrerConfigs[referrerType];
      let foundParams = 0;
      for (let i = 0; i < referrerConfig.length; i++) {
        if (params[referrerConfig[i]]) {
          foundParams++;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.regSvc.referralItems[referrerConfig[i]] = params[referrerConfig[i]];
        }
      }
      return foundParams >= referrerConfig.length;
    } else {
      return false;
    }
  }
}
