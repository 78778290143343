<bw-spinner *ngIf="loading"></bw-spinner>
<!-- forms intro-->
<bw-action-article
  *ngIf="false"
  [brightsideWebFb]="{event_name: 'verify_identity_intro'}"
  [minHeight]="minHeight">
  <bw-back header-actions [emit]="true" (back)="handleMainBack();"></bw-back>

  <div header-title *ngIf="!hideDefaults">
    <div class="flex-row space-between full-width">
      <typography title-medium class="mr-md" >
        {{ 'VERIFY_IDENTITY_INTRO_TITLE' | translate }}
      </typography>
      <bw-icon
        iconName="check_badge" color="var(--brand)" size="52"
        backgroundColor="var(--bg-blue)"
      ></bw-icon>
    </div>
  </div>
  <div content-section>
    <ng-container *ngIf="!hideDefaults">
      <bw-paragraph color="var(--secondary-text)">
        {{ 'VERIFY_IDENTITY_INTRO_DESC' | translate }}
      </bw-paragraph>
      <typography headline-regular class="mb-sm story-title" >
        {{ 'VERIFY_IDENTITY_INTRO_SUBHEADER' | translate }}
      </typography>
    </ng-container>
    <ol>
      <ng-container *ngFor="let field of requiredForms">
        <li *ngIf="field.display">
          <typography section-copy>{{ field.titleKey | translate }}</typography>
        </li>
      </ng-container>
    </ol>
  </div>
  <div footer-actions class="flex-row space-center full-width">
    <div>
      <button
        bw-button
        [processing]="processing"
        (click)="stepThroughForms(1)"
        [brightsideWebFb]="{event_name: 'verify_identity_intro'}"
      >{{ 'BUTTON_CONTINUE' | translate }}</button>
    </div>
  </div>
</bw-action-article>
<!-- forms summary-->
<bw-action-article
  *ngIf="requiredForms.length > 0 && showSummary"
  [brightsideWebFb]="{event_name: 'verify_identity'}"
  [minHeight]="minHeight">
  <div header-actions>
    <ui-nav-exit-back
      *ngIf="showBackbutton"
      [trackingDetail]="{ event_name: 'verify_identity' }"
      [skipActionEmit]="true"
    ></ui-nav-exit-back>
  </div>
  <div header-title *ngIf="!hideDefaults">
    <div class="flex-row space-between full-width">
      <typography title-medium class="mr-md" >
        {{ 'VERIFY_IDENTITY_TITLE' | translate }}
      </typography>
      <bw-icon
        iconName="check_badge" color="var(--brand)" size="52"
        backgroundColor="var(--bg-blue)"
      ></bw-icon>
    </div>
  </div>
  <div content-section>
    <ng-container *ngIf="!hideDefaults">
      <bw-paragraph color="var(--secondary-text)" [ngSwitch]="productCode">
        <ng-container *ngSwitchCase="'registration'">
          {{ 'VERIFY_IDENTITY_DATA_COLLECT_DESC' | translate }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ 'VERIFY_IDENTITY_DESC' | translate }}
        </ng-container>
      </bw-paragraph>
    </ng-container>
    <div *ngFor="let field of requiredForms">
      <bw-cell (clicked)="showForm(field, true)"
               labelLeft=" {{ field.controlConfig | genCellValue }}"
               labelSubLeft="{{ field.titleKey | translate }}"
               [flipLeftLabels]="true" iconNameRight="chevron_right">

      </bw-cell>
    </div>
  </div>
  <div footer-top class="flex-row space-center">
    <form [formGroup]="confirmDataForm">
      <bw-checkbox [formControlName]="'confirmCheckbox'">
        <typography text-medium color="var(--primary-text)">
          {{ 'VERIFY_IDENTITY_CONFIRM' | translate }}
        </typography>
      </bw-checkbox>
    </form>
  </div>
  <div footer-actions class="flex-row space-center full-width">
    <div>
      <button
        bw-button
        [processing]="processing"
        [disabled]="confirmDataForm.invalid"
        (click)="submitInfo()"
        [brightsideWebFb]="{event_name: 'verify_identity'}"
      >{{ 'BUTTON_CONTINUE' | translate }}</button>
    </div>
  </div>

</bw-action-article>
<!-- forms template-->
<bw-action-article
  *ngIf="formIsReady"
  [brightsideWebFb]="{event_name: pageCopy[activeFieldName].eventName}"
  [minHeight]="minHeight">
  <bw-back header-actions
           [emit]="true"
           (back)="stepThroughForms(-1)"></bw-back>
  <div header-title *ngIf="!hideDefaults">
    <div class="flex-row space-between full-width">
      <typography title-medium class="mr-md" >
        {{ pageCopy[activeFieldName].titleKey | translate }}
      </typography>
    </div>
  </div>
  <div content-section>
    <bw-paragraph color="var(--secondary-text)" *ngIf="!hideDefaults">
      {{ pageCopy[activeFieldName].bodyKey | translate }}
    </bw-paragraph>
    <form [formGroup]="eligibilityForm" (submit)="updateInfo();">
      <div
        *ngFor="let controlConfig of formControls; let i = index;">

        <ng-container [ngSwitch]="controlConfig.key">
          <bw-input
            *ngSwitchCase="'annualSalary'"
            [isCustomControl]="true"
            [label]="controlConfig.labelKey | translate"
            [inputError]="controlConfig.control.invalid &&
                (
                  controlConfig.control[controlConfig.inputOptions?.beginValidationOn] ?
                  controlConfig.control[controlConfig.inputOptions?.beginValidationOn] : controlConfig.control.touched
                ) &&
                controlConfig.errorTextKey !== ''"
            [inputErrorText]="controlConfig.errorTextKey | translate"
            [hasInfo]="controlConfig.showInfo"
            (emitInfoClicked)="displayInfo(controlConfig)"
            [isFocused]="controlConfig.hasFocus"
            [inputHintText]="(controlConfig.inputOptions?.inputHintTextKey | translate) ?? ''"
            [showSuffixIcon]="!!controlConfig.inputOptions?.suffixIconName"
            [suffixIconName]="controlConfig.inputOptions?.suffixIconName ?? ''"
          >
            <input
              [formControlName]="controlConfig.key"
              inputmode="decimal"
              currencyMask
              [options]="currencyOptions"
              label="{{controlConfig.labelKey | translate}}"
              [autofocus]="i===0"
              (focus)="controlConfig.hasFocus = true;"
              (blur)="controlConfig.hasFocus  = false;"
            />
          </bw-input>
          <bw-input
            *ngSwitchCase="'fullSSN'"
            [label]="controlConfig.labelKey | translate"
            placeholder="{{ 'SSN_HINT' | translate }}"
            [formControlName]="controlConfig.key"
            inputMask="000-00-0000"
            [inputError]="controlConfig.control.invalid &&
                (
                  controlConfig.control[controlConfig.inputOptions?.beginValidationOn] ?
                  controlConfig.control[controlConfig.inputOptions?.beginValidationOn] : controlConfig.control.touched
                ) &&
                controlConfig.errorTextKey !== ''"
            [inputErrorText]="controlConfig.errorTextKey | translate"
            maxlength="11"
            minlength="11"
            [autoFocus]="true"
            [inputHintText]="(controlConfig.inputOptions?.inputHintTextKey | translate) ?? ''"
            [showSuffixIcon]="!!controlConfig.inputOptions?.suffixIconName"
            [suffixIconName]="controlConfig.inputOptions?.suffixIconName ?? ''"
          ></bw-input>
          <bw-input
            *ngSwitchDefault
            [isCustomControl]="true"
            [label]="controlConfig.labelKey | translate"
            [inputError]="controlConfig.control.invalid &&
                (
                  controlConfig.control[controlConfig.inputOptions?.beginValidationOn] ?
                  controlConfig.control[controlConfig.inputOptions?.beginValidationOn] : controlConfig.control.touched
                ) &&
                controlConfig.errorTextKey !== ''"
            [inputErrorText]="controlConfig.errorTextKey | translate"
            [hasInfo]="controlConfig.showInfo"
            (emitInfoClicked)="displayInfo(controlConfig)"
            [isFocused]="controlConfig.hasFocus"
            [inputHintText]="(controlConfig.inputOptions?.inputHintTextKey | translate) ?? ''"
            [showSuffixIcon]="!!controlConfig.inputOptions?.suffixIconName"
            [suffixIconName]="controlConfig.inputOptions?.suffixIconName ?? ''"
          >
            <input
              [formControlName]="controlConfig.key"
              label="{{controlConfig.labelKey | translate}}"
              pattern="{{controlConfig.inputOptions?.pattern}}"
              minlength="{{controlConfig.inputOptions?.minlength}}"
              maxlength="{{controlConfig.inputOptions?.maxlength}}"
              type="{{controlConfig.type}}"
              [autofocus]="i===0"
              (focus)="controlConfig.hasFocus = true;"
              (blur)="controlConfig.hasFocus  = false;"
            />
          </bw-input>
        </ng-container>

      </div>

      <button
        type="submit"
        hidden
        [disabled]="eligibilityForm.invalid"
        [brightsideWebFb]="{event_name: pageCopy[activeFieldName].eventName}">
      </button>
    </form>
  </div>
  <div footer-actions class="flex-row space-center full-width">
    <div>
      <button
        bw-button
        (click)="updateInfo()"
        [disabled]="eligibilityForm.invalid"
        [brightsideWebFb]="{event_name: pageCopy[activeFieldName].eventName}"
      >{{ 'BUTTON_CONTINUE' | translate }}</button>
    </div>
  </div>
</bw-action-article>


<bw-modal
  *ngIf="showErrorModal"
  (closeModal)="closeErrorModal();"
  [brightsideWebFb]="{event_name: 'error_shown', custom: {'error id': displayedError.errorId }}"
  [error_view]="true">
  <typography title-medium color="var(--gray-70)">
    {{ displayedError.errorTitleKey | translate }}
  </typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      <div [innerHTML]="displayedError.errorDescKey | translate"></div>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="closeErrorModal();">
        {{ displayedError.ctaKey | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
