import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Environment } from "@micro-core/environment";
import {BsAuthService} from "../Auth/bs-auth.service";
import { SalesforceModel } from "./salesforce.model";
import {Intercom} from "ng-intercom";
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import {FeatureFlagService} from "../FeatureFlag/feature-flag.service";
import {FirebaseService, KnownFeatureFlags} from "@brightside-web/desktop/data-access/shared";
import {BsCacheService} from "../Cache/bs-cache.service";
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { take } from 'rxjs/operators';

declare let embeddedservice_bootstrap: SalesforceModel;

interface SalesforceInterface {
  organizationId: string;
  developerName: string;
  url: string;
  scrt2URL: string;
  chatScript: string;
}

@Injectable({
  providedIn: 'root'
})
export class BsChatService {

  private _chatLoaded = new BehaviorSubject<boolean>(false);
  public readonly chatLoaded: Observable<boolean> = this._chatLoaded.asObservable();

  private _chatConfig: SalesforceInterface;
  private _chatInitialized: boolean;
  private _chatScript: HTMLScriptElement;
  private renderer: Renderer2;
  alreadyLaunchedChat = false;
  private serviceLoaded: boolean;
  private _inMaintenance: boolean
  private _chatLoadingInProgress = false;

  private _rules: {rules:{ regex: string; product: string; inMaintenance: boolean }[]};
  private _productMaintenanceMap: { [product: string]: boolean } = {};
  private _guidAttempts = 0;

  constructor(
    private env: Environment,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private bsAuthService: BsAuthService,
    private intercom: Intercom,
    private featureFlagSvc: FeatureFlagService,
    private bsCacheService: BsCacheService,
    private analytics: FirebaseService,
    // Microapps don't like the MaintenanceService being imported into this service
    // private _maintenanceService: MaintenanceService,
    private _router: Router,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.analytics.ensureInitialized().then(() => {
      try {
        this._rules = JSON.parse(this.analytics.getValueAsString('domainMappingPaths'));
        this.reduceRulesToMaintenanceMap();
      } catch (e) {
        console.warn('Issue with JSON string');
      }
    });
  }

  loadChat() {
    if (this.env.chat && this.isSalesForceConfig(this.env.chat)) {
      this._chatConfig = this.env.chat;
      this._inMaintenance = this.isProductInMaintenance('chat');
      if (!this.alreadyLaunchedChat && !this._inMaintenance) this._initializeChat();
    } else {
      console.error('Invalid chat configuration');
    }
  }

  private _initializeChat() {
    this.alreadyLaunchedChat = true;
    this._chatScript = this._loadChatScript();
    this._chatScript.onload = () => {
      embeddedservice_bootstrap.settings.language = 'en_US';
      embeddedservice_bootstrap.init(
        this._chatConfig.organizationId,
        this._chatConfig.developerName,
        this._chatConfig.url,
        {
          scrt2URL: this._chatConfig.scrt2URL,
        }
      );

      this.serviceLoaded = true;
      window.addEventListener('onEmbeddedMessagingReady', () => {
        this._verifyUser();
      });

    };
  }

  private _verifyUser() {
    this.bsAuthService.getToken().subscribe({
      next: token => {
        if (token) {
          this.bsAuthService.fetchUserAttributes(true).subscribe({
            next: attributes => {
              if (attributes.guid) {
                // Send your identity token to Salesforce
                embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
                  identityTokenType: 'JWT',
                  identityToken: token
                });
                // Send data to Salesforce
                embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
                  "Guid": attributes.guid,
                  "Language": attributes.locale || "en",
                });

                // Remove any items from the previous list that you don't want to send
                embeddedservice_bootstrap.prechatAPI.removeHiddenPrechatFields();

                this._setUpOtherListeners();
              } else {
                if(this._guidAttempts <= 9) {
                  setTimeout(() => {
                    this._guidAttempts++;
                    this._verifyUser();
                  }, 100);
                } else {
                  Sentry.withScope(scope => {
                    scope.setFingerprint(['chat_guid_not_found']);
                    scope.setLevel("warning");
                    Sentry.captureMessage('User\'s GUID not found for chat initialization');
                  });
                }
              }
            }
          })
        }
      }
    });
  }

  private _refreshToken() {
    this.bsAuthService.getToken().subscribe({
      next: token => {
        if (token && this.serviceLoaded) {
          this.bsAuthService.fetchUserAttributes().subscribe({
            next: () => {
              embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
                identityTokenType: 'JWT',
                identityToken: token
              });
            }
          })
        }
      }
    });
  }

  public showChat(): void {
    if (this._chatLoaded.value) {
      if (!this._inMaintenance) {
        this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
          .pipe(
            tap(enableSalesforce => {
              if (enableSalesforce && this.serviceLoaded) {
                embeddedservice_bootstrap.utilAPI.launchChat();
              } else {
                this.intercom.show();
              }
            })
          )
          .subscribe();
      } else {
        this._router.navigate(['maintenance', 'feature']);
      }
    } else if (!this._chatLoadingInProgress) {
      this._chatLoadingInProgress = true;

      this.chatLoaded
        .pipe(
          filter(isLoaded => isLoaded),
          take(1)
        )
        .subscribe({
          next: () => {
            this._chatLoadingInProgress = false;
            this.showChat();
          },
          error: () => {
            this._chatLoadingInProgress = false;
          }
        });
    }
  }


  private _loadChatScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this._chatConfig.chatScript;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  clearSession() {
    if (!this._inMaintenance) {
      this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
        .pipe(
          tap(enableSalesforce => {
            if (enableSalesforce && this.serviceLoaded) {
              embeddedservice_bootstrap.userVerificationAPI
                .clearSession()
                .finally(() => {
                });
            } else {
              this.intercom.shutdown();
            }
          })
        )
        .subscribe();
    }
  }

  private isSalesForceConfig(chat: any): chat is SalesforceInterface {
    return (
      chat &&
      typeof chat.organizationId === 'string' &&
      typeof chat.developerName === 'string' &&
      typeof chat.url === 'string' &&
      typeof chat.scrt2URL === 'string' &&
      typeof chat.chatScript === 'string'
    );
  }

  private _setUpOtherListeners() {
    this._chatLoaded.next(true);

    window.addEventListener('onEmbeddedMessagingConversationStarted', (data) => {
      console.log('ken checking onEmbeddedMessagingConversationStarted', data);
    })

    window.addEventListener('onEmbeddedMessagingIdentityTokenExpired', () => {
      this._refreshToken();
    });

    window.addEventListener('onEmbeddedMessagingWindowMinimized',() => {
      this.analytics.logEvent('chat_dismissed', {}, true);
    });

    window.addEventListener('onEmbeddedMessagingWindowMaximized',() => {
      this._setUpClickWatcher();
      this.bsCacheService.getItem('fromOnboarding').then(
        fromOnboarding => {
          if (fromOnboarding === true) {
            this.analytics.logEvent('chat_shown', { source: 'onboarding' }, true);
            this.bsCacheService.removeItem('fromOnboarding');
          } else {
            this.analytics.logEvent('chat_shown', { source: 'client' }, true);
          }
        }
      )
    });
  }

  private _setUpClickWatcher() {
    window.addEventListener('message', (event) => {
      // console.log('ken is here listening to messages', event);

      // Handle the message as described above
    });
    // const maxRetryAttempts = 5;
    // let retriesCompleted = 0;
    // console.log('ken is in setUpClickWatcher');
    // const tryToConnectWatcher = () => {
    //   console.log('ken is in tryToConnectWatcher');
    //   setTimeout(() => {
    //     try {
    //       console.log('ken is in tryToConnectWatcher timeout try', retriesCompleted);
    //       const embeddedMessenger = document.getElementById('embeddedMessagingFrame') as HTMLIFrameElement;
    //       const contentWindow = embeddedMessenger.contentWindow;
    //       if (contentWindow) {
    //         contentWindow.removeEventListener(`click`, () => {});
    //         contentWindow.addEventListener(`click`, (e: any) => {
    //           const origin = e.target.closest('a') as HTMLAnchorElement;
    //           if (origin && origin.href.includes('gobrightside://')) {
    //             e.preventDefault();
    //             console.log('ken has clicked');
    //             // this.internalLinkRouting.routeToLink(origin.href);
    //           }
    //         });
    //       }
    //       console.log('ken here', embeddedMessenger, contentWindow);
    //     } catch (e) {
    //       console.log('ken is in tryToConnectWatcher timeout catch', e);
    //       if (maxRetryAttempts > 0 && maxRetryAttempts < retriesCompleted) {
    //         return;
    //       }
    //
    //       setTimeout(() => {
    //         tryToConnectWatcher();
    //         retriesCompleted++;
    //       }, 1000);
    //     }
    //
    //   }, 1000);
    // }
    // tryToConnectWatcher();
  }

  // Microapps don't like the MaintenanceService being imported into this service
  private reduceRulesToMaintenanceMap() {
    this._rules.rules.forEach(rule => {
      const { product, inMaintenance } = rule;
      if (inMaintenance) {
        this._productMaintenanceMap[product] = true;
      } else if (!(product in this._productMaintenanceMap)) {
        this._productMaintenanceMap[product] = false;
      }
    });
  }

  public isProductInMaintenance(productType: string): boolean {
    return this._productMaintenanceMap[productType] || false;
  }
}
