import { Injectable } from '@angular/core';
import { ApiCacheService } from './api-cache.service';
import { ApiResponse, AxiosResponse } from '@brightside-web/desktop/data-access/shared';
import {forkJoin, from, Observable, of, Subscription} from 'rxjs';
import {
  AwsApiWrapperService
} from '@brightside-web/desktop/data-access/core-services';
import {map, tap} from 'rxjs/operators';


export interface TermsType {
  forHelix?: boolean;
  url?: any;
  key:string;
  label:string;
  path?:string;
  filter?:string;
}

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  sub = new Subscription();
  termTypes: TermsType[];

  constructor(
    private apiCache:ApiCacheService,
    private awsApiWrapperService: AwsApiWrapperService
  ) { }

  getTypes() : Observable<TermsType[]> {
    if (this.termTypes && this.termTypes.length !== 0) {
      return of(this.termTypes);
    } else {
      return from(this.awsApiWrapperService.get('api-mobile-noauth', '/cdn-resource/terms/types.json', {body: null})).pipe(
        tap(terms => {
          this.termTypes = terms;
        })
      );
    }
  }



  //MW terms.service
  acceptTerms(...keys:string[]): Observable<boolean>{
    return forkJoin(keys.map<Observable<ApiResponse>>(key => from(this.postTerms(key)))).pipe(
      map(responses => responses.every((response: ApiResponse) => response.statusCode === 200))
    );
  }

  acceptTermsFromConsentArray(consents: {key:string}[]): Observable<boolean>{
    const keys: string[] = consents.filter((consent) => !!consent.key).map((consentLine) => consentLine.key);
    return this.acceptTermsFromArray(keys);
  }

  acceptTermsFromArray(keys: string[]): Observable<boolean>{
    return forkJoin(keys.map<Observable<ApiResponse>>(key => from(this.postTerms(key)))).pipe(
      map(responses => responses.every((response: ApiResponse) => response.statusCode === 200))
    );
  }

  private postTerms(key:string):Promise<ApiResponse>{
    this.apiCache.refreshItem(`/terms/acceptance?type=${key}`);
    return this.awsApiWrapperService.post('api-mobile',`/terms/acceptance?type=${key}`, {headers:{"Content-Type":"application/json"}})
  }

  //MW terms.service
  fetchTerms(key: string): Observable<string> {
    return this.apiCache.get<string>('api-mobile-noauth', `/terms?deviceType=html&type=${key}`, {
      'responseType': 'text'
    });
  }

  //MW terms.service fetchTermsAcceptance
  getTermsAcceptance(...keys:string[]): Observable<boolean>{
    return forkJoin(keys.map<Observable<AxiosResponse>>(key => from(this.getAcceptance(key)))).pipe(
      map(responses => responses.every((response: AxiosResponse) => response.status === 200))
    );
  }

  private getAcceptance(key:string): Observable<AxiosResponse>{
    return this.apiCache.get<AxiosResponse>('api-mobile',`/terms/acceptance?type=${key}`, {response: true});
  }

}
