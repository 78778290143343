import { MessageBusOutgoingEventKey } from '../models/message-bus.enum';
import { MessageBusInternalHubEvent } from '../models/message-bus.interface';

export const StandardEvent_PageLoaded: MessageBusInternalHubEvent = {
  event: MessageBusOutgoingEventKey.LOADED,
  data: {
    body: {},
  },
};

export const StandardEvent_ShowLoader: MessageBusInternalHubEvent = {
  event: MessageBusOutgoingEventKey.SHOW_LOADING,
  data: {
    body: {},
  },
};

export const StandardEvent_HideLoader: MessageBusInternalHubEvent = {
  event: MessageBusOutgoingEventKey.HIDE_LOADING,
  data: {
    body: {},
  },
};

export const StandardEvent_ExitFeature: MessageBusInternalHubEvent = {
  event: MessageBusOutgoingEventKey.EXIT_VIEW,
  bridgeSetting: {
    data: {},
  },
  fallbackSetting: {
    routeToUrl: '/home',
  },
};

export const StandardEvent_ExitFeatureToChat: MessageBusInternalHubEvent = {
  event: MessageBusOutgoingEventKey.ROUTE,
  bridgeSetting: {
    data: {
      path: 'chat',
    },
  },
  fallbackSetting: {
    action: MessageBusOutgoingEventKey.CHAT,
    routeToUrl: '/home'
  },
};

export const StandardEvent_EnterExternalAccountConnect: MessageBusInternalHubEvent = {
  event: MessageBusOutgoingEventKey.ROUTE,
  bridgeSetting: {
    data: {
      path: 'chat',
    },
  },
  fallbackSetting: {
    routeToUrl: '/external-accounts/intro/launch',
  },
};
