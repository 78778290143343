enum MessageBusHalfsheetStyle {
  DEFAULT = 'Default',
  PRIMARY = 'Primary',
  WARNING = 'Warning',
  LIST = 'List',
}
enum MessageBusHalfsheetType {
  TEXT = 'Text',
  CELL = 'Cell',
}

enum MessageBusHalfsheetCellStyle {
  DEFAULT = 'Default',
  INFO = 'InfoText',
}

export { MessageBusHalfsheetCellStyle, MessageBusHalfsheetStyle, MessageBusHalfsheetType };
