import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {LanguageSelectorService, SupportedLangsInterface} from "../language-selector.service";

import {Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'brightside-web-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        maxHeight: '209px',
        opacity: '1'
      })),
      state('out', style({
        maxHeight: '0px',
        opacity: '0'
      })),
      transition('in => out', animate('400ms 100ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class LanguageSelectorComponent {
  private _displayDropdown: boolean

  @Input() bypassPut = false;

  @Input() set displayDropdown(val: boolean) {
    this.displaySelected = val;
    this._displayDropdown = val;
  }
  get displayDropdown(): boolean {
    return this._displayDropdown;
  }

  displaySelected: boolean;
  sub = new Subscription();
  displayedOption: SupportedLangsInterface | undefined;
  displayLangSelector: boolean;
  hasFocus: Boolean;

  @ViewChild('langSelectInput') langSelectInput: ElementRef;

  constructor(
    private langSelectSvc: LanguageSelectorService,
  ) {
    this.sub.add(
      this.langSelectSvc.ready.subscribe(isReady => {
        if (isReady) setTimeout(()=>{this.setUpSelector()}, 100);
      })
    )
  }

  setUpSelector() {
    if (!this.langSelectSvc.disableChangeLang) {
      this.displayedOption = this.langSelectSvc.supportedLanguages.find(lang => {
        if (this.displaySelected) return lang.locale === this.langSelectSvc.activeLang;
        return lang.locale !== this.langSelectSvc.activeLang;
      });
      if (!this.displayedOption && this.displayDropdown) {
        this.displayedOption = this.langSelectSvc.supportedLanguages.find(lang => {
          return lang.locale === 'en';
        });
      }
      this.displayLangSelector = true;
    }
  }

  changeLangTo(locale: string, defocus = false) {
    this.langSelectSvc.use(locale, this.bypassPut);
    setTimeout(()=>{
      if (defocus && this.langSelectInput) this.langSelectInput.nativeElement.blur();
      this.displayedOption = this.langSelectSvc.supportedLanguages.find(lang => {
        if (this.displaySelected) return lang.locale === this.langSelectSvc.activeLang;
        return lang.locale !== this.langSelectSvc.activeLang;
      });
    }, 10);
  }
}
