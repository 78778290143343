import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'bw-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent  {

  @Input() leftIconName = 'close';
  @Input() rightIconName = 'ellipses';

  @Output() leftIconAction = new EventEmitter();
  @Output() rightIconAction = new EventEmitter();

  constructor() { }


  handleLeftAction(event: Event) {
    this.leftIconAction.emit(event);
  }

  handleRightAction(event: Event) {
    this.rightIconAction.emit(event);
  }

}
