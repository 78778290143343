import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bw-action-cell',
  templateUrl: './action-cell.component.html',
  styleUrls: ['./action-cell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionCellComponent implements OnInit {

  @Input() label: string;
  @Input() detail: string;

  @Input() emphasize = true;
  @Input() disabled = false;
  @Input() iconName: string;
  @Input() iconColor: string;
  @Input() leftIconName: string;
  @Input() leftIconColor: string;

  @Output() actionCellClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onActionCellClick() {
    if (!this.disabled) {
      this.actionCellClick.emit();
    }
  }

}
