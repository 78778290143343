import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation } from '@angular/core';

import { UiBaseDataMoleculeComponent, UiBaseDataMoleculeSchema } from '../../shared/base-data/base-data-molecule.component';

import { UiFooterType } from '../footer.enum';
import { UiFooterDefaultData, UiFooterDefaultStateCta } from '../footer.interface';

const DEFAULT_STATE: UiFooterDefaultStateCta = {
  disabled: false,
  processing: false,
};

@Component({
  selector: 'ui-footer-default',
  templateUrl: './footer-default.component.html',
  styleUrls: ['./footer-default.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFooterDefaultComponent extends UiBaseDataMoleculeComponent implements UiBaseDataMoleculeSchema, OnInit {
  /**
   * Allow developer to pass in data with an object
   *
   * Interface UiFooterDefaultData
   */
  @Input() override data: UiFooterDefaultData;

  /**
   * Allow developer to pass in data single fields at a time
   */
  @Input() cta1Label: string;
  @Input() cta2Label: string;
  @Input() trackingDetail: { event_name: string };
  @Input() type: UiFooterType;

  @Input() cta1State: UiFooterDefaultStateCta | undefined | null = DEFAULT_STATE;
  @Input() cta2State: UiFooterDefaultStateCta | undefined | null = DEFAULT_STATE;

  @Output() cta1ActionHandler = new EventEmitter();
  @Output() cta2ActionHandler = new EventEmitter();

  footerTypeEnum = UiFooterType;

  ngOnInit(): void {
    this.schemaValidationConfig = {
      directInputValues: [this.cta1Label, this.cta2Label],
    };

    super.ngOnInit();
  }

  public override uiOnDirectInputsFromDataObject(): void {
    this.cta1Label = this.data?.inputs?.cta1Label || this.cta1Label || '';
    this.cta2Label = this.data?.inputs?.cta2Label || this.cta2Label || '';

    this.trackingDetail = this.data?.inputs?.trackingDetail || this.trackingDetail || { event_name: 'missing_event_name_footer' };
    this.type = this.data?.inputs?.type || this.type || UiFooterType.DEFAULT;
  }
}
