<article class="page-article">
  <div class="page-cover"></div>
  <header class="page-header"><ng-content select="[page-header]"></ng-content></header>
  <div class="page-sections">
    <section class="page-primary-section"><ng-content select="[page-primary-section]"></ng-content></section>
    <section class="page-secondary-section"><ng-content select="[page-secondary-section]"></ng-content></section>
  </div>
  <footer class="page-footer"><ng-content select="[page-footer]"></ng-content></footer>
</article>

