<ng-container
  *ngTemplateOutlet="
    type !== footerTypeEnum.ROW ? footerTemplate : displayAsForcedRow;
    context: { cta1Label: cta1Label | translate, cta2Label: cta2Label | translate }
  "
></ng-container>

<ng-template #displayAsForcedRow let-cta1Label="cta1Label" let-cta2Label="cta2Label">
  <div class="display-as-bui-flex-row">
    <ng-container *ngTemplateOutlet="footerTemplate; context: { cta1Label: cta1Label, cta2Label: cta2Label }"></ng-container>
  </div>
</ng-template>

<ng-template #footerTemplate let-cta1Label="cta1Label" let-cta2Label="cta2Label">
  <button
    *ngIf="cta1Label"
    bw-button
    [disabled]="cta1State?.disabled || false"
    [processing]="cta1State?.processing|| false"
    [coreTracking]="trackingDetail"
    (click)="cta1ActionHandler.emit()"
  >
    {{ cta1Label }}
  </button>
  <button *ngIf="cta2Label" bw-link-button [coreTracking]="trackingDetail" (click)="cta2ActionHandler.emit()">
    {{ cta2Label }}
  </button>
</ng-template>
