import {inject, Injectable, Injector} from '@angular/core';
import {from, Observable, of, switchMap} from 'rxjs';
import {
  AwsApiWrapperService,
  BsCacheService,
} from '@brightside-web/desktop/data-access/core-services';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ApiCacheService {

  awsApiWrapperService: AwsApiWrapperService;
  bsCacheService: BsCacheService;

  constructor(private injector: Injector) {}

  private getBsCacheService(): BsCacheService {
    if (!this.bsCacheService) {
      this.bsCacheService = this.injector.get(BsCacheService);
    }
    return this.bsCacheService;
  }

  private getAwsApiWrapperService(): AwsApiWrapperService {
    if (!this.awsApiWrapperService) {
      this.awsApiWrapperService = this.injector.get(AwsApiWrapperService);
    }
    return this.awsApiWrapperService;
  }

  get<T>(api: string, key: string, init?: { [key: string]: any }): Observable<T> {
    const bsCacheService = this.getBsCacheService();
    const awsApiWrapperService = this.getAwsApiWrapperService();
    return from(bsCacheService.getItem(key)).pipe(
      switchMap((cached: T) => {
        if (cached) {
          return of(cached);
        } else {
          return from(awsApiWrapperService.get(api, key, init)).pipe(
            tap((response) => {
              bsCacheService.setItem(key, response, {expires: moment().add(30, 'seconds').valueOf()})
            })
          );
        }
      })
    )
  }

  getForced<T>(api: string, key: string, init?: { [key: string]: any }): Observable<T> {
    const bsCacheService = this.getBsCacheService();
    bsCacheService.removeItem(key);
    return this.get<T>(api, key, init);
  }

  async getItem<T>(key: string): Promise<T> {
    const bsCacheService = this.getBsCacheService();
    return await bsCacheService.getItem(key);
  }

  setItem(key: string, value: any) {
    const bsCacheService = this.getBsCacheService();
    bsCacheService.setItem(key, value);
  }

  refreshItem(key: string) {
    const bsCacheService = this.getBsCacheService();
    bsCacheService.removeItem(key);
  }
}
