import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: 'bw-currency-select',
  templateUrl: "currency-select.component.html",
  styleUrls: ["currency-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CurrencySelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CurrencySelectComponent
    }
  ]
})
export class CurrencySelectComponent implements ControlValueAccessor, Validator, OnDestroy, OnInit {
  @Input() fcGroup: FormGroup;
  @Input() fcName = 'currencyInputAmount';

  @Input() increment = 10;
  @Input() suppressKeyboardInput = false;

  private childFormGroup: FormGroup = this.fb.group({
    currencyInputAmount: [null, [Validators.required]]
  });

  quantity = 0;
  touched = false;
  disabled = false;
  
  onChangeSubs: Subscription[] = [];

  onTouched = () => {};

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.suppressKeyboardInput) {
      this.formInput?.disable();
    }
  }

  ngOnDestroy() {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  get form() {
    return this.fcGroup || this.childFormGroup;
  }

  get formInput() {
    return this.form.get(this.fcName);
  }
  get formInputValue() {
    return this.formInput?.value || this.increment;
  }

  /* by = 'increase' 'decrease' */
  private adjustInputValue(by: string) {
    const newValue = this.formInputValue + (by === 'increase' ? this.increment : -this.increment);

    this.markAsTouched();
    this.writeValue(newValue);
    this.formInput?.updateValueAndValidity(newValue);
  }

  writeValue(value: any) {
    if (!this.disabled && this.formInput && value) {
      this.formInput.setValue(value, {emitEvent: false});
    }
  }

  onAdd() {
    this.adjustInputValue('increase');
  }

  onRemove() {
    this.adjustInputValue('decrease');
  }

  registerOnChange(onChange: any) {
    const sub = this.form.valueChanges.subscribe(onChange);

    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const quantity = control.value;
    if (quantity <= 0) {
      return {
        mustBePositive: {
          quantity
        }
      };
    } else {
      return null
    }
  }
}
