<!-- TODO: replace function call w/ string interpolation for fb.directive-->
<div class="content-layout" [brightsideWebFb]="{ event_name: config.fbEventName }">
  <bw-action-article>
    <ui-nav-exit-back *ngIf="!config.pageHideBackButton && !pageIsFullScreen" back-action-top [trackingDetail]="{ event_name: config.fbEventName }" [skipActionEmit]="!config.pageOnBackCtaPath" (exitActionHandler)="handleBackClick()"></ui-nav-exit-back>
    <ui-nav-exit-back *ngIf="!config.pageHideBackButton && pageIsFullScreen" back-action-bottom [trackingDetail]="{ event_name: config.fbEventName }" [skipActionEmit]="!config.pageOnBackCtaPath" (exitActionHandler)="handleBackClick()"></ui-nav-exit-back>

    <div header-title class="mb-sm">
      <typography title-medium>{{ config.pageTitleKey === '' ? config.pageTitle : config.pageTitleKey | translate }}</typography>
    </div>

    <div content-section>
      <p text-medium-regular class="mb-md" color="var(--secondary-text)">
        {{ config.pageSubTitleKey === '' ? config.pageSubTitle : config.pageSubTitleKey | translate }}
      </p>

      <brightside-web-desktop-cards
        *ngIf="config.pageCards.length > 0"
        [cards]="config.pageCards"
        (clicked)="handleCardAction($event)"
      ></brightside-web-desktop-cards>
    </div>

    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="config.pageCtaLabel"
        bw-button
        [brightsideWebFb]="{ event_name: config.fbEventName }
        "
        [processing]="processing"
        (click)="handleCtaClick()"
      >
        {{ config.pageCtaLabel | translate }}
      </button>

      <typography text-large-semibold>
        <a *ngIf="config.pageSecondaryCtaLabel"
           [brightsideWebFb]="{ event_name: config.fbEventName }"
           [secondaryCta]="true"
           bw-anchor class="bs-blue mr-sm" (click)="handleSecondaryCtaClick()">{{
          config.pageSecondaryCtaLabel | translate
        }}</a>
      </typography>
    </div>
  </bw-action-article>
</div>

<bw-modal *ngIf="showProcessError" [brightsideWebFb]="{ event_name: 'error_shown', custom: {'error id': config.fbEventName }}">
  <typography title-medium color="var(--gray-70)">{{ processErrorMessageTitle | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ processErrorMessage | translate }}
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="handleErrorClose()" >
        Ok
      </button>
    </div>
  </footer>
</bw-modal>
