import { AmplitudeService} from "./amplitude.service";
import {Environment} from "@brightside-web/micro/core/environment";
import {FirebaseApp} from "firebase/app";
import {Analytics as FbAnalytics} from "firebase/analytics";
import { RemoteConfig } from "firebase/remote-config";

export interface AnalyticsAttributesV4 {
  event_name: string;
  action?: string;
  custom?: { [key: string]: string }
}

/**
 * Available keys for RemoteConfig.
 * should match up with info at
 * https://healthydollar.atlassian.net/wiki/spaces/EN/pages/2371944477/Remote+Config#Desktop-specific-properties
 */
export const RemoteConfig_UnlockedCaseTypes = 'unlockedCaseTypes';
export const RemoteConfig_DesktopNativeMapping = 'desktop_native_mapping';
export const RemoteConfig_SupportNumber = 'supportNumber';
export const RemoteConfig_BankHolidays = 'bankHolidays';
export const RemoteConfig_Companies = 'companies';
export const RemoteConfig_Savings_Transfer_Reasons = 'microSavingsTransferReasons';


interface ThrottledEvent {
  lastCall: number;
  throttleTime: number;
}

export abstract class FirebaseService {

  abstract events: { [p: string]: ThrottledEvent };

  abstract amplitudeService: AmplitudeService;
  abstract env: Environment;
  abstract lastPageShownEvent: string;
  abstract typeOveride: { [p: string]: Function };
  abstract typeOverRideKeys: string[];

  abstract firebaseApp: FirebaseApp;
  abstract fbRemoteConfig: RemoteConfig;
  abstract fbAnalytics: FbAnalytics;

  abstract isEventThrottled(
    eventName: string,
    throttleTime?: number
  ) : boolean;

  abstract logEvent(
    eventName: string,
    eventParams?: {
      [key: string]: any;
    },
    shouldThrottle?: boolean,
    throttleTime?: number
  ): void;

  abstract setUserId(id: string, options?: any): void;

  abstract clearProperties(): void;

  abstract setProperties(properties: { [key: string]: string }): void;
  // see https://firebase.google.com/docs/remote-config/get-started?platform=web#web-version-8
  abstract setupRemoteConfig(): void;

  abstract remoteConfig(): RemoteConfig;
  abstract initializeApp(): FirebaseApp;
  abstract getRemoteConfig(): RemoteConfig;
  abstract getAnalytics(): FbAnalytics;
  abstract getValueAsString(valueKey: string): string;
  abstract getValueAsBoolean(valueKey: string): boolean;
  abstract ensureInitialized(): Promise<void>;
  abstract fetchConfig(): Promise<void>
  abstract remoteConfigMapping(): { [key: string]: any };
}
