import { Component, OnInit, Input } from '@angular/core';

export type TextStyleOptions = 'Body1' | 'Body2';

@Component({
  selector: 'bw-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})
export class ParagraphComponent  {
  /** The text style: Body1 | Body2 */
  @Input() textStyle: TextStyleOptions = 'Body1';

  /** The space between the bottom of this element and the top of its subsequent sibling */
  @Input() verticalSpace = '24';

  /** Whether the text is centered */
  @Input() center: boolean;

  /** The text color of the paragraph */
  @Input() color: string;

  constructor() {}

}
