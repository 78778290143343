export interface OAuthEnvironment {
  id: string;
  url: string;
  redirectBaseUrl: string;
  scope: string[];
  companies: string[];
  config: { [key: string]: OAuthCompanyConfig };
}

export interface OAuthCompanyConfig {
  name: string;
  provider: string;
}

export interface PerformanceSentryConfig {
  environment: string;
  dsn: string;
}

interface LaunchDarklyInitInterface {
  clientSideId: string,
}

/**
 * PLEASE DO NOT ADD ANYTHING HERE THAT CAN BE FETCHED FROM FIREBASE REMOTE CONFIG
 * THIS SHOULD ONLY BE FOR INITIALIZING SERVICES THAT CANNOT DEPEND ON FRC BEING AVAILABLE YET
 */
export abstract class Environment {
  awsConnectWidgetId: string;
  awsConnectSnippetId: string;
  production: boolean;
  baseEnv: string;
  awsmobile: { [key: string]: any };
  amplitude: { [key: string]: any };
  performance: { sentry: PerformanceSentryConfig };
  oauth: OAuthEnvironment;
  firebase: { [key: string]: string };
  intercomAppId: string;
  crmUrl: string;
  cdnUrl: string;
  googleTagManagerId: string;
  googleTrackingId: string;
  hostedDomain: string;
  sprigId: string;
  featureFlags: { [key: string]: boolean };
  appVersion?: string;
  featureFlagApp: LaunchDarklyInitInterface;
  smfaDynamicLink: string;
  socure: string;
  auth0: Auth0Interface;
  chat?: { [key: string]: string };
}

interface Auth0Interface {
  domain?: string;
  clientId?: string;
  audience?: string;
  clientSecret?: string;
}

export class CacheKeys {
  static FeedToast = 'FEED_TOAST';
  static FeedSuccessToad = 'FEED_SUCCESS_TOAST';
  static Guid = 'GUID';
}
