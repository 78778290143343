<div id="uiHeaderMenu" class="mt-smd mb-md">
  <ng-container *ngTemplateOutlet="headerTemplate; context: { title: title | translate, menu: menu }"></ng-container>
</div>

<ng-template #headerTemplate let-title="title" let-menu="menu">
  <div title class="mt-xs mb-sm">
    <typography title-medium color="var(--primary-text)">{{ title }}</typography>

    <div class="flex-row right pointer">
      <ng-container *ngFor="let exposedItem of menu.exposed">
        <ng-container *ngIf="!menuState.hide.includes(exposedItem.key)">
          <div class="mr-xs" (click)="handleMenuItemSelect(exposedItem)">
            <bw-icon
              [color]="'var(' + exposedItem.iconColor || '--secondary-text' + ')'"
              [iconName]="exposedItem.iconName"
            ></bw-icon>
          </div>
          <div class="mr-md secondary-text hidden-mobile" (click)="handleMenuItemSelect(exposedItem)">
            {{ exposedItem.displayTitle | translate }}
          </div>
        </ng-container>
      </ng-container>

      <bw-icon
        *ngIf="menu.items.length > 0"
        color="var(--secondary-text)"
        [iconName]="menu.iconName || 'ellipses' | translate"
        [coreTracking]="{ event_name: trackingDetail + '_menu_toggle' }"
        (click)="handleMenuToggleOpenClose($event)"
      ></bw-icon>
      <bw-menu #actionMenu>
        <ng-container *ngFor="let item of menu.items">
          <a
            *ngIf="!menuState.hide.includes(item.key)"
            bw-anchor
            anchorStyle="text-medium-regular"
            [color]="item.color"
            (click)="handleMenuItemSelect(item)"
          >
            {{ item.displayTitle | translate }}
          </a>
        </ng-container>
      </bw-menu>
    </div>
  </div>
</ng-template>
