import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { MessageBusEventChannel, MessageBusOutgoingEventKey } from '../../models/message-bus.enum';
import {
  MessageBusEventData,
  MessageBusInternalHubEvent,
  MessageBusOutgoingEvent,
  MessageBusOutgoingEventData,
} from '../../models/message-bus.interface';

//No other class should use this directly.
export class MessageBusPlatformNativeEventBody {
  event: MessageBusOutgoingEventKey;
  data: MessageBusEventData | MessageBusOutgoingEventData = {};

  constructor(
    event: MessageBusOutgoingEventKey,
    data: MessageBusEventData | MessageBusOutgoingEventData = {},
    moveBodyNode: boolean = false
  ) {
    this.event = event;
    this.data = data;

    if (moveBodyNode && data.body) {
      this.data = { ...(data as any).body };

      this.data = this.deepInstantHtmlCopySwap(this.data);
    }
  }


  private deepInstantHtmlCopySwap(processObject: Record<string, any>): any {
    if (!processObject || typeof processObject !== 'object') {
      return processObject;
    }

    let rtnObj = {};

    Object.keys(processObject).map((key: string) => {
      let value = (processObject as any)[key];

      if (value) {
        if (Array.isArray(value)) {
          value = value.map((arryValue) => this.deepInstantHtmlCopySwap(arryValue));
        } else if (key === 'code') {
          value = value;
        } else if (typeof value === 'object') {
          value = this.deepInstantHtmlCopySwap(value);
        } else if (typeof value === 'string') {
          value = value.replace(/<br>/g, '\n');
        }
      }

      rtnObj = { ...rtnObj, [key]: value };
    });

    return rtnObj;
  }

  stringify(): string {
    return JSON.stringify(this as object);
  }

  toJson(): object {
    return this as object;
  }
}

/**
 * @description
 * This is the base class any new platform should extend to ensure coverage of
 * needed methods and values.
 */
export class MessageBusPlatformBaseMessageHandler {
  protected allowVerboseDebugMode = true;
  protected allowedBodyKeys = ['path', 'route'];

  constructor() {}

  protected logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode) console.log('debug: MessageBusService Debugger', message, optionalParams);
  }

  protected blockedMessageReceiver(event: MessageBusOutgoingEvent) {
    this.logForDebugging('MessageReceiver was a noop and your message was not delivered');
    this.logForDebugging(event);
  }

  protected cleanDataBody(body: MessageBusOutgoingEventData = {}) {
    return this.allowedBodyKeys.reduce((cleanedResponse, bodyKey) => {
      if (!body[bodyKey]) return cleanedResponse;

      return { ...cleanedResponse, [bodyKey]: body[bodyKey] };
    }, {});
  }

  protected prepareNativeEvent(event: MessageBusOutgoingEvent, moveBodyNode: boolean = false) {
    if (event?.bridgeSetting?.data) {
      event.data = {
        body: event?.bridgeSetting?.data as MessageBusOutgoingEventData,
      };
    } else if (event?.data?.body) {
      event.data.body = this.cleanDataBody(event.data.body as MessageBusOutgoingEventData);
    }

    return new MessageBusPlatformNativeEventBody(event.key, event.data, moveBodyNode);
  }

  protected get messageReceiver() {
    return this.blockedMessageReceiver;
  }

  protected sendInternalHubEvent(channel: MessageBusEventChannel, eventData: MessageBusInternalHubEvent) {
    BsHubService.dispatchStatic(channel, eventData);
  }
}
