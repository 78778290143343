import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type GenericObject = Record<string, any>;

/**
 * This service is available as a util for micro application as a way
 * to add additional copy that is stored locally in the micro app library
 * asset folder.
 */
@Injectable({
  providedIn: 'root',
})
export class MicroUtilTranslationService extends TranslateService implements TranslateService {
  static Language = {
    English: { code: 'en', label: 'English' },
    Spanish: { code: 'es', label: 'Spanish' },
    Testing: { code: 'zz', label: 'Testing' },
  };
  static SupportedLanguages = [
    MicroUtilTranslationService.Language.English.code,
    MicroUtilTranslationService.Language.Spanish.code,
    MicroUtilTranslationService.Language.Testing.code,
  ];

  /**
   * If you wish to have developer logging turn this on
   *
   * Default: true
   */
  private allowVerboseDebugMode = true;

  /**
   * Override this if you want your template to log
   * with certain prefix string
   *
   * Default: 'MicroUtilTranslationService - '
   */
  private logPrefix = 'MicroUtilTranslationService - ';

  private logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode) console.log(`debug: ${this.logPrefix || '<notSetUp>'}`, message, optionalParams);
  }

  /**
   * This is a passthrough to the underlying translation service that will return
   * the current (or if undefined the default) lang code
   */
  get currentCurrentLanguageCode(): string {
    return this.currentLang || this.defaultLang;
  }

  setLanguageAs(newLang: string) {
    this.logForDebugging('Setting language to ', newLang);

    if (MicroUtilTranslationService.SupportedLanguages.includes(newLang)) {
      this.use(newLang);
    }
  }

  /**
   * This will update (append) translation data to a specific
   * lang code.
   *
   * @param forLang <string>
   * @param translations <{ [key: string]: string }>
   *
   * @returns void
   */
  updateTranslationsWith(forLang: string, translations: { [key: string]: string }) {
    this.setTranslation(forLang, translations, true);
  }

  /**
   * Used by modules to pass in all there translation information on lazy loaded
   *
   * @param translationsByCode <{ [key: string]: { [key: string]: string } }>
   *
   * @returns void
   */
  updateTranslations(translationsByCode: { [key: string]: { [key: string]: string } }) {
    this.logForDebugging('appendCopy with', translationsByCode);

    Object.entries(translationsByCode).forEach(([code, translations]) => {
      this.updateTranslationsWith(code, translations);
    });
  }

  /**
   * This should be utilized from the module class for your micro application
   * only if you have local copy to add into the translation mapping.
   *
   * @param translations { [key: string]: string }
   */
  updateTranslate(translations: { [key: string]: string }): void {
    this.logForDebugging('updateTranslate with', { currentLang: this.currentLang, translations });

    this.setTranslation(this.currentLang, translations, true);
    this.setLanguageAs(this.currentLang);
  }

  /**
   * Utilize this to traverse a template object and look to replace
   * copyKeys with pre-rendered text. This can be used to replace dynamic
   * values in the copy string since the template will not have any data
   * to do this.
   *
   * @param processObject type GenericObject = Record<string, any>;
   * @param replaceMapping { [key: string]: string } - key (Should be the copyKey) : value (Should be the display copy you want shown)
   * @returns <any>
   */
  deepCopyKeySwapForDynamicValue(processObject: GenericObject, replaceMapping: { [key: string]: string }): any {
    if (!processObject || typeof processObject !== 'object') {
      return processObject;
    }

    const replaceKeys = Object.keys(replaceMapping);

    let rtnObj = {};

    Object.keys(processObject).map((key: string) => {
      let value = (processObject as any)[key];

      if (value) {
        if (Array.isArray(value)) {
          value = value.map((arryValue) => this.deepCopyKeySwapForDynamicValue(arryValue, replaceMapping));
        } else if (replaceKeys.includes(value)) {
          value = replaceMapping[value];
        } else if (typeof value === 'object') {
          value = this.deepCopyKeySwapForDynamicValue(value, replaceMapping);
        }
      }

      rtnObj = { ...rtnObj, [key]: value };
    });

    return rtnObj;
  }
}
