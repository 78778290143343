<form [formGroup]="surveyGroupForm">
  <div class="survey-selector__container">
    <ng-container *ngFor="let dataItem of data">      
      <div
        *ngIf="!dataItem.hidden"
        class="survey-selector"
        [ngClass]="{ 'survey-selector--active': dataItem.checked }"
        (click)="onSurveySelectorClick($event, dataItem, dataItem[valueKey])"
      >
        <div
          *ngIf="dataItem.label"
          class="survey-selector__label"
          [attr.disabled]="dataItem.disabled || disabled"
          [ngClass]="{ 'survey-selector__label--active': dataItem.checked }"
        >
          {{ dataItem.label }}
        </div>
        <div class="survey-selector__header">
          <div
            class="survey-selector__header-title"
            [ngClass]="{ 'survey-selector__header-title--active': dataItem.checked }"
            [attr.disabled]="dataItem.disabled || disabled"
          >
            {{ dataItem.title }}
          </div>
          <ng-container [ngSwitch]="selectorType">
            <bw-checkbox
              #checkboxElem
              *ngSwitchCase="SurveySelectorType.Checkbox"
              [rounded]="true"
              [disabled]="dataItem.disabled || disabled"
              [singleMargin]="true"
              [formControl]="dataItem.formControl"
              [value]="dataItem[valueKey]"
            ></bw-checkbox>
            <bw-radio
              #radioElem
              *ngSwitchCase="SurveySelectorType.Radio"
              [disabled]="dataItem.disabled || disabled"
              [name]="selectorName"
              [singleMargin]="true"
              [formControlName]="surveyGroupFormKey"
              [value]="dataItem[valueKey]"
            ></bw-radio>
            <div
              *ngSwitchCase="SurveySelectorType.Instant"
              [attr.disabled]="dataItem.disabled || disabled"
            >
              <bw-icon
                iconName="chevron_right"
                size="24"
                [color]="dataItem.checked ? 'var(--blue-50)' : 'var(--gray-50)'"
              ></bw-icon>
            </div>
          </ng-container>
        </div>
        <typography
          *ngIf="dataItem.description"
          text-small-regular
          class="secondary-text mt-sm survey-selector__body"
          [attr.disabled]="dataItem.disabled || disabled"
          >{{ dataItem.description }}
        </typography>
      </div>
    </ng-container>
  </div>
</form>
