<bw-panel>
  <section class='flex-column'>
      <ng-container *ngIf='submitted'>

          <header>
            <typography title-medium class="mb-sm" >Thanks, {{firstName.value}}!</typography>
          </header>
          <section class='flex-grow'>
            <p text-large-regular class="secondary-text mb-md">You'll receive an email when Brightside becomes available to setup your account</p>
            <div class="mb-md">
              &nbsp;
            </div>
            <div class='center'>
              <img src="../assets/waitlist.svg" alt="Airplane man" width="222" class="mb-xs">
            </div>
            <div class="mb-xl"></div>
            <!-- can the button be at the bottom? -->
          </section>
          <footer>
            <div class="flex-column full-width">
              <button
                bw-button
                fluidWidth
                type="submit"
                (click)='clearThankYou()'
                [disabled]="false"
                >
                Close
              </button>

            </div>
          </footer>

      </ng-container>
      <ng-container *ngIf='!submitted'>
        <header>
          <typography title-medium class="mb-sm" *ngIf='!submitted'>Join the waiting list</typography>
        </header>
        <form [formGroup]="waitlistForm" (ngSubmit)="onSubmit()">
      <section>
        <p text-large-regular class="secondary-text mb-md">Please enter your details to be notified when Brightside is available to you</p>

        <bw-input
          label="First Name"
          placeholder=""
          formControlName="firstName"
          [inputError]="firstName?.invalid && firstName?.touched"
          inputErrorText="Please enter your first name."
          minlength="2"
        >
        </bw-input>
        <bw-input
          label="Last Name"
          placeholder=""
          formControlName="lastName"
          [inputError]="lastName?.invalid && lastName?.touched"
          inputErrorText="Please enter your last name."
          minlength="2">
        </bw-input>
        <bw-input
          label="Personal Email"
          placeholder=""
          type="email"
          required="true"
          formControlName="email"
          [inputError]="email?.invalid && email?.touched"
          inputErrorText="Please enter a valid email address."
          [pattern]="email_pattern"
        >
        </bw-input>
        <bw-input
          label="Mobile Number"
          [formControlName]="'phone'"
          type="text"
          inputMask="000-000-0000"
          pattern="\d{3}-\d{3}-\d{4}"
          minlength="12"
          maxlength="12"
          >
        </bw-input>
      </section>
        <div class="mb-md"></div>
      <footer>
        <div class="flex-column full-width">
          <button
            bw-button
            fluidWidth
            type="submit"
            [processing]="processing"
            [disabled]="waitlistForm.invalid"
            >
            Submit
          </button>

        </div>
      </footer>
        </form>
      </ng-container>

  </section>
</bw-panel>
