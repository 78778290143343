import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { MessageBusIncomingEventKey, MessageBusOutgoingEventKey, MessageBusEventChannel } from '../../models/message-bus.enum';
import { MessageBusOutgoingEvent, HubPayload } from '../../models/message-bus.interface';

import { MessageBusInternalService } from '../../internal/message-bus-internal.service';
import { MessageBusPlatformBaseMessageHandler } from './platform-base.service';

/**
 * jsHandler = How Android application can speak with this application when deployed inside their webview
 */
// eslint-disable-next-line no-var
declare var jsHandler: any;

const IsRunningAndroid = () => typeof jsHandler !== 'undefined';

/**
 * @description
 * Android specific service
 */
class MessageBusPlatformAndroidMessageHandler extends MessageBusPlatformBaseMessageHandler {
  static TriggerCameraEvent() {
    BsHubService.dispatchStatic(MessageBusEventChannel.OUTGOING, {
      event: MessageBusOutgoingEventKey.SHOW_CAMERA,
      data: {
        body: {},
      },
    });
  }

  static SetupCameraEventWatcher(element: HTMLInputElement) {
    /**
     * This watcher will wait for events from the camera
     */
    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INCOMING,
      filterByEvents: [MessageBusIncomingEventKey.CAMERA_RESPONSE],
      take: 1,
      callbackListener: (payload: HubPayload) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const imageSrc = payload.data.body.rawMessage;

        (element.onchange as any)(new Event('fileChange'), imageSrc);
      },
    });
  }

  static TriggerPickerEvent() {
    BsHubService.dispatchStatic(MessageBusEventChannel.OUTGOING, {
      event: MessageBusOutgoingEventKey.SHOW_PHOTO_PICKER,
      data: {
        body: {},
      },
    });
  }

  static SetupPickerEventWatcher(element: HTMLInputElement) {
    /**
     * This watcher will wait for events from the camera
     */
    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INCOMING,
      filterByEvents: [MessageBusIncomingEventKey.CAMERA_RESPONSE],
      take: 1,
      callbackListener: (payload: HubPayload) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const imageSrc = payload.data.body.rawMessage;

        (element.onchange as any)(new Event('fileChange'), imageSrc);
      },
    });
  }

  constructor() {
    super();

    HTMLInputElement.prototype.click = new Proxy(HTMLInputElement.prototype.click, {
      apply: function (target, element: HTMLInputElement, receiver) {
        //If we are an input file type with camera set up, trigger show camera
        if (element.type === 'file') {
          if (element.getAttribute('capture') === 'camera') {
            MessageBusPlatformAndroidMessageHandler.TriggerCameraEvent();
            MessageBusPlatformAndroidMessageHandler.SetupCameraEventWatcher(element);
          } else {
            MessageBusPlatformAndroidMessageHandler.TriggerPickerEvent();
            MessageBusPlatformAndroidMessageHandler.SetupPickerEventWatcher(element);
          }
        } else {
          //This calls original click method
          Reflect.apply(target, element, receiver);
        }
      },
    });
  }

  override get messageReceiver() {
    return (event: MessageBusOutgoingEvent) => {
      if (event.key === MessageBusOutgoingEventKey.ROUTE && !event.data?.forceBridgeFire) {
        this.handlePreBridgeRouteEvent(event);
        return;
      }
      return jsHandler.postMessage(this.prepareNativeEvent(event, true).stringify());
    }
  }

  private handlePreBridgeRouteEvent(event: MessageBusOutgoingEvent) {
    let routeToUrl = '';

    if (event.fallbackSetting?.routeToUrl) {
      routeToUrl = event.fallbackSetting.routeToUrl as string;
    } else if (event?.data?.routeToUrl) {
      routeToUrl = event.data.routeToUrl as string;
    } else if (event.data?.body && ((event.data.body as any).route as string)) {
      routeToUrl = (event.data.body as any).route as string;
    }

    this.triggerRouteByUrlEvent(event, routeToUrl);
  }

  private triggerRouteByUrlEvent(event: MessageBusOutgoingEvent, routeToUrl: string = '') {
    BsHubService.dispatchStatic('RoutingServiceChannel', {
      event: 'ROUTE_BY_URL', //These hard coded values need to come from util but doing so breaks builds...
      data: {
        routeToUrl,
        originalEvent: event
      },
    });
  }
}

export { IsRunningAndroid, MessageBusPlatformAndroidMessageHandler };
