import {Injectable} from '@angular/core';
import {BsApiService} from "@brightside-web/desktop/data-access/core-services";
import {map, take} from "rxjs/operators";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";

export enum DocumentsKeyEnum {
  SAVINGS_ACCOUNT = "savings_account_creation",
  SPENDING_ACCOUNT = "spending_account_creation",
  CASH_ADVANCE = "cash_advance_creation",
  Kashable = "kashable_loan_creation",
  SalaryFinance = "salary_finance_loan_creation",
  TrueConnect = "true_connect_loan_creation",
  CREDIT_LINKING = "credit_linking",
}

export interface LegalDocsResponsePayloadItems {
  titleCopyKey: string;
  descriptionCopyKey: string;
  downloadUrlCopyKey: string;
  downloadUrl: string;
  documentVersionId: number;
}

export interface LegalDocsResponsePayload {
  legalDocuments: LegalDocsResponsePayloadItems[];
}

export interface LegalDocsResponse {
  payload?: LegalDocsResponsePayload;
}

interface LegalDocsGetRequired {
  documentsKey: DocumentsKeyEnum;
}

enum LegalDocsAcceptActions {
  ACCEPTED= 'ACCEPTED',
  DECLINED = 'DECLINED'
}

interface LegalDocsAccept {
  validateByDocumentsKey: DocumentsKeyEnum;
  documentVersionIds: number[];
  action: LegalDocsAcceptActions
}

@Injectable()
export class LegalDocumentsService {

  apiPath = '/legal-docs';
  apiName = 'api-mobile';
  activeDocumentsKey: DocumentsKeyEnum;
  activeRequiredDocuments: LegalDocsResponsePayloadItems[];

  private _requiredDocuments = new Subject<LegalDocsResponsePayloadItems[]>();
  public readonly requiredDocuments: Observable<LegalDocsResponsePayloadItems[]> = this._requiredDocuments.asObservable();

  private _processing = new BehaviorSubject<boolean>(false);
  public readonly processing: Observable<boolean> = this._processing.asObservable();

  private _displayGeneralError = new BehaviorSubject<boolean>(false);
  public readonly displayGeneralError: Observable<boolean> = this._displayGeneralError.asObservable();

  private _docsSubmitted = new Subject<boolean>();
  public readonly docsSubmitted: Observable<boolean> = this._docsSubmitted.asObservable();

  private _displayDocsRequiredModal = new BehaviorSubject<boolean>(false);
  public readonly displayDocsRequiredModal: Observable<boolean> = this._displayDocsRequiredModal.asObservable();

  constructor(
    private bsAPI: BsApiService
  ) { }

  getDocsByKey(documentsKey: DocumentsKeyEnum): Observable<LegalDocsResponse> {
   return this.bsAPI.get<LegalDocsResponse>(
     this.apiName,
     `${this.apiPath}/required?documentsKey=${documentsKey}`,
     {cache:true});
  }

  getRequiredDocuments(documentsKey: DocumentsKeyEnum) {
    this.hideAdditionalDocsModal();
    this._processing.next(false);

    this.getDocsByKey(documentsKey)
      .pipe(
        take(1),
        map(resp => {
          if (resp.payload?.legalDocuments && resp.payload.legalDocuments.length > 0) {
            resp.payload.legalDocuments = this._uppercaseCopyKeys(resp.payload.legalDocuments);
          }
          return resp;
        })
      )
      .subscribe((resp) => {

        if (resp.payload) {
          this.activeDocumentsKey = documentsKey;
          this.activeRequiredDocuments = resp.payload.legalDocuments;
          this._requiredDocuments.next(resp.payload.legalDocuments);
        }
        if (resp.payload?.legalDocuments.length === 0) {
          this._docsSubmitted.next(true);
        }
      });
  }

  acceptRequiredDocuments() {
    this._processing.next(true);

    this.bsAPI.post<LegalDocsResponse>(this.apiName, `${this.apiPath}/action`, <LegalDocsAccept>{
      validateByDocumentsKey: this.activeDocumentsKey,
      action: LegalDocsAcceptActions.ACCEPTED,
      documentVersionIds: this._getDocumentIdArray()
    }).pipe(take(1))
      .subscribe(resp => {
          switch (resp.result.code) {
            case 'ADDITIONAL_LEGAL_DOCS_REQUIRED':
              this._processing.next(false);
              this.showAdditionalDocsModal();
              break;
            case 'SERVICE_OK':
              this._processing.next(false);
              this._docsSubmitted.next(true);
              break;
            default:
              //todo replace w/ Sentry logging?
              throw new Error('Unhandled Response code');
              break;
          }
        },
        (error) => {
          this._processing.next(false);
          this._docsSubmitted.next(false);
          this.showGeneralError();
        });
  }

  /**
   * not yet fully implemented; meant for legal under profile/settings
   **/
  getAcceptedDocuments(): Observable<LegalDocsResponse> {
    return this.bsAPI.get<LegalDocsResponse>(this.apiName, `${this.apiPath}/accepted`);
  }

  private _getDocumentIdArray(): number[] {
    const documentIdArray: number[] = [];
    this.activeRequiredDocuments.forEach(doc => {
      documentIdArray.push(doc.documentVersionId);
    });
    return documentIdArray;
  }

  private _uppercaseCopyKeys(legalDocs: LegalDocsResponsePayloadItems[]): LegalDocsResponsePayloadItems[] {
    legalDocs.forEach(doc => {
      doc.titleCopyKey = doc.titleCopyKey ? doc.titleCopyKey.toUpperCase() : '';
      doc.descriptionCopyKey = doc.descriptionCopyKey ? doc.descriptionCopyKey.toUpperCase() : '';
      doc.downloadUrlCopyKey = doc.downloadUrlCopyKey ? doc.downloadUrlCopyKey.toUpperCase() : '';
    });
    return legalDocs;
  }

  showAdditionalDocsModal() {
    this._displayDocsRequiredModal.next(true);
  }
  hideAdditionalDocsModal() {
    this._displayDocsRequiredModal.next(false);
  }
  showGeneralError() {
    this._displayGeneralError.next(true);
  }
  hideGeneralError() {
    this._displayGeneralError.next(false);
  }
}
