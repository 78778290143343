<ng-container *ngIf="displayLangSelector">
  <ng-container *ngIf="!displayDropdown else dropdownTemplate">
    <a (click)="changeLangTo(displayedOption.locale)">{{ displayedOption.displayName }}</a>
  </ng-container>
</ng-container>

<ng-template #dropdownTemplate>
  <bw-input
    *ngIf="displayLangSelector && displayedOption"
    [label]="'PREFERRED_LANGUAGE' | translate"
    isCustomControl="true"
    [suffixIconName]="'pen'"
    [showSuffixIcon]="true"
  >
    <input
      (focus)="hasFocus = true;"
      (blur)="hasFocus = false;"
      [value]="displayedOption.displayName"
      #langSelectInput
    />
    <div class="list-container">
      <div [@slideInOut]="hasFocus === true ? 'in' : 'out'"
           class="list-display-container">
        <ul>
          <li *ngFor="let lang of langSelectSvc.supportedLanguages; let idx = index" class="item">
            <bw-cell class="address-cell"
                     [iconNameRight]="'chevron_right'"
                     [contentLeft]="true"
                     separatorType="full"
                     (clicked)="changeLangTo(lang.locale, true)">
              <ng-container content-left>
                <typography text-medium-regular>
                  <p>{{ lang.displayName }}</p>
                </typography>
              </ng-container>
            </bw-cell>
          </li>
        </ul>
      </div>
    </div>
  </bw-input>

</ng-template>
