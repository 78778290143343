import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FirebaseService } from '@brightside-web/desktop/data-access/shared';
import { MessageBusEventChannel, MessageBusInternalEventKey, MessageBusInternalService } from '@micro-core/message-bus';

type OpenValueType = string | boolean | number | any[];

interface SimpleKeyValueMapping {
  [key: string]: OpenValueType | unknown;
}

export interface RemoteConfigMicroApp {
  config?: SimpleKeyValueMapping;
  development?: { [key: string]: { [key: string]: string } };
  queryParam?: { [key: string]: string };
}

export interface RemoteConfigDomainRules {
  rules: RemoteConfigDomain[];
}

export interface RemoteConfigDomain {
  regex: string;
  domain: string;
  product: string;
}

/**
 * Available keys for RemoteConfig.
 * should match up with info at
 * https://healthydollar.atlassian.net/wiki/spaces/EN/pages/2371944477/Remote+Config#Desktop-specific-properties
 */
export enum RemoteConfigKey {
  'BANK_HOLIDAYS' = 'bankHolidays',
  'COMPANIES' = 'companies',
  'DESKTOP_NATIVE_MAPPING' = 'desktop_native_mapping',
  'DOMAIN_MAPPING' = 'domainMappingPaths',
  'MICRO_ACCOUNTS' = 'microAppAccounts',
  'MICRO_CONTEST' = 'microAppContest',
  'MICRO_HELPING_HANDS' = 'microAppHelpingHands',
  'MICRO_MPAP' = 'microAppMpap',
  'PRODUCT_CONFIG' = 'productConfig',
  'SAVINGS_TRANSFER_REASONS' = 'microSavingsTransferReasons',
  'SUPPORT_NUMBER' = 'supportNumber',
  'SUPPORTED_LANGUAGES' = 'supportedLanguages',
  'UNLOCKED_CASE_TYPES' = 'unlockedCaseTypes',
}

export enum RemoteConfigFeatureFlagKey {
  'USER_CREATION' = 'savingsCreationMicroApp_enabled' /* TODO remove this */,
}

@Injectable({
  providedIn: 'root',
})
export class MicroCoreUtilRemoteConfigService {
  constructor(private firebaseService: FirebaseService) {
    console.log('debug: MicroCoreUtilRemoteConfigService loaded');
    this.getDomainMappingPaths();
  }

  private getDomainMappingPaths() {
    this.configAsObjectObservable<RemoteConfigDomainRules>(RemoteConfigKey.DOMAIN_MAPPING)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log('debug: RemoteConfigDomainRules loaded');

          MessageBusInternalService.sendInternalHubEvent(
            {
              event: MessageBusInternalEventKey.DOMAIN_MAPPING_LOADED,
              data: { rules: result?.rules },
            },
            MessageBusEventChannel.INTERNAL
          );
        },
        () => console.log('debug: RemoteConfigDomainRules failed')
      );
  }

  getValueAsString(valueKey: string) {
    return this.firebaseService.getValueAsString(valueKey);
  }

  configAsObject<T>(configKey: RemoteConfigKey): T | undefined {
    try {
      return JSON.parse(this.getValueAsString(configKey)) as T;
    } catch (e) {
      //console.error(e);

      return undefined;
    }
  }

  configAsObjectObservable<T>(configKey: RemoteConfigKey): Observable<T | undefined> {
    return new Observable<T | undefined>((responseSubscriber) => {
      const runAttempt = () => {
        const result = this.configAsObject<T>(configKey);

        if (result !== undefined) {
          responseSubscriber.next(result);
          responseSubscriber.complete();
        } else {
          setTimeout(() => {
            runAttempt();
          }, 500);
        }
      };

      runAttempt();
    });
  }
}
