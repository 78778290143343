<input
  #cb
  type="checkbox"
  [checked]="checked"
  [disabled]="disabled"
  [value]="value"
  [name]="name"
  [readOnly]="readonly"
  (change)="handleChange($event)"
  (focus)="onFocus()"
  (blur)="onBlur()"
/>

<div class="checkbox-wrapper flex-row" [ngClass]="{'mobile-space-center': centerAlign, 'disabled': disabled}">
  <span #placeholderLabel class="faux-checkbox" [id]="name+value" (click)="onClick($event, cb, true)" role="checkbox" [ngClass]="{'bw-checkbox-single-margin': singleMargin}">
    <ng-container *ngIf="rounded">
      <img [ngClass]="checked? 'rounded-checkbox-checked': 'rounded-checkbox-unchecked'" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iMC4wMTgwMDU0IiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHJ4PSIxMCIgZmlsbD0iIzAwNUZGRiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0Ljc1OTUgNi43NDg0MkMxNC45MDkgNi44OTE2OSAxNC45MTQgNy4xMjg5OSAxNC43NzA3IDcuMjc4NDVMOC41Mzk5NCAxMy43NzYzQzguNDY5MjIgMTMuODUgOC4zNzE0NCAxMy44OTE3IDguMjY5MjMgMTMuODkxN0M4LjE2NzAyIDEzLjg5MTcgOC4wNjkyNSAxMy44NSA3Ljk5ODUyIDEzLjc3NjNMNS4yMjkyOSAxMC44ODg0QzUuMDg1OTcgMTAuNzM4OSA1LjA5MDk5IDEwLjUwMTYgNS4yNDA1IDEwLjM1ODNDNS4zOTAwMSAxMC4yMTUxIDUuNjI3NCAxMC4yMjAxIDUuNzcwNzEgMTAuMzY5NUw4LjI2OTIzIDEyLjk3NTFMMTQuMjI5MyA2Ljc1OTYzQzE0LjM3MjYgNi42MTAxNyAxNC42MSA2LjYwNTE1IDE0Ljc1OTUgNi43NDg0MloiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==" [attr.alt]="checked? 'checked' : 'unchecked' + ' checkbox'">
    </ng-container>
    <ng-container *ngIf="!rounded">
      <img *ngIf="checked" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD1SURBVHgB7ZRtFYIwFIbfcQigDYggDTSIoA00gZBAGwgJjIARbCANXALnZTCVw8eAob94zhnb3WXPDgMuMFEDe4884VN0otEMw0hhIcCZxVW5L+50dWAGR8zmKrC+Eg7MKT21hR9iKk/pYFeyH1meiyN2pX47plyJU2zEAgKXPnKObuIliRM0fL5VOUNMzUX9OX7E2X/RIm6SJ3Jx9UXdSmKGCBqq8ictWotdsYErpQPE9fJ89kiSA8m4lOZiLuc6ijPsxgyjOuEJkDSUcS4O0AO7Nas2UOOe2No7BkgVf6stHOak9XKGPRoKkAZRtAc5QkzoeAHrIk2PxBJn9wAAAABJRU5ErkJggg==" alt="checked checkbox">
      <img *ngIf="!checked" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgB7ZTBDYAgDEVr4wCMIGEwZ3AD3MAVHIzACEwAQoIGezFgTDz0JRBKm//bSwGYB4Y6sNbOIYQtPQX04RBRSyn38wPrbBLXL8QzU2nwYqQF+VJKDdCBMSYCaRDhY9iADdiADf5gQLepT0eUrdiLq4PbBDHGhRY04pPGCkwLB50PIr6oYSc4AAAAAElFTkSuQmCC" alt="unchecked checkbox">
    </ng-container>
  </span>
  <label class="body"
         [innerHTML]="label"
         (click)="onClick($event, cb, true)" *ngIf="label" [attr.for]="name+value">
  </label>
  <label [attr.for]="name+value" *ngIf="!label" class="body" (click)="onClick($event, cb, true)"><ng-content></ng-content></label>
</div>
