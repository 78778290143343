import { Component, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';

import { UseThisForStandaloneOrThat } from '@micro-core/message-bus';

import { UiFooterDefaultData, UiFooterType } from '@micro-ui/molecule';
import { UiTemplateFormContentValueChanged } from '@micro-ui/template/content';

import { UiTemplateAction } from '../shared/model/page-template.enum';
import { UiTemplateStateKey } from '../service/state-handler/state.enum';

import { UiTemplateBaseComponent } from '../shared/base-page-template.component';

import { UiTemplateActionHandler } from '../service/action-handler/action-handler.shared';
import { UiTemplateStateHandlerService } from '../service/state-handler/state-handler.service';

import { UiTemplateFormFlyerInterface } from '../form-flyer/form-flyer-page-template.component';

@Component({
  selector: 'ui-page-half-sheet',
  templateUrl: './half-sheet-page-template.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTemplateHalfSheetComponent extends UiTemplateBaseComponent {
  @Input() override data: UiTemplateFormFlyerInterface;
  @Input() override logPrefix = 'UiTemplateHalfSheetComponent -';
  @Input() override allowVerboseDebugMode = false;

  @Input() state: UiTemplateStateHandlerService;

  /**
   * state handler should be the only way to update/access
   * state.
   *
   * Interface UiTemplateStateHandler
   */
  stateKeys = UiTemplateStateKey;

  //Make sure the nav types can be used in template
  action = UiTemplateAction;

  fromDataAction: UiTemplateActionHandler = new UiTemplateActionHandler();
  fromDataFooter: UiFooterDefaultData;
  fromDataTrackingDetail = { event_name: '' };

  private updateActionsFromData() {
    this.fromDataAction.updateUiComponent(this);
    this.fromDataAction.updateActions(this.data?.actions || {});
  }

  private updateFooterFromData() {
    this.fromDataFooter = {
      inputs: {
        cta1Label: this.data?.inputs?.cta1Label || '',
        cta2Label: this.data?.inputs?.cta2Label || '',
        trackingDetail: this.fromDataTrackingDetail,
        link: this.data?.inputs?.link || '',
        infoText: this.data?.inputs?.infoText || '',
        type: UiFooterType.ROW,
      },
    };
  }

  public templateDataChanged(): void {
    super.templateDataChanged();

    if (!this.data?.inputs) {
      return;
    }

    this.fromDataTrackingDetail.event_name = this.data?.inputs?.viewName || 'halfsheet_modal';

    this.updateActionsFromData();
    this.updateFooterFromData();

    this.logForDebugging('Current State', this.state.getState());
  }

  public formValueChanged(change: UiTemplateFormContentValueChanged) {
    if (change && change.name) {
      this.state.updateWithPartial({ [UiTemplateStateKey.CHILD_MODAL]: { [change.name]: change.value } });
    } else {
      console.warn('UiTemplateFormFlyerComponent Form value was changed but it is missing the name or entire object.');
    }
  }
}
