<bw-modal *ngIf="visible" modalWidth="medium" [showCloseButton]="true" (closeModal)="handleClick(false)">
  <typography title-medium color="var(--primary-text)" class="mb-sm">{{ 'PROGRESS_STEP_CONNECT_CREDIT' | translate }}</typography>
  <section class="body" [brightsideWebFb]="{ event_name: 'credit_details_shown' }">
    <bw-paragraph>
      <typography text-large-regular color="var(--secondary-text)">
        <div [innerHTML]="'CREDIT_WELCOME_DESC' | translate"></div>
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [brightsideWebFb]="{ event_name: 'credit_details_tapped', action: 'click' }"
        (click)="results.emit(true)"
      >
        {{ 'BUTTON_CONTINUE' | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
