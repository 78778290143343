import { Injector } from '@angular/core';

import { UiNavigationExitType } from '@micro-ui/molecule';

import { DeepPartial, UiTemplateState } from '../../service/state-handler/state.interface';

import { UiTemplateBaseActionRoute } from './page-template.interface';

enum UiTemplateNavType {
  NONE = UiNavigationExitType.NONE,
  BACK = UiNavigationExitType.BACK,
  CLOSE = UiNavigationExitType.CLOSE,
}

enum UiTemplateAction {
  cta1 = 'cta1',
  cta2 = 'cta2',
  footerLink = 'footer.link',
  formValueChanged = 'action.form.value.changed',
  headerMenuItem = 'action.menu.select',
  navLeftIcon = 'navLeftIcon',
  stateChanged = 'action.state.changed',
}

enum UiTemplateLifecycleHook {
  templateDataInitLoaded = 'lifecycle.hook.template.data.init',
}

/**
 * These util methods can be used to perform common actions without
 * needing to remain the right information to pass. Can be changed
 * by caller if more details are needed in route.
 *
 * ROUTE_SAME_LEVEL(appendRouteInfo: string[]) -> Will do the step in route to clear modal and ensure
 * we navigate to a path that is the same base level: level1/<current route key> - appendRouteInfo will
 * be appended to level1/<...appendRouteInfo>
 *
 * MODAL_OPEN(modalId: string) -> Used to trigger a modal/halfsheet with the information passed to the
 * flyer template modal section.
 * Example interface: UiTemplateFlyerInterface
 * modals?: { [key: string]: UiTemplateFlyerModal };
 *
 * The modalId you pass should be the key in the above example. This will render out inside a bw-modal.
 *
 * MODAL_CLOSE -> Used to dismiss the modal and clear data from the route snapshot.
 */
const GetUiTemplateActionRoute: { [key: string]: (arg?: any) => UiTemplateBaseActionRoute } = {
  ROUTE_TO: (appendRouteInfo: string[]) => ({
    route: [...appendRouteInfo],
    options: { queryParams: { modal: null }, queryParamsHandling: 'merge' },
  }),
  ROUTE_SAME_LEVEL: (appendRouteInfo: string[]) => ({
    route: ['./', ...appendRouteInfo],
    options: { queryParams: { modal: null }, queryParamsHandling: 'merge' },
  }),
  MODAL_OPEN: (modalId: string) => ({ route: [], options: { queryParams: { modal: modalId }, queryParamsHandling: 'merge' } }),
  MODAL_CLOSE: () => ({ route: [], options: { queryParams: { modal: null }, queryParamsHandling: 'merge' } }),
};

/**
 * Used in action function handlers to help define what the
 * argument for updating state looks like.
 *
 * Expected function is like: UiTemplateStateHandlerService.updateWithPartial
 */
type UiTemplateActionUpdateStateFunctionType = (partialState: DeepPartial<UiTemplateState>) => void;

/**
 * Used when something is needed to utilize the injection but not
 * affect the current template data directly.
 *
 * Arguments
 * injector: Injector,
 *
 */
type UiTemplateActionInjectorFunctionType = (injector: Injector) => void;

/**
 * Utilize this to handle actions that need to act on state information
 * and trigger unrelated event or simple state updates
 *
 * Arguments
 * state?: UiTemplateState,
 * updateState?: UiTemplateActionUpdateStateFunctionType
 *
 * RETURNS <void | null | undefined | UiTemplateBaseActionRoute>
 */
type UiTemplateBaseActionFunction = (
  state?: UiTemplateState,
  updateState?: UiTemplateActionUpdateStateFunctionType
) => void | null | undefined | UiTemplateBaseActionRoute;

/**
 * Utilize this to handle actions that need to act on state information
 * and return a route detail directly to handler.
 *
 * Arguments
 * state?: UiTemplateState,
 * updateState?: UiTemplateActionUpdateStateFunctionType
 *
 * RETURNS <UiTemplateBaseActionRoute>
 */
type UiTemplateActionFunctionReturnRouter = (
  state?: UiTemplateState,
  updateState?: UiTemplateActionUpdateStateFunctionType
) => UiTemplateBaseActionRoute;

/**
 * Anytime you add a new action interface you should include here so
 * it's included as a supported type in interfaces.
 */
type UiTemplateBaseAction = UiTemplateBaseActionRoute | UiTemplateActionFunctionReturnRouter | UiTemplateBaseActionFunction;

export {
  GetUiTemplateActionRoute,
  UiTemplateAction,
  UiTemplateActionFunctionReturnRouter,
  UiTemplateActionInjectorFunctionType,
  UiTemplateActionUpdateStateFunctionType,
  UiTemplateBaseAction,
  UiTemplateBaseActionFunction,
  UiTemplateBaseActionRoute,
  UiTemplateLifecycleHook,
  UiTemplateNavType,
};
