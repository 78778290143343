import { Component, Renderer2, ElementRef, ViewChildren, QueryList, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'bw-reels',
  templateUrl: './reels.component.html',
  styleUrls: ['./reels.component.scss']
})
export class ReelsComponent implements AfterViewInit {
  @Input() reels: any[];
  currentReelIndex: number;
  nextReelIndex: number;
  previousReelIndex: number;
  reelDuration: number;
  currentReelProgress: string;
  reelCount: number;
  currentReelSrc: string;
  vidArray: any[];
  indArray: any[];
  indBgArray: any[];
  durations: number[] = [];

  @ViewChildren('videos') videos: QueryList<ElementRef>;
  @ViewChildren('indicators') indicators: QueryList<ElementRef>;
  @ViewChildren('indBg') indBg: QueryList<ElementRef>;

  constructor(private renderer:Renderer2) {
    this.genReelIndex(0);
  }

  ngAfterViewInit(): void {
    this.indArray = this.indicators.toArray();
    this.vidArray = this.videos.toArray();
    this.indBgArray = this.indBg.toArray();
    this.reelCount = this.videos.length - 1;

    this.indBg.forEach((vEl, i) => {
      this.renderer.setStyle(vEl.nativeElement, 'transition-duration', this.durations[i] + 's');
    });

    this.startAutoplay(0);
  }

  onEnded(e:any, videos:any, i:number) {
    if (this.currentReelIndex !== this.reelCount) {
      this.currentReelProgress = '0';
    }
    // Add 'complete' class to ended reel before moving on to nextReel
    this.renderer.addClass(this.indArray[i].nativeElement, "complete");
    if (this.currentReelIndex < this.reelCount) {
      this.genReelIndex(this.currentReelIndex + 1);
      this.vidArray[this.currentReelIndex].nativeElement.play();
    }
  }

  onMetadata(e: any, video: { duration: any; }) {
    this.reelDuration = video.duration;
    this.durations.push(video.duration);
  }

  buildReelSrc(crn: number) {
    this.currentReelSrc = this.reels[crn].src;
  }

  startAutoplay(num: number) {
    this.vidArray[0].nativeElement.play();
  }

  playingReel(index: any) {
    this.indArray.forEach(vEl => {
      this.renderer.removeClass(vEl.nativeElement, 'current');
    });
    this.renderer.addClass(this.indArray[index].nativeElement, "current");
    
    this.vidArray.forEach(vEl => {
      this.renderer.removeClass(vEl.nativeElement, 'current');
    });
    this.renderer.addClass(this.vidArray[index].nativeElement, "current");
  }

  playForward() {
    // set the current reel id current time to 0 but keep the 'complete' class
    this.resetReel();
    // If the current reel index is lass than the reel count, increnment the reel id and play that one
    if (this.currentReelIndex < this.reelCount) {
      this.genReelIndex(this.currentReelIndex + 1);
      this.vidArray[this.currentReelIndex].nativeElement.play();
    }
    // Loop through all the indicators and add a css class of 'complete' to the previcous indicator element 
    this.indArray.forEach((vEl, vInd) => {
      if (this.currentReelIndex <= vInd) {
        this.renderer.addClass(this.indArray[vInd - 1].nativeElement, "complete");
      }
    });
    
  }

  playBack() {
    // set the current reel id current time to 0, so it plays from the beginning if landed on again
    this.resetReel();
    // if the current reel id is less than or equal to the reel count decrement the current reel id and play that one
    if (this.currentReelIndex <= this.reelCount) {
      this.genReelIndex(this.currentReelIndex - 1);
      this.vidArray[this.currentReelIndex].nativeElement.play();
    }
    // Loop through the indicators and remove the'complete' class if the current reel id is less than or equal video index
    this.indArray.forEach((vEl, vInd) => {
      if (this.currentReelIndex <= vInd) {
        this.renderer.removeClass(vEl.nativeElement, 'complete');
      }
    });
  }

  genReelIndex(ci: number) {
    this.currentReelIndex = ci;
    this.previousReelIndex = this.currentReelIndex - 1;
    this.nextReelIndex = this.currentReelIndex + 1;
  }

  currentReelTime(index: number) {
    const progress = this.vidArray[index].nativeElement.currentTime / this.vidArray[index].nativeElement.duration * 100;
    this.currentReelProgress = progress + '%';
  }

  resetReel() {
    this.vidArray[this.currentReelIndex].nativeElement.pause();
    this.vidArray[this.currentReelIndex].nativeElement.currentTime = 0;
  }
}