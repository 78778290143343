<div class="card-wrapper" [ngClass]="{'has-banner': hasCardBanner}">
  <div *ngIf='isPromoCard'>
    <div class="card-bg" *ngIf="bgImageSrc" [ngStyle]="{'background-image': 'url('+bgImageSrc+')'}"></div>
    <bw-icon *ngIf="closeable" iconName="close" size="24" (click)="handleCloseCard($event)"></bw-icon>
    <typography text-small-regular class="brand mb-sm">
      {{title}}
    </typography>
    <div class="card-body mb-sm">
      <p headline-semibold [ngStyle]="{'color': cardTextColor}">
        <ng-container *ngTemplateOutlet="cardBody"></ng-container>
      </p>
    </div>
    <div class="card-footer">
      <ng-content select="bw-card-footer"></ng-content>
    </div>
  </div>

  <div *ngIf='isActionCard' (click)="handleCardAction($event)">
    <bw-icon iconName="chevron_right" size="24" [color]="invertedColor ? 'var(--white)' : 'var(--gray-50)' "></bw-icon>
    <typography text-small-regular class="mb-sm" [ngStyle]="{'color': invertedColor ? 'var(--white)' : 'var(--brand)'}">
      {{title}}
    </typography>
    <div class="card-body">
      <typography text-large-semibold>
        <ng-container *ngTemplateOutlet="cardBody"></ng-container>
      </typography>
    </div>
  </div>

  <div *ngIf='isInfoCard' (click)="handleCardAction($event)">
    <typography headline-semibold class="mb-xs">
      {{title}}
    </typography>
    <typography text-small-regular class="mb-sm">
      {{subtitle}}
    </typography>
    <div class="card-body">
      <typography text-large-semibold>
        <ng-container *ngTemplateOutlet="cardBody"></ng-container>
      </typography>
    </div>
  </div>

  <div *ngIf='isActiveProductCard' (click)="handleCardAction($event)">
    <div class="flex-row space-between">
      <typography headline-semibold>
        {{title}}
      </typography>
      <bw-icon iconName="chevron_right" size="24" [color]="invertedColor ? 'var(--white)' : 'var(--gray-50)' "></bw-icon>
    </div>
    <typography text-small-regular class="mb-sm">
      {{subtitle}}
    </typography>
    <div class="card-header" class="mb-sm">
      <ng-content select="bw-card-header"></ng-content>
    </div>
    <div class="card-body">
      <typography text-large-semibold>
        <ng-container *ngTemplateOutlet="cardBody"></ng-container>
      </typography>
    </div>
  </div>
</div>
<ng-content select="bw-card-banner"></ng-content>

<ng-template #cardBody>
  <ng-content select="bw-card-body"></ng-content>
</ng-template>
