import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * Use this to search for data in the current and parent routes.
 *
 * <dataKey>: <T>
 *
 * MicroRouteFindDataByKey<T>(routeSnapshot, 'testData');
 *
 * @param routeSnapshot <ActivatedRouteSnapshot>
 * @param dataKey <string> - Should be the key of the data you are looking for
 * @returns T | undefined
 */
const MicroRouteFindDataByKey = <T>(routeSnapshot: ActivatedRouteSnapshot, dataKey: string): T | undefined => {
  if (routeSnapshot.data[dataKey]) {
    return routeSnapshot.data[dataKey];
  }

  if (routeSnapshot.parent) {
    return MicroRouteFindDataByKey(routeSnapshot.parent, dataKey);
  }

  return undefined;
};

export { MicroRouteFindDataByKey };
