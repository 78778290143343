import { Injectable } from '@angular/core';

export interface UiTemplatePageTransitionAnimationLog {
  phase: string; //'start' | 'done'
  from: string;
  to: string;
}

@Injectable({ providedIn: 'root' })
export class UiTemplatePageTransitionService {
  private _lastNavWasReverseExitAnimation = false;
  private _animationStackTrace: UiTemplatePageTransitionAnimationLog[][] = [];

  constructor() {
    (window as any).GetAnimationStack = () => this.animationStackTrace;
  }

  get needsReverseTransition(): boolean {
    return Boolean(this._lastNavWasReverseExitAnimation);
  }

  setNeedsReverseTransition(flag: boolean) {
    this._lastNavWasReverseExitAnimation = flag;
  }

  get animationStackTrace() {
    return this._animationStackTrace;
  }

  insertAnimationStackTrace(animationLogs: UiTemplatePageTransitionAnimationLog[]) {
    this.animationStackTrace.push(animationLogs);
  }
}
