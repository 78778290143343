import { MessageBusIncomingEventKey, MessageBusOutgoingEventKey } from '../../models/message-bus.enum';
import { MessageBusInternalHubEvent } from '../../models/message-bus.interface';

import { MessageBusHalfsheetStyle, MessageBusHalfsheetType } from '../../models/event/halfsheet-event.enum';
import { MessageBusHalfsheetConfig } from '../../models/event/halfsheet-event.interface';

const DEFAULT_HALFSHEET_CONFIG = {
  analytics: {
    category: 'smartwallet',
    subCategory: 'transfer',
  },
  dismissable: true,
  id: '',
  style: MessageBusHalfsheetStyle.DEFAULT,
  title: null,
  subText: null,
  image: null,
  type: MessageBusHalfsheetType.TEXT,
  cells: [],
};

const BuildFallback = (fallbackMessageCode: string) => ({
  incomingEvent: {
    key: MessageBusIncomingEventKey.HALF_SHEET,
    body: { code: fallbackMessageCode },
    rawMessage: `${MessageBusIncomingEventKey.HALF_SHEET}{"code":"${fallbackMessageCode}"}`,
  },
});

/**
 * @description
 * This will help you create a halfsheet bridge event
 *
 * @param config: MessageBusHalfsheetConfig
 * @returns MessageBusInternalHubEvent
 */
export const BuildHalfsheetConfigAndReturnStandardEvent = (
  config: Partial<MessageBusHalfsheetConfig>,
  fallbackMessageCode: string = ''
): MessageBusInternalHubEvent => {
  const fallbackSetting = !fallbackMessageCode ? undefined : BuildFallback(fallbackMessageCode);

  return {
    event: MessageBusOutgoingEventKey.SHOW_HALFSHEET,
    bridgeSetting: {
      data: { ...DEFAULT_HALFSHEET_CONFIG, ...config },
    },
    fallbackSetting,
  };
};
