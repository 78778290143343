import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {AngularFormControlValidationTypes, DisplayFormControlConfig} from "@brightside-web/shared/desktop";
import {FirebaseService, ClientConfirmationErrorCodes as errorCodes} from "@brightside-web/desktop/data-access/shared";

export interface EmployeeIdRules {
  company: string;
  rules: EmployeeIdRule[];
  allowAlreadyExists?: boolean;
  allowDoesNotExist?: boolean;
  consentsKey?: string;
}

export interface EmployeeIdRule {
  regex: string;
  key?: string;
  match?: boolean;
}

export class UniqueIDFormElement {
  aliasRulesArray: any[] = [];
  aliasValidatorRules: EmployeeIdRules;


  public CONTROL_UNIQUE_ID: DisplayFormControlConfig = {
    key: 'uniqueId',
    labelKey: 'ALIAS_TITLE',
    control: new FormControl('',
      [Validators.required]
    ),
    type: 'text',
    errorTextKey: '',
    inputOptions: {
      beginValidationOn: AngularFormControlValidationTypes.DIRTY
    },
    inputInfo: {
      infoKey: 'ALIAS_INFO_BUTTON',
      titleKey: 'BADGE_ID_HELP_HALFSHEET_TITLE',
      bodykey: 'BADGE_ID_HELP_HALFSHEET_DESC',
      ctaKey: 'GOT_IT',
      eventName: 'employee_id_moreinfo'
    }
  };

  constructor(private firebase: FirebaseService) {
  }

  private splitDotJoin(stringToMod: string) : string {
    return stringToMod.toLowerCase().split(' ').join('_');
  }

  public aliasValidator(company: string) : ValidatorFn {

    if (this.aliasRulesArray.length === 0) {
      this.aliasRulesArray = JSON.parse(this.firebase.getValueAsString('employeeIdRules'));
    }

    this.aliasValidatorRules = this.aliasRulesArray.find(ruleSet => this.splitDotJoin(ruleSet.company) === this.splitDotJoin(company));

    return (control: AbstractControl): ValidationErrors | null => {

      let rulesMatch;
      let errorTextKey = '';

      if (this.aliasValidatorRules) {
        const aliasRules = this.aliasValidatorRules as EmployeeIdRules
        rulesMatch = aliasRules.rules.find(rule => {
          if (rule.match) {
            return !control.value.match(rule.regex);
          } else {
            return control.value.match(rule.regex);
          }
        });
      } else {
        return null;
      };

      if (rulesMatch && rulesMatch.key) {
        errorTextKey = rulesMatch.key.toUpperCase();
        this.CONTROL_UNIQUE_ID.errorTextKey = errorTextKey;
      } else {
        this.CONTROL_UNIQUE_ID.errorTextKey = '';
      }

      return rulesMatch ? {forbiddenValue: {value: control.value}, errorTextKey:errorTextKey} : null;
    };
  }

}
