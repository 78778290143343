import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiredLegalDocumentsComponent } from './required-legal-documents/required-legal-documents.component';
import {BrightsideUiModule} from "@brightside/brightside-ui";
import {SharedDesktopModule} from "@brightside-web/shared/desktop";
import { MicroUiMoleculeModule } from "@micro-ui/molecule";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {BrightsideUiServicesModule} from "@brightside/brightside-ui-services";

@NgModule({
  imports: [
    CommonModule,
    BrightsideUiModule,
    BrightsideUiServicesModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedDesktopModule,
    MicroUiMoleculeModule
  ],
  declarations: [
    RequiredLegalDocumentsComponent
  ],
  exports: [
    RequiredLegalDocumentsComponent
  ]
})
export class SharedLegalDocumentsModule {}
