import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export class StateSubject<T> extends BehaviorSubject<T> {
  private initialValue: T;

  constructor(value: T) {
    super(value);
    this.initialValue = value;
  }

  get value$(): Observable<T> {
    //ToDo: Need deep matching here
    return super.asObservable();
  }

  update(value: T): void {
    this.next(value);
  }

  reset(): void {
    this.next(this.initialValue);
  }
}
