import { Injectable } from '@angular/core';

import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { AmplitudeService } from './tracking/amplitude.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingClientService extends AmplitudeService {
  constructor(
    private bsHubService: BsHubService
  ) {
    super();
  }

  private logWithBridge(eventName: string, eventProperties?: { [key: string]: string }) {
    const data: { event: string; action: string; parameters?: { [key: string]: string } } = { event: eventName, action: '' };

    if (eventProperties) {
      data.parameters = eventProperties;
    }

    return {
      event: 'ANALYTICS',
      data,
    };
  }

  override logEvent(eventName: string, eventProperties?: { [key: string]: string }) {
    eventName = eventName.split('_').join(' ').toLowerCase();

    this.bsHubService.dispatch('MessageBusOutgoingChannel', this.logWithBridge(eventName, eventProperties));
  }
}
