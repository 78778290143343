import { Injectable, Injector} from '@angular/core';
import {BehaviorSubject, filter, from, Observable, of, switchMap, take} from 'rxjs';
import { ApiCacheService} from './api-cache.service';
import {
  ApiResponse,
  MobileStateResponse,
  MobileStateActionRequest,
} from './api-contracts';

import {
  AwsApiWrapperService
} from "@brightside-web/desktop/data-access/core-services";

import {HttpErrorResponse} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {EligibleProduct} from "./api-enums";

@Injectable({
  providedIn: 'root',
})
export class MobileStateService {

  private apiCache: ApiCacheService;
  private awsApiWrapperService: AwsApiWrapperService;

  private _mobileState: BehaviorSubject<string> = new BehaviorSubject<string>('')
  public readonly mobileState: Observable<string> = this._mobileState.asObservable();

  private _mobileStateSubject$: BehaviorSubject<MobileStateResponse | null> = new BehaviorSubject<MobileStateResponse | null>(null);
  private _fetchingMobileState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor (private injector: Injector) {}

  private getApiCacheService(): ApiCacheService {
    if (!this.apiCache) {
      this.apiCache = this.injector.get(ApiCacheService);
    }
    return this.apiCache;
  }

  private getAwsApiWrapperService(): AwsApiWrapperService {
    if (!this.awsApiWrapperService) {
      this.awsApiWrapperService = this.injector.get(AwsApiWrapperService);
    }
    return this.awsApiWrapperService;
  }

   getData() {
     const error = new HttpErrorResponse({status: 422});
    return of(error) as any;
  }

  get(force = false): Observable<MobileStateResponse> {
    // Check if another service is already fetching data
    if (this._fetchingMobileState$.value) {
      // Wait for the existing call to complete and return its result
      return this._mobileStateSubject$.pipe(
        switchMap(state => {
          if (state) {
            return of(state); // Return cached value if available
          } else {
            return this._mobileStateSubject$.asObservable(); // Otherwise, wait for next update
          }
        }),
        filter((state: any) => !!state), // Ensure non-null state is returned
        take(1) // Take only one emitted value
      );
    }

    // Set the fetching state to true to lock other requests
    this._fetchingMobileState$.next(true);

    const apiCache = this.getApiCacheService();

    if (force) {
      apiCache.refreshItem('/client/mobilestate');
    }

    // Make the API call
    return apiCache.get<MobileStateResponse>('api-mobile', '/client/mobilestate').pipe(
      tap(ms => {
        this._mobileStateSubject$.next(ms); // Cache the fetched state
        this._fetchingMobileState$.next(false); // Release the lock after successful fetch
      }),
      catchError(error => {
        console.error('Error fetching mobile state:', error);
        this._fetchingMobileState$.next(false); // Release the lock on error
        return of({
          supportNumber: '8559401507',
          home: defaultHome,
        });
      })
    );
  }

  put(requestBody: MobileStateActionRequest | {redirectCompleted:boolean}): Observable<ApiResponse> {
    const apiCache = this.getApiCacheService();
    const awsApiWrapperService = this.getAwsApiWrapperService();
    apiCache.refreshItem('/client/mobilestate');
    return from(awsApiWrapperService.put('api-mobile', `/client/mobilestate`, { body: requestBody }));
  }

  getProductOnboardingEligibility(product:EligibleProduct):Observable<boolean>{
    return this.get().pipe(
      map(mobileState => Boolean(mobileState.products?.includes(product)))
    )
  }
}

const defaultHome = {
  "waysWeCanHelp": [
    {
      "title": "Credit score help",
      "toolText": "Connect your credit report for personalized insights",
      "text": "We'll help you understand and create a plan for your credit",
      "icon": "gauge",
      "ctaPath": "topic/topics/creditScore.html",
      "key": "creditScore"
    },
    {
      "title": "Money emergencies",
      "icon": "help_ring",
      "text": "We'll help you find the right solution for your emergency",
      "ctaPath": "topic/topics/emergencyMoney.html",
      "key": "emergencyMoney"
    },
    {
      "title": "Reducing debt",
      "text": "We'll help you make a plan to pay down your debt",
      "icon": "credit_card",
      "ctaPath": "topic/topics/debtReduction.html",
      "key": "debtReduction"
    },
    {
      "icon": "money_bag",
      "text": "We make it easy to start saving regularly and reach your goals",
      "toolText": "Get rewards for saving directly from your paycheck",
      "title": "Saving money",
      "ctaPath": "topic/topics/savings.html",
      "key": "savings"
    }
  ],
  "financialTools": [
    {
      "icon": "money_bag",
      "text": "Get rewards for saving directly from your paycheck",
      "title": "Saving money",
      "key": "savings",
      "ctaPath": "chat"
    },
    {
      "title": "Credit score help",
      "text": "Connect your credit report for personalized insights",
      "icon": "gauge",
      "key": "creditScore",
      "ctaPath": "chat"
    },
    {
      "title": "Spending account",
      "icon": "help_ring",
      "text": "Set up a spending account to get advances on your paycheck",
      "key": "spendingAccount",
      "ctaPath": "chat"
    }
  ]
};
