import {Injectable} from '@angular/core';
import {
  MappingDetail,
  RouteInfoInterface
} from "@brightside-web/desktop/data-access/shared";

@Injectable({
  providedIn: 'root'
})
export class TopicRoutingService {

  constructor() { }

  navigateToTopic(topicLink: string) : RouteInfoInterface | undefined {
    const breakRoute = topicLink.replace('gobrightside://', '').replace('.html', '').split('?');

    const topic = breakRoute[0].split('/').pop();

    const route : MappingDetail = {
      path: `ways-we-can-help/topic/${topic}`
    }
    return {mappingDetail: route, queryString: breakRoute[1]};
  }
}

