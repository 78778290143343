import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bw-copy-cell',
  templateUrl: './copy-cell.component.html',
  styleUrls: ['./copy-cell.component.scss']
})
export class CopyCellComponent implements OnInit {

  @Input() label: string;
  @Input() detail: string;
  @Input() iconName: string;
  @Input() border: boolean = true;
  @Input() size: 'small'|'medium' = 'medium';
  @Input() color: 'primary'|'secondary' = 'primary';
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  cellClicked() {
    this.clicked.emit(true);
  }

  ngOnInit(): void {
  }

}
