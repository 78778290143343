<div class="bui-row-container">
  <div class="row-icon row-icon-left" *ngIf="leftIconName">
    <bw-icon iconName="{{leftIconName}}" color="{{leftIconColor}}" backgroundColor="{{leftIconBackgroundColor}}" [size]="leftIconSize"></bw-icon>
  </div>
  <div class="row-content">
    <ng-content select=".row-content"></ng-content>
  </div>
  <div class="row-value">
    <ng-content select=".row-value"></ng-content>
  </div>
  <div class="row-icon row-icon-right" *ngIf="rightIconName">
    <bw-icon iconName="{{rightIconName}}" color="{{rightIconColor}}" backgroundColor="{{rightIconBackgroundColor}}"></bw-icon>
  </div>
</div>
