import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  Route, UrlSegment
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {
  BsCacheService as Cache
} from '@brightside-web/desktop/data-access/core-services';
import { FullSsnService } from '@brightside-web/desktop/data-access/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VfullssnGuard implements CanActivate, CanLoad {
  hasUpdatedSsn: boolean;
  constructor(protected service: FullSsnService, protected router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.checkVerifiedSsn();
  }

  checkVerifiedSsn(): Observable<boolean> {
    // TODO: disable calling this guard in general
    return of(true);
      // return this.service.getRequiredVerification().pipe(
      //   map(resp => {
      //     this.hasUpdatedSsn = Cache.getItem('fullSsnSet');
      //     if (!resp || this.hasUpdatedSsn) {
      //       return true;
      //     }
      //     this.router.navigate(['/registration/verify-full-ssn']);
      //     return false;
      //   })
      // );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkVerifiedSsn();
  }
}
