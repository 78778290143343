<bw-action-article [brightsideWebFb]="config.analyticsAttributes()">
  <div *ngIf='config.analytics && config.analytics.shown'
       [brightsideWebFb]='{ event_name: config.analytics.shown }'>
  </div>
  <div pre-title *ngIf="config.pageTitleImage">
      <img src="../assets/{{ config.pageTitleImage }}" [alt]="config.pageTitleImage" class="mb-md" />
  </div>
  <div header-title class="mb-sm">    
    <typography title-medium>
      {{ !config.pageTitleKey ? config.pageTitle : config.pageTitleKey | translate }}
    </typography>
  </div>
  <div content-section>
    <p text-large-regular class="mb-md" color="var(--secondary-text)">
      {{ !config.pageSubTitleKey ? config.pageSubTitle : config.pageSubTitleKey | translate }}
    </p>
    <div class="flex-column space-center"></div>
  </div>
  <div footer-actions class="flex-row row-reverse">
    <button
      *ngIf="config.pageCtaLabel || config.pageCtaLabelKey"
      bw-button
      (click)="handleMoveForward()"
      type="submit"
      [brightsideWebFb]="config.analyticsAttributes('submit')"
    >
      {{ !config.pageCtaLabelKey ? config.pageCtaLabel : config.pageCtaLabelKey | translate }}
    </button>
  </div>
</bw-action-article>
