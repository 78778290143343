import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IntercomModule} from "ng-intercom";

@NgModule({
  imports: [CommonModule, IntercomModule.forRoot({
    appId: ""
  })],
})
export class MicroCoreChatModule {}

