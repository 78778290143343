import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface ActionCardConfigInterface {
  title?: string;
  body?: string;
  bgColor?: string;
  selected?: boolean;
  image?: string;
  label?: string;
  labelSub?: string;
  labelIcon?: string;
  iconButton?: string;
  iconButtonSize?: number;
  iconbuttonColor?: string;
  cta?: string;
  hideCta?: boolean;
  backgroundImagePosition?: string;
  ctaString?: string;
  optionalIcon?: string;
  cardStyle: 'large' | 'small';
  removeMinHeight?: boolean;
  removeMaxWidth?: boolean;
  marginBottom?: 'medium' | 'large';
}

@Component({
  selector: 'bw-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionCardComponent implements OnInit {

  @Input() cardConfig!: ActionCardConfigInterface;
  @Output() ctaClicked: EventEmitter<any> = new EventEmitter<any>();

  backgroundImg: string;

  constructor() {}

  ngOnInit(): void {
    if (this.cardConfig.image) {
      const imgPath = this.cardConfig.image;
      if (this.isImage(imgPath)) {
        this.backgroundImg = `'url(${this.cardConfig.image})'`;
      } else {
        console.warn('ActionCardComponent: cardConfig.image must be of image type jpg, jpeg, or png');
      }
    }
  }

  isImage(url: string) {
    return /\.(jpg|jpeg|png)$/.test(url);
  }

  emitCtaClicked() : void {
    this.ctaClicked.emit(true);
  }

}
