import { Injectable } from '@angular/core';
import {
  AwsApiWrapperService,
  BsCacheService,
} from '@brightside-web/desktop/data-access/core-services';
import { from, Observable } from 'rxjs';
import {ApiResponse} from '@brightside-web/desktop/data-access/shared';
import { ListData } from '@brightside/brightside-ui';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {

  referralItems : {[p:string]: string} = {};
  skipAccountCreation = false;

  constructor(
    private awsApiWrapperService: AwsApiWrapperService,
    private bsCacheService: BsCacheService,
  ) {
    this.unpackSkipCreationReferral();
  }

  static clearOnboardingCache() {
    BsCacheService.removeItemStatic('firstName');
    BsCacheService.removeItemStatic('lastName');
    BsCacheService.removeItemStatic('last4ssn');
    BsCacheService.removeItemStatic('email');
    BsCacheService.removeItemStatic('dob');
    BsCacheService.removeItemStatic('create-account');
    BsCacheService.removeItemStatic('phoneNumber');
    BsCacheService.removeItemStatic('recoveryToken');
    BsCacheService.removeItemStatic('flow');

    //We need to clear mobile cache so the cards will adjust as needed
    BsCacheService.removeItemStatic('/client/mobilestate');
  }


  storeReferralToSession() {
      window.sessionStorage.setItem('skipCreationReferral', JSON.stringify(this.referralItems));
  }

  unpackSkipCreationReferral() {
    const referralSessionInfo = window.sessionStorage.getItem('skipCreationReferral');
    if (referralSessionInfo) {
      this.referralItems = JSON.parse(referralSessionInfo);
      this.skipAccountCreation = true;
    }
  }

  clearReferralItems() {
    this.referralItems = {};
    this.skipAccountCreation = false;
    window.sessionStorage.removeItem('skipCreationReferral')
  }

  createAccountForSSO(body: CreateAccountSSORequest): Observable<CreateAccountSSOResponse> {
    return this.callPostClientAccount(body);
  }

  submitAccountCode(body: SubmitAccountCodeRequest): Observable<CreateAccountSSOResponse> {
    return this.callPostClientAccount(body);
  }

  private callPostClientAccount(body: CreateAccountSSORequest | SubmitAccountCodeRequest): Observable<CreateAccountSSOResponse> {
    return from(this.awsApiWrapperService.post('api-mobile', '/client/account', { body }));
  }

  newRegistrationPrecheck(payload: NewPrecheckPayload): Observable<PrecheckResponse> {
    return from(this.awsApiWrapperService.post('api-mobile-noauth', '/client/precheck', { body: payload }));
  }

  forgotPasswordPrecheck(payload: ForgotPrecheckPayload, isRegistration?: boolean): Observable<ForgotPasswordResponse> {
    return from(this.awsApiWrapperService.post(isRegistration ? 'api-mobile-noauth' : 'api-mobile', '/client/forgot', { body: payload }));
  }

  changeNumber(payload: ChangeNumberPayload): Observable<ApiResponse> {
    return from(this.awsApiWrapperService.patch('api-mobile', '/client/username', { body: { ...payload, devicePushId: '' } }));
  }

  companyList(): Observable<ListData[]> {
    return from(this.awsApiWrapperService.get('api-mobile-noauth', '/cdn-resource/resources/companies.json', { body: null }));
  }

  postOnboarding(endPoint: string = '') {
    return this.awsApiWrapperService.post('api-mobile', endPoint, {});
  }

  postProvisional(payload?: {}, version?: string): Observable<ArgyleApiResponse> {
    const path = version ? `/provisional?apiVersion=${version}` : `/provisional`;
    return from(this.awsApiWrapperService.post('api-mobile', path, { body: { ...payload } }));
  }

  // patchProvisional(payload: ArgylePatchProvisionalPayload): Observable<ArgyleApiResponse> {
  //   return from(API.patch('api-mobile-noauth', '/provisional', { body: { ...payload } }));
  // }

  public submitEmployeeAliasPrecheck(
    payload:string, workstate?:string,
    zipCode?:string, lName?:string
    ):Observable<ApiResponse> {
    if (workstate) {
      return from(this.awsApiWrapperService.post('api-mobile','/client/employment/precheck',{body: {unique_id: payload, work_state: workstate, lastName:lName,zip_code: zipCode}}));
    } else {
      return from(this.awsApiWrapperService.post('api-mobile','/client/employment/precheck',{body: {unique_id: payload}}));
    }
  }

  submitEmployeeAlias(payload:string):Observable<ApiResponse> {
    return from(this.awsApiWrapperService.put('api-mobile', '/client', {body: {employee_alias: payload}}));
  }

  public submitHomeAddress(payload:HomeAddressPayload):Observable<ApiResponse>{
    return from(this.awsApiWrapperService.put('api-mobile','/client',{body: payload}));
  }

  public submitClientUpdate(payload:{ [key: string]: string }):Observable<ApiResponse>{
    if (payload.ssn){
      payload.ssn = payload.ssn.replace(/[^0-9]/gi, '');
    }
    return from(this.awsApiWrapperService.put('api-mobile','/client',{body: payload}));
  }

}

export interface EmployeeAliasPayload {
  employee_alias: string;
}

export interface HomeAddressPayload {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip_code: string;
}

export interface ArgyleApiResponse {
  prov_guid: string;
  type: string;
  status: string;
  argyle: {
    config: {
      [key: string]: string;
      plugin_key: string;
      api_host: string;
    };
    intro: {
      title: string;
      subtitle: string;
    };
  };
}

export interface CreateAccountSSORequest {
  username: string;
  password: string;
}

export interface CreateAccountSSOResponse extends ApiResponse {
  code: number;
}

export interface SubmitAccountCodeRequest {
  username: string;
  code: string;
}

export interface ForgotPrecheckPayload {
  last_name: string;
  last_four_ssn: string;
  date_of_birth?: string;
}

export interface NewPrecheckPayload extends ForgotPrecheckPayload {
  first_name: string;
}

export enum ClientType {
  Primary = 'primary',
  Dependent = 'dependent',
}

export interface PrecheckResponse extends ApiResponse {
  company: string;
  clientType: ClientType;
}

export interface ForgotPasswordResponse {
  phoneNumber: string;
  phoneVerified: boolean;
  email: string;
  emailVerified: boolean;
  recoveryToken: string;
}

export interface ChangeNumberPayload {
  newUsername: string;
  username: string;
  recoveryToken?: string | null;
  password: string;
}

export interface ReferralParamsInterface {
  referrerType?: string;
  firstName?: string;
  clientType?: string;
  company?: string;
}
