<a
  bw-anchor
  color="var(--secondary-text)"
  anchorStyle="text-large-regular"
  prefixIconName="arrow_left"
  [dismissedCta]="true"
  [coreTracking]="trackingDetail"
  (click)="exitActionTrigger()"
  ><span class="hidden-mobile">{{ infoLabel | translate }}</span></a
>
