import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UiTemplateContentCdnUrl } from '../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-cdn-url',
  template: '<div class="center"><img [src]="fullPath" /></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTemplateContentCdnUrlComponent implements OnInit {
  @Input() data: UiTemplateContentCdnUrl;

  constructor(protected httpClient: HttpClient) {}

  fullPath = '';

  ngOnInit(): void {
    this.fullPath = `assets${this.data.path}`;
  }
}
