import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { MessageBusOutgoingEventKey } from '../../models/message-bus.enum';
import { MessageBusOutgoingEvent } from '../../models/message-bus.interface';
import { MessageBusNativeWindowReference } from '../../native-window/message-bus-native-window.reference';

import { MessageBusPlatformBaseMessageHandler, MessageBusPlatformNativeEventBody } from './platform-base.service';

/**
 * Window.nativeWindow.webkit.messageHandlers.buttonClicked.postMessage = For IOS and testing of injection into bus
 */
declare global {
  interface Window {
    nativeWindow: {
      webkit: {
        messageHandlers: {
          buttonClicked: { postMessage: Function };
        };
      };
    };
  }
}

const checkForUserAgentMatch = () =>
  !/safari/.test(window.navigator.userAgent.toLowerCase()) && /iphone|ipod|ipad/.test(window.navigator.userAgent.toLowerCase());
const checkForBridgeListener = () => typeof (window as any).webkit?.messageHandlers?.buttonClicked !== 'undefined';

const IsRunningIOS = () => checkForUserAgentMatch() || checkForBridgeListener();

/**
 * @description
 * iOS specific service
 */
class MessageBusPlatformIosMessageHandler extends MessageBusPlatformBaseMessageHandler {
  constructor(private nativeWindowReference: MessageBusNativeWindowReference) {
    super();
  }

  private get webkit() {
    return this.nativeWindowReference?.nativeWindow?.webkit || {};
  }

  override get messageReceiver() {
    return (event: MessageBusOutgoingEvent) => {
      this.logForDebugging('messageReceiver event', event);

      if (event.key === MessageBusOutgoingEventKey.ROUTE && !event.data?.forceBridgeFire) {
        this.handlePreBridgeRouteEvent(event);
        return;
      }

      const generatedEventClass: MessageBusPlatformNativeEventBody = this.prepareNativeEvent(event, true);

      this.webkit.messageHandlers.buttonClicked.postMessage.bind(this.webkit.messageHandlers.buttonClicked)(generatedEventClass);
    };
  }

  private handlePreBridgeRouteEvent(event: MessageBusOutgoingEvent) {
    let routeToUrl = '';

    if (event.fallbackSetting?.routeToUrl) {
      routeToUrl = event.fallbackSetting.routeToUrl as string;
    } else if (event?.data?.routeToUrl) {
      routeToUrl = event.data.routeToUrl as string;
    } else if (event.data?.body && ((event.data.body as any).route as string)) {
      routeToUrl = (event.data.body as any).route as string;
    }

    this.logForDebugging('routeToUrl', routeToUrl);

    this.triggerRouteByUrlEvent(event, routeToUrl);
  }

  private triggerRouteByUrlEvent(event: MessageBusOutgoingEvent, routeToUrl: string = '') {
    BsHubService.dispatchStatic('RoutingServiceChannel', {
      event: 'ROUTE_BY_URL', //These hard coded values need to come from util but doing so breaks builds...
      data: {
        routeToUrl,
        originalEvent: event,
      },
    });
  }
}

export { IsRunningIOS, MessageBusPlatformIosMessageHandler };
