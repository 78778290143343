import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';
import {BsChatService} from "@brightside-web/desktop/data-access/core-services";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  /** note this class assumes that the desktop app module has already loaded in the Intercom module, it will not work otherwise **/
  constructor(private _bsChatService:BsChatService) {
  }

  openIntercomChat(){
    this._bsChatService.showChat();
  }
}
