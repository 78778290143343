export const ToastDelay = 3000;

export class Toast {
  id: string;
  fade ?= false;
  type: ToastType;
  message: string;
  transient ?= true;
  link?: boolean;
  closing?: boolean;

  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
  }
}

export enum ToastType {
  Success,
  Info,
  Warning,
  Error,
  InfoBlue
}
