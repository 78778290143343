import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EMAIL_PATTERN } from '@brightside-web/shared/desktop';
import { FirebaseService, RemoteConfig_Companies } from '@brightside-web/desktop/data-access/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { phoneValidator } from '@brightside/brightside-ui';
import { WaitlistService } from '../waitlist.service';
import { AxiosError } from 'axios';
import * as libphonenumber from 'libphonenumber-js/max';

@Component({
  selector: 'brightside-web-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: ['./waitlist.component.scss'],
})
export class WaitlistComponent implements OnInit {
  submitted = false;
  public waitlistForm: FormGroup;
  error: boolean;
  processing: boolean;
  sub = new Subscription();
  email_pattern = EMAIL_PATTERN;
  signInModalVisible: boolean;
  showSubmittedModal: boolean;
  company = 'unknown';

  _bodyEl = document.getElementsByTagName('body')[0];

  constructor(
    protected analytics: FirebaseService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected service: WaitlistService
  ) {
    this._bodyEl.classList.add('waitlist', 'brightside', 'signup');

    this.waitlistForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(new RegExp(this.email_pattern))]),
      firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });

    this.waitlistForm.addControl(
      'phone',
      new FormControl('', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        Validators.pattern('\\d{3}-\\d{3}-\\d{4}'),
        phoneValidator(),
      ])
    );
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.customer) {
      this.company = this.activatedRoute.snapshot.queryParams.customer;
    } else if (this.activatedRoute.snapshot.queryParams.company) {
      this.company = this.activatedRoute.snapshot.queryParams.company;
    } else {
      this.company = 'missing';
    }

    if (this.company && this.company !== 'missing') {
      this.analytics
        .ensureInitialized()
        .then((_) => {
          if (this.analytics.getValueAsString(RemoteConfig_Companies).includes(this.company)) {
            this.router.navigate(['get_started']);
          }
        });
    }

    console.log(`company is ${this.company}`);
  }

  clearThankYou() {
    this.waitlistForm.controls['firstName'].reset('');
    this.waitlistForm.controls['lastName'].reset('');
    this.waitlistForm.controls['email'].reset('');
    this.waitlistForm.controls['phone'].reset('');

    this.submitted = false;
  }

  onSubmit() {
    this.processing = true;

    const phone_number = libphonenumber.parseDigits(this.waitlistForm.controls['phone'].value);

    this.service
      .submitEntry({
        company: this.company ?? 'unknown',
        first_name: this.waitlistForm.controls['firstName'].value,
        last_name: this.waitlistForm.controls['lastName'].value,
        email: this.waitlistForm.controls['email'].value,
        phone_number,
      })
      .subscribe(
        () => {
          this.processing = false;
          this.handleResponse(true);
        },
        (err: AxiosError) => {
          this.processing = false;
          this.handleResponse(false);
        }
      );
  }

  handleResponse(success: boolean) {
    this.submitted = true;
  }

  get email() {
    return this.waitlistForm.get('email');
  }

  get firstName() {
    return this.waitlistForm.get('firstName');
  }

  get lastName() {
    return this.waitlistForm.get('lastName');
  }

  get phone() {
    return this.waitlistForm.get('phone');
  }
}
