import { Injectable } from '@angular/core';
import {from, Observable} from "rxjs";
import {
  AwsApiWrapperService
} from '@brightside-web/desktop/data-access/core-services';

export interface ClientConfirmationSuccess {
  guid: string;
}

export interface ClientConfirmationError {
  error: ClientConfirmationErrorItems;
}

export interface ClientConfirmationErrorItems {
  code: ClientConfirmationErrorCodes;
  title?: string;
  message?: string;
  details?: { [key: string]: string }
}

export enum ClientConfirmationErrorCodes {
  CLIENT_EXISTS_PII = "CLIENT_EXISTS_PII",
  CLIENT_EXISTS_UNIQUEID = "CLIENT_EXISTS_UNIQUEID",
  INVALID_UNIQUEID = "INVALID_UNIQUEID",
  CLIENT_NOT_FOUND = "CLIENT_NOT_FOUND",
  CLIENT_ALREADY_VERIFIED = "CLIENT_ALREADY_VERIFIED",
  CLIENT_NOT_IN_COHORT = "CLIENT_NOT_IN_COHORT",
  CLIENT_NOT_ELIGIBLE = "CLIENT_NOT_ELIGIBLE",
  HOUSEHOLD_CLIENT_NOT_FOUND = "HOUSEHOLD_CLIENT_NOT_FOUND",
  PRIMARY_EMPLOYEE_NOT_FOUND = "PRIMARY_EMPLOYEE_NOT_FOUND",
  PRIMARY_EMPLOYEE_NOT_ELIGIBLE = "PRIMARY_EMPLOYEE_NOT_ELIGIBLE",
  PRIMARY_EMPLOYEE_WRONG_COMPANY = "PRIMARY_EMPLOYEE_WRONG_COMPANY",
  INVALID_PII = 'INVALID_PII'
}

export interface ClientConfirmationSubmissionInterface {
  employment_type: string;
  skip_precheck?: boolean;
  fa_referred?: boolean;
  error_code?: ClientConfirmationErrorCodes;
  [clientEligibilityFields: string]: any;
}

export interface ClientConfirmationErrorCode {
  errorId: string;
  errorTitleKey: string;
  errorDescKey: string;
  ctaKey?: string;
  ctaPath?: string;
  ctaKey2?: string;
  ctaPath2?: string;
  errorCount?: number;
  maxRetry?: number;
  softCheckKey?: string;
  faqCount?: number;
}

export const ClientConfirmationErrorCodesErrors: {[x:string]:ClientConfirmationErrorCode} = {
  [ClientConfirmationErrorCodes.INVALID_PII]: {
    errorId: 'pii_does_not_exist',
    errorTitleKey: 'ERROR_CLIENT_NOT_FOUND_PII_TITLE',
    errorDescKey: 'ERROR_CLIENT_NOT_FOUND_PII_DESC',
    maxRetry: 2,
    errorCount: 0,
    ctaKey: 'TRY_AGAIN',
  },
  [ClientConfirmationErrorCodes.INVALID_PII + '_max_retry']: {
    errorId: 'pii_max_attempts_blocked',
    errorTitleKey: 'ERROR_CLIENT_NOT_FOUND_MAX_ATTEMPTS_TITLE',
    errorDescKey: 'ERROR_CLIENT_NOT_FOUND_MAX_ATTEMPTS_DESC',
    ctaKey: 'CLOSE',
    ctaPath: 'registration/sign_up'
  },
  [ClientConfirmationErrorCodes.CLIENT_NOT_ELIGIBLE]: {
    errorId: 'not_eligible',
    errorTitleKey: 'ERROR_ID_INELIGIBLE_TITLE',
    errorDescKey: 'ERROR_ID_INELIGIBLE_DESC',
    ctaKey: 'CLOSE'
  },
  [ClientConfirmationErrorCodes.CLIENT_NOT_IN_COHORT]: {
    errorId: 'out_of_pilot',
    errorTitleKey: 'OUT_OF_PILOT_TITLE',
    errorDescKey: 'OUT_OF_PILOT_DESC',
    ctaKey: 'OUT_OF_PILOT_CTA',
    ctaKey2: 'OUT_OF_PILOT_CTA2',
    errorCount: 0,
    softCheckKey: ''
  },
  [ClientConfirmationErrorCodes.CLIENT_EXISTS_UNIQUEID]: {
    errorId: 'id_already_exists',
    errorTitleKey: 'ERROR_ID_ALREADY_EXISTS_TITLE',
    errorDescKey: 'ERROR_ID_ALREADY_EXISTS_DESC',
    ctaKey: 'TRY_AGAIN',
    errorCount: 0,
    softCheckKey: ''
  },
  [ClientConfirmationErrorCodes.CLIENT_NOT_FOUND]: {
    errorId: 'client_not_found',
    errorTitleKey: 'ERROR_CLIENT_NOT_FOUND_TITLE',
    errorDescKey: 'ERROR_CLIENT_NOT_FOUND_DESC',
    ctaKey: 'TRY_AGAIN',
    errorCount: 0,
    maxRetry: 2
  },
  [ClientConfirmationErrorCodes.CLIENT_NOT_FOUND + '_max_retry']: {
    errorId: 'client_not_found_max_attempts_blocked',
    errorTitleKey: 'ERROR_CLIENT_NOT_FOUND_MAX_ATTEMPTS_TITLE',
    errorDescKey: 'ERROR_CLIENT_NOT_FOUND_MAX_ATTEMPTS_DESC',
    ctaKey: 'CLOSE',
    ctaPath: 'registration/sign_up'
  },
  [ClientConfirmationErrorCodes.INVALID_UNIQUEID]: {
    errorId: 'id_does_not_exist',
    errorTitleKey: 'ERROR_ID_DOES_NOT_EXIST_TITLE',
    errorDescKey: 'ERROR_ID_DOES_NOT_EXIST_DESC',
    ctaKey: 'TRY_AGAIN',
    errorCount: 0,
    maxRetry: 3,
    softCheckKey: 'allowDoesNotExist'
  },
  [ClientConfirmationErrorCodes.HOUSEHOLD_CLIENT_NOT_FOUND]: {
    errorId: 'dependent_not_found_retry',
    errorTitleKey: 'ERROR_DEPENDENT_NOT_FOUND_RETRY_TITLE',
    errorDescKey: 'ERROR_DEPENDENT_NOT_FOUND_RETRY_DESC',
    ctaKey: 'TRY_AGAIN',
    errorCount: 0,
    maxRetry: 1
  },
  [ClientConfirmationErrorCodes.HOUSEHOLD_CLIENT_NOT_FOUND + '_get_primary']: {
    errorId: 'dependent_not_found',
    errorTitleKey: 'ERROR_DEPENDENT_NOT_FOUND_TITLE',
    errorDescKey: 'ERROR_DEPENDENT_NOT_FOUND_DESC',
    ctaKey: 'BUTTON_CONTINUE'
  },
  [ClientConfirmationErrorCodes.CLIENT_EXISTS_PII]: {
    errorId: 'client_already_exist',
    errorTitleKey: 'ERROR_CLIENT_ALREADY_EXISTS_TITLE',
    errorDescKey: 'ERROR_CLIENT_ALREADY_EXISTS_DESC',
    ctaKey: 'SIGN_IN',
    ctaPath: 'registration/sign_in'
  },
  [ClientConfirmationErrorCodes.PRIMARY_EMPLOYEE_NOT_ELIGIBLE] : {
    errorId: 'primary_ineligible',
    errorTitleKey: 'ERROR_CLIENT_INELIGIBLE_TITLE',
    errorDescKey: 'ERROR_CLIENT_INELIGIBLE',
    ctaKey: 'CLOSE',
  },
  [ClientConfirmationErrorCodes.PRIMARY_EMPLOYEE_NOT_FOUND] : {
    errorId: 'primary_invalid',
    errorTitleKey: 'ERROR_PRIMARY_TITLE',
    errorDescKey: 'ERROR_PRIMARY_DESC',
    ctaKey: 'CLOSE',
  },
  [ClientConfirmationErrorCodes.PRIMARY_EMPLOYEE_WRONG_COMPANY] : {
    errorId: 'primary_wrong_company',
    errorTitleKey: 'ERROR_PRIMARY_TITLE',
    errorDescKey: 'ERROR_PRIMARY_DESC',
    ctaKey: 'CLOSE',
  },
  max: {
    errorId: 'id_max_attempts',
    errorTitleKey: 'ERROR_ID_MAX_ATTEMPTS_TITLE',
    errorDescKey: 'ERROR_ID_MAX_ATTEMPTS_DESC',
    ctaKey: 'ERROR_ID_MAX_ATTEMPTS_CTA',
    ctaPath: 'registration/sign_in'
  },
  maxSoft: {
    errorId: 'id_max_attempts_soft',
    errorTitleKey: 'ERROR_ID_MAX_ATTEMPTS_SOFT_TITLE',
    errorDescKey: 'ERROR_ID_MAX_ATTEMPTS_SOFT_DESC',
    ctaKey: 'ERROR_ID_MAX_ATTEMPTS_CTA'
  },
  process: {
    errorId: 'employee_id_collect',
    errorTitleKey: 'ERROR_GENERAL_TITLE',
    errorDescKey: 'ERROR_GENERAL_DESC',
    ctaKey: 'OK'
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClientConfirmationService {

  fieldVersion = "1.4";
  constructor(
    private awsApiWrapperService: AwsApiWrapperService
  ) {};

  errors = {...ClientConfirmationErrorCodesErrors};

  public submitClientVerification(payload: ClientConfirmationSubmissionInterface)
    : Observable<ClientConfirmationSuccess | ClientConfirmationError>
  {
    return from(
      this.awsApiWrapperService.post('api-mobile', `/client/confirmation?fieldVersion=${this.fieldVersion}`, {body: {...payload, fieldVersion: this.fieldVersion}})
    );
  }

}
