<div class="action-cell" [ngClass]="{'highlight': emphasize, 'disabled': disabled}" (click)="onActionCellClick()">
  <div class="action-cell-icon-left mr-xs" *ngIf="leftIconName">
    <bw-icon iconName="{{leftIconName}}" color="{{leftIconColor}}"></bw-icon>
  </div>
  <div class="action-cell-label-wrapper">
    <div class="action-cell-label text-small-regular">{{label}}</div>
    <div class="action-cell-detail text-large-semibold">{{detail}}</div>
  </div>
  <div class="action-cell-icon">
    <bw-icon iconName="{{iconName}}" color="{{iconColor}}"></bw-icon>
  </div>
</div>