import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment, UrlTree
} from '@angular/router';

import {
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';
import {Observable, of, from} from 'rxjs';
import {
  MobileStateService,
  RequiredVerificationsEnums,
  RequiredVerificationService,
  VerificationStatus
} from '@brightside-web/desktop/data-access/shared';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequiredVerificationGuard implements CanActivate, CanLoad {
  verificationPathsPiece = [
    'create-account','collect-email','employee-alias','home-address','home-address-auto', 'identity'
  ]

  knownEligibility = [
    'unique_id',
    'first_name',
    'last_name',
    'date_of_birth',
    'last_four_ssn',
    'home_zip_code',
    'work_state'
  ]

  constructor(
    protected service: RequiredVerificationService,
    protected router: Router,
    private mobileStateService: MobileStateService,
    private bsCacheService: BsCacheService
    ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.checkRequiredVerification(next.url.join('/'));
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.checkRequiredVerification(route.path || '');
  }

  private checkRequiredVerification(path: string): Observable<boolean | UrlTree> {
    return this.mobileStateService.get(true).pipe(
      switchMap(value => {
        if (value.onboardingComplete) {
          return of(true);
        } else {
          return this.service.getNextRequiredVerification().pipe(
            switchMap(resp => {
              const foundPath = this.verificationPathsPiece.some(element => path.includes(element));
              const foundEligibility = this.knownEligibility.some(element => resp.type.toLowerCase() === element.toLowerCase());

              if (resp.type === 'none' && foundPath) {
                return of(this.router.createUrlTree(['/']));
              }

              if (resp.type === 'account') {
                return of(this.router.createUrlTree(['/sso-setup/create-account']));
              } else if (resp.type === 'email') {
                return of(this.router.createUrlTree(['/sso-setup/collect-email']));
              } else if (resp.type === RequiredVerificationsEnums.HOMEADDRESS && !path.includes('home-address')) {
                return from(this.bsCacheService.getItem('useManualHomeAddressForm')).pipe(
                  map(useManualHomeAddressForm => {
                    const homeAddressRoute = useManualHomeAddressForm === true ? '/registration/home-address' : '/registration/home-address-auto';
                    return this.router.createUrlTree([homeAddressRoute]);
                  })
                );
              } else if (foundEligibility && !path.includes('identity') && !this.service.isIdentityCompleted) {
                return of(this.router.createUrlTree(['/registration/identity']));
              }

              return of(true);
            })
          );
        }
      })
    );
  }
}
