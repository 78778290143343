<section class="content-layout">
  <bw-action-article [brightsideWebFb]="config.analyticsAttributes()" [minHeight]="0">
    <div header-actions class="flex-row pointer" *ngIf="!config.pageHideBackButton">
      <bw-icon iconName="close" size="24" (click)="handleCloseAction()"></bw-icon>
    </div>
    <div *ngIf="config.pageTitle" header-title class="flex-row space-between flex-end">
      <typography title-medium class="mb-sm">{{ config.pageTitle | translate }}</typography>
    </div>

    <div content-section>
      <div class="flex-column space-center mb-xl">
        <bw-spinner></bw-spinner>
        <p *ngIf="config.pageSubTitle" text-large-semibold class="mb-sm">{{ config.pageSubTitle | translate }}</p>
        <typography *ngIf="config.pageBodyText" text-large-regular color="var(--secondary-text)">
          <p class="center" style="max-width: 432px">{{ config.pageBodyText | translate }}</p>
        </typography>
      </div>
    </div>
    <div footer-actions class="flex-row row-reverse"></div>
  </bw-action-article>
</section>
