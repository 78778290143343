import { MessageBusOutgoingEventKey } from '../../models/message-bus.enum';
import { MessageBusInternalHubEvent } from '../../models/message-bus.interface';

/**
 * @description
 * Use this to create an event that will navigate locally in desktop or trigger a native bridge event to route
 *
 * @param body:{ path: string; route: string }
 * path = The value that will be sent to the native bridge event to navigate to
 * route = Internal route event for anything outside of a native container
 * @returns MessageBusInternalHubEvent
 */
export const BuildForPathAndReturnStandardEvent = (body: { path: string; route: string }): MessageBusInternalHubEvent => ({
  event: MessageBusOutgoingEventKey.ROUTE,
  data: {
    body,
  },
});
