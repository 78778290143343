import { Component, ViewEncapsulation, HostBinding, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'bw-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent {
  constructor(private elementRef: ElementRef) { }
  @HostBinding('style.visibility') visibility = 'hidden';
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (targetElement.hasAttribute('bw-anchor')) {
      this.close(event);
    }
    if (!clickedInside) {
      this.close(event);
    }
  }

  open(e: MouseEvent) {
    this.visibility = 'visible';
    e.stopPropagation();
  }

  close(e: MouseEvent) {
    this.visibility = 'hidden';
  }

  toggle(e: MouseEvent) {
    this.visibility = this.visibility === 'visible' ? 'hidden' : 'visible';
    e.stopPropagation();
  }
}
