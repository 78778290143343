import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskEmail'
})
export class MaskEmailPipe implements PipeTransform {

  transform(email: string, ...args: unknown[]): string {
    const parts = email.split("@");
    const name = parts[0];
    const sliceLength = 3;
    let result = name.slice(0,sliceLength);
    for(let i=sliceLength + 1; i<name.length; i++) {
      result += "*";
    }
    result += name.charAt(name.length - 1);
    result += "@";
    result += parts[1];

    return result;
  }

}
