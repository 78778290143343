import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor() {}

  getModalModel(key: string): ModalModel {
    return modalModelMap[key];
  }
}

export interface ModalModel {
  primaryCta: string;
  secondaryCta?: string;
  title: string;
  bodyText: string[];
}
export interface ModalModelMap {
  [key: string]: ModalModel;
}
const modalModelMap: ModalModelMap = {
  equifaxBlurb: {
    primaryCta: 'OK',
    title: 'About VantageScore 3.0',
    bodyText: [
      'VantageScore 3.0 is a credit scoring model that uses data from your Equifax credit report to calculate your credit score.',
      'Third parties may use a different version of the VantageScore 3.0 model or a different credit scoring model altogether to assess your creditworthiness.',
    ],
  },
};
