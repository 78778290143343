<bw-modal modalWidth="medium">
  <typography title-medium>{{model.title}}</typography>
  <typography *ngFor="let text of model.bodyText" class="body mb-md" text-large-regular color="var(--secondary-text)">
    {{text}}
  </typography>
  <div class="footer flex-row row-reverse">
      <button bw-button (click)="primaryCtaClick($event);">
        <typography text-large-semibold>{{model.primaryCta}}</typography></button>
      <button bw-link-button class="mr-xs" (click)="secondaryCtaClick($event)" *ngIf="model.secondaryCta">{{model.secondaryCta}}</button>
  </div>
</bw-modal>
