import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bw-action-article',
  templateUrl: './action-article.component.html',
  styleUrls: ['./action-article.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionArticleComponent implements AfterViewInit {
  @Input() hideFooter: boolean;
  @Input() pinnedFooter = true;
  @Input() minHeight = 576;
  @Input() maxWidth = '100%';
  @Input() edgeToEdge = false;
  @Input() centeredFooter = false;

  @ViewChild('footer_wrapper', { static: true }) footerWrapper: ElementRef;

  ngAfterViewInit() {
    const footerEl = this.footerWrapper.nativeElement;
    const footerActionsContainer = footerEl.querySelector('div[footer-actions]');
    const primaryCTA = footerEl.querySelector('button[bw-button]');
    const actionCount =
      footerEl.querySelectorAll('button[bw-button]').length + footerEl.querySelectorAll('button[bw-link-button]').length;
    const footerWidth = footerEl.offsetWidth;
    const footerBackButton = footerEl.querySelector('bw-back');

    if (footerBackButton) {
      console.log('NOTE: footerBackButton exists so we do not want centeredFooter to be true');
      this.centeredFooter = false;
    }

    // Look for only one cta, then if it has a button and the width of the parent container is 320px or less, make the button fluid width
    if (footerEl && footerActionsContainer && footerWidth <= 320 && actionCount === 1 && primaryCTA) {
      footerActionsContainer.classList.add('full-width');
      primaryCTA.classList.add('fluid-width');
    }
  }
}
