import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'brightside-web-financial-account-creation-error',
  templateUrl: './financial-account-creation-error.component.html',
  styleUrls: ['./financial-account-creation-error.component.css']
})
export class FinancialAccountCreationErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
