<div class="reels-wrapper">
  <div class="reel-background">
    <video *ngFor="let reel of reels; let i = index;" muted="true" playsinline="true" #videos (ended)="onEnded($event, videos, i)" (loadedmetadata)="onMetadata($event, videos)" (playing)="playingReel(i)" (timeupdate)="currentReelTime(i)" [ngClass]="'video'+i">
      <source [attr.src]="reel.src" type="video/mp4">
    </video>
  </div>
  <div class="reel-indicators">
    <div *ngFor="let reel of reels; let i = index;" #indicators class="reel-indicator" [ngClass]="'video-ind'+i">
      <div #indBg [ngStyle]="{'width': i === currentReelIndex ? this.currentReelProgress : ''}" class="indbg"></div>
    </div>
  </div>
  <div class="reel-content">
    <button class="page-button play-back" (click)="playBack()" [disabled]="currentReelIndex === 0"></button>
    <button class="page-button play-forward" (click)="playForward()" [disabled]="currentReelIndex === reelCount"></button>
  </div>
</div>
