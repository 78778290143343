import { Injectable } from '@angular/core';
import { MappingDetail, RouteInfoInterface } from '@brightside-web/desktop/data-access/shared';
import { BsCacheService } from 'libs/desktop/data-access/core-services/src/lib/Cache/bs-cache.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyRoutingService {
  constructor(private _bscacheService: BsCacheService) {}

  navigateToSurvey(surveyLink: string): RouteInfoInterface | undefined {
    const breakRoute = surveyLink.replace('gobrightside://', '').split('/');
    let surveyType: string = '';
    let surveyRoute: string = '';
    if (breakRoute.includes('chat')) {
      this._bscacheService.setItem('openChatAfterSurvey', true);
      surveyType = breakRoute[2];
      surveyRoute = breakRoute[3] || '';
    } else {
      surveyType = breakRoute[1];
      surveyRoute = breakRoute[2] || '';
    }

    const route: MappingDetail = {
      path: `survey/${surveyType}${surveyRoute ? '/' : ''}${surveyRoute}`,
    };
    return { mappingDetail: route, queryString: '' };
  }
}
