import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'bw-input-group',
  animations: [
    trigger(
      'enterAnimation', [
        state('show', style({
          minHeight: '16px',
          visibility: 'visible',
          opacity: 1
        })),
        state('hide', style({
          minHeight: '0px',
          visibility: 'hidden',
          opacity: 0
        })),
        transition('show => hide', [
          animate('0.05s')
        ]),
        transition('hide => show', [
          animate('0.1s 0.2s')
        ]),
      ]
    )
  ],
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputGroupComponent implements AfterViewInit {
  error = false;
  hint = true;
  isFocused: boolean;

  /** The label string that will be renderend inside the label element */
  @Input() label: string;

  /** Whether the input has an error in validation */
  @Input() inputError: boolean;

  /** Text that is shown below the input if there is a validation error */
  @Input() inputErrorText: string;

  /** Text that is shown below the input that serves as an input hint */
  @Input() inputHintText: string;

  @Input() verticalSpace = 'mb-xs';
  @Input() horizontalSpace = 'mr-null';

  @Input() autoFocus:boolean;
  @ViewChild('elementRef') el:ElementRef;

  ngAfterViewInit(): void {
    if (this.autoFocus){
      //If you dont use a timeout, you get expressionchangedafterithasbeenchecked error
      setTimeout(()=>{
        this.el.nativeElement.focus();
      }, 1)
    }
  }
}
