import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AnimationEvent, state, style, transition, trigger } from '@angular/animations';

import { UseThisForStandaloneOrThat } from '@micro-core/message-bus';

import { UiTemplatePageTransitionService, UiTemplatePageTransitionAnimationLog } from './page-transition.service';

import {
  ANIMATION_FROM_RIGHT,
  ANIMATION_STAY_ENTER,
  ANIMATION_STAY_EXIT,
  ANIMATION_TO_RIGHT,
  ANIMATION_TRANSFORM_OFF_SCREEN,
  ANIMATION_TRANSFORM_ON_SCREEN,
} from './page-transition.animations';

enum PageTransitionTriggerName {
  UNSET = 'unset',
  VOID = 'void',
  PAGE_BLANK = 'pageBlank',
  PAGE = 'page',
  PAGE_REVERSE = 'pageReverse',
  PAGE_EXIT = 'pageExit',
  PAGE_EXIT_REVERSE = 'pageExitReverse',
}

const ANIMATION_TARGET_TRIGGER = 'pageTransitionTrigger';
const ANIMATION_TARGET_SELECTOR = '.action-article';

const AllStates = [
  state(PageTransitionTriggerName.PAGE_BLANK, style(ANIMATION_TRANSFORM_OFF_SCREEN)),
  state(PageTransitionTriggerName.PAGE_REVERSE, style(ANIMATION_TRANSFORM_ON_SCREEN)),
];
const AllTransitions = [
  transition(`${PageTransitionTriggerName.PAGE_BLANK} => ${PageTransitionTriggerName.PAGE}`, [
    ANIMATION_FROM_RIGHT(ANIMATION_TARGET_SELECTOR),
  ]),
  transition(`${PageTransitionTriggerName.PAGE_BLANK} => ${PageTransitionTriggerName.PAGE_REVERSE}`, [
    ANIMATION_STAY_EXIT(ANIMATION_TARGET_SELECTOR),
  ]),
  transition(`${PageTransitionTriggerName.PAGE} => ${PageTransitionTriggerName.VOID}`, [
    ANIMATION_STAY_ENTER(ANIMATION_TARGET_SELECTOR),
  ]),
  transition(`${PageTransitionTriggerName.PAGE_EXIT} => ${PageTransitionTriggerName.VOID}`, [
    ANIMATION_STAY_ENTER(ANIMATION_TARGET_SELECTOR),
  ]),
  transition(`${PageTransitionTriggerName.PAGE_EXIT_REVERSE} => ${PageTransitionTriggerName.VOID}`, [
    ANIMATION_TO_RIGHT(ANIMATION_TARGET_SELECTOR),
  ]),
];

//We are using "UseThisForStandaloneOrThat" to make sure animations are only applied in micro app setting.
export const PageTransitionAnimationTriggers = [
  trigger(ANIMATION_TARGET_TRIGGER, UseThisForStandaloneOrThat([...AllStates, ...AllTransitions], [])),
];

@Component({
  animations: PageTransitionAnimationTriggers,
  selector: 'ui-page-transition',
  template: `<span
    [@pageTransitionTrigger]="getTransitionTriggerName()"
    (@pageTransitionTrigger.start)="onAnimationStartEvent($event)"
    (@pageTransitionTrigger.done)="onAnimationDoneEvent($event)"
  >
    <ng-content select="[page-template]"></ng-content>
  </span>`,
})
export class UiTemplatePageTransitionComponent implements OnDestroy {
  @Input() reverseExitAnimation = false;

  private animationLog: UiTemplatePageTransitionAnimationLog[] = [];

  transitionTriggerName = PageTransitionTriggerName.VOID;
  lastTriggerNameCompleted = PageTransitionTriggerName.VOID;

  constructor(protected pageTransitionService: UiTemplatePageTransitionService, protected changeDetectorRef: ChangeDetectorRef) {
    this.pageTransitionService.insertAnimationStackTrace(this.animationLog);
  }

  ngOnDestroy(): void {
    this.pageTransitionService.setNeedsReverseTransition(this.reverseExitAnimation);
  }

  private getCorrectPageEnterTrigger() {
    return this.reverseExitAnimation || this.pageTransitionService.needsReverseTransition
      ? PageTransitionTriggerName.PAGE_REVERSE
      : PageTransitionTriggerName.PAGE;
  }

  private getCorrectPageExitTrigger() {
    return this.reverseExitAnimation ? PageTransitionTriggerName.PAGE_EXIT_REVERSE : PageTransitionTriggerName.PAGE_EXIT;
  }

  getTransitionTriggerName() {
    const matchForEnterTriggers = [PageTransitionTriggerName.PAGE, PageTransitionTriggerName.PAGE_REVERSE];
    const matchForExitTriggers = [PageTransitionTriggerName.PAGE_EXIT, PageTransitionTriggerName.PAGE_EXIT_REVERSE];

    if (this.lastTriggerNameCompleted === this.transitionTriggerName) {
      if (this.transitionTriggerName === PageTransitionTriggerName.VOID) {
        this.transitionTriggerName = PageTransitionTriggerName.PAGE_BLANK;
      } else if (this.transitionTriggerName === PageTransitionTriggerName.PAGE_BLANK) {
        this.transitionTriggerName = this.getCorrectPageEnterTrigger();
      } else if (
        matchForExitTriggers.includes(this.transitionTriggerName) ||
        matchForEnterTriggers.includes(this.transitionTriggerName)
      ) {
        this.transitionTriggerName = this.getCorrectPageExitTrigger();
      }
    }

    return this.transitionTriggerName;
  }

  onAnimationStartEvent(event: AnimationEvent) {
    this.animationLog.push({
      phase: event.phaseName,
      from: (event.fromState || PageTransitionTriggerName.UNSET) as PageTransitionTriggerName,
      to: (event.toState || PageTransitionTriggerName.UNSET) as PageTransitionTriggerName,
    });
  }

  onAnimationDoneEvent(event: AnimationEvent) {
    if (event.phaseName === 'done') {
      this.lastTriggerNameCompleted = event.toState as PageTransitionTriggerName;
    }
  }
}
