import { Injectable } from '@angular/core';
import { BsCacheService, AwsApiWrapperService } from '@brightside-web/desktop/data-access/core-services';
import { from, Observable, of, pipe, throwError } from 'rxjs';
import { catchError, retryWhen, take, delay, concatMap, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import * as moment from 'moment';
import { FirebaseService } from '@brightside-web/desktop/data-access/shared';
import { Environment } from '@micro-core/environment';

export interface GenericResponseResult {
  result: GenericResponseResultItems;
}

export interface GenericResponseResultItems {
  status: number;
  code: string;
  message: string;
  suggestion: string;
  suggestionCopyKey: string;
  docs: string;
}

export enum GenericResponseCode {
  SERVICE_OK = 'SERVICE_OK',
  MISSING_REQ_FIELD = 'MISSING_REQ_FIELD',
  INVALID_FIELD = 'INVALID_FIELD',
  SERVICE_ERROR = 'SERVICE_ERROR',
}

export interface APIOptionsInterface {
  errorCallback?(error: unknown): unknown;
  retryCount?: number;
  cache?: boolean;
}

abstract class APIWrapperClass {
  constructor(protected amplitude: FirebaseService, protected env: Environment, protected awsAPIWrapper: AwsApiWrapperService) {}

  defaultRetryCount = 3;

  protected _apiGet<T>(
    apiName: string,
    path: string,
    init: any,
    options?: APIOptionsInterface
  ): Observable<T & GenericResponseResult> {
    const initBody = init ? { body: { ...init } } : {};
    return from(this.awsAPIWrapper.get(apiName, path, initBody)).pipe(this._apiPipe<T>(apiName, path, init, 'get', options));
  }

  protected _apiPost<T>(
    apiName: string,
    path: string,
    init: any,
    options?: APIOptionsInterface
  ): Observable<T & GenericResponseResult> {
    const initBody = init ? { body: { ...init } } : {};
    return from(this.awsAPIWrapper.post(apiName, path, initBody)).pipe(this._apiPipe<T>(apiName, path, init, 'post', options));
  }

  protected _apiPatch<T>(
    apiName: string,
    path: string,
    init: any,
    options?: APIOptionsInterface
  ): Observable<T & GenericResponseResult> {
    const initBody = init ? { body: { ...init } } : {};
    return from(this.awsAPIWrapper.patch(apiName, path, initBody)).pipe(this._apiPipe<T>(apiName, path, init, 'patch', options));
  }

  protected _apiPut<T>(
    apiName: string,
    path: string,
    init: any,
    options?: APIOptionsInterface
  ): Observable<T & GenericResponseResult> {
    const initBody = init ? { body: { ...init } } : {};
    return from(this.awsAPIWrapper.put(apiName, path, initBody)).pipe(this._apiPipe<T>(apiName, path, init, 'put', options));
  }

  protected _apiDel<T>(
    apiName: string,
    path: string,
    init: any,
    options?: APIOptionsInterface
  ): Observable<T & GenericResponseResult> {
    const initBody = init ? { body: { ...init } } : {};
    return from(this.awsAPIWrapper.del(apiName, path, initBody)).pipe(this._apiPipe<T>(apiName, path, init, 'del', options));
  }

  _apiPipe<T>(apiName: string, path: string, init: any, callType: string, options?: APIOptionsInterface) {
    return pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(500),
          take(options?.retryCount ?? this.defaultRetryCount),
          concatMap((error) => throwError(error))
        )
      ),
      tap<T & GenericResponseResult>((response) => {
        const result = response.result;
        this.amplitude.logEvent(
          'api call completed',
          {
            reason_code: result.code,
            message: result.message,
            status: result.status,
            type: callType,
            url: `${this.env.awsmobile.endpoints['api-mobile'].endpoint}${path}`,
          },
          true,
          100
        );
      }),
      catchError((error) => this._APIErrorHandler(apiName, path, init, error, callType, options))
    );
  }

  _APIErrorHandler = (
    apiName: string,
    path: string,
    init: any,
    error: unknown,
    callType: string,
    options?: APIOptionsInterface | undefined
  ): Observable<any> => {

    if (path.includes('financialsnapshot')) {
      try {
        console.log('fs api call failed');
        this.amplitude.logEvent(
          'fs api call failed',
          {
            error: JSON.stringify(error),
            type: callType,
            url: `${this.env.awsmobile.endpoints['api-mobile'].endpoint}${path}`,
          },
          true,
          100
        );
      } catch (e) {}
    }

    // todo: update w/ core error logger when it is completed
    if (!this.env.production) console.log('APIErrorHandler: logging error');
    Sentry.withScope(function (scope) {
      scope.setFingerprint([`url: ${path}`, `request body: ${JSON.stringify(init)}`, `method: ${callType}`]);
      if (error instanceof Error) {
        Sentry.captureException(error);
      } else {
        try {
          // Convert or log non-Error objects
          const convertedError = new Error(JSON.stringify(error));
          Sentry.captureException(convertedError);
        } catch (er) {
          Sentry.captureException(er);
        }
      }
    });
    if (options?.errorCallback) {
      options.errorCallback(error);
      return of([]);
    } else {
      return throwError(error);
    }
  };
}

@Injectable({
  providedIn: 'root',
})
export class BsApiService extends APIWrapperClass {
  constructor(
    protected override amplitude: FirebaseService,
    protected override env: Environment,
    protected override awsAPIWrapper: AwsApiWrapperService,
    private bsCacheService: BsCacheService
  ) {
    super(amplitude, env, awsAPIWrapper);
  }

  get<T>(apiName: string, path: string, init?: any, options?: APIOptionsInterface): Observable<T & GenericResponseResult> {
    // if (options?.cache) {
    //   const cached = this.bsCacheService.getItem(path);
    //   if (cached) {
    //     return of(cached);
    //   } else {
    //     return this._apiGet<T>(apiName, path, init, options).pipe(
    //       tap((response) => {
    //         Cache.setItem(path, response, {expires: moment().add(30, 'seconds').valueOf()})
    //       })
    //     );
    //   }
    // } else {
    return this._apiGet<T>(apiName, path, init, options);
    // }
  }
  post<T>(apiName: string, path: string, init?: any, options?: APIOptionsInterface): Observable<T & GenericResponseResult> {
    return this._apiPost<T>(apiName, path, init, options);
  }
  patch<T>(apiName: string, path: string, init?: any, options?: APIOptionsInterface): Observable<T & GenericResponseResult> {
    return this._apiPatch<T>(apiName, path, init, options);
  }
  put<T>(apiName: string, path: string, init?: any, options?: APIOptionsInterface): Observable<T & GenericResponseResult> {
    return this._apiPut<T>(apiName, path, init, options);
  }
  del<T>(apiName: string, path: string, init?: any, options?: APIOptionsInterface): Observable<T & GenericResponseResult> {
    return this._apiDel<T>(apiName, path, init, options);
  }
}
