import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ContentChildren, QueryList, Output, EventEmitter, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from "@angular/animations";
import { AccordionItemDirective } from './accordion-item/accordion-item.directive';

@Component({
  selector: 'bw-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('contentExpansion', [
      transition(':enter', [
        style({height: '0px', opacity: 0, visibility: 'hidden'}),
        animate('300ms cubic-bezier(.37,1.04,.68,.98)',
        style({height: '*', opacity: 1, visibility: 'visible'}))
      ]),
      transition(':leave',
        animate('300ms cubic-bezier(.37,1.04,.68,.98)',
        style({height: '0px', opacity: 0, visibility: 'hidden'})
      )),
    ])
  ]
})
export class AccordionComponent implements OnInit {
  expanded = new Set<number>();
  @Output() allClosed = new EventEmitter<boolean>()
  @Input() collapsing = true;
  @Input() startOpen = false;
  @ContentChildren(AccordionItemDirective) items: QueryList<AccordionItemDirective>;

  ngOnInit(): void {
    if (this.startOpen) this.toggleState(0);
  }

  toggleState = (index: number) => {

    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }

    if (this.expanded.size > 0) {
      this.allClosed.emit(false);
    } else {
      this.allClosed.emit(true);
    }
  };

}
