import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { MessageBusOutgoingEvent } from '../../models/message-bus.interface';

import { MessageBusPlatformBaseMessageHandler } from './platform-base.service';

import { ChatService } from '@brightside-web/micro/core/chat';
import {
  MessageBusEventChannel,
  MessageBusInternalHubEvent,
  MessageBusInternalService,
  MessageBusOutgoingEventKey,
} from '@micro-core/message-bus';
import { EventDataMap } from '@aws-amplify/core/src/Hub/types/HubTypes';

/**
 * @description
 * Browser specific service
 */
class MessageBusPlatformBrowserMessageHandler extends MessageBusPlatformBaseMessageHandler {
  private hasExternalLink = false;

  constructor(private chatService: ChatService) {
    super();
  }

  private watchForExternalLinkEvents(event: MessageBusOutgoingEvent) {
    if (event.key !== MessageBusOutgoingEventKey.EXTERNAL_LINK) {
      return;
    }

    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INTERNAL,
      filterByEvents: [MessageBusOutgoingEventKey.EXTERNAL_LINK],
      take: 1,
      callbackListener: (payload: MessageBusInternalHubEvent | EventDataMap) => {
        this.logForDebugging('External Link Event Received', payload);

        if ('bridgeSetting' in payload && payload.bridgeSetting?.data?.url) {
          window.open(`${payload.bridgeSetting.data.url}`, '_blank');
        }
      },
    });
  }

  private handleBridgeOutgoingMessage(event: MessageBusOutgoingEvent) {
    //Setup listeners one time to react if needed
    this.watchForExternalLinkEvents(event);

    //We have to convert the 'key' in the outgoing to the 'event' in the internal so
    //things like desktop can handle when no native layer is present
    MessageBusInternalService.sendInternalHubEvent({ ...event, event: event.key } as MessageBusInternalHubEvent);
  }

  private handleDataBodyMessage(event: MessageBusOutgoingEvent) {
    if (event?.data?.routeToUrl) {
      this.handleRouteMessage(event.data.routeToUrl as string);
    } else if (event.data?.body && ((event.data.body as any).route as string)) {
      this.handleRouteMessage((event.data.body as any).route as string);
    }
  }

  private handleDataFallbackMessage(event: MessageBusOutgoingEvent) {
    //The order of these checks will look for: routeToUrl, incomingEvent, then action
    if (event.fallbackSetting?.routeToUrl) {
      if (event.fallbackSetting?.action === MessageBusOutgoingEventKey.CHAT) {
        setTimeout(() => {
          this.chatService.openIntercomChat();
        }, 750);
      }
      this.handleRouteMessage(event.fallbackSetting.routeToUrl as string);
    } else if (event.fallbackSetting?.incomingEvent) {
      (window as any).BsCrossCom.publishMessage(event.fallbackSetting.incomingEvent.rawMessage || '');
    } else if (event.fallbackSetting?.action === MessageBusOutgoingEventKey.CHAT) {
      this.chatService.openIntercomChat();
    } else {
      this.blockedMessageReceiver(event);
    }
  }

  private handleRouteMessage(routeToUrl: string = '') {
    BsHubService.dispatchStatic('RoutingServiceChannel', {
      event: 'ROUTE_BY_URL', //These hard coded values need to come from util but doing so breaks builds...
      data: {
        routeToUrl,
      },
    });
  }

  protected get messageReceiver() {
    return (event: MessageBusOutgoingEvent) => {
      if (event?.fallbackSetting) {
        this.handleDataFallbackMessage(event);
      } else if (Object.keys(event?.bridgeSetting || {}).length > 0) {
        this.handleBridgeOutgoingMessage(event);
      } else if (Object.keys(event?.data || {}).length > 0) {
        this.handleDataBodyMessage(event);
      } else {
        this.blockedMessageReceiver(event);
      }
    };
  }
}

export { MessageBusPlatformBrowserMessageHandler };
