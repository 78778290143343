import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingStateService } from '@brightside/brightside-ui-services';
import {
  DesktopCard,
  Settings,
  isTemplateFullScreen,
  CompanyService
} from '@brightside-web/desktop/data-access/shared';


import { Observable } from 'rxjs';

import {
  FirebaseService,
  AnalyticsAttributesV4
} from '@brightside-web/desktop/data-access/shared';
import { Environment } from '@brightside-web/micro/core/environment';
import {TranslateService, TranslatePipe} from "@ngx-translate/core";

export interface DisplayCardsProcessResponse {
  status: string;
  errorMessage?: string;
  errorMessageTitle?: string;
  errorNavigateOnClose?: string[];
}

export class DisplayCardsSettings extends Settings {

  constructor(config:any={}) {
    super(config);
    this.pageCards = config.pageCards || [];
    this.processUpdater = config.processUpdater || null;
  }

  pageCards: DesktopCard[];

  processUpdater?: (injector: Injector,
                    cardKey: string,
                    translatePipe: TranslatePipe,
                    companyService: CompanyService
                    ) => Observable<DisplayCardsProcessResponse>;
}

@Component({
  selector: 'brightside-web-display-cards',
  templateUrl: './display-cards.component.html',
  styleUrls: ['./display-cards.scss'],
})
export class DisplayCardsComponent implements OnInit {
  config: DisplayCardsSettings = new DisplayCardsSettings({
    fbPage: 'display',
    fbCategory: 'cards',
    fbEventName: '',
    pageTitle: '',
    pageTitleKey: '',
    pageSubTitle: '',
    pageSubTitleKey: '',
    pageCtaLabel: 'Continue',
    pageCtaLabelKey: '',
    pageSecondaryCtaLabel: '',

    pageCards: [],

    pageOnForwardCtaPath: [],
  });

  fbEvent: AnalyticsAttributesV4;
  processing: boolean;
  pageIsFullScreen: boolean;

  processErrorMessage: string;
  processErrorMessageTitle: string;
  processErrorNavigateOnClose: string[];

  showProcessError: boolean;

  constructor(
    private injector: Injector,
    private routingState: RoutingStateService,
    private activatedRoute: ActivatedRoute,
    private analytics: FirebaseService,
    private env: Environment,
    private translatePipe: TranslatePipe,
    private companyService: CompanyService) {}

  ngOnInit(): void {
    this.checkForConfigViaData();
  }

  getFbInfo(extendWith?: any) {
    this.fbEvent =  { event_name: this.config.fbEventName, ...extendWith };
  }

  private checkForConfigViaData() {
    if (isTemplateFullScreen(this.activatedRoute)) {
      this.pageIsFullScreen = true;
    }

    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new DisplayCardsSettings(this.activatedRoute.snapshot.data.pageConfig);
      this.getFbInfo();
    }
  }

  private handleMoveBackward() {
    this.routingState.popAndNavigateTo(this.config.pageOnBackCtaPath || []);
  }

  private handleMoveForward() {
    this.routingState.popAndNavigateTo(this.config.pageOnForwardCtaPath || []);
  }

  handleBackClick() {
    this.handleMoveBackward();
    return false;
  }

  handleCtaClick() {
    if (this.processing) {
      return;
    }

    this.processing = true;
    this.handleMoveForward();
  }

  handleSecondaryCtaClick() {}

  handleCardAction(cardDetails: DesktopCard) {

    if (this.processing) {
      return;
    }

    if (cardDetails.analytics && cardDetails.analytics.tapped) {
      this.analytics.logEvent(cardDetails.analytics.tapped);
      if (!this.env.production) {
        console.log(`FIREBASE: v4: click: ${cardDetails.analytics.tapped}`);
      }
    }


    const navToCardCta = () => {
      if (cardDetails.ctaPath) {
        this.routingState.navigateTo(cardDetails.ctaPath.split('/'));
      }
    };

    this.processing = true;
    this.showProcessError = false;
    this.processErrorMessage = '';

    if (this.config.processUpdater) {
      const results = this.config.processUpdater(
                      this.injector,
              cardDetails.key || '',
                      this.translatePipe,
                      this.companyService);

      results.subscribe(
        (result: DisplayCardsProcessResponse) => {
          if (result.status === 'success') {
            navToCardCta();
          } else {
            this.showProcessError = true;
            this.processErrorMessage = result.errorMessage || '';
            this.processErrorMessageTitle = result.errorMessageTitle || 'Something went wrong';
            this.processErrorNavigateOnClose = result.errorNavigateOnClose || [];
            this.processing = false;
          }
        },
        () => {
          this.showProcessError = true;
          this.processErrorMessage = '';
          this.processErrorMessageTitle = '';
          this.processErrorNavigateOnClose = [];
          this.processing = false;
        }
      );
    } else {
      navToCardCta();
    }
  }

  handleErrorClose() {
    this.showProcessError = false;

    if (this.processErrorNavigateOnClose && this.processErrorNavigateOnClose.length > 0) {
      this.routingState.navigateTo(this.processErrorNavigateOnClose);
    }
  }
}
