<article class="action-article"
         [ngStyle]="{'min-height.px': minHeight, 'max-width': maxWidth}"
         [ngClass]="{'edge-to-edge': edgeToEdge}">
  <header class="action-header">
    <div class="header-actions-container">
      <ng-content select="[back-action-top]"></ng-content>
      <ng-content select="[header-actions]"></ng-content>
    </div>
    <ng-content select="[pre-title]"></ng-content>
    <ng-content select="[header-title]"></ng-content>
  </header>
  <section class="action-content">
    <ng-content select="[content-section]"></ng-content>
  </section>
  <footer class="action-footer" [ngClass]="{'hidden-footer': hideFooter, 'bottom-pinned': pinnedFooter, 'centered-footer': centeredFooter}">
    <ng-content select="[footer-top]"></ng-content>
    <div #footer_wrapper class="footer-actions-container">
      <ng-content select="[footer-actions]"></ng-content>
      <ng-content select="[back-action-bottom]"></ng-content>
    </div>
  </footer>
</article>
