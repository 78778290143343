import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { UiMoleculeMediaType } from './media.enum';
import { UiMoleculeMedia } from './media.interface';

@Component({
  selector: 'ui-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiMediaComponent {
  @Input() data: UiMoleculeMedia[];

  /**
   * Base path can be changed if you know your images are stored someplace else.
   *
   * DEFAULT ../assets/
   */
  @Input() basePath = '../assets/';

  mediaType = UiMoleculeMediaType;
}
