import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {
  BsHubService,
} from '@brightside-web/desktop/data-access/core-services';


import {MessageBusInternalService} from '../internal/message-bus-internal.service';

import {
  MessageBusEventChannel,
  MessageBusOutgoingEventKey,
  MessageBusOutgoingEventKeyByValue,
} from '../models/message-bus.enum';
import {
  HubPayload,
  MessageBusEventData,
  MessageBusOutgoingEvent,
  MessageBusOutgoingEventData,
} from '../models/message-bus.interface';
import {MessageBusBaseService} from '../service/message-bus-base.service';
import {MessageBusNativeWindowReference} from '../native-window/message-bus-native-window.reference';

import {ChatService} from '@brightside-web/micro/core/chat';

declare global {
  interface Window {
    GLOBAL_LOADER: unknown;
  }
}

type GenericObject = Record<string, any>;

@Injectable({
  providedIn: 'root',
})
export class MessageBusOutgoingService extends MessageBusBaseService {
  private outgoingEventKeyAsStrings: string[] = Object.entries(MessageBusOutgoingEventKey).map(([_, value]) => value);

  constructor(
    protected chatService: ChatService,
    protected nativeWindowReference: MessageBusNativeWindowReference,
    protected routerService: Router,
    protected translateService: TranslateService,
    protected bsHubService: BsHubService
  ) {
    super();

    this.setUpListenerForOutgoingEvents();

    //This will execute a side-affect on the micro app side to show the spinner on exit
    this.setUpListenerForOutgoingRoute();
  }

  /**
   * Utilized to groom the data and translate any keys into strings at time
   * of event being triggered.
   *
   * @param processObject <objectFromEvent>
   * @returns <objectFromEvent>
   */
  private deepInstantTranslationCopySwap(processObject: GenericObject): any {
    if (!processObject || typeof processObject !== 'object') {
      return processObject;
    }

    let rtnObj = {};

    Object.keys(processObject).map((key: string) => {
      let value = (processObject as any)[key];

      if (value) {
        if (Array.isArray(value)) {
          value = value.map((arryValue) => this.deepInstantTranslationCopySwap(arryValue));
        } else if (key === 'code') {
          value = value;
        } else if (typeof value === 'object') {
          value = this.deepInstantTranslationCopySwap(value);
        } else if (typeof value === 'string') {
          value = this.translateService.instant(value);
        }
      }

      rtnObj = { ...rtnObj, [key]: value };
    });

    return rtnObj;
  }

  /**
   * This method will establish an application wide listener for any outgoing (to native) calls
   * See MessageBusOutgoingEventKey enum and bridge documentation on Wiki
   * https://healthydollar.atlassian.net/wiki/spaces/EN/pages/762970140/Bridge+Events
   */
  private setUpListenerForOutgoingEvents() {
    this.logForDebugging('setUpListenerForOutgoingEvents');

    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.OUTGOING,
      filterByEvents: [...this.outgoingEventKeyAsStrings],
      callbackListener: (payload: HubPayload) => {
        this.logForDebugging('Internal hub event hit', JSON.stringify(payload));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.sendOutgoingEvent({ ...payload, key: MessageBusOutgoingEventKeyByValue(payload.event) });
      },
    });
  }

  private setUpListenerForOutgoingRoute() {
    const windowObjAsAny = this.nativeWindowReference?.nativeWindow as any;

    //If we are exiting micro app view, we need to show loader
    if (windowObjAsAny.GLOBAL_LOADER) {
      MessageBusInternalService.addHubListenerWithEventFilter({
        channel: MessageBusEventChannel.OUTGOING,
        filterByEvents: ['ROUTE'],
        callbackListener: () => {
          windowObjAsAny.GLOBAL_LOADER.show();
        },
      });
    }
  }

  /**
   * This can serve as a place to modify data if the outbound receiver needs special treatment
   * @param outgoingEventData: MessageBusOutgoingEventData
   * @returns MessageBusOutgoingEventData
   */
  private groomMessageBody(
    outgoingEventData: MessageBusEventData | MessageBusOutgoingEventData = {}
  ): MessageBusOutgoingEventData {
    //Add conversation to the chain and make sure they return the copy
    return this.deepInstantTranslationCopySwap(outgoingEventData) as MessageBusOutgoingEventData;
  }

  private checkAndTriggerSideAffectFrom(eventKey: MessageBusOutgoingEventKey): void {
    //When we are using the ROUTE outgoing bridge this means we are going back to
    //container layer and having them redirect the webview. Which should likely be
    //another application.
    if (eventKey === MessageBusOutgoingEventKey.ROUTE || eventKey === MessageBusOutgoingEventKey.EXIT_VIEW) {
      this.bsHubService.dispatch('MessageBusOutgoingChannel', {
        event: MessageBusOutgoingEventKey.ANALYTICS,
        data: { event: "web", action: "exited", parameters: {url: window.location.hostname} },
      });
    }
  }

  /**
   * Direct trigger to send events
   * Internal application callers should utilize MessageBusInternalService.sendOutgoingHubEvent normally
   * @param outgoingEvent MessageBusOutgoingEvent
   */
  sendOutgoingEvent(outgoingEvent: MessageBusOutgoingEvent) {
    if (outgoingEvent.bridgeSetting?.data) {
      outgoingEvent.bridgeSetting.data = this.groomMessageBody(outgoingEvent.bridgeSetting?.data);
    } else {
      outgoingEvent.data = this.groomMessageBody(outgoingEvent.data);
    }

    this.checkAndTriggerSideAffectFrom(outgoingEvent.key);
    this.messageReceiver(outgoingEvent);
  }
}
