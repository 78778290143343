<div class="topbar-wrapper">
  <div class="topbar">
    <div class="logo" >
      <img (click)="logoClick()"
        src="../assets/brightsideLockup.svg"
        alt="Brightside logo"
        height="24"
        class="pointer"
      />
      <ng-content select=".customer-logo"></ng-content>
    </div>
    <div class="actions">
      <ng-content select=".action"></ng-content>
    </div>
    <ng-content select="bw-toast"></ng-content>
  </div>
</div>
