<ng-container *ngIf="data">
  <ng-container
    *ngTemplateOutlet="titleTextTemplate; context: { title: data.title | translate, subTitle: data.subTitle | translate }"
  ></ng-container>
</ng-container>

<ng-template #titleTextTemplate let-title="title" let-subTitle="subTitle">
  <bw-icon
    *ngIf="data.icon"
    [size]="41.6"
    [iconName]="data.icon"
    class="mb-xs"
    color="var(--brand)"
    backgroundColor="var(--bg-blue)"
  ></bw-icon>

  <div *ngIf="title" class="mb-xs">
    <typography headline-semibold>{{ title }}</typography>
  </div>

  <p
    *ngIf="subTitle && (!data.style || data.style === 'default')"
    class="mb-sm"
    text-large-regular
    color="var(--secondary-text)"
    [innerHTML]="subTitle"
  ></p>

  <p
    *ngIf="subTitle && data.style === 'terms'"
    class="mb-xs"
    text-medium-regular
    color="var(--secondary-text)"
    [innerHTML]="subTitle"
  ></p>
</ng-template>
