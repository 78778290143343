import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'bw-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  animations: [
    trigger('enterAnimation', [
      state('show', style({ minHeight: '16px', visibility: 'visible', opacity: 1 })),
      state('hide', style({ minHeight: '0px', visibility: 'hidden', opacity: 0 })),
      transition('show => hide', animate('0.05s')),
      transition('hide => show', animate('0.1s 0.2s'))
    ])
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() value: string = '';
  @Input() verticalSpace = 'mb-xs';
  @Input() horizontalSpace = 'mr-null';
  @Input() inputError = false;
  @Input() hasInfo = false;
  @Input() disabled = false;
  @Input() isFocused = false;
  @Input() maxCharacterWarning = 200;
  @Input() maxCharacterLimit = 250;
  @Input() textAreaHeight = 234;
  @Input() placeholder = '';
  @Input() inputErrorText?: string;

  @Output() inputModelChange = new EventEmitter<string>();
  @Output() hasFocus = new EventEmitter<boolean>();
  @Output() emitInfoClicked = new EventEmitter<boolean>();

  charCount = 0;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor(
    @Self() @Optional() private _ngControl: NgControl,
    private _sanitizer: DomSanitizer
  ) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this;
    }
  }

  infoClicked() {
    this.emitInfoClicked.emit(true);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChangeInput(e: string) {
    this.charCount = e.length;
    const sanitizedValue = this._sanitizer.sanitize(1, e) || '';
    this.inputModelChange.emit(sanitizedValue);
    this.onChange(sanitizedValue);
    this.updateControlStatus();
  }

  private updateControlStatus() {
    const isInvalid = this.charCount > this.maxCharacterWarning;
    if (this._ngControl && this._ngControl.control) {
      this._ngControl.control.setErrors(isInvalid ? { characterCount: true } : null);
    }
  }

  get isInvalid(): boolean | undefined {
    return this._ngControl?.control?.invalid && this._ngControl?.control?.touched;
  }

  onFocus(e: Event) {
    this.hasFocus.emit(true);
    this.isFocused = true;
  }

  onBlur(e: Event) {
    this.hasFocus.emit(false);
    this.isFocused = false;
    this.onTouched();
  }
}
