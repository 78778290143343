import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';

import {
  MessageBusInternalHubEvent,
  MessageBusInternalBridgeSetting,
  MessageBusInternalService,
  MessageBusEventUtil,
} from '@brightside-web/micro/core/message-bus';
import { AnalyticsAttributesV4 } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-display-halfsheet',
  templateUrl: './display-halfsheet.component.html',
})
export class SharedDisplayHalfsheetComponent implements OnChanges {
  @Input() eventName: AnalyticsAttributesV4;
  @Input() internalHubEvent: MessageBusInternalHubEvent;

  displayAnalytics: {
    shown: AnalyticsAttributesV4;
    dismiss: AnalyticsAttributesV4;
    submit: AnalyticsAttributesV4;
  } = {
    shown: {event_name:''},
    dismiss: {event_name:''},
    submit: {event_name:''}
  };

  halfSheetSettings: MessageBusInternalBridgeSetting | undefined;

  showHalfsheet = false;

  constructor() {}

  private sendIncomingEventAndDismiss(triggerCode: string) {
    MessageBusInternalService.sendOutgoingHubEvent(
      MessageBusEventUtil.event.builder.halfsheetConfigAndReturnStandardEvent({}, triggerCode)
    );

    this.dismissModal();
  }

  private setUpAnalytics() {
    if (this.eventName) {
      this.displayAnalytics.shown = { event_name: `${this.eventName}_shown` };
      this.displayAnalytics.dismiss = { event_name: `${this.eventName}_dismiss` };
      this.displayAnalytics.submit = { event_name: `${this.eventName}_tapped` };
    }
  }

  private dismissModal() {
    this.showHalfsheet = false;
    this.halfSheetSettings = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.internalHubEvent?.currentValue?.bridgeSetting) {
      this.setUpAnalytics();

      this.halfSheetSettings = changes.internalHubEvent.currentValue.bridgeSetting;
      this.showHalfsheet = true;
    }
  }

  onPrimaryClick() {
    if (this.halfSheetSettings?.data?.primaryCta) {
      this.sendIncomingEventAndDismiss((this.halfSheetSettings?.data.primaryCta as any).code);
    } else {
      //Log error...
      this.dismissModal();
    }
  }

  onSecondaryClick() {
    if (this.halfSheetSettings?.data?.secondaryCta) {
      this.sendIncomingEventAndDismiss((this.halfSheetSettings?.data.secondaryCta as any).code);
    } else {
      //Log error...
      this.dismissModal();
    }
  }
}
