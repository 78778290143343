<bw-action-article>
    <div content-section>
        <div class="flex-column center mx-4">
            <img src="assets/go-to-brightside-desktop.png" alt="go-to-brightside" height="auto" width="400">

            <p class="go-to-brightside__message">
                Return to Brightside on your desktop
            </p>
        </div>
    </div>
</bw-action-article>