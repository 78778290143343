export class MicroUtilDebugLogger {
  /**
   * If you wish to have developer logging turn this on
   *
   * Default: true
   */
  protected allowVerboseDebugMode = true;

  /**
   * Override this if you want your template to log
   * with certain prefix string
   *
   * Default: ' - '
   */
  protected logPrefix = ' - ';

  protected logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode) console.log(`debug: ${this.logPrefix || '<notSetUp>'}`, message, optionalParams);
  }
}
