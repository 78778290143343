<bw-action-article>
  <ui-nav-exit-back header-actions [trackingDetail]="{ event_name: 'waiting_dismissed' }"></ui-nav-exit-back>

  <div content-section>
    <ng-container>
      <div class="mb-xxl">
        <bw-steps [model]="items" [readonly]="true" [activeIndex]="stepIndex"></bw-steps>
      </div>
      <div class="mb-xxl"></div>
      <bw-spinner brightsideWebTimeout *ngIf="waiting"></bw-spinner>
      <div class="center">
      <div class="mb-sm"><bw-icon *ngIf="failed" iconName="red-alert" size="40"></bw-icon></div>
      <p text-large-semibold class="mb-xs">{{ actionHeader | translate }}</p>
      <p text-large-regular mwTextColor="var(--secondary-text)">
        {{ actionDescription1 | translate }}<br/>{{ actionDescription2 | translate }}
      </p>
      </div>
    </ng-container>
  </div>
</bw-action-article>

