import { Injectable } from '@angular/core';

import { RemoteConfigKey, MicroCoreUtilRemoteConfigService } from './remote-config.service';

export interface RemoteProductsConfig {
  products: Record<RemoteProductType, RemoteProductConfig>;
  'permission-scopes': Record<RemotePermissionScopeKey, RemotePermissionScopes>;
}

export interface RemoteProductConfig {
  permissionScopes: RemotePermissionScopeKey[];
  permission_scope: string; //this will exactly match the GET user api permission_scope
  required_docs: string[]; //this will match to GET user api docs_uploaded
}

export interface RemotePermissionScopes {
  dataToCollect: string[];
  successStatusList: string[];
  failureStatusList: string[];
}

export enum RemoteProductType {
  INTERNAL_SPENDING = 'internalSpending',
}

export enum RemotePermissionScopeKey {
  TIER_ONE = 'tier1',
  TIER_THREE = 'tier3',
}

export const REMOTE_PERMISSION_SCOPE_BASE = 'SEND|RECEIVE|TIER|';

@Injectable({
  providedIn: 'root',
})
export class MicroCoreUtilPermissionProductService {
  async getPermissionsForProduct(productType: RemoteProductType): Promise<RemotePermissionScopes[] | undefined> {
    const fullProductConfigObject: RemoteProductsConfig | undefined = await this.microCoreUtilRemoteConfigService
      .configAsObjectObservable<RemoteProductsConfig>(RemoteConfigKey.PRODUCT_CONFIG)
      .toPromise();

    if (fullProductConfigObject !== undefined) {
      return fullProductConfigObject.products[productType].permissionScopes.map(
        (tierKey) => fullProductConfigObject['permission-scopes'][tierKey]
      );
    }

    return undefined;
  }

  async getConfigForProduct(productType: RemoteProductType): Promise<RemoteProductConfig | undefined> {
    const fullProductConfigObject: RemoteProductsConfig | undefined = await this.microCoreUtilRemoteConfigService
      .configAsObjectObservable<RemoteProductsConfig>(RemoteConfigKey.PRODUCT_CONFIG)
      .toPromise();

    if (fullProductConfigObject !== undefined) {
      return fullProductConfigObject.products[productType];
    }

    return undefined;
  }

  constructor(private microCoreUtilRemoteConfigService: MicroCoreUtilRemoteConfigService) {}
}
