import { Injectable } from '@angular/core';
import {
  AwsApiWrapperService,
} from "@brightside-web/desktop/data-access/core-services";
import {Observable, from} from "rxjs";
import {GooglePlaceDetails, GooglePredictionsArray} from "./api-contracts";

@Injectable({
  providedIn: 'root'
})
export class AddressAutocompleteService {
  constructor(
    private awsApiWrapperService: AwsApiWrapperService,
    ) { }

  getPredictions(address: string, latLong: string): Observable<GooglePredictionsArray> {
    let pathString = `/address/predictions?input=${address}&location=${latLong}&radius=5000&types=address`;
    if (latLong === '') pathString = `/address/predictions?input=${address}&types=address`;

    return from(this.awsApiWrapperService.get('api-mobile', pathString, {}));
  }

  getDetails(placeId: string): Observable<GooglePlaceDetails> {
    const pathString = `/address/details?place_id=${placeId}`;
    return from(this.awsApiWrapperService.get('api-mobile', pathString, {}));
  }
}
