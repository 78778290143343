import {Injectable, OnDestroy} from '@angular/core';
import {from, Observable, of, Subscription, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  BsHubService,
  AwsApiWrapperService,
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';
import {
  RequiredVerification,
  RequiredVerificationsEnums,
  VerificationStatus,
  EligibilityFieldsObject,
  EligibilityFieldsInterface, ApiCacheService, VerificationStatusInterface, CompanyService
} from '..';
import {MobileStateService} from './mobile-state.service';


@Injectable({
  providedIn: 'root'
})
export class RequiredVerificationService implements OnDestroy {
  private sub = new Subscription();

  requiredVerifications: RequiredVerification[] | undefined;
  overriddenNextVerification: RequiredVerification | undefined;
  isIdentityCompleted: boolean;
  hasBeenCleared: boolean;

  company: string;
  employmentType: string;

  apiVersion = '1.4';

  electiveProducts: string[] = [];

  constructor(
    private mobile:MobileStateService, private apiSvc: ApiCacheService,
    private companyService: CompanyService,
    private bsCacheService: BsCacheService,
    private awsApiWrapper: AwsApiWrapperService,
    private bsHubService: BsHubService
    ) {
    this.bsHubService.listen(RequiredVerificationsEnums.HUBCHANNEL, data => {
      if (data.payload.event === 'clear') {
        this.clearRequiredVerifications();
      }
    });
  }

  getDataToCollect(replay: boolean = false) : Observable<EligibilityFieldsInterface>{
    const params = {};
    let payload: any;
    const company = this.companyService.getCompany();

    return from(this.bsCacheService.getItem('employmentType')).pipe(
      switchMap(employmentType => {
        if (company) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params['company'] = company;
        }
        if (employmentType) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params['employment_type'] = employmentType;
        }
        if (params) {
          payload = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return from(this.awsApiWrapper.get('api-mobile', `/client/eligibility/data-collect?fieldVersion=${this.apiVersion}&replay=${replay}&${payload}`, {}));
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  clearRequiredVerifications() {
    this.hasBeenCleared = true;
    this.requiredVerifications = undefined;
    window.localStorage.removeItem(RequiredVerificationsEnums.LOCALSTORAGE);
  }

  getRequiredVerifications(replay?: boolean, skipCreation?: boolean):Observable<RequiredVerification[] | null> {

    if (replay) {
      return this.getDataToCollect(true).pipe(
        map(response => {

            if (response.eligibility_fields && response.eligibility_fields.length > 0) {
              const newVerifications : RequiredVerification[] = [];
              response.eligibility_fields.forEach( value => {
                newVerifications.push({type: value.name, eligibility_field: value});
              });
              this.electiveProducts = response.elective_products ?? [];
              return newVerifications;
            } else {
              return [];
            }
          }
        )
      );
    }

    return this.getDataToCollect(false).pipe(
      map(response => {

          if (response.eligibility_fields && response.eligibility_fields.length > 0) {
            const newVerifications : RequiredVerification[] = [];
            this.requiredVerifications = [];

            response.eligibility_fields.forEach( value => {
              newVerifications.push({type: value.name, eligibility_field: value});
            });

            window.localStorage.setItem(RequiredVerificationsEnums.LOCALSTORAGE, JSON.stringify(newVerifications));
            this.requiredVerifications = newVerifications;

            this.electiveProducts = response.elective_products ?? [];

            return newVerifications;
          } else {
            this.requiredVerifications = [];
            return [];
          }
        }
      )
    )
  }

  getVerificationOfType(type: string): RequiredVerification | undefined {
    return this.requiredVerifications?.find(verification => verification.type === type);
  }

  setRequiredVerifications() {
    window.localStorage.setItem(RequiredVerificationsEnums.LOCALSTORAGE, JSON.stringify(this.requiredVerifications));
  }

  updateRequiredVerificationStatus(type: string, status: VerificationStatus) {
    if(this.requiredVerifications) {
      const updateVerification = this.getVerificationOfType(type);
      if (updateVerification) {
        updateVerification['status'] = status;
      } else {
        this.requiredVerifications.push({type: type, status: status});
      }
    } else {
      this.requiredVerifications = [{type: type, status: status}];
    }
    this.setRequiredVerifications();
  }

  getRequiredVerificationStatus(type: string): string | undefined {
    return this.getVerificationOfType(type)?.status;
  }

  getNextRequiredVerification():Observable<RequiredVerification> {

    return this.getRequiredVerifications(false).pipe(
      map( verifications => {
        if (verifications) {
          const nextVerification = verifications.find(verification => !verification.status || verification.status !== VerificationStatus.COMPLETED);
          return nextVerification ? nextVerification : {type: 'none'};
        } else {
          return {type: 'none'};
        }
      })
    );
  }

  setNextRequiredVerification(verification: RequiredVerification) {
    this.overriddenNextVerification = verification;
  }

  identityCompleted(isCompleted?:boolean) {
    this.isIdentityCompleted = true;
  }

}

