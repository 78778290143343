import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {Observable, of} from "rxjs";
import {
  MobileStateService,
  VerificationStatusEnum,
  VerificationStatusService
} from "@brightside-web/desktop/data-access/shared";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VerificationStatusGuard {
  constructor(
    private verificationStatus: VerificationStatusService,
    private router: Router,
    private mobileState: MobileStateService
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) : Observable<boolean> {
    return this.isUserEmploymentVerified(next.url.join('/'));
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isUserEmploymentVerified(route.path || '');
  }

  isUserEmploymentVerified(path: string) {
    const pathIncludesStatus = path.includes('status');

    return this.verificationStatus.getEmploymentVerificationStatus().pipe(
      map(resp => {
          if (path === "home") return true;
          if (resp.employment_data_verification_status.status === VerificationStatusEnum.VERIFIED) {
            if (pathIncludesStatus) {
              this.router.navigate(['/']);
              return false;
            }
            return true;
          } else {
            if (pathIncludesStatus) {
              return true;
            }
            this.router.navigate(['/verification/status']);
            return false;
          }
      }),
      catchError( (error) => {
        if (error.response.data.statusCode === 404) {
          this.router.navigate(['/registration/select-employer']);
        }
        return of(false);
      })
    )

  }

}
