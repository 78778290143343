import { Component, EventEmitter, Input, Output, ChangeDetectorRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true
};

@Component({
  selector: 'bw-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CHECKBOX_VALUE_ACCESSOR]
})
export class CheckboxComponent implements ControlValueAccessor {
  model: any;
  focused = false;

  @Input() checked = false;
  @Input() value: any;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() label: string;
  @Input() name: string;
  @Input() centerAlign = false;
  @Input() rounded = false;
  @Input() singleMargin = false;
  @Output() checkboxChanged: EventEmitter<any> = new EventEmitter();

  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  onClick(event: any, checkbox: any, focus: boolean) {
    // added so that consent links could trigger a new tab
    // with consent document
    const target  = event.target as HTMLElement;
    if (!target.classList.contains('bypass')) {
      event.preventDefault();

      if (this.disabled || this.readonly) {
        return;
      }

      this.checked = !this.checked;
      this.updateModel();

    }
  }

  updateModel() {
    if (this.checked) this.addValue();
    else this.removeValue();

    this.onModelChange(this.model);

    this.checkboxChanged.emit(this.checked);
  }

  removeValue() {
    this.model = false;
  }

  addValue() {
    this.model = true;
  }

  handleChange(event: any) {
    if (!this.readonly) {
      this.checked = event.target.checked;
      this.updateModel();
    }
  }

  isChecked(): boolean {
    return this.model;
  }

  writeValue(model: any): void {
    this.model = model;
    this.checked = this.isChecked();
    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
    this.onModelTouched();
  }
}
