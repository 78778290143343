import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import {map} from "rxjs/operators";
interface FeatureFlagKillSwitchResolverInterface {
  flag: string | string[];
  flagDefault?:boolean;
  toOnTrue?: string[];
  toOnFalse?: string[];
  allowIfFalse?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagKillSwitchGuard implements CanActivate {

  constructor(
    private featureFlagSvc: FeatureFlagService,
    private router: Router
  ) {}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkFeatureFlag(route);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkFeatureFlag(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkFeatureFlag(childRoute);
  }

  checkFeatureFlag(route: Route|ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (route.data?.featureFlagInfo) {
      const flagInfo = route.data?.featureFlagInfo as FeatureFlagKillSwitchResolverInterface;
      const flagsArray: string[] = typeof flagInfo.flag === 'string' ? [flagInfo.flag] : flagInfo.flag;
      return this.featureFlagSvc.checkMultipleFlags(flagsArray).pipe(
        map((boolResult: boolean) => this.killSwitchLogic(boolResult, flagInfo))
      );
    } else {
      console.warn('This guard requires data.featureFlagInfo');
      return of(false);
    }
  }

  killSwitchLogic(boolResult:boolean, flagInfo:FeatureFlagKillSwitchResolverInterface) : boolean {
    if (boolResult) {
      if (flagInfo.toOnTrue) {
        this.router.navigate(flagInfo.toOnTrue);
        return false;
      } else {
        // this guy means that if the flag is true we shouldn't go there
        if (flagInfo.allowIfFalse) {
          return false;
        }
        return true;
      }
    } else {
      if (flagInfo.toOnFalse) {
        this.router.navigate(flagInfo.toOnFalse);
        return false;
      } else if (flagInfo.allowIfFalse) {
        return true;
      }
      this.router.navigate(['home']);
      return false;
    }
  }
}
