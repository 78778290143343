import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {forkJoin, from, Observable, of} from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';
import {RegistrationService} from "@brightside-web/desktop/data-access/onboarding";
import { BsCacheService } from "@brightside-web/desktop/data-access/core-services";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IdentityGuard implements CanActivate {

  neededParams: string[]= ['referrerType', 'firstName', 'clientType', 'company'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private regSvc: RegistrationService,
    private bsCacheService: BsCacheService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.regSvc.skipAccountCreation) {
      return of(true);
    }
    return forkJoin([
      from(this.bsCacheService.getItem('customerType')),
      from(this.bsCacheService.getItem('employmentType'))
    ]).pipe(
      map(([customerType,employmentType]) => {
        if (customerType && employmentType) {
          return true;
        } else {
          return this.router.createUrlTree(['registration/type']);
        }
      })
    );
  }

}
