import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface ChangeCheckboxEventArgs {
  checked: boolean;
}

@Component({
  selector: 'bw-reactive-checkbox',
  templateUrl: './reactive-checkbox.component.html',
  styleUrls: ['./reactive-checkbox.component.scss']
})
export class ReactiveCheckboxComponent  {
  @Input() fcName: string;
  @Input() fGroup: FormGroup;
  @Input() fgName: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Output()
  readonly changed: EventEmitter<ChangeCheckboxEventArgs> = new EventEmitter<ChangeCheckboxEventArgs>();

  constructor() {}

  toggle() {
    if (this.disabled || this.readonly) {
      return;
    }

    // this.focused = false;
    this.checked = !this.checked;

    this.changed.emit({ checked: this.checked });
    // this._onChangeCallback(this.checked);
  }

  onCheckboxClick(event: any) {
    event.stopPropagation();

    if (this.readonly) {
      event.preventDefault();
    }

    this.toggle();
  }
}
