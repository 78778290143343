<div *ngIf="listItems && listItems.length > 0" class="list-container">
  <ng-container *ngFor="let item of listItems">
    <bw-cell
    [singleLeftLabel]="item.label"
    iconNameRight="chevron_right"
    separatorType="full"
    (clicked)="onLineClicked(item)"
  ></bw-cell>
  </ng-container>
</div>
