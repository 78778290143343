import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModel } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  @Input() fbPage: string;
  @Input() fbCategory: string;
  @Input() fbAction: string;
  @Input() model: ModalModel;
  @Output() primaryClick = new EventEmitter();
  @Output() secondaryClick = new EventEmitter();
  constructor() {}

  primaryCtaClick($event: MouseEvent) {
    this.primaryClick.emit($event);
  }

  secondaryCtaClick($event: MouseEvent) {
    this.secondaryClick.emit($event);
  }
}
