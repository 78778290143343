import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {DocumentsKeyEnum, LegalDocsResponsePayload, LegalDocumentsService, LegalDocsResponsePayloadItems} from "../legal-documents.service";
import {Observable, Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {take} from "rxjs/operators";
import {MessageBusEventUtil, MessageBusInternalService, MessageBusOutgoingEventKey} from "@micro-core/message-bus";

export interface LegalDocsPageConfigInterface {
  showCloseButton?: boolean;
  showBackbutton?: boolean;
  closeButtonRoute?: string[];
  pageOnForwardCtaPath: string[];
  documentsKey: DocumentsKeyEnum;
}

@Component({
  selector: 'brightside-web-required-legal-documents',
  templateUrl: './required-legal-documents.component.html',
  styleUrls: ['./required-legal-documents.component.scss'],
  providers: [ LegalDocumentsService ]
})
export class RequiredLegalDocumentsComponent implements OnInit {

  sub = new Subscription();

  @Input() documentsKey: string;
  @Input() pageOnForwardCtaPath: string[];
  @Input() showBackbutton = true;
  @Input() skipActionEmit = true;
  @Input() showCloseButton = false;
  @Input() closeButtonRoute: string[] | undefined;
  @Output() closeButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() backButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() termsSubmittedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() title = 'LEGAL_DOCUMENTS_TITLE';
  @Input() subtitle = 'LEGAL_DOCUMENTS_SUBTITLE';

  processing: Observable<boolean> = this.legalDocumentsService.processing;
  requiredDocs$: Observable<LegalDocsResponsePayloadItems[]>;
  docsSubmitted: Observable<boolean> = this.legalDocumentsService.docsSubmitted;
  displayDocsRequiredModal: Observable<boolean> = this.legalDocumentsService.displayDocsRequiredModal;
  displayGeneralError: Observable<boolean> = this.legalDocumentsService.displayGeneralError;

  confirmDataForm: FormGroup = new FormGroup({
    confirmCheckbox: new FormControl('', [Validators.requiredTrue])
  });

  config: LegalDocsPageConfigInterface;

  constructor(
    protected legalDocumentsService: LegalDocumentsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.requiredDocs$ = this.legalDocumentsService.requiredDocuments;
  }

  ngOnInit(): void {
    if (this.documentsKey) {
      setTimeout(()=>{
        this.legalDocumentsService.getRequiredDocuments(this.documentsKey as DocumentsKeyEnum);
      },0);
    } else if (
      this.activatedRoute?.snapshot?.data?.pageConfig?.documentsKey
    ) {
      this.config = this.activatedRoute?.snapshot?.data?.pageConfig;
      this.documentsKey = this.config.documentsKey;
      this.pageOnForwardCtaPath = this.config.pageOnForwardCtaPath ?? '';
      this.closeButtonRoute = this.config.closeButtonRoute ?? undefined;
      this.showCloseButton = this.config.showCloseButton ?? false;
      this.showBackbutton = this.config.showBackbutton ?? true;
      setTimeout(()=>{
        this.legalDocumentsService.getRequiredDocuments(this.documentsKey as DocumentsKeyEnum);
      },0);
    } else  {
      console.warn('Legal Docs component needs configs to run');
    }
    this.sub.add(
      this.docsSubmitted.subscribe(
        resp => {
          this.docsSubmittedResponse(resp);
        }
      )
    );
  }

  onSubmit() {
    this.legalDocumentsService.acceptRequiredDocuments();
  }

  handleCloseClick() {
    if (this.closeButtonRoute) {
      MessageBusEventUtil.routeToPath(this.closeButtonRoute.join('/'));
    } else {
      this.closeButtonEvent.emit(true);
    }
  }

  docsSubmittedResponse(success:boolean) {
    if (success) {
      if (!this.pageOnForwardCtaPath) {
        this.termsSubmittedEvent.emit(true);
      } else {
        MessageBusEventUtil.routeToPath(this.pageOnForwardCtaPath.join('/'));
      }
    } else {
      this.termsSubmittedEvent.emit(false);
      //unknown error?
    }
  }

  refetchDocuments() {
    this.confirmDataForm.reset();
    this.legalDocumentsService.getRequiredDocuments(this.documentsKey as DocumentsKeyEnum);
  }

  closeError() {
    this.legalDocumentsService.hideGeneralError();
  }

  handleDocClick(downloadUrl: string, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    MessageBusEventUtil.sendOutgoing({
      event: MessageBusOutgoingEventKey.EXTERNAL_LINK,
      bridgeSetting: {
        data: {
          url: downloadUrl,
          chatIcon: false,
        },
      },
    });
  }

  emitBackButtonEvent() {
    if (!this.skipActionEmit) this.backButtonEvent.emit(true);
  }
}
