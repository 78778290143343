import * as Sentry from '@sentry/angular'
import { Environment } from '@micro-core/environment';

export class PerformanceTrackingConfigure {
  constructor() {}

  public setUpConfig(environment: Environment) {
    Sentry.init({
      dsn: environment.performance.sentry.dsn,
      environment: environment.performance.sentry.environment,
      release: environment.appVersion || window.location.hostname.split('.')[1],
      integrations: [
        Sentry.browserTracingIntegration({
          instrumentNavigation: true          
        }),
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],

      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

  }
}
