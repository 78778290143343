import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Toast, ToastType, ToastDelay } from '@brightside/brightside-ui-services';


@Component({
  selector: 'bw-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() id = 'default-toast';
  @Input() fade = true;
  @Output() emit = new EventEmitter();
  @Output() emitClosed = new EventEmitter();
  @Input() toastObs = new Observable<Toast>();
  toasts: Toast[] = [];

  toastSubscription: Subscription;

  constructor() {}

  resetToasts(){
    this.toasts = [];
  }

  ngOnInit() {
    this.toastSubscription = this.toastObs.subscribe(toast => {
      if (toast.message === 'delete') {
        this.toasts = this.toasts.filter(t => t.id !== toast.id);
        return;
      }

      this.toasts.push(toast);

      if (toast.transient) {
        setTimeout(() => this.removeToast(toast), ToastDelay);
      }
    });
  }

  ngOnDestroy() {
    if (this.toastSubscription) {
      this.toastSubscription.unsubscribe();
    }
  }

  removeToast(toast: Toast) {
    toast.closing = true;
    this.emit.emit(toast);
    if (this.fade) {
      toast.fade = true;
      setTimeout(() => {
        this.toasts = this.toasts.filter(x => x !== toast);
      }, 301);
    } else {
      this.toasts = this.toasts.filter(x => x !== toast);
    }
  }

  cssClass(toast: Toast) {
    if (!toast) return;

    const classes = ['toast'];
    const toastTypeClass = {
      [ToastType.Success]: 'toast-success',
      [ToastType.Error]: 'toast-danger',
      [ToastType.Info]: 'toast-info',
      [ToastType.Warning]: 'toast-warning',
      [ToastType.InfoBlue]: 'toast-infoblue'
    };

    classes.push(toastTypeClass[toast.type]);

    if (toast.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  emitLinkClicked(toast: Toast) {
    const toastToDeliver = {...toast};
    this.removeToast(toast);
    this.emit.emit(toastToDeliver);
  }
}
