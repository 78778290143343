import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { UiTemplateContentTitleText } from '../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-title-text',
  templateUrl: './title-text-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTemplateContentTitleTextComponent {
  @Input() data: UiTemplateContentTitleText;
}
