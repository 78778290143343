<ng-container>

    <bw-action-article minHeight="0" [brightsideWebFb]="displayAnalytics.shown">
      <div id="displayFormHeaderAction" header-actions class="flex-row pointer">
        <bw-icon
          *ngIf="config.pageHideBackButton; else showBackButton"
          color="var(--secondary-text)"
          iconName="close"
          (click)="handleBackClick()"
        ></bw-icon>

        <ng-template #showBackButton>
          <ui-nav-exit-back
            *ngIf="!pageIsFullScreen"
            back-action-top
            [trackingDetail]="displayAnalytics.dismiss"
            [skipActionEmit]="!config.pageOnBackCtaPath"
            (exitActionHandler)="handleBackClick()"
          ></ui-nav-exit-back>
          <ui-nav-exit-back
            *ngIf="pageIsFullScreen"
            back-action-bottom
            [trackingDetail]="displayAnalytics.dismiss"
            [skipActionEmit]="!config.pageOnBackCtaPath"
            (exitActionHandler)="handleBackClick()"
          ></ui-nav-exit-back>
        </ng-template>
      </div>
      <div header-title>
        <typography title-medium class="mt-xs">{{ config.pageTitleKey || config.pageTitle | translate }}</typography>
      </div>
      <div content-section>
        <bw-paragraph>
          <span [innerHTML]="config.pageSubTitleKey || config.pageSubTitle | translate"></span>
        </bw-paragraph>
        <ng-container *ngFor="let item of config.pageContentArray">
          <typography text-medium-semibold>
              {{ item[config.pageContentArrayKey.title] | translate }}
          </typography>
          <bw-paragraph>
          <ul>
            <li *ngFor="let docs of item[config.pageContentArrayKey.listItems]">
              {{ docs | translate }}
            </li>
          </ul>
          </bw-paragraph>
        </ng-container>
      </div>

      <div footer-top>
        <ng-container *ngIf="config.pageConfirmCheckText && checkboxForm">
          <form [formGroup]="checkboxForm">
            <bw-checkbox
              required
              formControlName="controlConfirmCheckbox"
              [label]="config.pageConfirmCheckText | translate"
              [centerAlign]="true"
              (checkboxChanged)="checkboxForm.markAllAsTouched()"
            ></bw-checkbox>
          </form>
        </ng-container>
      </div>
      <div footer-actions class="flex-row row-reverse">
        <button
          *ngIf="config.pageCtaLabel || config.pageCtaLabelKey"
          bw-button
          [brightsideWebFb]="displayAnalytics.submit"
          [disabled]="config.pageConfirmCheckText && (!checkboxForm?.touched || !checkboxForm?.valid || !checkboxForm?.dirty)"
          (click)="handleCtaClick()"
        >
          {{ config.pageCtaLabelKey || config.pageCtaLabel | translate }}
        </button>
      </div>
    </bw-action-article>
</ng-container>

