import { Observable } from 'rxjs';

import {
  HubPayload,
  MessageBusInternalService,
  MessageBusEventUtil,
  MessageBusHalfsheetStyle,
  MessageBusEventChannel,
  UseThisForStandaloneOrThat,
  MessageBusIncomingEventKey,
  MessageBusInternalEventKey,
} from '@brightside-web/micro/core/message-bus';

const FILE_SIZE_LIMIT = 5.2;

const ICON_NAME_SELECTED = 'trash';
const ICON_NAME_UNSELECTED = UseThisForStandaloneOrThat('camera', 'upload');

const ACTION_EMPHASIZE_SELECTED = false;
const ACTION_EMPHASIZE_UNSELECTED = true;
const DELETE_HALF_SHEET_CONFIG = MessageBusEventUtil.event.builder.halfsheetConfigAndReturnStandardEvent({
  id: 'documentUploadImageDeleteConfirm',
  title: 'DOC_UPLOAD_DELETE_HALFSHEET_TITLE',
  subText: 'DOC_UPLOAD_DELETE_HALFSHEET_SUBTEXT',
  primaryCta: {
    code: 'documentUpload_DeleteFile',
    label: 'DOC_UPLOAD_DELETE_HALFSHEET_PRIMARY_CTA',
    style: MessageBusHalfsheetStyle.WARNING,
  },
  secondaryCta: {
    code: 'documentUpload_IgnoreAction',
    label: 'DOC_UPLOAD_DELETE_HALFSHEET_SECONDARY_CTA',
  },
});

const SIZE_HALF_SHEET_CONFIG = MessageBusEventUtil.event.builder.halfsheetConfigAndReturnStandardEvent({
  id: 'documentUploadImageSizeTooLargeConfirm',
  title: 'DOC_UPLOAD_SIZE_CAMERA_HALFSHEET_TITLE',
  subText: 'DOC_UPLOAD_SIZE_CAMERA_HALFSHEET_SUBTEXT',
  primaryCta: {
    code: 'documentUpload_NewPicture', //These should match with the codes expected in your hub handler
    label: 'DOC_UPLOAD_SIZE_CAMERA_HALFSHEET_PRIMARY_CTA',
    style: MessageBusHalfsheetStyle.PRIMARY,
  },
  secondaryCta: {
    code: 'documentUpload_ChoosePhoto', //These should match with the codes expected in your hub handler
    label: 'DOC_UPLOAD_SIZE_CAMERA_HALFSHEET_SECONDARY_CTA',
  },
});

const SIZE_HALF_SHEET_CONFIG_NO_CAPTURE = MessageBusEventUtil.event.builder.halfsheetConfigAndReturnStandardEvent({
  id: 'documentUploadImageSizeTooLargeConfirm',
  title: 'DOC_UPLOAD_SIZE_UPLOAD_HALFSHEET_TITLE',
  subText: 'DOC_UPLOAD_SIZE_UPLOAD_HALFSHEET_SUBTEXT',
  primaryCta: {
    code: 'documentUpload_ChoosePhoto', //These should match with the codes expected in your hub handler
    label: 'DOC_UPLOAD_SIZE_UPLOAD_HALFSHEET_PRIMARY_CTA',
    style: MessageBusHalfsheetStyle.PRIMARY,
  },
  secondaryCta: {
    code: 'documentUpload_Cancel', //These should match with the codes expected in your hub handler
    label: 'DOC_UPLOAD_SIZE_UPLOAD_HALFSHEET_SECONDARY_CTA',
  },
});

const _putFileIntoCache = (key: string, value: string, file?: Blob) => {
  MessageBusInternalService.sendInternalHubEvent({
    event: MessageBusInternalEventKey.SAVE_FILE,
    data: { docKey: key, docValue: value, file },
  });
};

const _buildFileStringAndPutToCache = (key: string, file: Blob, retry: boolean = false) => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);

  reader.onload = function () {
    _putFileIntoCache(key, btoa(reader.result as string), file);
  };
  reader.onerror = function () {
    if (!retry) {
      _buildFileStringAndPutToCache(key, file, true);
    }
  };
};

const showAndReactToFileSizeHalfSheet = (key: string) => {
  /**
   * This watcher will wait for events from the halfsheet bridge info
   * and execute the mapped callback for the code provided.
   */
  MessageBusInternalService.addHubListenerWithEventFilter({
    channel: MessageBusEventChannel.INCOMING,
    filterByEvents: [MessageBusIncomingEventKey.HALF_SHEET],
    take: 1, //Note this is here to make sure we don't get more than one image selector shown
    callbackListener: (payload: HubPayload) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const code = payload.data.body.code;

      if (code === 'documentUpload_NewPicture' || code === 'documentUpload_ChoosePhoto') {
        const fileInput = document.getElementById(`${key}FileInputField`);

        if (code === 'documentUpload_ChoosePhoto') {
          fileInput?.removeAttribute('capture');
        } else {
          fileInput?.setAttribute('capture', 'camera');
        }

        fileInput?.click();
      }
    },
  });

  MessageBusInternalService.sendOutgoingHubEvent(
    UseThisForStandaloneOrThat(SIZE_HALF_SHEET_CONFIG, SIZE_HALF_SHEET_CONFIG_NO_CAPTURE)
  );
};

const removeSelectedDocument = (key: string) => {
  const fileInput = document.getElementById(`${key}FileInputField`);

  if (fileInput) {
    (fileInput as any).value = null;
  }
};

const startDocumentUploadPicker = (key: string): Observable<string> =>
  new Observable<string>((responseSubscriber) => {
    const fileInput = document.getElementById(`${key}FileInputField`);

    if (fileInput) {
      fileInput.removeAttribute('capture');
      fileInput.onchange = (event: any, fileAsBase64: string = '') => {
        if (fileAsBase64) {
          _putFileIntoCache(key, fileAsBase64);

          responseSubscriber.next('document');
          responseSubscriber.complete();
        } else if (event?.target?.files) {
          const file: File = event.target.files[0];

          //We have a limitation on size of the file
          if (file.size && file.size / 1024 ** 2 > FILE_SIZE_LIMIT) {
            showAndReactToFileSizeHalfSheet(key);
            return;
          }

          _buildFileStringAndPutToCache(key, file);

          responseSubscriber.next(file.name);
          responseSubscriber.complete();
        } else {
          responseSubscriber.next('');
          responseSubscriber.complete();
        }
      };

      fileInput.click();
    } else {
      responseSubscriber.next('');
      responseSubscriber.complete();
    }
  });

const startDocumentUploadCapture = (key: string): Observable<string> =>
  new Observable<string>((responseSubscriber) => {
    const fileInput = document.getElementById(`${key}FileInputField`);

    if (fileInput) {
      fileInput.setAttribute('capture', 'camera');
      fileInput.onchange = (event: any, fileAsBase64: string = '') => {
        if (fileAsBase64) {
          _putFileIntoCache(key, fileAsBase64);

          responseSubscriber.next('document');
          responseSubscriber.complete();
        } else if (event?.target?.files) {
          const file: File = event.target.files[0];

          //We have a limitation on size of the file
          if (file.size && file.size / 1024 ** 2 > FILE_SIZE_LIMIT) {
            showAndReactToFileSizeHalfSheet(key);
            return;
          }

          _buildFileStringAndPutToCache(key, file);

          responseSubscriber.next(file.name);
          responseSubscriber.complete();
        } else {
          responseSubscriber.next('');
          responseSubscriber.complete();
        }
      };

      fileInput.click();
    } else {
      responseSubscriber.next('');
      responseSubscriber.complete();
    }
  });

export const SharedDisplayFormDocumentUtil = {
  getSetting: {
    selected: { icon: ICON_NAME_SELECTED, emphasize: ACTION_EMPHASIZE_SELECTED },
    unselected: { icon: ICON_NAME_UNSELECTED, emphasize: ACTION_EMPHASIZE_UNSELECTED },
    deleteHalfSheet: DELETE_HALF_SHEET_CONFIG,
  },
  removeSelectedDocument,
  startDocumentUploadCapture,
  startDocumentUploadPicker,
};
