import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';

import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { UiBaseDataMoleculeComponent, UiBaseDataMoleculeSchema } from '../../../shared/base-data/base-data-molecule.component';

import { UiNavigationExitType } from '../../navigation.enum';
import { UiNavigationExitData } from '../../navigation.interface';

@Component({
  selector: 'ui-nav-exit-back',
  templateUrl: './exit-back.component.html',
  styleUrls: ['./exit-back.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNavigationExitBackComponent
  extends UiBaseDataMoleculeComponent
  implements UiBaseDataMoleculeSchema, OnInit, OnDestroy
{
  /**
   * Allow developer to pass in data with an object
   *
   * Interface UiFooterDefaultData
   */
  @Input() override data: UiNavigationExitData;

  /**
   * Allow developer to pass in data single fields at a time
   */
  @Input() infoLabel = 'BACK_WEB';
  @Input() trackingDetail: { event_name: string } = { event_name: 'missing_event_name_nav_exit' };
  @Input() type: UiNavigationExitType;

  /**
   * Please set this to true to skip emitting on exit action
   */
  @Input() skipActionEmit = false;

  @Output() exitActionHandler = new EventEmitter();

  exitTypeEnum = UiNavigationExitType;

  constructor(private bsHubService: BsHubService) {
    super();
  }

  ngOnInit(): void {
    this.schemaValidationConfig = {
      directInputValues: [this.infoLabel],
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.exitActionHandler.unsubscribe();
  }

  public override uiOnDirectInputsFromDataObject(): void {
    this.infoLabel = this.data?.inputs?.infoLabel || this.infoLabel || 'BACK_WEB';

    this.trackingDetail = this.data?.inputs?.trackingDetail ||
      this.trackingDetail || { event_name: 'missing_event_name_nav_exit' };
    this.type = this.data?.inputs?.type || this.type || UiNavigationExitType.NONE;
  }

  public exitActionTrigger() {
    /**
     * If we are NOT skipping emitting and we have observer, trigger .emit
     */
    if (!this.skipActionEmit && this.exitActionHandler.observers.length > 0) {
      this.exitActionHandler.emit();
      this.logForDebugging('Emit done on exit action instead of default');
      return;
    }

    this.bsHubService.dispatch('RoutingServiceChannel', { event: 'EXIT_PAGE', data: { fromBack: true } });
  }
}
