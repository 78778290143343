import { IsRunningAndroid } from '../service/platform/platform-android.service';
import { IsRunningIOS } from '../service/platform/platform-ios.service';

const UseThisForStandaloneOrThat = (valueForStandalone: any, valueForThat: any) =>
  IsRunningAndroid() || IsRunningIOS() ? valueForStandalone : valueForThat;

const UseThisForEmbeddedOrThat = (valueForEmbedded: any, valueForThat: any) =>
  !IsRunningAndroid() && !IsRunningIOS() ? valueForEmbedded : valueForThat;

export { UseThisForStandaloneOrThat, UseThisForEmbeddedOrThat };
