import { Component, Input, Output, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { DesktopCard } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-desktop-cards',
  templateUrl: './desktop-cards.component.html',
  styleUrls: ['./desktop-cards.component.scss'],
})
export class DesktopCardsComponent implements OnInit {
  @Input() title: string;
  @Input() titleSize = 'title-medium';

  @Input() cards: DesktopCard[];
  @Input() cardStyle = 'default'; // Either default or singleLine

  @Input() verticalSpaceTopTitle = '0';
  @Input() verticalSpaceTitle = '0';

  @Output() clicked = new EventEmitter();
  @Output() clickedTitleAction = new EventEmitter();
  @Output() closed = new EventEmitter();

  isSingleLineStyle = false;

  constructor() {}

  ngOnInit(): void {
    this.isSingleLineStyle = this.cardStyle === 'singleLine';
  }

  clickedTitleLink() {
    this.clickedTitleAction.emit();
  }

  clickedPassthrough(card: DesktopCard) {
    this.clicked.emit(card);
  }

  closedPassthrough(key: string) {
    this.closed.emit(key);
  }
}
