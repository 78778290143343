import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import {
  UiTemplateContentSelectAmount,
  UiTemplateFormContentValueChanged,
} from '../../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-select-amount',
  template:
    '<bw-currency-select [fcGroup]="localFormGroup" [fcName]="localFormControlName" [increment]="data.increment || 1" [suppressKeyboardInput]="true"></bw-currency-select>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTemplateContentSelectAmountComponent implements OnInit {
  @Input() data: UiTemplateContentSelectAmount;

  @Output() formValuesChanged: EventEmitter<UiTemplateFormContentValueChanged> = new EventEmitter();

  localFormGroup: FormGroup;
  localFormControlName = 'currencySelect';

  ngOnInit(): void {
    this.localFormControlName = this.data.groupName || this.localFormControlName;
    this.localFormGroup = new FormGroup({
      [this.localFormControlName]: new FormControl(this.data.value || 0),
    });

    this.localFormGroup.valueChanges.subscribe(() => this.amountChanged());

    //Trigger the amount change to ensure we get the init value
    this.amountChanged();
  }

  amountChanged() {
    this.formValuesChanged.emit({
      name: this.localFormControlName,
      value: Number(this.localFormGroup.get(this.localFormControlName)?.value || 0),
    });
  }
}
