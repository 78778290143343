import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import {BrightsideUiModule} from "@brightside/brightside-ui";
import {BrightsideUiServicesModule} from "@brightside/brightside-ui-services";
import {RouterModule, Routes} from "@angular/router";
import {SharedDisplayModule} from "@brightside-web/shared/display";
import {
  FirebaseAnalyticsService,
  FirebaseService, MicroCoreUtilityModule,
  MicroUtilTranslationService
} from "@micro-core/utility";

import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MicroUiMoleculeModule} from "@micro-ui/molecule";
import {MicroUiTemplatePageModule} from "@micro-ui/template/page";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedDesktopModule} from "@brightside-web/shared/desktop";
import { TermsComponent } from './terms/terms.component';
import { SharedLegalDocumentsModule } from "@brightside-web/shared/legal-documents";

const moduleRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'terms',
        component: TermsComponent,
      },
      {
        path: '**',
        redirectTo: 'terms',
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'terms',
  },
]

@NgModule({
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    MicroCoreUtilityModule,
    MicroUiMoleculeModule,
    MicroUiTemplatePageModule,
    ReactiveFormsModule,
    RouterModule.forChild(moduleRoutes),
    TranslateModule,
    SharedDesktopModule,
    SharedDisplayModule,
    SharedLegalDocumentsModule
  ],
  declarations: [
    LandingComponent,
    TermsComponent
  ],
  providers: [
    { provide: FirebaseService, useClass: FirebaseAnalyticsService },
    { provide: TranslateService, useClass: MicroUtilTranslationService },
  ],
})
export class MicroTermsUpdateModule {
  constructor() {}
}
