<div class="content-layout" [brightsideWebFb]="displayFormAnalytics.shown">
  <bw-action-article>
    <ng-container *ngIf="config.pageOnBackCtaPath">
      <ui-nav-exit-back
        *ngIf="!config.pageHideBackButton && !pageIsFullScreen"
        back-action-top
        [trackingDetail]="displayFormAnalytics.dismiss"
        [skipActionEmit]="!config.pageOnBackCtaPath"
        (exitActionHandler)="handleBackClick()"
      ></ui-nav-exit-back>
      <ui-nav-exit-back
        *ngIf="!config.pageHideBackButton && pageIsFullScreen"
        back-action-bottom
        [trackingDetail]="displayFormAnalytics.dismiss"
        [skipActionEmit]="!config.pageOnBackCtaPath"
        (exitActionHandler)="handleBackClick()"
      ></ui-nav-exit-back>
    </ng-container>

    <div header-title class="mb-sm">
      <typography title-medium>{{ config.pageTitleKey || config.pageTitle | translate }}</typography>
    </div>

    <div content-section>
      <p text-large-regular class="mb-md" color="var(--secondary-text)">
        {{ config.pageSubTitleKey || config.pageSubTitle | translate }}
      </p>
<ng-container *ngIf="displayForm">
  <form [formGroup]="displayForm">
    <div class="flex-row flex-start flex-wrap mb-sm">
      <div
        *ngFor="let controlConfig of config.pageFormControls"
        class="mr-sm mb-sm"
        [ngClass]="{
              'flex-item-flexible max-form-width': config.pageFormControlFullWidth || config.pageFormControls.length === 1,
              'flex-item-half': !config.pageFormControlFullWidth && config.pageFormControls.length > 1
            }"
      >
        <bw-phone-form
          *ngIf="controlConfig.type === 'phone'; else nonePhoneInput"
          [label]="controlConfig.labelKey || controlConfig.label | translate"
          [fGroup]="displayForm"
          [fcName]="controlConfig.key"
          [inputError]="doesControlHaveError(controlConfig.key) && (controlConfig.errorText || controlConfig.errorTextKey)"
          [inputErrorText]="controlConfig.errorTextKey || controlConfig.errorText | translate"
          [disabled]="processing"
        ></bw-phone-form>
        <ng-template #nonePhoneInput>
          <bw-input
            [formControlName]="controlConfig.key"
            [type]="controlConfig.type"
            [label]="controlConfig.labelKey || controlConfig.label | translate"
            [disabled]="processing"
            [inputError]="doesControlHaveError(controlConfig.key) && (controlConfig.errorText || controlConfig.errorTextKey)"
            [inputErrorText]="controlConfig.errorTextKey || controlConfig.errorText | translate"
            [inputMask]="controlConfig.inputOptions?.mask"
            [minlength]="controlConfig.inputOptions?.minlength"
            [maxlength]="controlConfig.inputOptions?.maxlength"
            [pattern]="controlConfig.inputOptions?.pattern"
          >
          </bw-input>
        </ng-template>
        <a
          *ngIf="controlConfig.inputOptions?.link"
          bw-anchor
          anchorStyle="text-small-regular"
          color="var(--brand)"
          (click)="handleLinkClick(controlConfig.inputOptions?.link.path ?? [])"
          [brightsideWebFb]="displayFormAnalytics.submit"
        >
          {{ controlConfig.inputOptions?.link.textKey || controlConfig.inputOptions?.link.text || '' | translate }}
        </a>
      </div>
    </div>
  </form>
</ng-container>

    </div>

    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="config.pageCtaLabel || config.pageCtaLabelKey"
        bw-button
        [brightsideWebFb]="displayFormAnalytics.submit"
        [disabled]="!displayForm.touched || !displayForm.valid"
        [processing]="processing"
        (click)="handleCtaClick()"
      >
        {{ config.pageCtaLabelKey || config.pageCtaLabel | translate }}
      </button>

      <typography text-large-semibold>
        <a *ngIf="config.pageSecondaryCtaLabel" bw-anchor class="bs-blue mr-sm" (click)="handleSecondaryCtaClick()">{{
          config.pageSecondaryCtaLabel | translate
        }}</a>
      </typography>
    </div>
  </bw-action-article>
</div>

<bw-modal *ngIf="showProcessError" (closeModal)="showProcessError = false">
  <typography title-medium color="var(--gray-70)">{{ processErrorMessageTitle | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">{{ processErrorMessage | translate }}</bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="showProcessError = false"
        [brightsideWebFb]="config.analyticsAttributes('submit', { page: 'error_modal' })"
      >
        Ok
      </button>
    </div>
  </footer>
</bw-modal>
