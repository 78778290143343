import { Injectable } from '@angular/core';
import {forkJoin, from, Observable, of} from "rxjs";
import {ApiCacheService} from "./api-cache.service";
import {EligibilityFieldsObject} from "./api-contracts";

import {
  BsCacheService, SimpleGlobalModalService
} from '@brightside-web/desktop/data-access/core-services';
import { map } from 'rxjs/operators';


export interface ProdEligResponseInterface {
  products: ProdEligObjInterface[]
}

export interface ProdEligObjInterface {
  name: string;
  required_fields?: ProdEligReqFieldsInterface[]
}

export interface ProdEligReqFieldsInterface {
  name: string;
  api_parameter_name: string;
  unique_identifier_flag?: boolean;
  sub_fields?: EligibilityFieldsObject[];
  not_replayable?: boolean;
  value?: string;
}

export enum ProductPostIntroMapping {
  '/external-accounts/intro/launch' = 'cashFlow',
  '/home/savings/consents' = 'payrollSavings',
  '/credit-setup/consents' = 'creditScore',
  '/loans/consents' = 'loan'
}

export enum ProductCodes {
  PayrollSavings = "payrollSavings",
  Loan = "loan",
  Spending = "spending",
  CreditScore = "creditScore",
  CashFlow = "cashFlow",
  CashAdvance = "cashAdvance",
  Invite = "invite",
  Registration = "registration"
}

export const nonRequiredProductFields: string[] = ['middle_name', 'address2'];

@Injectable({
  providedIn: 'root',
})
export class ProductEligibilityService {

  completedVerifications: string[] = [];
  apiPath = '/client/product/data-collect?fieldVersion=1.1';

  constructor(
    private apiCache: ApiCacheService,
    private bsCacheService: BsCacheService,
    private globalModalSvc: SimpleGlobalModalService) {
    const routeKeys = Object.keys(ProductPostIntroMapping);
    const routeKeyCache$: Observable<any>[] = [];

    routeKeys.forEach((cacheKey) => {
      routeKeyCache$.push(from(this.bsCacheService.getItem(cacheKey)));
    });

  }

  getProductEligibility(): Observable<ProdEligResponseInterface>;
  getProductEligibility(productCode: ProductCodes): Observable<ProdEligObjInterface | undefined>;
  getProductEligibility(productCode?: ProductCodes): Observable<ProdEligResponseInterface | ProdEligObjInterface | undefined> {
    return from(this.apiCache.get<ProdEligResponseInterface>('api-mobile', this.apiPath, {})).pipe(
      map((response) => {
        if (productCode) {
          return response.products.find((product) => product.name === productCode);
        } else {
          return response;
        }
      })
    );
  }

  getProductCodeRedirect(productCode: string): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const redirectPath = Object.keys(ProductPostIntroMapping)[Object.values(ProductPostIntroMapping).indexOf(productCode)];
    return redirectPath ? redirectPath : '/';
  }

  hasMissingFields(productCode: ProductCodes): Observable<boolean> {
    return this.getProductEligibility(productCode).pipe(
      map(fields => {
        if (fields?.required_fields) {
          return this.checkFieldsForEmpty(fields.required_fields);
        } else {
          throw Error('Product not found');
        }
      })
    )
  }

  showVerifyIntroModal(ctaActionFunc: Function) {
    this.globalModalSvc.createModal({
      title: 'VERIFY_IDENTITY_INTRO_TITLE',
      headerImagePath: './assets/data-verification.png',
      contentKey: 'VERIFY_IDENTITY_INTRO_DESC',
      displayCloseButton: false,
      eventName: 'identity_verification_halfsheet',
      isError: false,
      size: "medium",
      ctaKey: 'BUTTON_CONTINUE',
      ctaAction: () => {
        ctaActionFunc();
      }
    });
  }

  testReqValueForEmpty(field: ProdEligReqFieldsInterface): boolean {
    return field.value === null
      || (!field.value && !nonRequiredProductFields.includes(field.name))
      || (field.name === 'middle_name' && field.value === 'middle');
  }

  checkFieldsForEmpty(fields: ProdEligReqFieldsInterface[]): boolean {
    for (const field of fields) {
      if (field.sub_fields) {
        for (const subField of field.sub_fields) {
          if (!subField.not_replayable) {
            if (this.testReqValueForEmpty(subField)) {
              return true;
            }
          }
        }
      } else if (field.api_parameter_name) {
        if (!field.not_replayable) {
          if (this.testReqValueForEmpty(field)) {
            return true;
          }
        }
      }
    }
    return false;
  }


}
