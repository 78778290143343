<div class="tx-cell" (click)="handleClick($event)" [ngClass]="{clickable: _isClickable}">
  <div class="tx-cell-head">
    <div class="tx-cell-label secondary-text type-sm">{{txCellLabel}}</div>
    <div class="tx-cell-value type-md">{{txCellValue}}</div>
  </div>
  <div class="tx-cell-details">
    <p class="type-sm">
    {{txCellDetails}}
    </p>
  </div>
</div>
