<ng-container [ngSwitch]="cardConfig.cardStyle">
  <ng-container *ngSwitchCase="'large'">
    <ng-container *ngTemplateOutlet="imageTop"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'small'">
    <ng-container *ngTemplateOutlet="imageRight"></ng-container>
  </ng-container>
</ng-container>

<ng-template #imageTop>
  <div
    class="action-card action-card--image-top flex-column space-between mb-lg"
    [ngClass]="{ 'action-card--has-image': cardConfig.image }"
  >
    <div class="action-card__hero flex-column" [style.background-image]="'url(' + cardConfig.image + ')'"></div>
    <div class="flex-column action-card__content">
      <ng-container *ngIf="cardConfig.label">
        <ng-container *ngTemplateOutlet="label"></ng-container>
      </ng-container>
      <typography *ngIf="cardConfig.title" text-medium-semibold class="mb-sm">{{ cardConfig.title }}</typography>
      <typography text-xsmall-regular class="action-card__body">{{ cardConfig.body }}</typography>
      <div class="flex-row space-between action-card_footer_action mt-sm">
        <div class="flex-column">
          <bw-icon *ngIf="cardConfig.optionalIcon" [iconName]="cardConfig.optionalIcon"></bw-icon>
        </div>
        <div class="flex-column">
          <div tabindex="1" class="action-card__cta_icon" (click)="emitCtaClicked()" (keyup.enter)="emitCtaClicked()">
            {{ cardConfig.ctaString }}
            <bw-icon [iconName]="'arrow_right'" [color]="'var(--blue-50)'" [size]="18"></bw-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #imageRight>
  <div
    class="action-card action-card--image-right flex-row space-between"
    [ngClass]="{
      'action-card--has-image': cardConfig.image,
      'no-min-height': cardConfig.removeMinHeight,
      'no-max-width': cardConfig.removeMaxWidth,
      'mb-md': cardConfig.marginBottom === 'medium',
      'mb-lg': cardConfig.marginBottom === 'large'
    }"
    [style.background-color]="cardConfig.bgColor || 'unset'"
    (click)="emitCtaClicked()"
    (keyup.enter)="emitCtaClicked()"
  >
    <div class="flex-column action-card__content">
      <ng-container *ngIf="cardConfig.label">
        <ng-container *ngTemplateOutlet="label"></ng-container>
      </ng-container>
      <typography text-medium-semibold class="mb-sm">{{ cardConfig.title }}</typography>
      <typography text-xsmall-regular>{{ cardConfig.body }}</typography>
    </div>
    <div
      tabindex="1"
      class="action-card__cta flex-column ml-sm"
      [style.background-image]="'url(' + cardConfig.image + ')'"
      [style.background-position]="cardConfig.backgroundImagePosition || 'center'"
    >
      <bw-icon
        *ngIf="!!cardConfig.iconButton"
        [iconName]="cardConfig.iconButton"
        [horizontalSpace]="'mr-xxs'"
        [size]="cardConfig.iconButtonSize"
        [color]="cardConfig.iconbuttonColor"
        class="pr-sm"
      ></bw-icon>
      <div *ngIf="!cardConfig.hideCta" class="action-card__cta_icon">
        <bw-icon [iconName]="'arrow_right'" [color]="'var(--blue-50)'"></bw-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #label>
  <div class="flex-row action-card__label mb-xs">
    <bw-icon *ngIf="cardConfig.labelIcon" [iconName]="cardConfig.labelIcon" [size]="16" [horizontalSpace]="'mr-xs'"></bw-icon>
    <typography text-xsmall-regular>{{ cardConfig.label }}</typography>
    <typography *ngIf="cardConfig.labelSub" text-xsmall-regular>&nbsp;-&nbsp;{{ cardConfig.labelSub }}</typography>
  </div>
</ng-template>
