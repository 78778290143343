export enum MicroCoreUtilApiResponseCode {
  _200 = 200,
  _404 = 404,
  _405 = 405,
  _409 = 409,
}

export const MicroCoreUtilApiResponseErrorCode = {
  UNKNOWN: 'UNKNOWN_ERROR',
};
