import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';

export enum CardTypes {
  Promo = 'promo',
  Action = 'action',
  Info = 'info',
  ActiveProduct = 'active-product'
}
@Component({
  selector: 'bw-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() cardType: CardTypes;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() bgImageSrc: string;
  @Input() closeable: boolean;
  @Input() invertedColor: boolean;
  @Input() cardTextColor: string;
  @Input() cardBannerColor: string;

  @Output() closeCard = new EventEmitter();
  @Output() cardAction = new EventEmitter();

  isPromoCard: boolean;
  isActionCard: boolean;
  isInfoCard: boolean;
  isActiveProductCard: boolean;

  @HostBinding('className') hostClass: string;
  @HostBinding('class.has-banner') hasCardBanner = false;
  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    if (this.cardType === CardTypes.Promo) {
      this.isPromoCard = true;
    }
    if (this.cardType === CardTypes.Action) {
      this.isActionCard = true;
    }
    if (this.cardType === CardTypes.Info) {
      this.isInfoCard = true;
      this.hostClass = 'info-card';
    }
    if (this.cardType === CardTypes.ActiveProduct) {
      this.isActiveProductCard = true;
      this.hostClass = 'activated-product';
    }
    if (this.invertedColor) {
      this.hostClass = 'inverted';
    }
    this.hasCardBanner = this.elRef.nativeElement.lastElementChild.localName === "bw-card-banner";
  }

  handleCloseCard(event: Event) {
    this.closeCard.emit();
  }

  handleCardAction(event: Event) {
    this.cardAction.emit(event);
  }
}

@Component({
  selector: 'bw-card-header',
  template: ` <ng-content></ng-content> `
})
export class CardHeaderComponent {}

@Component({
  selector: 'bw-card-body',
  template: ` <ng-content></ng-content> `
})
export class CardBodyComponent {}

@Component({
  selector: 'bw-card-footer',
  template: ` <ng-content></ng-content> `,
})
export class CardFooterComponent {}

@Component({
  selector: 'bw-card-banner',
  template: ` <div class="card-banner"><div class="card-banner-bg" [ngStyle]="{ 'background-color': cardBannerColor }"></div><ng-content></ng-content></div> `,
  styleUrls: ['card-banner.scss']
})
export class CardBannerComponent {
  @Input() cardBannerColor: string;
}
