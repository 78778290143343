import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MicroUiMoleculeModule } from '@micro-ui/molecule';

import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { TranslateModule } from '@ngx-translate/core';

//ToDo: need to extract this from desktop
import { SharedDesktopModule } from '@brightside-web/shared/desktop';

import { SelectDropDownModule } from 'ngx-select-dropdown';

import { SharedDisplayCampaignComponent } from './display-campaign/display-campaign.component';
import { SharedDisplayCompleteComponent } from './display-complete/display-complete.component';
import { SharedDisplayFormChoiceComponent } from './display-form/form-choice/form-choice.component';
import { SharedDisplayFormComponent } from './display-form/shared-display-form.component';
import { SharedDisplayFormFileComponent } from './display-form/form-file/form-file.component';
import { SharedDisplayHalfsheetComponent } from './display-halfsheet/display-halfsheet.component';
import { SharedDisplayReviewComponent } from './display-review/display-review.component';
import { SharedDisplayWaitingComponent } from './display-waiting/display-waiting.component';
import { SharedDisplayContentListComponent } from "./display-content-list/shared-display-content-list.component";

const exported = [
  SharedDisplayCampaignComponent,
  SharedDisplayCompleteComponent,
  SharedDisplayFormChoiceComponent,
  SharedDisplayFormComponent,
  SharedDisplayFormFileComponent,
  SharedDisplayHalfsheetComponent,
  SharedDisplayReviewComponent,
  SharedDisplayWaitingComponent,
  SharedDisplayContentListComponent,
];

@NgModule({
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    MicroUiMoleculeModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    SharedDesktopModule,
    TranslateModule,
  ],
  declarations: [...exported],
  exports: exported,
})
export class SharedDisplayModule {}
