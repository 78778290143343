<div class="accordion">
  <div *ngFor="let item of items;index as i" class="accordion-item" [class.disabled]="item.disabled" [class.active]="expanded.has(i)">
    <ng-container [ngTemplateOutlet]="itemHeader" [ngTemplateOutletContext]="{$implicit: item, index: i, toggle: i | getToggleFunction: toggleState}"></ng-container>
    <div class="accordion-item-content" [class.expanded]="expanded.has(i)" @contentExpansion *ngIf="expanded.has(i)">
      <div class="pt-sm pr-sm pb-sm pl-sm">
        <ng-container *ngTemplateOutlet="item?.content?.templateRef"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #itemHeader let-item let-index="index">
  <header class="accordion-item-header" (click)="item.disabled ? {} :toggleState(index)">
    <ng-container *ngTemplateOutlet="itemTitle"></ng-container>
    <button class="accordion-toggle" [disabled]="item.disabled">
      <bw-icon iconName="chevron_right"></bw-icon>
    </button>
  </header>
  <ng-template #itemTitle>
    <p text-large-regular class="secondary-text">{{item?.title}}</p>
  </ng-template>
</ng-template>