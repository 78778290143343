import { Injectable } from '@angular/core';
import { ApiCacheService, SavingsPayrollScheduleResponse } from '@brightside-web/desktop/data-access/shared';
import {Observable, of} from 'rxjs';
const useRealData = true;
@Injectable({
  providedIn: 'root',
})
export class SavingsPayrollService {
  constructor(private apiCache: ApiCacheService) {}

  getSchedule(force: boolean = false): Observable<SavingsPayrollScheduleResponse> {
    if (useRealData) {
      if (force) {
        this.apiCache.refreshItem('/payroll/schedule');
      }

      return this.apiCache.get<SavingsPayrollScheduleResponse>('api-mobile', '/payroll/schedule');
    }
    return of({
    "schedule": [
        "2023-03-03",
        "2023-03-17",
        "2023-03-31",
        "2023-04-14",
        "2023-04-28",
        "2023-05-12",
        "2023-05-26",
        "2023-06-09",
        "2023-06-23",
        "2023-07-07",
        "2023-07-21",
        "2023-08-04",
        "2023-08-18",
        "2023-09-01",
        "2023-09-15",
        "2023-09-29",
        "2023-10-13",
        "2023-10-27",
        "2023-11-10",
        "2023-11-24",
        "2023-12-08",
        "2023-12-22",
        "2024-01-05",
        "2024-01-19",
        "2024-02-02",
        "2024-02-16",
        "2024-03-01",
        "2024-03-15",
        "2024-03-29",
        "2024-04-12",
        "2024-04-26",
        "2024-05-10",
        "2024-05-24",
        "2024-06-07",
        "2024-06-21",
        "2024-07-05",
        "2024-07-19",
        "2024-08-02",
        "2024-08-16",
        "2024-08-30",
        "2024-09-13",
        "2024-09-27",
        "2024-10-11",
        "2024-10-25",
        "2024-11-08",
        "2024-11-22",
        "2024-12-06",
        "2024-12-20",
        "2025-01-03"
    ]
})
  }
}
