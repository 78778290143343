import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AgeValidator } from '../validators/validators';


export interface DisplayFormControlConfig {
  infoKey?: string;
  classes?: string;
  styles?: string;
  key: string;
  control: FormControl;
  label?: string;
  labelKey?: string;
  type: string;
  errorText?: string;
  errorTextKey?: string;
  showInfo?: boolean;
  inputInfo?: InputInfoInterface;
  hasFocus?: boolean;
  inputOptions?: {
    initialValue?: string | number;
    placeholder?: string;
    mask?: string;
    minlength?: number;
    maxlength?: number;
    pattern?: any;
    link?: { path: string[]; text: string; textKey?: string };

    beginValidationOn?: AngularFormControlValidationTypes;

    inputHintText?: string;
    inputHintTextKey?: string;
    suffixIconName?: string;
    togglePassword?: boolean;
    // select is using a 3rd party component that does not translate for us, selectOptions and selectConfig must be PRE-translated
    selectOptions?: {
      name: string;
      code: string;
    }[];
    selectConfig?: { [key: string]: unknown };
  };
}

export interface InputInfoInterface {
  infoKey: string;
  titleKey: string;
  bodykey: string;
  ctaKey: string;
  eventName: string;
}

export enum AngularFormControlValidationTypes {
  TOUCHED = 'touched',
  DIRTY = 'dirty'
}

export const CONTROL_FNAME: DisplayFormControlConfig = {
  key: 'fName',
  labelKey: 'FIRST_NAME',
  control: new FormControl('', [Validators.required, Validators.minLength(2)]),
  type: 'text',
  errorTextKey: 'VERIFY_EMPLOYEES_FIRST_NAME_ERROR',
  inputOptions: {
    minlength: 2,
  },
};

export const CONTROL_FNAME_PRIMARY: DisplayFormControlConfig = {
  key: 'fName',
  labelKey: 'FIRST_NAME',
  control: new FormControl('', [Validators.required, Validators.minLength(2)]),
  type: 'text',
  errorTextKey: 'VERIFY_EMPLOYEES_FIRST_NAME_ERROR',
  inputOptions: {
    minlength: 2,
  },
  classes: 'flex-item-half'
};

export const CONTROL_MNAME: DisplayFormControlConfig = {
  key: 'mName',
  label: 'Middle Name(s)',
  labelKey: 'MIDDLE_NAME',
  control: new FormControl('', []),
  type: 'text',
  inputOptions: {
    minlength: 2,
  },
};

export const CONTROL_LNAME: DisplayFormControlConfig = {
  key: 'lName',
  labelKey: 'LAST_NAME',
  control: new FormControl('', [Validators.required, Validators.minLength(2)]),
  type: 'text',
  errorTextKey: 'VERIFY_EMPLOYEES_LAST_NAME_ERROR',
  inputOptions: {
    minlength: 2,
  },
};

export const CONTROL_LNAME_PRIMARY: DisplayFormControlConfig = {
  key: 'lName',
  labelKey: 'LAST_NAME',
  control: new FormControl('', [Validators.required, Validators.minLength(2)]),
  type: 'text',
  errorTextKey: 'VERIFY_EMPLOYEES_LAST_NAME_ERROR',
  inputOptions: {
    minlength: 2,
  },

  classes: 'flex-item-half'
};

export const CONTROL_DOB: DisplayFormControlConfig = {
  key: 'dateOfBirth',
  label: 'Date of birth',
  labelKey: 'DATE_OF_BIRTH',
  control: new FormControl('', [
    Validators.required,
    //Validators.pattern('(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d'),
    Validators.minLength(10),
    Validators.maxLength(10),
    AgeValidator(),
  ]),
  type: 'date',
  errorTextKey: 'VERIFY_EMPLOYEES_DOB_ERROR',
  inputOptions: {
    //pattern: '(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d',
  },
};

export const CONTROL_DOB_PRIMARY: DisplayFormControlConfig = {
  key: 'dateOfBirth',
  label: 'Date of birth',
  labelKey: 'DATE_OF_BIRTH',
  control: new FormControl('', [
    Validators.required,
    //Validators.pattern('(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d'),
    Validators.minLength(10),
    Validators.maxLength(10),
    AgeValidator(),
  ]),
  type: 'date',
  errorTextKey: 'VERIFY_EMPLOYEES_DOB_ERROR',
  inputOptions: {
    //pattern: '(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d',
  },
  classes: 'flex-item-flexible max-form-width'
};

export const CONTROL_LAST_FOUR: DisplayFormControlConfig = {
  key: 'lastFourSSN',
  label: 'Last 4 SSN',
  labelKey: 'LAST_4_SSN',
  control: new FormControl('', [
    Validators.required,
    Validators.maxLength(4),
    Validators.minLength(4),
    Validators.pattern('(\\d{4})'),
  ]),
  type: 'text',
  errorText: 'Please enter last 4 digits of your recipient’s SSN.',
  errorTextKey: 'ERROR_EMPTY_SSN',
  inputOptions: {
    mask: 'XXXX',
    minlength: 4,
    maxlength: 4,
    //pattern: '(\\d{4})',
  },
};

export const CONTROL_LAST_FOUR_PRIMARY: DisplayFormControlConfig = {
  key: 'lastFourSSN',
  label: 'Last 4 SSN',
  labelKey: 'LAST_4_SSN',
  control: new FormControl('', [
    Validators.required,
    Validators.maxLength(4),
    Validators.minLength(4),
    Validators.pattern('(\\d{4})'),
  ]),
  type: 'text',
  errorText: 'Please enter last 4 digits of your recipient’s SSN.',
  errorTextKey: 'ERROR_EMPTY_SSN',
  inputOptions: {
    mask: 'XXXX',
    minlength: 4,
    maxlength: 4,
    //pattern: '(\\d{4})',
  },
  classes: 'flex-item-flexible max-form-width'
};

export const CONTROL_SSN: DisplayFormControlConfig = {
  key: 'fullSSN',
  label: 'Social Security Number',
  labelKey: 'SOCIAL_SECURITY_NUMBER',
  control: new FormControl('', [Validators.required, Validators.maxLength(11), Validators.minLength(11)]),
  type: 'text',
  errorText: 'Please enter a valid SSN.',
  errorTextKey: 'ERROR_INVALID_SSN',
  inputOptions: {
    placeholder: 'XXX-XX-XXXX',
    mask: '000-00-0000',
    minlength: 11,
    maxlength: 11,
    inputHintText: 'We use AES-256 encryption to secure your data',
    inputHintTextKey: 'ENCRYPTION_DESC',
    suffixIconName: 'lock',
  },
};

export const CONTROL_EMAIL: DisplayFormControlConfig = {
  key: 'email',
  label: 'Email address',
  control: new FormControl('', [Validators.required, Validators.minLength(2)]),
  type: 'text',
  errorText: 'Please enter your recipient’s email.',
  inputOptions: {
    minlength: 2,
  },
};

export const CONTROL_PHONE: DisplayFormControlConfig = {
  key: 'phoneNumber',
  label: 'Phone number',
  control: new FormControl('', [Validators.required, Validators.minLength(2)]),
  type: 'phone',
  errorText: 'Please enter your recipient’s phone number.',
  inputOptions: {
    minlength: 2,
  },
};

export const CONTROL_ZIP: DisplayFormControlConfig = {
  key: 'zipCode',
  labelKey: 'ADDRESS_ZIP_CODE',
  control: new FormControl('', [
    Validators.required, Validators.maxLength(5),
    Validators.minLength(5)
  ]),
  type: 'text',
  errorTextKey: 'VERIFY_EMPLOYEES_ZIP_ERROR',
  inputOptions: {
    minlength: 5,
    maxlength: 5,
    pattern: '^[0-9]{5}?$'
  },
};

export const CONTROL_ZIP_PRIMARY: DisplayFormControlConfig = {
  key: 'zipCode',
  labelKey: 'ADDRESS_ZIP_CODE',
  control: new FormControl('', [
    Validators.required, Validators.maxLength(5),
    Validators.minLength(5)
  ]),
  type: 'text',
  errorTextKey: 'VERIFY_EMPLOYEES_ZIP_ERROR',
  inputOptions: {
    minlength: 5,
    maxlength: 5,
    pattern: '^[0-9]{5}?$'
  },
  classes: 'flex-item-flexible max-form-width'
};

export const CONTROL_STREET_ADDRESS_LINE_ONE: DisplayFormControlConfig = {
  key: 'streetAddressLineOne',
  label: 'Street address',
  labelKey: 'ADDRESS1_TITLE',
  control: new FormControl('', [Validators.required]),
  type: 'text',
  errorText: 'Please enter your address.',
  errorTextKey: 'Please enter your address.',
};

export const CONTROL_STREET_ADDRESS_LINE_TWO: DisplayFormControlConfig = {
  key: 'streetAddressLineTwo',
  label: 'Street address 2 (Optional)',
  labelKey: 'ADDRESS2_TITLE',
  control: new FormControl(''),
  type: 'text',
  errorText: 'Please enter your address.',
  errorTextKey: 'Please enter your address.',
};

export const CONTROL_ADDRESS_CITY: DisplayFormControlConfig = {
  key: 'addressCity',
  label: 'City',
  labelKey: 'CITY_TITLE',
  control: new FormControl('', [Validators.required]),
  type: 'text',
  errorText: 'Please enter your City.',
  errorTextKey: 'Please enter your City.',
};

export const CONTROL_ADDRESS_STATE: DisplayFormControlConfig = {
  key: 'addressState',
  label: 'State',
  labelKey: 'STATE_TITLE',
  control: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
  type: 'text',
  errorText: 'Please enter your State.',
  errorTextKey: 'Please enter your State.',
  inputOptions: {
    minlength: 2,
    maxlength: 2,
    pattern:
      '^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$',
  },
};


function fullStateValidator(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let statesArray = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
    statesArray = statesArray.map(state => state.toLowerCase());
    let stateFound = false;
    if (statesArray.includes(control.value.toLowerCase())) stateFound = true;
    return !stateFound ? {stateNotFound: 'state not found'} : null;
  }
}

export const CONTROL_ADDRESS_WORK_STATE: DisplayFormControlConfig = {
  key: 'workState',
  label: 'Work State',
  labelKey: 'WORK_STATE_TITLE',
  control: new FormControl('', [Validators.required, fullStateValidator()]),
  type: 'text',
  errorTextKey: 'Please enter your Work State.',
  inputOptions: {},
};



export const CONTROL_ADDRESS_ZIP: DisplayFormControlConfig = {
  key: 'zipCode',
  label: 'Zip code',
  control: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(5)]),
  type: 'text',
  errorText: "Please enter the employees' zip code",
  errorTextKey: "VERIFY_EMPLOYEES_ZIP_ERROR",
  inputOptions: {
    minlength: 5,
    maxlength: 5,
    pattern: '^[0-9]{5}?$',
  },
};

export const CONTROL_SALARY: DisplayFormControlConfig = {
  key: 'annualSalary',
  labelKey: 'ANNUAL_INCOME',
  control: new FormControl('', [Validators.required, Validators.min(1)]),
  type: 'text',
  errorTextKey: 'VERIFY_IDENTITY_SALARY_ERROR',
};
