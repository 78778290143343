import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {
  AccountCreationResponses,
  FinancialAccountsCreationService
} from "../services/financial-accounts-creation.service";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {GenericResponseCode} from "@brightside-web/desktop/data-access/core-services";
import {FinancialAccountTypes} from "../models/financial-account-models";


@Component({
  selector: 'brightside-web-financial-account-creation-waiting',
  templateUrl: './financial-account-creation-waiting.component.html',
  styleUrls: ['./financial-account-creation-waiting.component.css'],
})
export class FinancialAccountCreationWaitingComponent implements OnInit {

  createType:FinancialAccountTypes;

  accountCreatedResp: Observable<AccountCreationResponses | GenericResponseCode> = this.faCreateSvc.accountCreatedResp;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private faCreateSvc: FinancialAccountsCreationService) {
  }

  ngOnInit(): void {

    this.accountCreatedResp.pipe(
      take(1)
    )
    .subscribe( resp => {
      // if (resp) {
      //   this.router.navigate(['../dashboard'],{ relativeTo: this.route });
      // } else {
      //   throw new Error('oh no');
      // }
    });

    this.createType = this.route.snapshot.data['productType'];
    if (Object.values(FinancialAccountTypes).includes(this.createType)) {
      this.faCreateSvc.createAccount(this.createType);
    } else {
      throw new Error('invalid account type');
    }
  }

}
