import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { UiHeaderType } from '../header.enum';
import { UiHeaderDefaultInputs } from '../header.interface';

@Component({
  selector: 'ui-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiHeaderDefaultComponent implements UiHeaderDefaultInputs {
  type: UiHeaderType = UiHeaderType.DEFAULT;

  @Input() title: string;
  @Input() subTitle: string;
  @Input() icon: string;
}
