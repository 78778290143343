import { EventEmitter, Component, OnInit, Input, Output } from "@angular/core";
export interface CellListItem {
  label: string;
  value: string;
  hiddenValue?: string;
}
@Component({
  selector: 'bw-cell-list',
  templateUrl: './cell-list.component.html',
  styleUrls: ['./cell-list.component.scss'],
})
export class CellListComponent implements OnInit {
  @Input() listItems: CellListItem[];

  @Output() clicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  private copyToClip(copyText: string) {}

  onLineClicked(item: CellListItem) {
    this.clicked.emit(item);
  }
}
