<div class="bui-column-container">
  <ng-container *ngFor="let row of rows">
    <div class="bui-column-row" [ngStyle]="{'margin-bottom.px': row.spacing}">
      <ng-container [ngSwitch]="row.type">
        <typography class="type-lg-bold column-row-heading" *ngSwitchCase="'heading'" [ngStyle]="{'color': row.color}">{{row.content}}</typography>
        <typography class="type-md column-row-paragraph" *ngSwitchCase="'paragraph'" [ngStyle]="{'color': row.color}">{{row.content}}</typography>
        <typography class="type-xs column-row-label secondary-text" *ngSwitchCase="'label'" [ngStyle]="{'color': row.color}">{{row.content}}</typography>
        <typography class="type-md-bold column-row-detail" *ngSwitchCase="'detail'" [ngStyle]="{'color': row.color}">{{row.content}}</typography>
        <typography class="type-md column-row-single" *ngSwitchCase="'single'" [ngStyle]="{'color': row.color}">{{row.content}}</typography>
        <typography class="type-md column-row-wrapping" *ngSwitchCase="'wrapping'" [ngStyle]="{'color': row.color}">{{row.content}}</typography>
        <img class="column-row-image" *ngSwitchCase="'image'" [src]="row.content" />
        <bw-icon *ngSwitchCase="'icon'" iconName="row.content" color="row.color" [size]="row.size"></bw-icon>
        <typography class="type-md column-row-label" *ngSwitchDefault>{{row.content}}</typography>
      </ng-container>
    </div>
  </ng-container>
</div>
