import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirebaseService, NotificationService } from '@brightside-web/desktop/data-access/shared';
import { ToastService } from '@brightside/brightside-ui-services';
import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

@Component({
  selector: 'brightside-web-mobile-app-modal',
  templateUrl: './mobile-app-modal.component.html',
  styleUrls: ['./mobile-app-modal.component.scss'],
})
export class MobileAppModalComponent implements OnInit, OnDestroy {
  private sub = new Subscription();
  constructor(
    private notificationService: NotificationService,
    private toastService: ToastService,
    private firebaseService: FirebaseService,
    private bsHubService: BsHubService
  ) {}
  @Output() ctaClick = new EventEmitter();
  processing: any;
  ngOnInit(): void {
    this.bsHubService.listen('ErrorChannel', () => {
      this.onRetryEvent();
    });
  }

  handleTextMobileLink() {
    this.processing = true;
    this.sub.add(
      this.notificationService.generateDynamicLink('Chat', 'chat').subscribe(
        () => {
          this.processing = false;
          this.ctaClick.emit('submit');
        },
        () => {
          this.processing = false;
          this.toastService.error('Looks like something went wrong with Text Me Link. ', { link: 'Retry', transient: false });
          this.firebaseService.logEvent('error_shown', { 'error id': 'text_mobile_link' });
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private onRetryEvent() {
    this.toastService.success('Attempting retry of Text Me Link.');
    this.handleTextMobileLink();
  }
}
