export * from './content-util-map.enum';

export enum UiTemplateContentType {
  CDN_CONTENT = 'content.cdn-url',
  CUSTOM_COMPONENT = 'content.custom-component',
  GROUP_CELL = 'content.group-cell',
  LIST_ITEM = 'content.list-item',
  TITLE_TEXT = 'content.title-text',
}

export enum UiTemplateContentFormType {
  SELECT_AMOUNT = 'content.form.select.amount',
  GROUP_RADIO = 'content.form.group.radio',
}

export enum UiTemplateContentGroupRadioDisplayStyle {
  BLOCK = 'display.style.block',
  LIST = 'display.style.list'
}
