import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoggingClientService } from './logging-client.service';
import { MicroCoreApiCacheService } from './api/api-cache.service';
import { MicroCoreUtilRemoteConfigService } from './remote-config.service';
import { MicroCoreUtilTrackingDirective } from './tracking/tracking.directive';
import { MicroCoreUtilPermissionProductService } from './permission-product.service';

@NgModule({
  imports: [CommonModule],
  exports: [MicroCoreUtilTrackingDirective],
  declarations: [MicroCoreUtilTrackingDirective],
  providers: [
    LoggingClientService,
    MicroCoreApiCacheService,
    MicroCoreUtilRemoteConfigService,
    MicroCoreUtilPermissionProductService,
  ],
})
export class MicroCoreUtilityModule {}
