import {Component, OnInit, Input, ContentChild, TemplateRef} from '@angular/core';

@Component({
  selector: 'bw-section-card',
  templateUrl: './section-card.component.html',
  styleUrls: ['./section-card.component.scss']
})
export class SectionCardComponent implements OnInit {
  @Input() header: string;

  @ContentChild('wrappedContent') wrappedContentTemplate: TemplateRef<any>;
  @ContentChild('footerContent') footerContentTemplate: TemplateRef<any>;
  @ContentChild('altContent') altContentTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
    console.log(this.wrappedContentTemplate)
  }

}
