import { UiTemplateContentFormType } from './content-template.enum';
import { UiTemplateFormContentControl } from './content-template.interface';

import { BuildFormControls_GroupRadio } from '../../components/group/radio/group-radio.util';

type ReturnUtil = (data: any) => UiTemplateFormContentControl[];

export const UiTemplateContentGetFormControlUtil = (type: UiTemplateContentFormType): undefined | ReturnUtil => {
  switch (type) {
    case UiTemplateContentFormType.GROUP_RADIO:
      return BuildFormControls_GroupRadio;

    default:
      return undefined;
  }
};
