import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageBusInternalService } from './internal/message-bus-internal.service';
import { MessageBusIncomingService } from './incoming/message-bus-incoming.service';
import { MessageBusOutgoingService } from './outgoing/message-bus-outgoing.service';
import {IntercomModule} from "ng-intercom";

@NgModule({
  // I would rather not include Intercom here but it would mean that instead of routing hub messages through this module,
  // we would have to send them directly to the micro-core-chat module and have that module be a hub listener
  imports: [CommonModule, IntercomModule.forRoot({
    appId: ""
  })],
  providers: [MessageBusInternalService, MessageBusIncomingService, MessageBusOutgoingService],
})
export class MicroMessageBusModule {
  constructor(
    private messageBusInternalService: MessageBusInternalService,
    private messageBusIncomingService: MessageBusIncomingService,
    private messageBusOutgoingService: MessageBusOutgoingService
  ) {}
}
