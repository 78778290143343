import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bw-tx-cell',
  templateUrl: './tx-cell.component.html',
  styleUrls: ['./tx-cell.component.scss']
})
export class TxCellComponent implements OnInit {

  @Input() txCellLabel: string;
  @Input() txCellValue: string;
  @Input() txCellDetails: string;

  @Output() clickAction = new EventEmitter();
  _isClickable = false;

  ngOnInit(): void {
    this._isClickable = this.clickAction.observers.length > 0;
  }

  handleClick(event: Event) {
    this.clickAction.emit(event);
  }

}
