<form class="currency-select__input" [formGroup]="currencyForm">
    <input
      [formControlName]="'amount'"
      inputmode="decimal"
      currencyMask
      [options]="currencyOptions"
      (keydown)="deselectOptions()"
      aria-label="Enter amount"
      [attr.aria-invalid]="currencyForm.controls.amount.invalid ? 'true' : null"
    >
</form>
<div
  class="currency-select__optional-text"
  [ngClass]="{'more-space' :  optionalContent.childNodes.length > 0}">
  <typography text-small-regular color="var(--secondary-text)">
    <div #optionalContent><ng-content></ng-content></div>
  </typography>
</div>
<div
  *ngIf="amountOptions"
  class="currency-select__amount-options">
  <ng-container *ngFor="let option of amountOptions">
    <button
      class="currency-select__amount"
      [ngClass]="{'selected': option.selected}"
      (click)="selectOption(option)"
      aria-label="Select ${{option.value}}"
      role="button">
      <typography text-small-regular>${{option.value}}</typography>
    </button>
  </ng-container>
</div>

