<div class="progress-bar-container">
  <div class="flex-row space-between row-reverse" style="padding: 8px 16px;">
    <typography
      *ngIf="label"
      class="progress-bar-label"
      text-medium-regular>
        {{ label }}
    </typography>
    <div class="hidden-desktop">
      <ng-content select="[progress-bar-back]"></ng-content>
    </div>
  </div>
  <ngx-loading-bar
    mode="determinate"
    [includeSpinner]="false"
    [fixed]="false"
    [color]="color"
    [value]="percent"
  ></ngx-loading-bar>
</div>
