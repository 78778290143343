import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BrightsideUiModule } from '@brightside/brightside-ui';

import { MicroCoreUtilityModule } from '@micro-core/utility';
import { MicroUiMoleculeModule } from '@micro-ui/molecule';
import { MicroUiTemplateContentModule } from '@micro-ui/template/content';

import { UiTemplateFlyerComponent } from './flyer/flyer-page-template.component';
import { UiTemplateFormFlyerComponent } from './form-flyer/form-flyer-page-template.component';
import { UiTemplateHalfSheetComponent } from './half-sheet/half-sheet-page-template.component';

import { UiTemplateModalRouterComponent } from './util/modal-router/modal-router.component';

import { UiTemplateStateHandlerService } from './service/state-handler/state-handler.service';
import { UiTemplatePageTransitionComponent } from './util/page-transition/page-transition.component';
import { UiTemplatePageTransitionService } from './util/page-transition/page-transition.service';

@NgModule({
  imports: [
    BrightsideUiModule,
    CommonModule,
    MicroCoreUtilityModule,
    MicroUiMoleculeModule,
    MicroUiTemplateContentModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    UiTemplateFlyerComponent,
    UiTemplateFormFlyerComponent,
    UiTemplateHalfSheetComponent,
    UiTemplateModalRouterComponent,
    UiTemplatePageTransitionComponent,
  ],
  exports: [UiTemplatePageTransitionComponent],
  providers: [UiTemplatePageTransitionService, UiTemplateStateHandlerService],
})
export class MicroUiTemplatePageModule {}
