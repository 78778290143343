import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

const DEFAULT_COLOR = 'var(--blue-50)';

@Component({
  selector: 'bw-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent {
  @Input() percent = 0;
  @Input() label = '';

  @Input() color: string = DEFAULT_COLOR;

  @Input() verticalSpace = '16';

  constructor() {}
}
