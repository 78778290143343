import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'a[bw-anchor]',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss']
})
export class AnchorComponent implements OnInit {
  hasPrefix: boolean;
  hasSuffix: boolean;

  /** An icon that is placed before the anchor string */
  @Input() prefixIconName: string;

  /** An icon that is placed after the anchor string */
  @Input() suffixIconName: string;

  /**  The anchor style. This will allow any of the available typography css classes. That class will be added as a class to the span that contains the anchor string. */
  @Input() anchorStyle: string;

  /** If the anchor string should be underlined */
  @Input() anchorUnderline = false;

  /** The color of the anchor string */
  @Input() color: string;

  ngOnInit() {
    if (this.prefixIconName) {
      this.hasPrefix = true;
    }
    if (this.suffixIconName) {
      this.hasSuffix = true;
    }
  }
}
