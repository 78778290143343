<bw-modal
  (closeModal)="ctaClick.emit('close')"
  [brightsideWebFb]="{ event_name: 'magic_link_modal_shown' }"
>
  <typography title-medium color="var(--gray-70)">{{ 'NOT_AVAILABLE_ON_WEB_TITLE' | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ 'NOT_AVAILABLE_ON_WEB_DESC' | translate }}
    </bw-paragraph>
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ 'DONT_HAVE_APP' | translate }}
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="handleTextMobileLink()"
        [brightsideWebFb]="{ event_name: 'magic_link_modal_tapped', action: 'click' }"
        [processing]="processing"
      >
        {{ 'TEXT_ME' | translate }}
      </button>
      <a
        bw-anchor
        (click)="ctaClick.emit('cancel')"
        style="margin: 0 16px;"
        [brightsideWebFb]="{ event_name: 'magic_link_modal_dismissed', action: 'click' }"
        [dismissedCta]="true">
        {{ 'BUTTON_CANCEL' | translate }}</a>
    </div>
  </footer>
</bw-modal>
