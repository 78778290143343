<bw-modal [showCloseButton]="true" (closeModal)="onModalClose()" modalWidth="large">
  <typography title-medium>{{ title }}</typography>
  <div class="body">
    <vg-player [ngSwitch]="isMp4">

      <video *ngSwitchCase="false" #myMedia [vgHls]="fullVideoPath" id="my-video" type="application/x-mpegURL" controls></video>
      <video *ngSwitchCase="true" #myMedia [src]="fullVideoPath" id="my-video" type="video/mp4" controls></video>
    </vg-player>
  </div>
</bw-modal>
