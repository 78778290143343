<ng-container *ngIf="(requiredDocs$|async) as docs;else spinner">
  <ng-container *ngIf="docs.length > 0;">
    <bw-action-article
      [brightsideWebFb]="{ event_name: 'legal_documents' }">
      <div header-actions>
        <ui-nav-exit-back
          *ngIf="showBackbutton"
          [trackingDetail]="{ event_name: 'legal_documents' }"
          [skipActionEmit]="skipActionEmit"
          (exitActionHandler)="emitBackButtonEvent()"
        ></ui-nav-exit-back>
        <bw-icon
          *ngIf="showCloseButton"
          iconName="close"
          size="24"
          (click)="handleCloseClick()"
        ></bw-icon>
      </div>
      <div content-section>
        <typography title-medium class="mb-sm" color="var(--primary-text)">
          <div [innerHTML]="title | translate"></div>
        </typography>
        <typography text-medium-regular class="mb-md" color="var(--secondary-text)">
          <div [innerHTML]="subtitle | translate"></div>
        </typography>
        <typography text-large-semibold class="mb-sm" color="var(--primary-text)">{{ 'AGREEMENTS' | translate }}</typography>
        <ng-container *ngFor="let doc of docs">
          <div class="mb-sm">
            <typography *ngIf="doc.titleCopyKey" text-medium-semibold color="var(--primary-text)">
              <div [innerHTML]="doc.titleCopyKey | translate" class="mb-xs"></div>
            </typography>
            <typography *ngIf="doc.descriptionCopyKey" text-xsmall-regular color="var(--secondary-text)">
              <div [innerHTML]="doc.descriptionCopyKey | translate"></div>
            </typography>
            <ul class="legal-doc__links" *ngIf="doc.downloadUrl && doc.downloadUrlCopyKey">
              <li><a style="text-decoration: underline" target="_blank" (click)="handleDocClick(doc.downloadUrl, $event)">
                <typography text-xsmall-regular >
                  <div [innerHTML]="doc.downloadUrlCopyKey | translate"></div>
                </typography>
              </a></li>
            </ul>
          </div>
        </ng-container>
      </div>


      <div footer-top class="flex-row space-center">
        <form [formGroup]="confirmDataForm">
          <bw-checkbox [formControlName]="'confirmCheckbox'">
            <typography text-medium color="var(--primary-text)">
              {{ 'LEGAL_DOCUMENTS_AGREEMENT' | translate }}
            </typography>
          </bw-checkbox>
        </form>
      </div>
      <div footer-actions>
        <div class="flex-row row-reverse">
          <button
            bw-button
            type="submit"
            (click)="onSubmit()"
            [disabled]="confirmDataForm.invalid || (processing | async)"
            [processing]="(processing | async)"
            [brightsideWebFb]="{ event_name: 'legal_documents' }"
          >
            {{ 'ACCEPT' | translate }}
          </button>
        </div>
      </div>

    </bw-action-article>

    <bw-modal *ngIf="displayDocsRequiredModal | async" modalWidth="medium"
              [showCloseButton]="false">
      <typography title-medium color="var(--primary-text)" class="mb-sm">{{ 'ERROR_ADDITIONAL_DOCS_REQUIRED_TITLE' | translate }}</typography>
      <section class="body">
        <bw-paragraph>
          <typography text-large-regular color="var(--secondary-text)">
            <p class="mb-xs">
              {{ 'ERROR_ADDITIONAL_DOCS_REQUIRED_DESC' | translate }}
            </p>
          </typography>
        </bw-paragraph>
      </section>
      <footer class="footer">
        <div class="flex-row row-reverse">
          <button
            bw-button
            [brightsideWebFb]="{ event_name: 'legal_documents_required' }"
            (click)="refetchDocuments()">
            {{ 'BUTTON_CONTINUE' | translate }}
          </button>
        </div>
      </footer>
    </bw-modal>


    <bw-modal *ngIf="displayGeneralError | async" modalWidth="medium"
              [showCloseButton]="false"
              [brightsideWebFb]="{ event_name: 'error_shown', custom: { 'error id': 'legal_documents'}}">
      <typography title-medium color="var(--primary-text)" class="mb-sm">
        {{ 'PROCESS_ERROR_TITLE' | translate }}
      </typography>
      <section class="body">
        <bw-paragraph>
          <typography text-large-regular color="var(--secondary-text)">
            <p class="mb-xs">
              {{ 'PROCESS_ERROR_MSG' | translate }}
            </p>
          </typography>
        </bw-paragraph>
      </section>
      <footer class="footer">
        <div class="flex-row row-reverse">
          <button
            bw-button
            (click)="closeError()">
            {{ 'BUTTON_CONTINUE' | translate }}
          </button>
        </div>
      </footer>
    </bw-modal>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <bw-action-article>
    <div content-section>
      <div class="flex-column space-center">
        <bw-spinner></bw-spinner>
      </div>
    </div>
  </bw-action-article>
</ng-template>
