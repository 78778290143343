import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { AnalyticsAttributesV4 } from '@brightside-web/desktop/data-access/shared';
import {ButtonComponent} from "@brightside/brightside-ui";

@Component({
  selector: 'brightside-web-hr-modal',
  templateUrl: './hr-modal.component.html',
  styleUrls: ['./hr-modal.component.scss']
})
export class HrModalComponent {
  @Output() dismissed = new EventEmitter();
  @Input() title = 'Sorry, we didn’t find your account';
  @Input() prompt = 'Please check your information and try again. If you’re unsure if your company offers Brightside, please talk to your HR team.';
  @Input() ctaText = 'Dismiss';
  @Input() firebase: AnalyticsAttributesV4;
  @ViewChild('btnRef') button: ButtonComponent;
  constructor() { }

  ngAfterViewInit() {
    this.button._elementRef.nativeElement.focus();
  }

  dismiss() {
    this.dismissed.emit();
  }
}
