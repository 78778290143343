import { Injectable } from '@angular/core';
import {
  CognitoRefreshSession,
  FirebaseService
} from "@brightside-web/desktop/data-access/shared";

import { HubCapsule } from 'aws-amplify/utils';

import {
  AwsApiWrapperService,
  BsAuthService,
  BsCacheService,
  BsHubService
} from "@brightside-web/desktop/data-access/core-services";

import * as moment from "moment";

import {BehaviorSubject, filter, from, Observable, of, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {first, mergeMap, tap, catchError} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

export interface SupportedLangsInterface {
  locale: string;
  displayName: string;
  state?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectorService {

  private _ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly ready: Observable<boolean> = this._ready.asObservable();

  private _languageChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly languageChanged: Observable<string> = this._languageChanged.asObservable();

  sub = new Subscription();

  supportedLanguages: [SupportedLangsInterface];
  isUserLoggedIn = false;
  disableChangeLang = true;
  maxRetry = 10;
  fbAttempts = 0;

  get activeLang(): string {
    return this.translateSvc.currentLang || 'en';
  }

  constructor(
    private translateSvc: TranslateService,
    private analytics: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private awsApiWrapperService: AwsApiWrapperService,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService
    ) {
    this.setUpService();
    // this should always happen after everything has loaded
    // as it is not called if the user has an active session on refresh
    this.bsHubService.listen('bsAuth', this.authListener.bind(this));
  }

  public setUpService() {
    if (this.fbAttempts >= this.maxRetry) {
      // firebase may be down or supportedLanguages is not coming through
      return;
    }
    this.fbAttempts++;
    // remoteConfig is not available at construction, keep trying until we have a response
    const langs = this.analytics.getValueAsString('supportedLanguages');
    if (langs) {
      this.supportedLanguages = JSON.parse(langs);
      const supportedLocales = this.supportedLanguages.reduce((a:string[], lang) => [...a, lang.locale], []);
      if (supportedLocales.length > 0) { this.translateSvc.addLangs(supportedLocales); }
      if (supportedLocales.length > 1) { this.disableChangeLang = false; }
      if (!this.disableChangeLang) {

        this.bsAuthService.isAuthenticated().pipe(
          tap(isAuth => {
            this.isUserLoggedIn = isAuth;
          }),
          filter(isAuth => !!isAuth),
          mergeMap(() => this.bsAuthService.fetchUserAttributes().pipe(
              tap(attributes => {
                const locale = attributes['locale'];
                if (locale) {
                  this.use(locale, true);
                } else {
                  this.getSetCachedActiveLang(true);
                }
              }),
              catchError(err => {
                this.getSetCachedActiveLang(true);
                return of(null);
              })
            )),
          catchError(err => {
            this.getSetCachedActiveLang(true);
            return of(null);
          })
        ).subscribe();

        this._ready.next(true);
      }
    } else {
      setTimeout(()=>{
        this.setUpService();
      }, 250);
    }
  }

  private authListener(data: HubCapsule<any, any>) {
    const dataEvent = data.payload.event;
    if (dataEvent === 'signIn') {
      const locale = data.payload.data.attributes['locale'];
      this.isUserLoggedIn = true;
      if (locale) {
        this.use(locale, true);
      }
    } else if (dataEvent === 'signOut') {
      this.isUserLoggedIn = false;
    }
  }

  public isLangSupported(locale: string) {
    return this.supportedLanguages.some(lang => lang.locale === locale);
  }

  public use(locale: string, bypassPut = false) {
    if (!this.disableChangeLang && this.isLangSupported(locale)) {
      this.translateSvc.use(locale);
      this.storeActiveLang(locale);
      this._languageChanged.next(this.activeLang);
      if (this.isUserLoggedIn && !bypassPut) this.putChangeLang(locale);
    }
  }

  private putChangeLang(locale: string) {
    const myLocale = locale ?? this.activeLang;
    this.awsApiWrapperService.put('api-mobile', '/client', {
      body: {
        preferred_language: myLocale
      }
    }).then(
      _ => {
          setTimeout(() => {
            this.sub.add(
              this.bsAuthService.getToken().pipe(first()).subscribe(
                session => {}
              )
            )
          }, 150);
      }
    );
  }

  private storeActiveLang(locale: string) {
    this.bsCacheService.setItem('activeLang', locale, {expires: moment().add(48, 'hours').valueOf()});
  }

  public getSetCachedActiveLang(bypassPut = false) {
    if (this.activatedRoute.snapshot?.queryParams['lang']) {
        this.use(this.activatedRoute.snapshot.queryParams['lang']);
        return;
    }
    this.bsCacheService.getItem('activeLang').then(
      activeLang => {
        if (activeLang) {
          this.use(activeLang, bypassPut);
        } else {
          this.use('en', bypassPut);
        }
      }
    )
  }

}
