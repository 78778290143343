import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  RoutingService as BaseRoutingService,
  RoutingServiceDispatchEvent,
  LayoutStyle,
} from '@brightside-web/micro/core/utility';
import { Environment } from '@brightside-web/micro/core/environment';

@Injectable({
  providedIn: 'root',
})
export class RoutingService extends BaseRoutingService {
  constructor(protected environmentService: Environment, protected childRouter: Router) {
    super(childRouter, environmentService.production, environmentService.googleTagManagerId);
  }
}
export { RoutingServiceDispatchEvent, LayoutStyle };
