<bw-action-cell
  *ngIf="controlConfig.cellDetails?.action"
  label="{{ controlConfig.cellDetails.labelLeft || '' | translate }}"
  detail="{{ controlConfig.cellDetails.labelSubLeft || '' | translate }}"
  iconName="{{ controlConfig.cellDetails.iconNameRight || '' | translate }}"
  [emphasize]="controlConfig.cellDetails.action.emphasize"
  (actionCellClick)="handleActionCellClick()"
  class="full-width"
></bw-action-cell>
<ng-container *ngIf="controlConfig.cellDetails?.action?.supportedHelpHint">
  <typography text-small-regular color="var(--secondary-text)" [innerHTML]="controlConfig.cellDetails?.action?.supportedHelpHint | translate">
  </typography>
</ng-container>

<input
  *ngIf="controlConfig.type === 'file'"
  type="file"
  style="display: none"
  [id]="controlConfig.key + 'FileInputField'"
  [multiple]="inputSupportMultiple"
  [accept]="inputSupportedFiles"
/>
