import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  templateUrl: './modal-router.component.html',
  styleUrls: ['./modal-router.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiTemplateModalRouterComponent {
  constructor() {}
}
