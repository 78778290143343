import { Injectable } from '@angular/core';
import {from, Observable, of, Subscription} from "rxjs";
import {
  AwsApiWrapperService
} from "@brightside-web/desktop/data-access/core-services";

import {map} from "rxjs/operators";
import {ApiCacheService} from "./api-cache.service";
import {CreditSummary} from "./api-contracts";
import {AuthService} from "./auth.service";

export interface VerificationStatusInterface {
  implementation_type: string;
  employment_data_verification_status: {status: VerificationStatusEnum};
}

export enum VerificationStatusEnum {
  MANUAL = 'manual',
  PENDING = 'pending',
  VERIFIED = 'verified',
  FAILED = 'failed',
  TERMINATED = 'terminated'
}

@Injectable({
  providedIn: 'root'
})
export class VerificationStatusService {

  sub = new Subscription();

  verificationStatus: VerificationStatusInterface | null;
  tryAgain:boolean;
  constructor(
    private apiSvc: ApiCacheService,
    private authService: AuthService,
    private awsApiWrapperService: AwsApiWrapperService
    ) {
    this.sub.add(
      this.authService.getUserLoggedIn().subscribe(
        response => {
          // reset when user logs in/out
          this.verificationStatus = null;
        }
      )
    )
  }

  public getEmploymentVerificationStatusFromAPI():Observable<VerificationStatusInterface> {
    return from(this.awsApiWrapperService.get('api-mobile', '/client/status?type=employment_data_verification_status', {}));
  }

  public getEmploymentVerificationStatus(force = false):Observable<VerificationStatusInterface> {
    if (this.verificationStatus && !force) {
      return of(this.verificationStatus);
    } else {
      return this.getEmploymentVerificationStatusFromAPI().pipe(
        map( response => {
          if (!response.employment_data_verification_status || !Object.values(VerificationStatusEnum).includes(response.employment_data_verification_status.status)) {
            throw new Error();
          }
          this.verificationStatus = response;
          return this.verificationStatus;
        })
      )
    }
  }

  public resetStatus() {
    this.tryAgain = true;
  }
}
