import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { UiMoleculeBaseData } from '../shared.interface';

/**
 * @description
 * A base molecule component to be utilized when your component supports passing
 * input info via a data object or direct key/value inputs
 *
 * @usageNotes
 * None
 *
 */
export declare interface UiBaseDataMoleculeSchema {
  schemaValidationConfig: UiBaseDataMoleculeSchemaValidationConfig;

  /**
   * Components must contain a data object defined.
   *
   * Example: @Input() data: <yourDataInterface>;
   */
  data: unknown;

  /**
   * This method will be called when you are using a data object instead
   * of direct input key/values. The idea is anytime you want to update local
   */
  uiOnDirectInputsFromDataObject(): void;
}

export interface UiBaseDataMoleculeSchemaValidationConfig {
  directInputValues: unknown[];
}

@Component({
  template: '',
})
export class UiBaseDataMoleculeComponent implements UiBaseDataMoleculeSchema, OnInit, OnChanges {
  /**
   * These properties will be set inside validateDataSchemeUsage based
   * on the developer input.
   */
  private hasDataObjectUsed = false;
  private hasDirectInputsSet = false;

  /**
   * Flag should be marked true when data object is being used
   * over direct inputs.
   */
  private shouldUpdateWithDataChanges = false;

  /**
   * If you wish to have developer logging turn this on
   *
   * Default: true
   */
  protected allowVerboseDebugMode = true;

  public schemaValidationConfig: UiBaseDataMoleculeSchemaValidationConfig;

  /**
   * Allow developer to pass in data with an object
   *
   * Interface UiMoleculeBaseData
   */
  @Input() data: UiMoleculeBaseData;

  ngOnInit(): void {
    this.validateDataSchemeUsage();
    this.updateDirectInputsFromDataObject();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //We need to check: updateWithData flag and that this is NOT the first change
    if (this.shouldUpdateWithDataChanges) {
      if (changes.data?.isFirstChange()) {
        return;
      }

      this.updateDirectInputsFromDataObject();
    }
  }

  protected logForDebugging(message?: any, ...optionalParams: any[]): void {
    if (this.allowVerboseDebugMode) console.log('debug: UiBaseDataMoleculeComponent - ', message, optionalParams);
  }

  /**
   * We should be calling this method on the init of the component to ensure
   * correct usage of the data schema. Example: Don't support data object
   * and direct value inputs
   *
   * Matching against direct input values from this.schemaValidationConfig
   */
  private validateDataSchemeUsage(): void {
    this.hasDataObjectUsed = this.data === undefined ? false : Object.keys(this.data).length > 0;
    this.hasDirectInputsSet = this.schemaValidationConfig.directInputValues.filter((value) => Boolean(value)).length > 0;

    //If we have have both patterns used, we need to warn the developer
    //that we are going to ignore the data object entirely
    if (this.hasDataObjectUsed && this.hasDirectInputsSet) {
      console.warn(
        'Inside your usage of the UiFooterDefaultComponent class, you must not pass both patterns. Please utilize data object or direct input values.'
      );
    }
  }

  /**
   * Should be called when the data object was passed without direct
   * inputs also being defined.
   *
   */
  private updateDirectInputsFromDataObject(): void {
    //Ensure we have a data object and no direct inputs defined
    //Valid case: hasDataObjectUsed === true && hasDirectInputsSet === false
    if (this.hasDataObjectUsed && !this.hasDirectInputsSet) {
      this.uiOnDirectInputsFromDataObject();
      this.shouldUpdateWithDataChanges = true;
    }
  }

  public uiOnDirectInputsFromDataObject(): void {
    this.logForDebugging('Oops, you forgot to handle updating values when the data object changes.');
  }
}
