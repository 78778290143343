import { Component, Input } from '@angular/core';

@Component({
  selector: 'bw-bui-column',
  templateUrl: './bui-column.component.html',
  styleUrls: ['./bui-column.component.scss']
})
export class BuiColumnComponent {

  @Input() rows: any[] = [];
  @Input() rowSpacing: number = 0;

}
