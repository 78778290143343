<div class="content-layout" [brightsideWebFb]="config.analyticsAttributes()">
  <bw-action-article>
    <ui-nav-exit-back
      *ngIf="questionIndex !== 0 || canBackOut"
      back-action-bottom
      [trackingDetail]="{ event_name: config.fbEventName }"
      (exitActionHandler)="handleBackClick()"
    ></ui-nav-exit-back>

    <div header-title style="flex-direction: column">
      <typography text-medium-regular *ngIf="questions.length > 1">
        <p class="secondary-text mb-sm">{{ 'SURVEY_QUESTION_NUMBER_WEB' | translate:{numberOne:questionIndex + 1,numberTwo:questions.length} }}</p>
      </typography>
      <typography title-medium [ngClass]="{ 'mb-md': !isChoiceMulti, 'mb-xs': isChoiceMulti }">{{
        currentQuestion.field_label | translate
      }}</typography>
    </div>

    <div content-section>
      <form [formGroup]="questionForm">
        <typography text-medium-regular *ngIf="isChoiceMulti">
          <p *ngIf="currentQuestion.instructions_copy" class="secondary-text mb-md">
            {{ currentQuestion.instructions_copy | translate }}
          </p>
          <p *ngIf="!currentQuestion" class="secondary-text mb-md">
            >
            {{ 'SURVEY_INSTRUCTIONS_TOPICS' | translate }}
          </p>
        </typography>

        <ng-container *ngIf="currentQuestion.id">
          <div *ngFor="let _ of currentQuestion.choices; let i = index" class="mb-sm">
            <bw-radio
              *ngIf="!isChoiceMulti; else checkboxTemplate"
              name="questionAnswer"
              formControlName="questionAnswer"
              [value]="currentQuestion.choices[i]"
              [label]="currentQuestion.choices[i] | translate"
              (radioClick)="checkAndToggleOtherRadio(i, currentQuestion.choices[i])"
            ></bw-radio>

            <ng-template #checkboxTemplate>
              <bw-checkbox
                [id]="currentQuestion.choices[i].replace('&', '').split(' ').join('-').toLowerCase()"
                formControlName="questionAnswer"
                (checkboxChanged)="
                  checkAndToggleOtherBox(i, currentQuestion.choices[i]); handleCheckboxChanged($event, currentQuestion.choices[i])
                "
              >
                <typography text-large-regular color="var(--primary-text)">
                  {{ currentQuestion.choices[i] | translate }}</typography
                >
              </bw-checkbox>
            </ng-template>

            <div style="margin-top: 8px; margin-left: 42px" *ngIf="otherAnswerShowIndexes.includes(i)">
              <typography text-small-semibold>
                <p class="secondary-text">{{ 'PLEASE_SPECIFY' | translate }}</p>
              </typography>

              <bw-input formControlName="questionAnswerOther" placeholder="-"></bw-input>
            </div>
          </div>
        </ng-container>
      </form>
    </div>

    <div footer-actions class="flex-row flex-item-flexible space-between">
      <div></div>
      <div class="flex-row row-reverse">
        <button
          *ngIf="config.pageCtaLabel"
          bw-button
          [brightsideWebFb]="{ event_name: config.fbEventName }"
          [disabled]="isChoiceMulti ? multiAnswerPlaceholder.length === 0 : questionForm.invalid"
          [processing]="processing"
          (click)="handleCtaClick()"
        >
          {{ config.pageCtaLabel | translate }}
        </button>

        <typography text-large-semibold>
          <a
            *ngIf="questionIsSkippable"
            bw-anchor
            class="bs-blue mr-sm"
            (click)="handleSecondaryCtaClick()"
            [brightsideWebFb]="{ event_name: config.fbEventName }"
            [secondaryCta]="true"
            >{{ config.pageSecondaryCtaLabel | translate }}</a
          >
        </typography>
      </div>
    </div>
  </bw-action-article>
</div>
