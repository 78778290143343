import {Inject, Injectable} from '@angular/core';
import {
  TranslateService,
  TranslateStore,
  TranslateLoader,
  TranslateCompiler,
  TranslateParser,
  MissingTranslationHandler,
  USE_DEFAULT_LANG,
  USE_STORE, USE_EXTEND,
  DEFAULT_LANGUAGE, MissingTranslationHandlerParams
} from "@ngx-translate/core";
import {forkJoin, from, isObservable, Observable, of, Subscription} from "rxjs";
import {first, map} from "rxjs/operators";
import {
  BsAuthService,
  BsCacheService,
  BsHubService
} from "@brightside-web/desktop/data-access/core-services";
import {CompanyService} from "./company.service";
import { TitleCasePipe } from "@angular/common";
import {FirebaseService} from "./firebase.service";
import {Environment} from "@micro-core/environment";
import * as Sentry from "@sentry/angular";

class TranslationError extends Error {
  constructor(msg:string) {
    super(msg);
    Object.setPrototypeOf(this, TranslationError.prototype);
  }
}

@Injectable({
  providedIn: 'root',
})
export class BrightsideTranslateService extends TranslateService implements TranslateService {

  private sub = new Subscription();

  _company: string;
  _companyDisplay: string;

  _hours: string;

  get hours(): string {
    return this._hours;
  }

  get companyDisplay(): string {
    return this._companyDisplay;
  }

  set companyDisplay(value: string) {
    if (value.toLowerCase() === 'mckesson') {
      this._companyDisplay = 'McKesson';
    } else if (value.toLowerCase() === 'airproducts') {
      this._companyDisplay = 'Air Products';
    } else if (value.toLowerCase() === 'amyskitchen') {
      this._companyDisplay = "Amy's Kitchen";
    } else if (value.toLowerCase() === 'bigy') {
      this._companyDisplay = "Big Y";
    } else if (value.toLowerCase() === 'schreiberfoods') {
      this._companyDisplay = "Schreiber Foods";
    } else {
      this._companyDisplay = this.titleCasePipe.transform(value.toLowerCase());
    }
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value.toUpperCase().split(' ').join('_');
    this.companyDisplay = value;
  }

  _supportNumber: string;

  get supportNumber(): string {
    return this._supportNumber;
  }

  set supportNumber(value: string) {
    this._supportNumber = value;
  }

  firstName = '';
  lastName = '';

  get fullName() : string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(
    public store: TranslateStore,
    public currentLoader: TranslateLoader,
    public compiler: TranslateCompiler,
    public parser: TranslateParser,
    public missingTranslationHandler: MissingTranslationHandler,
    private companyService: CompanyService,
    @Inject(USE_DEFAULT_LANG) useDefaultLang: boolean = true,
    @Inject(USE_STORE) isolate: boolean = false,
    @Inject(USE_EXTEND) extend: boolean = false,
    @Inject(DEFAULT_LANGUAGE) defaultLanguage: string = 'en',
    private analytics: FirebaseService,
    private titleCasePipe: TitleCasePipe,
    private env: Environment,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService
  ) {
    super(store, currentLoader, compiler, parser, missingTranslationHandler, useDefaultLang, isolate, extend, defaultLanguage);
    if (!this._hours) {
      this.bsCacheService.getItem('FAHOURS').then(
        fahours => {
          const cacheHours = fahours;
          this._hours = cacheHours ?? 'Weekdays, 8 a.m. to 8 p.m. ET';
          if (!cacheHours) this.bsCacheService.setItem('FAHOURS', this._hours);
        }
      )
    }
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          if (value) {
            this.company = value;
          }
        }
      )
    );
    this.sub.add(
      this.companyService.supportNumber.subscribe(
        value => {
          if (value) {
            this.supportNumber = value;
          }
        }
      )
    );
    this.bsHubService.listen('AuthChannel', (event: any) => {
      if (event.payload.event === 'logged out') {
        this.firstName = '';
        this.lastName = '';
      } else if (event.payload.event === 'logged in') {
        this.lastName = event.payload.data['family_name'];
        this.firstName = event.payload.data['given_name'];
      }
    });
    this.bsHubService.listen('OnboardingChannel', (data:any) => {
      if (data.payload.event === 'OnboardingComplete') {
        this.subscribeAndGetUserInfo();
      }

    });
    this.subscribeAndGetUserInfo();
  }

  public setLanguageAs(newLang: string) {
    //Only support en and es
    if (['en', 'es'].includes(newLang)) {
      this.use(newLang);
    }
  }

  private subscribeAndGetUserInfo() {
    this.sub.add(from(this.bsAuthService.fetchUserAttributes()).pipe(first()).subscribe(
      attributes => {
        if (attributes?.['last_name'] && attributes?.['first_name']) {
          this.lastName = attributes['last_name'];
          this.firstName = attributes['first_name'];
        }
      },
      err => {
        // no active session, catching error
      }
    ));
  }

  public getParsedResult(translations: any, key: any, interpolateParams?: Object): any {
    let res: string | Observable<string> | undefined;
    if (key instanceof Array) {
      let result: any = {},
        observables: boolean = false;
      for (let k of key) {
        result[k] = this.getParsedResult(translations, k, interpolateParams);
        if (isObservable(result[k])) {
          observables = true;
        }
      }
      if (observables) {
        const sources = key.map(k => isObservable(result[k]) ? result[k] : of(result[k] as string));
        return forkJoin(sources).pipe(
          map((arr: Array<unknown>) => {
            const obj: any = {};
            arr.forEach((value: unknown, index: unknown) => {
              obj[key[index as number]] = value;
            });
            return obj;
          })
        );
      }
      return result;
    }
    const tempUpperCaseKey = key.toUpperCase();
    // try company key first
    if (this.company) {

      const companyKey = `${tempUpperCaseKey}.${this.company}`;

      if (translations) {
        res = this.parser.interpolate(this.parser.getValue(translations, companyKey), interpolateParams);
      }

      if (typeof res === "undefined" && this.defaultLang != null && this.defaultLang !== this.currentLang && true) {
        res = this.parser.interpolate(this.parser.getValue(this.translations[this.defaultLang], companyKey), interpolateParams);
        if (typeof res !== "undefined" && typeof this.currentLang !== "undefined" && this.defaultLang !== this.currentLang) {
          this.logSentryTranslationNotFound(this.currentLang, companyKey);
        }
      }
    }
    if (typeof res === "undefined") {
      res = this.parser.interpolate(this.parser.getValue(translations, tempUpperCaseKey), interpolateParams);
      if (typeof res === "undefined" && typeof this.currentLang !== "undefined") {
        this.logSentryTranslationNotFound(this.currentLang, tempUpperCaseKey);
      }
    }
    if (typeof res === "undefined" && this.defaultLang != null && this.defaultLang !== this.currentLang && true) {
      res = this.parser.interpolate(this.parser.getValue(this.translations[this.defaultLang], tempUpperCaseKey), interpolateParams);
    }
    if (typeof res === "undefined") {
      const params: MissingTranslationHandlerParams = {key, translateService: this};
      if (typeof interpolateParams !== 'undefined') {
        params.interpolateParams = interpolateParams;
      }
      res = this.missingTranslationHandler.handle(params);
    }

    // res = res === "undefined" ?? this.globalsReplacement(res);

    return typeof res !== "undefined" ? this.globalsReplacement(res) : key;
  }

  logSentryTranslationNotFound(lang: string, key: string) {
    const _count = key.match(/_/g) || [];
    if (!this.env.production && _count.length >= 1 ) {
      const errorMsg = `Copy Error: Missing ${lang} translation - ${key}`;
      Sentry.withScope(scope => {
        scope.setFingerprint(['copy_error']);
        scope.setLevel("warning");
        Sentry.captureException(new TranslationError(errorMsg));
      });
    }
  }

  globalsReplacement(parsedString: string | Observable<string>) {
    if (typeof parsedString === 'string') {
      let newResp = parsedString;
      newResp = newResp.replace(/\$COMPANY/gm, this.companyDisplay);
      newResp = newResp.replace(/\$HOURS/gm, this.hours);
      newResp = newResp.replace(/\$PHONE/gm, this.supportNumber);
      if (this.firstName) {
        newResp = newResp.replace(/\$FIRST_NAME/gm, this.firstName);
        newResp = newResp.replace(/\$LAST_NAME/gm, this.lastName);
        newResp = newResp.replace(/\$FULL_NAME/gm, this.fullName);
      }
      return newResp;
    } else {
      return parsedString;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
