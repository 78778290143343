<label class="input-group"
       [ngClass]="[
       verticalSpace ? verticalSpace : '',
       horizontalSpace ? horizontalSpace : '']">
  <typography text-small-semibold class="primary-text mb-xs flex-row">
    <span [ngClass]="{'has-error' : inputError || charCount >= maxCharacterWarning + 1}">{{ label }}</span>
    <a *ngIf="hasInfo" (click)="infoClicked()" tabindex="0">
      <typography text-small-regular color="var(--gray-50)" class="ml-xs">
        <ng-content select="[infoCopy]"></ng-content>
      </typography>
    </a>
  </typography>
  <div class="text-field" [ngClass]="{ focused: isFocused }">
    <textarea
      [style.height]="textAreaHeight + 'px'"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (input)="onChangeInput($event.target.value)"
      [placeholder]="placeholder"
      [value]="value"
      [disabled]="disabled"
      [attr.maxlength]="maxCharacterLimit"
    >
    </textarea>
  </div>
  <typography
    text-xsmall-regular
    color="var(--secondary-text)"
    [ngClass]="{'character-limit-hit': charCount >= maxCharacterWarning + 1}"
  >{{ 'TEXT_AREA_CHARACTERS' | translate:{length:charCount,maxLength:maxCharacterWarning} }}</typography>

  <div [@enterAnimation]="inputError ? 'show' : 'hide'" class="error">
    <ng-container *ngIf="inputError">{{ inputErrorText }}</ng-container>
  </div>
</label>
