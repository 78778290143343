import { Component, OnInit, ChangeDetectionStrategy, OnChanges, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bw-page, [bw-page]',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PageComponent implements OnInit, OnChanges {
  _currentTemplate: string;

  @Input() template: string;

  ngOnInit() {
    this._currentTemplate = this.template;
  }

  ngOnChanges() {
    const body = document.getElementsByTagName('body')[0];
    
    if (this.template) {
      body.classList.remove(this._currentTemplate);
      body.classList.add(this.template);
      this._currentTemplate = this.template;
    }
  }
}
