import { Component, Input } from '@angular/core';

@Component({
  selector: 'bw-icon-bounds',
  template: `
    <div class="icon-bounds" [ngStyle]="{'background': bgColor}"><ng-content></ng-content></div>
  `,
  styleUrls: ['./icon-bounds.component.scss']
})
export class IconBoundsComponent {

  @Input() bgColor = 'var(--bg-gray)';

}
