import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  Router, RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class IsMobileGuard implements CanActivate {
  // this guard is strictly for views you want to display for mobile only
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router) {
  }
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

}
