import { Injectable } from '@angular/core';
import { HubCapsule } from 'aws-amplify/utils';

import {
  AwsApiWrapperService,
  BsHubService,
} from '@brightside-web/desktop/data-access/core-services';
import { forkJoin, from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MicroCoreUtilRemoteConfigService } from '@micro-core/utility';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  ApiCacheService,
  ApiResponse,
  EligibleProduct, KnownFeatureFlags,
  LinkedBank,
  LinkedBankStatus,
  MobileStateService,
  SavingsAccount,
  SavingsAch,
  SavingsGoal,
  SavingsScheduleCreateRequest,
  SavingsSchedulePatchRequest,
  SavingsScheduleResponse,
  SavingsSetupStatus,
  SavingsStatementsResponse,
  SavingsTransaction,
  SavingsTransactionCategory,
  SpendingAccount,
  TransferAccountDisplay,
  TransferAccountType,
  TransferRequest,
} from '@brightside-web/desktop/data-access/shared';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";

const NoAccountStatuses = [undefined, SavingsSetupStatus.canceled];
const FailedCreationStatuses = [SavingsSetupStatus.errorCustodialCreation, SavingsSetupStatus.errorFboCreation];
const PendingAccountStatuses = [SavingsSetupStatus.accountCreated, ...FailedCreationStatuses];
const TransferableLinkedBankStatuses = [LinkedBankStatus.LINKED, LinkedBankStatus.ACTIVE];

export interface SavingsScheduleRequest {
  schedule_type: string;
  next_transfer_date: string;
  amount: number;
  source: string | null;
}

export enum InitiatingSource {
  DEFAULT = 'default',
  ONBOARDING = 'onboarding',
  LOAN = 'loan',
  ONE_CLICK = 'one-click',
}

@Injectable({
  providedIn: 'root',
})
export class SavingsAccountService {

  constructor(
    private apiCache: ApiCacheService,
    private mobile: MobileStateService,
    private featureFlagSvc: FeatureFlagService,
    private awsApiWrapperService: AwsApiWrapperService,
    private bsHubService: BsHubService,) {
    this.bsHubService.listen('SavingsAccountChannel', (data: HubCapsule<any, any>) => {
      if (data.payload.event === 'refreshStatus') {
        this.mobile
          .get(true)
          .subscribe((response) =>
            this.bsHubService.dispatch('SavingsAccountChannel', { event: 'accountRefresh', data: response.savingsSetup?.status })
          );
      } else if (data.payload.event === 'clearAllCache') {
        this.clearSavingsCache();
      }
    });
  }

  static AutosaveInitialAmount(microCoreUtilRemoteConfigService: MicroCoreUtilRemoteConfigService) {
    try {
      return JSON.parse(microCoreUtilRemoteConfigService.getValueAsString('internalAutosaveDefaults'))?.initialAmount;
    } catch (_) {
      return 20;
    }
  }

  static isFunctional(isPayrollEligible: boolean, status?: SavingsSetupStatus): boolean {
    if (typeof status === undefined) {
      return false;
    }
    if (isPayrollEligible) {
      return status === SavingsSetupStatus.payrollSetup;
    }

    return [SavingsSetupStatus.accountCreated, SavingsSetupStatus.payrollSetup].includes(status as SavingsSetupStatus);
  }

  static isFailedCreation(status?: SavingsSetupStatus) {
    return FailedCreationStatuses.includes(status as SavingsSetupStatus);
  }

  static isAutoSaving(savingsAccount: SavingsAccount): boolean {
    return Boolean(this.hasSetupAutosaveBefore(savingsAccount) && !savingsAccount.schedule_paused);
  }

  static isLinkedBankPresent(savingsAccount: SavingsAccount): boolean {
    return savingsAccount.banks && savingsAccount.banks.length > 0;
  }

  static hasTransferableBank(savingsAccount: SavingsAccount): boolean {
    return savingsAccount.banks.some((bank) => TransferableLinkedBankStatuses.includes(bank.status));
  }

  static hasSetupAutosaveBefore(savingsAccount: SavingsAccount ): boolean {
    return Boolean(savingsAccount.next_deposit_amount && savingsAccount.schedule_type);
  }

  static allowDelete(savingsAccount: SavingsAccount): boolean {
    return savingsAccount.balance === 0 || SavingsAccountService.hasTransferableBank(savingsAccount);
  }

  //MW MobileService fetchMobileState & others
  hasFunctionalSavingsAccount(): Observable<boolean> {
    return this.mobile
      .get()
      .pipe(
        map((mobileState) =>
          SavingsAccountService.isFunctional(
            Boolean(mobileState.products?.includes(EligibleProduct.payrollSavings)),
            mobileState?.savingsSetup?.status
          )
        )
      );
  }

  hasPendingSavingsAccount(): Observable<boolean> {
    return this.mobile
      .get()
      .pipe(map((mobileState) => PendingAccountStatuses.includes(mobileState?.savingsSetup?.status as SavingsSetupStatus)));
  }

  hasFailedCreationSavingsAccount(): Observable<boolean> {
    return this.mobile
      .get()
      .pipe(
        map((mobileState) => SavingsAccountService.isFailedCreation(mobileState?.savingsSetup?.status as SavingsSetupStatus))
      );
  }
  //MW finsol SavingsService.getAccount
  getSavingsAccount(): Observable<SavingsAccount | undefined> {
    return this.apiCache.get<SavingsAccount>('api-mobile', '/savings');
  }

  //MW finsol SavingsService.getTransactions
  getSavingsTransactions(filterCategory?: SavingsTransactionCategory): Observable<SavingsTransaction[]> {
    return this.apiCache
      .get<SavingsTransaction[]>('api-mobile', '/savings/transactions')
      .pipe(map((rewards) => rewards.filter((reward) => !filterCategory || reward.category === filterCategory)));
  }

  hasSavingsGoal(): Observable<boolean> {
    return this.apiCache
      .get<SavingsGoal>('api-mobile', '/savings/goals')
      .pipe(map((goal) => Boolean(Object.keys(goal).length > 0)));
  }
  // should always call hasSavingsGoal first to avoid encountering the empty goal
  // MW savings.service getGoals
  getSavingsGoal(): Observable<SavingsGoal | undefined> {
    return this.apiCache
      .get<SavingsGoal>('api-mobile', '/savings/goals')
      .pipe(map((goal: SavingsGoal) => (Object.keys(goal).length > 0 ? goal : undefined)));
  }

  getStatements(): Observable<SavingsStatementsResponse | undefined> {
    return this.apiCache.get<SavingsStatementsResponse>('api-mobile', '/savings?filter=statementrange');
  }

  getStatement(month: string, year: string): Observable<any> {
    return this.apiCache.get<any>('api-mobile', `/savings/statement?month=${month}&year=${year}`);
  }

  //MW uses finsol.service GET /deposit/link which is same response as GET /savings banks BUT deposit is better since it doesnt assume the existence of BS accounts
  getLinkedBanks(): Observable<LinkedBank[]> {
    return this.apiCache.get<{ banks: LinkedBank[] }>('api-mobile', '/deposit/link').pipe(
      map((response) => response?.banks ?? []),
      catchError(() => [])
    );
  }

  getTransferableLinkedBanks(): Observable<LinkedBank[]> {
    return this.getLinkedBanks().pipe(
      map((banks) => banks.filter((bank) => TransferableLinkedBankStatuses.includes(bank.status)))
    );
  }

  getDepositAccount(): Observable<SpendingAccount> {
    return this.apiCache.get<SpendingAccount>('api-mobile', '/deposit');
  }

  getTransferAccounts(): Observable<TransferAccountDisplay[]> {
    return forkJoin([
      this.getSavingsAccount(),
      this.getTransferableLinkedBanks(),
      this.getDepositAccount(),
      this.featureFlagSvc.getFlag<boolean>(KnownFeatureFlags.FREEZETRANSFERPULL)
    ]).pipe(
      map((accounts) => {
        const transferAccounts: TransferAccountDisplay[] = [];
        if (accounts[0]) {
          transferAccounts.push({
            type: TransferAccountType.SAVINGS,
            name: accounts[0]?.name,
            balance: accounts[0]?.available_balance,
            lastFour: accounts[0].last_four_accnum,
          });
        }
        accounts[1].map((linkedBank) =>
          transferAccounts.push({
            type: TransferAccountType.ACH,
            id: linkedBank.id,
            name: linkedBank.bank_name,
            lastFour: linkedBank.last_four,
            balance: 0,
          })
        );
        if (accounts[2]) {
          transferAccounts.push({
            type: TransferAccountType.SPENDING,
            name: accounts[2].name,
            lastFour: accounts[2].last_four_accnum,
            balance: accounts[2].balance,
          });
        }
        return transferAccounts;
      })
    );
  }

  //MW savings.service getAccountDetails
  getSavingsAch(): Observable<SavingsAch | undefined> {
    return this.apiCache.get<SavingsAch>('api-mobile', '/savings?filter=accountRoutingNum');
  }

  clearSavingsCache() {
    this.apiCache.refreshItem('/savings');
    this.apiCache.refreshItem('/savings?filter=accountRoutingNum');
    this.apiCache.refreshItem('/savings?filter=statementrange');
    this.apiCache.refreshItem('/savings/transactions');
    this.apiCache.refreshItem('/client/mobilestate');
  }

  //MW savings.service
  createAccount(): Observable<ApiResponse> {
    this.clearSavingsCache();
    return from(this.awsApiWrapperService.post('api-mobile', '/savings', { headers: { 'Content-Type': 'application/json' } }));
  }

  createAccountWithSchedule(payload: SavingsScheduleRequest, source: InitiatingSource): Observable<ApiResponse> {
    this.clearSavingsCache();
    return from(
      this.awsApiWrapperService.post('api-mobile', '/savings', {
        body: { schedule: payload, initiating_source: source },
      })
    );
  }

  updateSchedule(requestBody: SavingsSchedulePatchRequest): Observable<SavingsScheduleResponse> {
    this.clearSavingsCache();

    return from(this.awsApiWrapperService.patch('api-mobile', '/savings/schedule', { body: requestBody }));
  }

  createSchedule(requestBody: SavingsScheduleCreateRequest): Observable<SavingsScheduleResponse> {
    this.clearSavingsCache();

    return from(this.awsApiWrapperService.post('api-mobile', '/savings/schedule', { body: requestBody }));
  }

  //MW finsol service transferFunds
  // NOTE there is a deposit api version of this same call but the logic is identical
  createTransfer(request: TransferRequest): Observable<ApiResponse> {
    this.clearSavingsCache();
    return from(this.awsApiWrapperService.post('api-mobile', '/savings/transactions', { body: request }));
  }

  //MW savings service deleteAccount
  deleteAccount(): Observable<ApiResponse> {
    this.clearSavingsCache();
    return from(this.awsApiWrapperService.del('api-mobile', '/savings', { headers: { 'Content-Type': 'application/json' } }));
  }

  canAccessAutosave(): Observable<boolean> {
    return forkJoin([this.getSavingsAccount(), this.mobile.getProductOnboardingEligibility(EligibleProduct.payrollSavings)]).pipe(
      // @ts-ignore
      map(([account, eligibility]) => !!(account && (eligibility || SavingsAccountService.hasSetupAutosaveBefore(account))))
    );
  }
}
