import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'bw-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit, OnChanges {
  @Input() startSelected = false;
  @Input() letCallerSetOn = false;
  @Input() label = '';

  @Output() statusChanged = new EventEmitter();

  checked = false;

  constructor() {}

  ngOnInit() {
    if (this.startSelected) {
      this.checked = true;
    }
  }

  ngOnChanges() {
    if (this.letCallerSetOn) {
      this.checked = this.startSelected;
    }
  }

  handleToggleClick() {
    if (!this.letCallerSetOn) {
      this.checked = !this.checked;
    }

    this.statusChanged.emit(!this.checked);
  }
}
