import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from "@angular/forms";
import {ngxMaskOptions} from "@brightside/brightside-ui";
import {CurrencyMaskInputMode} from "ngx-currency";
import {debounceTime} from "rxjs/operators";
import {Subscription} from "rxjs";

export interface CurrencySelectAmountOption {
  value: number;
  selected: boolean;
}

@Component({
  selector: 'bw-new-currency-select',
  templateUrl: './new-currency-select.component.html',
  styleUrls: ['./new-currency-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: NewCurrencySelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: NewCurrencySelectComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCurrencySelectComponent implements OnInit, OnDestroy {

  sub = new Subscription();
  private _keypressed: boolean;

  @Input() amountOptions: CurrencySelectAmountOption[];
  @Input() precision = 2;
  @Output() selectionChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() preselectedValue: number|null;
  @Input() forcedMaxValue: number|null;

  currencyForm: FormGroup = new FormGroup({
    amount: new FormControl('', Validators.required)
  });

  currencyOptions = {
    ...ngxMaskOptions,
  }

  constructor() {
  }

  ngOnInit(): void {
    this.currencyOptions = {
      ...ngxMaskOptions,
      precision: this.precision,
      prefix: '$',
      allowNegative: false,
      align: 'center',
      nullable: true,
      inputMode: CurrencyMaskInputMode.NATURAL, //Use '.FINANCIAL' for cash register type entry
    }
    this.sub.add(
      this.currencyForm.controls['amount'].valueChanges.pipe(
        debounceTime(250)
      ).subscribe(
        value => {
          if (value != null) {
            if (this.forcedMaxValue && (value > this.forcedMaxValue)) {                
              this.currencyForm.controls['amount'].setValue(this.forcedMaxValue);
            } else {                            
              this.selectionChanged.emit(value);
            }            
          }
        }
      )
    );
    this.setInitialValues();
  }

  setInitialValues() {
    if (this.preselectedValue) {
      this.currencyForm.controls['amount'].setValue(this.preselectedValue);
    } else if (this.amountOptions?.length > 0) {
      const selectedOption = this.amountOptions.find(option => option.selected);
      if (selectedOption) {
        this.currencyForm.controls['amount'].setValue(selectedOption.value);
      }
    }
  }

  selectOption(option: CurrencySelectAmountOption) {
    this.currencyForm.controls['amount'].setValue(option.value);
    const currentOption = this.amountOptions.find(opt => opt.selected);
    if (currentOption) currentOption.selected = false;
    option.selected = true;
    this._keypressed = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deselectOptions() {
    if (this._keypressed) return;
    if (this.amountOptions?.length > 0) {
      const currentOption = this.amountOptions.find(opt => opt.selected);
      if (currentOption) currentOption.selected = false;
    }
  }
}
