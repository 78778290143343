<section class="content-layout">
  <bw-action-article [brightsideWebFb]="{ event_name: eventName }">
    <bw-icon
      *ngIf="closeable"
      back-action-top
      iconName="close"
      size="24"
      [brightsideWebFb]="displayFormAnalytics.dismiss"
      (click)="handleCloseClick()"
    ></bw-icon>

    <div header-title class="mb-sm">
      <typography title-medium class="mb-sm">{{ titleText | translate }}</typography>
    </div>

    <div content-section>
      <p text-large-regular class="mb-md" color="var(--secondary-text)">{{ bodyText | translate }}</p>
      <div class="flex-column space-center mb-xxl">
        <img *ngIf="imageName" src="../assets/{{ imageName }}.svg" [alt]="imageName" class="mb-md" />
      </div>
      <div class="mb-xxl"></div>
    </div>

    <div footer-actions class="flex-row row-reverse">
      <button bw-button fluidWidth type="submit" [brightsideWebFb]="{ event_name: eventName }" (click)="handlePrimaryClick()">
        {{ primaryCtaText | translate }}
      </button>

      <typography text-large-semibold>
        <a
          *ngIf="secondaryCtaText"
          bw-anchor
          fluidWidth
          class="bs-blue brand pr-xl"
          [brightsideWebFb]="{ event_name: eventName + '_secondary' }"
          [dismissedCta]="true"
          (click)="handleSecondaryClick()"
          >{{ secondaryCtaText | translate }}</a
        >
      </typography>
    </div>
  </bw-action-article>
</section>
