export enum MessageBusChannelType {
  ERROR = 'ErrorChannel',
  TOAST = 'ToastChannel',
}

export enum MessageBusEventChannel {
  INCOMING = 'MessageBusIncomingChannel',
  OUTGOING = 'MessageBusOutgoingChannel',
  INTERNAL = 'MessageBusInternalChannel',
}

export enum MessageBusProductEventChannel {
  SAVINGS = 'SavingsAccountChannel',
}

export enum MessageBusDetectedDeviceType {
  IOS = 'IOS',
  BROWSER = 'BROWSER',
  ANDROID = 'ANDROID',
  UNKNOWN = 'UNKNOWN',
}

export enum MessageBusIncomingEventKey {
  AUTH = 'auth ',
  CAMERA_RESPONSE = 'data:image/png;base64,', // Needed for Android handling since it's returned via bridge
  CASHFLOW = 'cashflow-',
  FILE_RESPONSE = 'data:',
  FOREGROUND = 'foregrounded',
  HALF_SHEET = 'halfSheet ',
  NEW_TOKEN = 'NEWTOKEN',
  UNKNOWN = 'No matches found',
}

export const MessageBusIncomingEventKeyList: {
  key: string;
  value: string;
}[] = Object.entries(MessageBusIncomingEventKey).map(([key, value]) => ({ key, value }));

export const MessageBusIncomingEventKeyByValue = (keyString: string) =>
  MessageBusIncomingEventKey[keyString as keyof typeof MessageBusIncomingEventKey];

export enum MessageBusInternalEventKey {
  AUTH_UPDATED = 'authorize.updated',
  CHANGED_LANGUAGE = 'language.changed',
  DOMAIN_MAPPING_LOADED = 'remote.config.domain.mapping.loaded',
  NAVIGATION_END = 'navigation.end',
  SAVE_FILE = 'document.upload.save',
  SELECT_LANGUAGE = 'language.display.select',
}

export const MessageBusInternalEventKeyList: {
  key: string;
  value: string;
}[] = Object.entries(MessageBusInternalEventKey).map(([key, value]) => ({ key, value }));

export const MessageBusInternalEventKeyByValue = (keyString: string) =>
  MessageBusInternalEventKey[keyString as keyof typeof MessageBusInternalEventKey];

export enum MessageBusOutgoingEventKey {
  ANALYTICS = 'ANALYTICS',
  AUTH = 'AUTH',
  CALL = 'CALL',
  CASHFLOW_LINK = 'CASHFLOW_LINK',
  CHAT = 'CHAT',
  CREDENTIALS = 'CREDENTIALS',
  EXIT_VIEW = 'EXIT_VIEW',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  VIEW_FILE = 'VIEW_FILE',
  FIREBASE = 'FIREBASE',
  HIDE_ANIMATION = 'HIDE_ANIMATION',
  HIDE_LOADING = 'HIDE_LOADING',
  HIDE_NAV = 'HIDE_NAV',
  LOADED = 'LOADED',
  ROUTE = 'ROUTE',
  SHARE_FILE = 'SHARE_FILE',
  SHOW_ANIMATION = 'SHOW_ANIMATION',
  SHOW_CAMERA = 'SHOW_CAMERA',
  SHOW_FEED = 'SHOW_FEED',
  SHOW_HALFSHEET = 'SHOW_HALFSHEET',
  SHOW_LOADING = 'SHOW_LOADING',
  SHOW_NAV = 'SHOW_NAV',
  SHOW_PHOTO_PICKER = 'SHOW_PHOTO_PICKER',
  UPLOAD_FILE = 'UPLOAD_FILE',
}

export const MessageBusOutgoingEventKeyList: {
  key: string;
  value: string;
}[] = Object.entries(MessageBusOutgoingEventKey).map(([key, value]) => ({ key, value }));

export const MessageBusOutgoingEventKeyByValue = (keyString: string) =>
  MessageBusOutgoingEventKey[keyString as keyof typeof MessageBusOutgoingEventKey];
