import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  UiTemplateContentGetFormControlUtil,
  UiTemplateFormContent,
  UiTemplateFormContentControl,
  UiTemplateFormContentValueChanged,
} from '@micro-ui/template/content';

import {
  UiTemplateFlyerActions,
  UiTemplateFlyerComponent,
  UiTemplateFlyerInputs,
  UiTemplateFlyerInterface,
} from '../flyer/flyer-page-template.component';

import { UiTemplateStateKey } from '../service/state-handler/state.enum';
import { UiTemplatePageStateForm, UiTemplateState } from '../service/state-handler/state.interface';

import { UiTemplateAction } from '../shared/model/page-template.enum';

export interface UiTemplateFormFlyerInterface extends UiTemplateFlyerInterface {
  inputs: UiTemplateFormFlyerInputs;
  actions: UiTemplateFlyerActions;
}

export interface UiTemplateFormFlyerInputs extends UiTemplateFlyerInputs {
  formContent?: UiTemplateFormContent[];
}

export type UiTemplateFormState = UiTemplateState;
export type UiTemplateFormFlyerActions = UiTemplateFlyerActions;

@Component({
  selector: 'ui-page-form-flyer',
  templateUrl: './form-flyer-page-template.component.html',
  styleUrls: ['./form-flyer-page-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiTemplateFormFlyerComponent extends UiTemplateFlyerComponent {
  @Input() override data: UiTemplateFormFlyerInterface;
  @Input() override logPrefix = '';
  @Input() override allowVerboseDebugMode = true;

  formGroup: FormGroup;

  fromDataFormContent: UiTemplateFormContent[];

  private startFormWatcher() {
    this.logForDebugging('started an observable for page form');

    this.sub.add(
      this.formGroup.valueChanges.subscribe((values) => {
        const updateStateWithForm: UiTemplatePageStateForm = {
          lastUpdate: new Date().toTimeString(),
          touched: this.formGroup.touched,
          valid: this.formGroup.valid,
          dirty: this.formGroup.dirty,
          values: values,
        };

        this.setUpdateStateWith({ [UiTemplateStateKey.FORM]: updateStateWithForm });
      })
    );
  }

  private buildFormControls() {
    this.fromDataFormContent.forEach((contentDetails: UiTemplateFormContent) => {
      const controlUtil = UiTemplateContentGetFormControlUtil(contentDetails.type);

      if (!controlUtil || typeof controlUtil !== 'function') {
        console.warn(
          `UiTemplateFormFlyerComponent is being used but the UiTemplateFormContent (${contentDetails.type}) type does not have a util function mapped.`
        );

        return;
      }

      const contentControls = controlUtil(contentDetails.data);

      contentControls.forEach((contentControlDetails: UiTemplateFormContentControl) =>
        this.formGroup.addControl(contentControlDetails.name, contentControlDetails.control)
      );
    });
  }

  public formValueChanged(change: UiTemplateFormContentValueChanged) {
    this.logForDebugging('form values have changed with ', change);

    if (change && change.name) {
      this.formGroup.setValue({ [change.name]: change.value });
    } else {
      console.warn('UiTemplateFormFlyerComponent Form value was changed but it is missing the name or entire object.');
    }
  }

  public templateDataChanged(): void {
    super.templateDataChanged();

    if (!this.formGroup) {
      this.formGroup = new FormGroup({});

      this.startFormWatcher();
    }

    if (this.data?.inputs?.formContent) {
      this.fromDataFormContent = this.data.inputs.formContent;

      this.buildFormControls();
    }

    if (!this.logPrefix) {
      this.logPrefix = `${this.data?.inputs?.viewName || '<missingViewName>'}`;
    }
  }

  public templateStateChanged(): void {
    super.templateStateChanged();

    this.fromDataAction.executeAction(UiTemplateAction.stateChanged);
  }
}
