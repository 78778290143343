import { FormGroup } from '@angular/forms';

const getProperty = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

/**
 * Utilize this to map form information to a interface type. Either directly or with a special mapping defined.
 * @param form FormGroup
 * @param specialMapping { [key: string]: string }
 * @param keyMapping { [key: string]: { attribute:string, value:string } }
 * @returns Passed T
 */
const ExtractValuesAndMapToType = <T>(form: FormGroup, specialMapping: { [key: string]: string } = {}, keyMapping: { [key: string]: { attribute:string, value:string } } = {}): T | any => {
  if (form) {
    const hasSpecialMapping = Object.keys(specialMapping).length > 0;
    const hasKeyMapping = Object.keys(keyMapping).length > 0;
    const dataAsType = Object.keys(form.controls).reduce((buildingObj, nextKey: string) => {
      //Check for special mapping to rename value search
      const specialInsertKey = specialMapping[nextKey] || null;
      const keyInsertObj = keyMapping[nextKey] || null;
      // const specialInsertVal = form.get(nextKey)?.value[keyMapping] || form.get(nextKey)?.value;
      //If we have special mapping but don't find the key, skip it
      if (hasSpecialMapping && !specialInsertKey) {
        return buildingObj;
      }
      // this is used to pull a value from an object in the form.value and put it into
      // a new key while retaining the existing key:value
      if (hasKeyMapping && keyInsertObj) {
        const newVal = form.get(nextKey)?.value[keyInsertObj.value];
        if (newVal) {
          return { ...buildingObj, [keyInsertObj.attribute]: newVal, [nextKey]: form.get(nextKey)?.value};
        }
      }

      return { ...buildingObj, [specialInsertKey || nextKey]: form.get(nextKey)?.value };
    }, {});

    return dataAsType as T;
  }

  return {};
};

/**
 * Place any util methods you wish to provide application code access with it.
 */
export const SharedDisplayFormUtil = {
  ExtractValuesAndMapToType,
};
