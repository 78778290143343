import { Component, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnInit } from '@angular/core';

@Component({
  selector: 'bw-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, AfterViewInit {
  @Input() title = '';
  @Input() cdnBase = '';
  @Input() path = '';
  @Input() show = false;

  @Output() modalClosed = new EventEmitter();

  fullVideoPath = '';
  isMp4 = false;

  constructor(private cdRef: ChangeDetectorRef) {
    /* ToDo: need tracking on video playback */
  }

  ngOnInit() {
    if (this.path.includes('mp4')) this.isMp4 = true;
    this.fullVideoPath = `${this.cdnBase}${this.path.replace('video:', '')}`;
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onModalClose() {
    this.modalClosed.emit();
  }
}
