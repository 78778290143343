import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthSsoService {
  constructor() {}

  static getSubDomain(): string {
    const hostnameSplit = window.location.hostname.toLowerCase().split('.');
    const domain = hostnameSplit[hostnameSplit.length - 2];
    if (hostnameSplit[0] === 'app' || domain !== 'gobrightside') {
      return '';
    } else {
      return hostnameSplit[0];
    }
  }

  static getRouteFragment(router: Router) {
    if (router.getCurrentNavigation() && router.getCurrentNavigation()?.extractedUrl) {
      return router.getCurrentNavigation()?.extractedUrl.fragment;
    }

    return '';
  }

  static getRouteFragmentAsObject(router: Router) {
    return (AuthSsoService.getRouteFragment(router) || '')
      .split('&')
      .sort()
      .reduce((lastState, nextFragmentSection: string) => {
        const [key, value] = nextFragmentSection.split('=');

        if (!key || !value) {
          return lastState;
        }

        return { ...lastState, [key]: value };
      }, {});
  }

  //Can't use OAuthEnvironment here because it's a circular dep.. maybe we should move definition which is why we used ANY :(
  static getSSOConfig(oauthInfo: any, company: string = ''): { oauthEnv: any; company: string } | undefined {
    const oauthEnv = oauthInfo || { companies: [], config: {} };
    const ssoCompanies = oauthEnv.companies || [];
    const subdomain = company || AuthSsoService.getSubDomain();

    if (ssoCompanies.includes(subdomain) && oauthEnv.config[subdomain]) {
      return { oauthEnv, company: subdomain };
    }

    return;
  }

  /**
   * This should return the redirect URL if either the passed in company, or the subdomain indicates the company supports SSO.
   *
   * @param oauthInfo oauth configurations
   * @param company the runtime company, for example, from PrecheckResponse.company.
   */
  static getRedirectTo(oauthInfo: any, company: string = ''): string {
    const oauthConfig = AuthSsoService.getSSOConfig(oauthInfo, company);

    if (oauthConfig) {
      const companyConfig = oauthConfig.oauthEnv.config[oauthConfig.company];
      const redirectTo = `${oauthConfig.oauthEnv.url}/oauth2/authorize?identity_provider=${
        companyConfig.name
      }&redirect_uri=https://${oauthConfig.company}${oauthConfig.oauthEnv.redirectBaseUrl}&response_type=TOKEN&client_id=${
        oauthConfig.oauthEnv.id
      }&scope=${oauthConfig.oauthEnv.scope.join(' ')}`;

      return redirectTo;
    }

    return '';
  }

  //ToDo: should move any specific SSO auth services here as well
}
