import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe,
    ) {}

  transform(value: number): string {
    value = value - 1;

    if (value < 0 || value > 11) {
      return 'Invalid month';
    }

    const date = new Date();
    date.setMonth(value);

    return this.datePipe.transform(date, 'MMMM', undefined) || '';
  }
}
