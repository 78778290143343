import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bw-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss']
})
export class CaptionComponent {

  /** Whether the text is center aligned */
  @Input() centerAlign = false;

  /** The color of the text, can take any color class derived from css color variables found in theme.component.scss */
  @Input() color = 'bs-mid-gray';

  /** The space between the bottom of this element and the top of its subsequent sibling */
  @Input() verticalSpace = '0';

  constructor() {}

}
