import {
  UiTemplateAction,
  UiTemplateActionFunctionReturnRouter,
  UiTemplateBaseActionFunction,
  UiTemplateBaseActionRoute,
} from '../../shared/model/page-template.enum';
import { UiTemplateBaseActions } from '../../shared/model/page-template.interface';

import { UiTemplateStateComponent } from '../../shared/state-page-template.component';
import { UiTemplateBaseComponent } from '../../shared/base-page-template.component';

type ActionFunction = UiTemplateActionFunctionReturnRouter | UiTemplateBaseActionFunction;

export class UiTemplateActionHandler {
  /**
   * If you wish to have developer logging turn this on
   *
   * Default: true
   */
  protected allowVerboseDebugMode = true;
  protected logPrefix = '';

  protected lastExecutedActionKey: UiTemplateAction;

  constructor(
    private actions: UiTemplateBaseActions = {},
    private uiComponent: UiTemplateStateComponent | UiTemplateBaseComponent | undefined = undefined
  ) {
    this.logForDebugging(actions);

    if (uiComponent) this.updateUiComponent(uiComponent);

    this.updateActions(actions);
  }

  protected logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode)
      console.log(`action:${this.uiComponent?.templateDisplayName || '<notFound>'} - `, message, optionalParams);
  }

  /**
   * Will execute the target action, providing state when applicable.
   * If the action produces a routing side affect, it will be handled
   * via the uiComponent routeTo method
   *
   * @param action <UiTemplateBaseActionFunction>
   * @returns void
   */
  protected action(action: UiTemplateBaseActionFunction): void {
    if (!this.uiComponent || this.uiComponent.hasActionLocked) {
      console.warn('UiTemplateActionHandler: Trying to trigger action when another action is in progress');
      return;
    }

    //Lock actions until this one is complete
    this.uiComponent.hasActionLocked = true;

    const state = (this.uiComponent as UiTemplateStateComponent).state || undefined;
    const stateObj = state.getState() || undefined;
    const stateUpdateWith = state?.updateWithPartial.bind(state) || undefined;

    const actionFnResponse = action(stateObj, stateUpdateWith);

    this.logForDebugging('response of action function', actionFnResponse);

    //Unlock actions
    this.uiComponent.hasActionLocked = false;

    //If the action returns a route object, let's direct traffic there.
    if (actionFnResponse?.route) {
      this.uiComponent.routeTo(actionFnResponse, this.lastExecutedActionKey);
    }
  }

  updateActions(actions: UiTemplateBaseActions) {
    this.actions = actions;
  }

  updateUiComponent(uiComponent: UiTemplateBaseComponent) {
    this.uiComponent = uiComponent;
  }

  clear() {
    this.actions = {};
    this.uiComponent = undefined;
  }

  executeAction(actionKey: UiTemplateAction) {
    this.logForDebugging('executeAction with ', actionKey);
    this.lastExecutedActionKey = actionKey;

    //Check if we don't have a UI component
    if (!this.uiComponent) {
      this.logForDebugging('We could not complete this action because we do not have a UI component reference');
      return;
    }

    //Check if we have an action to use
    if (!this.actions[actionKey]) {
      this.logForDebugging('We could not complete this action. There was nothing configured in your template data');
      return;
    }

    if (typeof this.actions[actionKey] === 'function') {
      this.logForDebugging('executeAction ACTION FUNCTION');

      this.action(this.actions[actionKey] as ActionFunction);
      return;
    }

    this.uiComponent.routeTo(this.actions[actionKey] as UiTemplateBaseActionRoute, this.lastExecutedActionKey);
  }
}
