import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'bw-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() percentProgress?: string;
  @Input() color? = 'blue';

  ngOnInit() {
    switch (this.color) {
      case 'blue':
        this.color = '#005fff';
        break;
      case 'green':
        this.color = 'green';
        break;
      default:
        this.color = '#005fff';
        break;
    }
  }

}
