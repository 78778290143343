import { Component, OnInit, Injector } from '@angular/core';
import {AnalyticsAttributesV4, isTemplateFullScreen, Settings} from "@brightside-web/desktop/data-access/shared";
import {RoutingStateService} from "@brightside/brightside-ui-services";
import {ActivatedRoute} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export class SharedDisplayContentArraySettings extends Settings {
  constructor(config: any = {}) {
    super(config);
    this.pageContentArray = config.pageContentArray || [];
    this.pageContentArrayKey = config.pageContentArrayKey || undefined;
    this.pageConfirmCheckText = config.pageConfirmCheckText;
  }

  pageConfirmCheckText? = '';

  // one should not exist without the other
  pageContentArray?: [];
  pageContentArrayKey?: {title: string; listItems:string};
}

@Component({
  selector: 'brightside-web-display-content-array',
  templateUrl: './shared-display-content-list.component.html',
  styleUrls: ['./shared-display-content-list.component.scss'],
})
export class SharedDisplayContentListComponent implements OnInit {
  config: SharedDisplayContentArraySettings = new SharedDisplayContentArraySettings({
    fbPage: 'page',
    fbCategory: '',
    pageTitle: '',
    pageSubTitle: '',
    pageCtaLabel: 'BUTTON_CONTINUE',
    pageSecondaryCtaLabel: '',
    pageSecondaryCtaEvent: null,
    pageOnForwardCtaPath: [],
  });

  pageIsFullScreen: boolean;

  displayAnalytics: {
    shown: AnalyticsAttributesV4;
    dismiss: AnalyticsAttributesV4;
    submit: AnalyticsAttributesV4;
  } = {
    shown: { event_name: '' },
    dismiss: { event_name: '' },
    submit: { event_name: '' },
  };

  isCheckboxClicked = false;

  checkboxForm: FormGroup;

  constructor(
    private routingState: RoutingStateService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.checkForConfigViaData();
  }

  private checkForConfigViaData() {
    if (isTemplateFullScreen(this.activatedRoute)) {
      this.pageIsFullScreen = true;
    }

    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new SharedDisplayContentArraySettings(this.activatedRoute.snapshot.data.pageConfig);
      this.setUpAnalytics();
      this.setUpConfirmCheckbox();
    }

    this.activatedRoute.data.subscribe(() => {
      this.config = new SharedDisplayContentArraySettings(this.activatedRoute.snapshot.data.pageConfig);
      this.setUpAnalytics();
      this.setUpConfirmCheckbox();
    });
  }

  private setUpAnalytics() {
    if (this.config.analyticsAttributes) {
      this.displayAnalytics.shown = this.config.analyticsAttributes('shown');
      this.displayAnalytics.dismiss = this.config.analyticsAttributes('dismiss');
      this.displayAnalytics.submit = this.config.analyticsAttributes('tapped');
    }
  }

  private setUpConfirmCheckbox() {
    if (this.config.pageConfirmCheckText) {
      this.checkboxForm = new FormGroup({
        'controlConfirmCheckbox': new FormControl(false, [Validators.required, Validators.requiredTrue])
      })
    }
  }

  handleBackClick() {
    this.handleMoveBackward();
    return false;
  }

  protected handleMoveBackward() {
    this.routingState.popAndNavigateTo(this.config.pageOnBackCtaPath || []);
  }

  handleCtaClick() {
    this.handleMoveForward();
  }
  protected handleMoveForward() {
    this.routingState.popAndNavigateTo(this.config.pageOnForwardCtaPath || []);
  }

}
