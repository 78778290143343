import { FormGroup } from '@angular/forms';
import {
  HubPayload,
  MessageBusEventChannel,
  MessageBusIncomingEventKey,
  MessageBusInternalService,
} from '@brightside-web/micro/core/message-bus';

import { take } from 'rxjs/operators';

import { SharedDisplayFormControlConfig, SharedDisplayFormDocumentUtil } from '../../../index';

/**
 * @description
 * Responder can be used by any action that wants to handle file uploads. Supports both file upload
 * and image capture.
 *
 * Should match ClickResponderFunction type
 *
 *
 * @param {FormGroup} form;
 * @param {SharedDisplayFormControlConfig} targetControlConfig;
 *
 *
 * @usageNotes
 * File will be broadcasted out via message bus like below:
 *
 * MessageBusInternalService.sendInternalHubEvent({
 *  event: MessageBusInternalEventKey.SAVE_FILE,
 *  data: { docKey: key, docValue: value, file },
 * });
 *
 *
 * @example
 * n/a
 *
 */
export const SharedDisplayFormFileActionResponder = (form: FormGroup, targetControlConfig: SharedDisplayFormControlConfig) => {
  //TODO translate this default filename
  const DocumentUploadMarkControlFilled = (fileName: string = 'document') => {
    if (targetControlConfig.cellDetails?.action) {
      targetControlConfig.cellDetails.action.emphasize = SharedDisplayFormDocumentUtil.getSetting.selected.emphasize;
      targetControlConfig.cellDetails.iconNameRight = SharedDisplayFormDocumentUtil.getSetting.selected.icon;

      (targetControlConfig.cellDetails as any).originalLabelSubLeft = targetControlConfig.cellDetails.labelSubLeft;
      targetControlConfig.cellDetails.labelSubLeft = fileName;

      form.get(targetControlConfig.key)?.setValue(fileName);

      DocumentUploadAddRemoveActionEvent();
    }
  };
  const DocumentUploadAddRemoveActionEvent = () => {
    if (targetControlConfig.cellDetails?.action) {
      (targetControlConfig.cellDetails as any).originalActionEvent = targetControlConfig.cellDetails.action.event;
      targetControlConfig.cellDetails.action.event = SharedDisplayFormDocumentUtil.getSetting.deleteHalfSheet;
    }
  };
  const DocumentUploadRemoveActionEvent = () => {
    if (targetControlConfig.cellDetails?.action) {
      targetControlConfig.cellDetails.action.event = (targetControlConfig.cellDetails as any).originalActionEvent;
      delete (targetControlConfig.cellDetails as any).originalActionEvent;
    }
  };

  const DocumentUploadCaptureHandler = () => {
    SharedDisplayFormDocumentUtil.startDocumentUploadCapture(targetControlConfig.key)
      .pipe(take(1))
      .subscribe((fileName: string) => {
        //ToDo: should we do anything with the case of not picking a file... tracking at least?
        if (fileName) {
          form.markAsTouched();
          form.markAsDirty();

          DocumentUploadMarkControlFilled(fileName);
        }
      });
  };
  const DocumentUploadPickerHandler = () => {
    SharedDisplayFormDocumentUtil.startDocumentUploadPicker(targetControlConfig.key)
      .pipe(take(1))
      .subscribe((fileName: string) => {
        //ToDo: should we do anything with the case of not picking a file... tracking at least?
        if (fileName) {
          form.markAsTouched();
          form.markAsDirty();

          DocumentUploadMarkControlFilled(fileName);
        }
      });
  };
  const DocumentUploadDeleteHandler = () => {
    SharedDisplayFormDocumentUtil.removeSelectedDocument(targetControlConfig.key);

    form.markAsUntouched();
    form.get(targetControlConfig.key)?.setValue('');

    if (targetControlConfig.cellDetails?.action) {
      targetControlConfig.cellDetails.action.emphasize = SharedDisplayFormDocumentUtil.getSetting.unselected.emphasize;
      targetControlConfig.cellDetails.iconNameRight = SharedDisplayFormDocumentUtil.getSetting.unselected.icon;
      targetControlConfig.cellDetails.labelSubLeft = (targetControlConfig.cellDetails as any).originalLabelSubLeft;

      delete (targetControlConfig.cellDetails as any).originalLabelSubLeft;

      DocumentUploadRemoveActionEvent();
    }
  };

  /**
   * This watcher will wait for events from the halfsheet bridge info
   * and execute the mapped callback for the code provided.
   */
  MessageBusInternalService.addHubListenerWithEventFilter({
    channel: MessageBusEventChannel.INCOMING,
    filterByEvents: [MessageBusIncomingEventKey.HALF_SHEET],
    take: 1, //Note this is here to make sure we don't get more than one image selector shown
    callbackListener: (payload: HubPayload) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const code = payload.data.body.code;

      if (code === 'documentUpload_NewPicture') {
        DocumentUploadCaptureHandler();
      } else if (code === 'documentUpload_ChoosePhoto') {
        DocumentUploadPickerHandler();
      } else if (code === 'documentUpload_DeleteFile') {
        DocumentUploadDeleteHandler();
      }
    },
  });
};
