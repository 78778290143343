import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingStateService } from '@brightside/brightside-ui-services';
import { Settings, isTemplateFullScreen } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-display-message',
  templateUrl: './display-message.component.html',
})
export class DisplayMessageComponent implements OnInit {
  config: Settings = new Settings({
    fbPage: 'page',
    fbCategory: 'consents',
    fbEventName: '',
    pageTitle: '',
    pageTitleImage: '',
    pageTitleKey: '',
    pageSubTitle: '',
    pageSubTitleKey: '',
    pageCtaLabel: 'BUTTON_CONTINUE',
    pageCtaLabelKey: '',
    pageSecondaryCtaLabel: '',
    pageSecondaryCtaLabelKey: '',

    pageOnForwardCtaPath: [],
  });

  processing: boolean;

  pageIsFullScreen: boolean;

  constructor(private routingState: RoutingStateService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.checkForConfigViaData();
  }

  private checkForConfigViaData() {
    if (isTemplateFullScreen(this.activatedRoute)) {
      this.pageIsFullScreen = true;
    }

    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new Settings(this.activatedRoute.snapshot.data.pageConfig);
    }
  }

  handleMoveForward() {
    let options = {};

    if (this.config.pageOnForwardCtaData) {
      options = { queryParams: this.config.pageOnForwardCtaData };
    }

    this.routingState.navigateTo(this.config.pageOnForwardCtaPath, options);
  }
}
