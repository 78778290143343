import { Component, OnInit, ViewEncapsulation, Input, Renderer2 } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'bw-theme, [bw-theme]',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
  deviceInfo: any;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;

  constructor(private deviceService: DeviceDetectorService, private renderer: Renderer2) {
    if (this.deviceInfo) {
      this.getDevice();
    }
  }

  ngOnInit() {
    this.screenSizeClasses();
    if (this.deviceInfo) {
      this.renderer.addClass(document.documentElement, this.deviceInfo.os);
      if (this.isMobile) {
        this.renderer.addClass(document.documentElement, 'mobile-device');
      }
      if (this.isTablet) {
        this.renderer.addClass(document.documentElement, 'tablet-device');
      }
      if (this.isDesktop) {
        this.renderer.addClass(document.documentElement, 'desktop-device');
      }
    }
  }

  screenSizeClasses() {
    if (window.innerWidth < 640) {
      this.renderer.removeClass(document.documentElement, 'tablet-view');
      this.renderer.removeClass(document.documentElement, 'desktop-view');
      this.renderer.addClass(document.documentElement, 'mobile-view');
    } else if (window.innerWidth >= 640 && window.innerWidth < 1007) {
      this.renderer.removeClass(document.documentElement, 'mobile-view');
      this.renderer.removeClass(document.documentElement, 'desktop-view');
      this.renderer.addClass(document.documentElement, 'tablet-view');
    } else {      
      this.renderer.removeClass(document.documentElement, 'tablet-view');
      this.renderer.removeClass(document.documentElement, 'mobile-view');
      this.renderer.addClass(document.documentElement, 'desktop-view');
    }
  }

  onResize(event: Event) {
    this.screenSizeClasses();
  }

  getDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }
}
