import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

declare global {
  interface Window {
      Cypress: any;
  }
}

@Component({
  selector: 'bw-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
})
export class PhoneFormComponent implements OnInit {
  phoneForm: FormGroup;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() fGroup: FormGroup;
  @Input() fcName = 'phone';
  /** Whether the input has an error in validation */
  @Input() inputError: boolean;
  /** Text that is shown below the input if there is a validation error */
  @Input() inputErrorText: string;
  @Input() autoFocus: boolean;
  @Input() disabled = false;
  @Input() isProd: boolean;

  prodPhoneControl = new FormControl('', [
    Validators.required,
    Validators.minLength(12),
    Validators.maxLength(12),
    Validators.pattern('\\d{3}-\\d{3}-\\d{4}'),
    phoneValidator(),
  ]);

  nonProdPhoneControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(12),
  ]);

  constructor() {}

  ngOnInit() {
    if (this.isProd) {
      if (!this.fGroup) {
        this.phoneForm = new FormGroup({});
        this.phoneForm.addControl(
          this.fcName,
          this.prodPhoneControl
        );
      } else {
        this.fGroup.addControl(
          this.fcName,
          this.prodPhoneControl
        );
      }
    } else {
      if (!this.fGroup) {
        this.phoneForm = new FormGroup({});
        this.phoneForm.addControl(
          this.fcName,
          this.nonProdPhoneControl
        );
      } else {
        this.fGroup.addControl(
          this.fcName,
          this.nonProdPhoneControl
        );
      }
    }
  }
}

/**
 * right now this validates using US mobile # rules
 * if we need flexibility to validate a non-mobile # vs a mobile # we can add an input type and then import the proper parser
 * based on the type
 */
export const phoneValidator = (): ValidatorFn =>
   (control: AbstractControl): { [key: string]: any } | null => {
    //Check if we are during E2E testing
    if (window && window.Cypress) {
      return null;
    }

    const phone = parsePhoneNumberFromString(control.value, 'US');

    return phone && phone.isValid() ? null : { phoneFail: { value: control.value } };
  };

