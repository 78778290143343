import {Component, Input, OnInit, Output, EventEmitter, OnDestroy, HostListener, Directive} from '@angular/core';


import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  CONTROL_DOB,
  CONTROL_LAST_FOUR,
  CONTROL_ZIP,
  CONTROL_ADDRESS_WORK_STATE,
  DisplayFormControlConfig,
  InputInfoInterface,
  UniqueIDFormElement,
  CONTROL_FNAME,
  CONTROL_LNAME,
  CONTROL_FNAME_PRIMARY,
  CONTROL_LNAME_PRIMARY,
  CONTROL_DOB_PRIMARY,
  CONTROL_ZIP_PRIMARY, EmployeeIdRules
} from "@brightside-web/shared/desktop";
import {
  CompanyService,
  EligibilityFieldsObject,
  FirebaseService, RequiredVerification,
  RequiredVerificationService,
  TermsService,
  ClientConfirmationErrorItems,
  ClientConfirmationErrorCodes as errorCodes,
  ClientConfirmationService,
  ClientConfirmationSubmissionInterface as submissionObject, CacheStorageService, ClientConfirmationErrorCode
} from "@brightside-web/desktop/data-access/shared";
import {forkJoin, from, Subscription, tap} from "rxjs";
import {Environment} from "@brightside-web/micro/core/environment";
import {RegistrationService} from "@brightside-web/desktop/data-access/onboarding";
import {
  BsAuthService,
  BsCacheService
} from "@brightside-web/desktop/data-access/core-services";
import * as moment from "moment";
import {Router} from "@angular/router";
import {
  animate,
  style,
  transition,
  trigger,
  state
} from "@angular/animations";


@Component({
  selector: 'brightside-web-eligibility-check',
  templateUrl: './eligibility-check.component.html',
  styleUrls: ['./eligibility-check.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        maxHeight: '209px'
      })),
      state('out', style({
        maxHeight: '0px'
      })),
      transition('in => out', animate('400ms 100ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class EligibilityCheckComponent implements OnInit, OnDestroy {

  @Output() identitySubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() analyticName: string;
  @Input() replay = false;
  @Input() showBackButton = false;
  @Input() emitBackButton = false;
  @Input() analyticEventProps: { [key: string]: string };

  faHours: string;

  mainAnalytics: unknown;

  homeZipControl = CONTROL_ZIP;

  statesArray = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
  statesToDisplayArray = [...this.statesArray];
  selectedState: string;
  uniqueIdFormElement: UniqueIDFormElement;
  formControls: DisplayFormControlConfig[] = [];
  eligibilityFormControls: FormControl[] = [];
  eligibilityForm: FormGroup;
  electiveProductsForm: FormGroup;
  electiveProductChoices: {value: string}[] = [];
  showElectiveProducts = false;
  selectedProducts = 'none';
  EligibilityToControls = {
    unique_id: () => {
      this.uniqueIdFormElement = this.uniqueIdFormElement ? this.uniqueIdFormElement : new UniqueIDFormElement(this.analytics);
      this.uniqueIdFormElement.CONTROL_UNIQUE_ID.showInfo = true;
      this.uniqueIdFormElement.CONTROL_UNIQUE_ID.infoKey = this.uniqueIdFormElement.CONTROL_UNIQUE_ID.inputInfo?.infoKey;
      return this.uniqueIdFormElement.CONTROL_UNIQUE_ID;
    },
    first_name: CONTROL_FNAME,
    last_name: CONTROL_LNAME,
    date_of_birth: CONTROL_DOB,
    last_four_ssn: CONTROL_LAST_FOUR,
    home_zip_code: this.homeZipControl,
    work_state: CONTROL_ADDRESS_WORK_STATE
  };



  primaryEmployeeForm: FormGroup;
  primaryEmployeeFormControls: DisplayFormControlConfig[] = [
    CONTROL_FNAME_PRIMARY,
    CONTROL_LNAME_PRIMARY,
    CONTROL_ZIP_PRIMARY,
    CONTROL_DOB_PRIMARY,
  ]
  showCollectPrimaryForm = false;
  collectPrimary: boolean;

  ControlSubmissionMap: { [key: string]: EligibilityFieldsObject } = {};

  sub = new Subscription();
  supportNumber: string; //= Cache.getItem('supportNumber');
  employmentType: string;
  customerType: string;
  userNumber: string;
  cdnUrl: string;

  company: string;
  processing = false;
  aliasValidatorWithRules: ValidatorFn;

  showInfoModal: boolean;
  displayModal: InputInfoInterface;
  displayError: ClientConfirmationErrorCode;
  showErrorModalCloseBtn: boolean;
  showErrorModal: boolean;
  errorRedirectURL: string;
  maxRetriesExceeded: boolean;
  maxRetry = 3;
  canContinue: boolean;
  disableSubmit: boolean;

  formIsReady: boolean;

  lastName: string;

  processError =  {
    errorId: 'identity',
    errorTitleKey: 'ERROR_GENERAL_TITLE',
    errorDescKey: 'ERROR_GENERAL_DESC',
    ctaKey: 'OK'
  }

  hasConsent: boolean;
  consentPath: string;

  displayDefaultIcon = false;

  @Output() backEmitted = new EventEmitter();

  skipAccountCreation: boolean;
  utmVal: any;

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.nodeName === 'A' && target.classList.contains('track-me')) {
      const aTarget = target as HTMLAnchorElement;
      this.analytics.logEvent('link_tapped', {company: this.companyService.getCompany(), url: aTarget.href})
    }
  }

  constructor(
    private analytics: FirebaseService,
    private companyService: CompanyService,
    private registrationService: RegistrationService,
    private requiredVerificationService: RequiredVerificationService,
    private env: Environment,
    private termsService: TermsService,
    private router: Router,
    private clientConfirmationService: ClientConfirmationService,
    private cacheStorageSvc: CacheStorageService,
    private bsCacheService: BsCacheService,
    private bsAuthService: BsAuthService
  ) {
  }

  ngOnInit() {

    forkJoin([
      from(this.cacheStorageSvc.getItem('supportNumber')),
      from(this.bsCacheService.getItem('employmentType')),
      from(this.bsCacheService.getItem('utm_term')),
      from(this.bsCacheService.getItem('FAHOURS')),
      from(this.bsCacheService.getItem('customerType'))
    ])
      .subscribe( ([supportNumber,employmentType,utmVal,faHours,customerType]) => {
        this.supportNumber = supportNumber;
        this.employmentType = this.registrationService.referralItems?.employmentType ?? employmentType;
        this.utmVal = utmVal;
        this.faHours = faHours;
        this.customerType = customerType;
        this.skipAccountCreation = this.registrationService.skipAccountCreation;
        this.homeZipControl.labelKey = 'ZIP_CODE';
        this.sub.add(
          from(this.bsAuthService.fetchUserAttributes()).subscribe((attributes) => {
            if (!attributes) return;
            if (attributes && attributes.last_name) this.lastName = attributes.last_name;
            this.launchCheck();
          })
        );
      });
  }

  launchCheck() {

    if (this.analyticEventProps) {
      this.mainAnalytics = {event_name: this.analyticName, custom: this.analyticEventProps}
    } else {
      this.mainAnalytics = {event_name: this.analyticName};
    }
    this.cdnUrl = this.env.cdnUrl;
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          if (value && !this.company) {
            this.company = value;
            this.sub.add(
              this.requiredVerificationService.getRequiredVerifications(this.replay, this.skipAccountCreation).subscribe(
                response => {
                  if (response && response.length > 0) {
                    this.handleRequiredVerifications(response);
                  } else {
                    this.router.navigate(['/']);
                  }
                }
              )
            );
          }
        }
      )
    )
  }

  handleRequiredVerifications(response: RequiredVerification[]) {

    response.forEach(required => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let control = this.EligibilityToControls[required.eligibility_field.name];

      if (typeof control === "function") {
        control = control();
      }
      if(control && required.eligibility_field) {
        control['classes'] = 'flex-item-flexible max-form-width';
        this.formControls.push(control);
        this.ControlSubmissionMap[control.key] = required.eligibility_field;
      } else if (required.eligibility_field && required.eligibility_field.sub_fields) {
        required.eligibility_field.sub_fields.forEach( (value, index) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const selectedControl = this.EligibilityToControls[value.name];
          if(selectedControl) {
            selectedControl['classes'] = 'flex-item-half';
            this.formControls.push(selectedControl);
            this.ControlSubmissionMap[selectedControl.key] = value;
          }
        })
      }

    });
    this.setUpForm();
  }

  setUpForm() {
    const controls: { [key: string]: FormControl | FormArray } = {};

    if (this.requiredVerificationService.electiveProducts && this.requiredVerificationService.electiveProducts.length > 0) {

      this.requiredVerificationService.electiveProducts.forEach(choice => {
        this.electiveProductChoices.push({value: choice});
      });
      this.electiveProductsForm = new FormGroup(
        {selectedElectiveProduct: new FormControl('', Validators.required)}
      );

      if (this.utmVal) {
        for (const electiveProd in this.requiredVerificationService.electiveProducts) {
          if (electiveProd) {
            const prodString = this.requiredVerificationService.electiveProducts[electiveProd];
            if (this.utmVal.toUpperCase() === prodString.toUpperCase()) {
              this.electiveProductsForm.setValue({'selectedElectiveProduct': prodString} )
              this.productRadioChanged(true, prodString);
              break;
            }
          }
        }
      }

      this.showElectiveProducts = true;
    }

    if (this.formControls) {
      this.formControls.forEach((controlConfig: DisplayFormControlConfig) => {
        controls[controlConfig.key] = controlConfig.control;
        if (controlConfig.key === 'uniqueId') {
          this.aliasValidatorWithRules = this.aliasValidatorWithRules ? this.aliasValidatorWithRules : this.uniqueIdFormElement.aliasValidator(this.company);
          controlConfig.control.addValidators(this.aliasValidatorWithRules);
          controlConfig.control.updateValueAndValidity();
        }

        if (controlConfig.key.toLowerCase() === 'workstate') {
          this.sub.add(
            controlConfig.control.valueChanges.subscribe(
              value => {
                this.statesToDisplayArray = this.statesArray.filter( pickedState => pickedState.toLowerCase().startsWith(value.toLowerCase()));
              }
            )
          )
        }

        if (this.replay && this.ControlSubmissionMap[controlConfig.key].value) {
          controls[controlConfig.key].setValue(this.ControlSubmissionMap[controlConfig.key].value);
        }
      });

      if (this.uniqueIdFormElement && this.uniqueIdFormElement.aliasValidatorRules.consentsKey) {
        this.hasConsent = true;
        controls['consentItem'] = new FormControl('', [Validators.requiredTrue]);
        this.consentPath = `${this.cdnUrl}terms/${this.uniqueIdFormElement.aliasValidatorRules.consentsKey}.pdf`;
      }

      this.eligibilityForm = new FormGroup(controls);
      this.formIsReady = true;

    } else {
      this.showProcessErrorMessage();
    }
  }

  onSubmit() {
    this.processing = true;
    const errorCount = this.clientConfirmationService.errors[errorCodes.CLIENT_NOT_FOUND].errorCount ?? 0;
    const maxRetry = this.clientConfirmationService.errors[errorCodes.CLIENT_NOT_FOUND].maxRetry ?? 0;
    if (errorCount > maxRetry) {
      this.displayError = this.clientConfirmationService.errors[errorCodes.CLIENT_NOT_FOUND + '_max_retry'];
      this.showErrorModal = true;
    } else {
      this.submitConsent();
    }

  }

  submitConsent(skipPrecheck = false) {
    const consent = this.eligibilityForm.get('consentItem');
    if (consent && consent.value && this.uniqueIdFormElement.aliasValidatorRules.consentsKey) {
      this.sub.add(
        this.termsService.acceptTerms(this.uniqueIdFormElement.aliasValidatorRules.consentsKey).subscribe(
          response => {
            this.completeSubmission(skipPrecheck);
          },
      error => {
            this.showProcessErrorMessage();
          }
        )
      )
    } else if(consent && !consent.value && this.uniqueIdFormElement.aliasValidatorRules.consentsKey) {
      this.showProcessErrorMessage()
    } else {
      this.completeSubmission(skipPrecheck);
    }
  }

  completeSubmission(skipPrecheck = false) {
    const submissionObj: submissionObject = {
      employment_type: this.employmentType
    };

    for (const k in this.eligibilityForm.value) {
      if (k) {
        const key = this.ControlSubmissionMap[k];
        if (key) submissionObj[this.ControlSubmissionMap[k].api_parameter_name] = this.eligibilityForm.value[k];
      }
    }

    submissionObj['company'] = this.company;
    submissionObj['skip_precheck'] = skipPrecheck;

    if (this.replay) {
      submissionObj["employee_data_verification_status"] = "Pending";
    }

    if (this.skipAccountCreation) {
      submissionObj["fa_referred"] = true;
    }

    if (this.electiveProductChoices.length > 0) {
      const selections = this.electiveProductsForm.get('selectedElectiveProduct') as FormControl;

      submissionObj['elective_products'] = selections.value ? [selections.value] : [''];
    }

    if (this.showCollectPrimaryForm && this.primaryEmployeeForm.valid) {
      const primaryKey = 'primary_info';
      submissionObj[primaryKey] = {};
      for (const k in this.primaryEmployeeForm.value) {
        if (k) {
          switch (k) {
            case 'fName':
              submissionObj[primaryKey]['first_name'] = this.primaryEmployeeForm.value[k];
              break;
            case 'lName':
              submissionObj[primaryKey]['last_name'] = this.primaryEmployeeForm.value[k];
              break;
            case 'zipCode':
              submissionObj[primaryKey]['zip_code'] = this.primaryEmployeeForm.value[k];
              break;
            case 'dateOfBirth':
              submissionObj[primaryKey]['date_of_birth'] = this.primaryEmployeeForm.value[k];
              break;
          }
        }

      }
    }

    this.sub.add(
      this.clientConfirmationService.submitClientVerification(submissionObj).subscribe(
        response => {
          this.resolveSubmissionCompleted();
        },
        error => {
          const validationError = error.error.error;
          this.failedSubmissionHandler(validationError);
        }
      )
    );
  }

  resolveSubmissionCompleted() {
    this.identitySubmitted.emit(true);
  }

  displayInfo(controlConfig: DisplayFormControlConfig) {
    if (controlConfig.inputInfo) {
      this.displayModal = controlConfig.inputInfo;
      this.showInfoModal = true;
    }
  }

  clearId() {
    if (this.uniqueIdFormElement) {
      const uniqueIdFormControl = this.eligibilityForm.get(this.uniqueIdFormElement.CONTROL_UNIQUE_ID.key);
      if (uniqueIdFormControl) {
        setTimeout(() => {
          uniqueIdFormControl.setValue('');
        }, 10);
      }
    }
  }

  setErrorModal(errorCode: string, incrementErrorCount = false) {
    this.displayError = this.clientConfirmationService.errors[errorCode];
    if (incrementErrorCount && typeof this.clientConfirmationService.errors[errorCode].errorCount === 'number') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.clientConfirmationService.errors[errorCode].errorCount++;
    }
  }

  failedSubmissionHandler(error: ClientConfirmationErrorItems) {
    switch (error.code) {
      case errorCodes.CLIENT_NOT_FOUND: {
        this.setErrorModal(error.code, (this.customerType === 'full' || this.skipAccountCreation));
        const errorCount = this.clientConfirmationService.errors[errorCodes.CLIENT_NOT_FOUND].errorCount ?? 0;
        const maxRetry = this.clientConfirmationService.errors[errorCodes.CLIENT_NOT_FOUND].maxRetry ?? 0;
        if (errorCount > maxRetry) {
          this.displayError = this.clientConfirmationService.errors[errorCodes.CLIENT_NOT_FOUND + '_max_retry'];
        }
        this.showErrorModal = true;
        break;
      }
      case errorCodes.CLIENT_NOT_IN_COHORT:
        if (error.details && error.details.out_pilot_url) {
          this.errorRedirectURL = error.details.out_pilot_url;
        }
        this.showErrorModalCloseBtn = true;
        this.setErrorModal(errorCodes.CLIENT_NOT_IN_COHORT);
        this.showErrorModal = true;
        break;
      case errorCodes.CLIENT_NOT_ELIGIBLE:
        this.showErrorModalCloseBtn = true;
        this.setErrorModal(errorCodes.CLIENT_NOT_ELIGIBLE);
        this.showErrorModal = true;
        break;
      case errorCodes.CLIENT_EXISTS_UNIQUEID:
        this.setErrorModal(error.code);
        this.showErrorModal = true;
        this.clearId();
        break;
      case errorCodes.INVALID_UNIQUEID: {
        this.setErrorModal(errorCodes.INVALID_UNIQUEID, true);
        const errorCount = this.clientConfirmationService.errors[errorCodes.INVALID_UNIQUEID].errorCount ?? 0;
        const maxRetry = this.clientConfirmationService.errors[errorCodes.INVALID_UNIQUEID].maxRetry ?? 0;
        this.maxRetriesExceeded = errorCount >= maxRetry;
        this.canContinue = this.maxRetriesExceeded && this.uniqueIdFormElement.aliasValidatorRules.allowDoesNotExist ? true : false;
        if (this.maxRetriesExceeded) {
          this.showMaxRetriesModal();
          break;
        }
        this.showErrorModal = true;
        this.clearId();
        break;
      }
      case errorCodes.CLIENT_ALREADY_VERIFIED:
        this.resolveSubmissionCompleted();
        break;
      case errorCodes.CLIENT_EXISTS_PII:
        this.userNumber = error.details ? error.details.existing_phone_number : '';
        this.setErrorModal(errorCodes.CLIENT_EXISTS_PII);
        this.showErrorModal = true;
        break;
      case errorCodes.HOUSEHOLD_CLIENT_NOT_FOUND: {
        this.setErrorModal(errorCodes.HOUSEHOLD_CLIENT_NOT_FOUND, true);
        const errorCount = this.clientConfirmationService.errors[errorCodes.HOUSEHOLD_CLIENT_NOT_FOUND].errorCount ?? 0;
        const maxRetry = this.clientConfirmationService.errors[errorCodes.HOUSEHOLD_CLIENT_NOT_FOUND].maxRetry ?? 0;
        if (errorCount > maxRetry) {
          this.displayError = this.clientConfirmationService.errors[errorCodes.HOUSEHOLD_CLIENT_NOT_FOUND + '_get_primary'];
          this.collectPrimary = true;
        }
        this.showErrorModal = true;
        break;
      }
      case errorCodes.INVALID_PII: {
        this.setErrorModal(error.code, true);
        const errorCount = this.clientConfirmationService.errors[error.code].errorCount ?? 0;
        const maxRetry = this.clientConfirmationService.errors[error.code].maxRetry ?? 0;
        if (errorCount > maxRetry) {
          this.displayError = this.clientConfirmationService.errors[error.code + '_max_retry'];
        }
        this.showErrorModal = true;
        break;
      }
      case errorCodes.PRIMARY_EMPLOYEE_WRONG_COMPANY:
      case errorCodes.PRIMARY_EMPLOYEE_NOT_FOUND:
      case errorCodes.PRIMARY_EMPLOYEE_NOT_ELIGIBLE:
        this.setErrorModal(error.code);
        this.showErrorModal = true;
        break;
      default:
        this.showProcessErrorMessage();
        break;
    }
  }

  closeErrorModal(isCTA = false) {
    this.showErrorModalCloseBtn = false;
    if (this.displayError.ctaPath) {
      if (this.displayError.errorId==='client_not_found_max_attempts_blocked') {
        this.bsAuthService.logout().subscribe();
        return;
      }
      this.router.navigate([this.displayError.ctaPath]);
      return;
    }
    if (this.collectPrimary) {
      this.createCollectPrimaryForm();
      return;
    }
    if (this.canContinue) {
      this.submitConsent(true);
    } else {
      this.processing = false
    }

    if (isCTA && this.errorRedirectURL) {
      window.location.href = this.errorRedirectURL;
    }

    this.showErrorModal = false;
  }

  createCollectPrimaryForm() {
    const controls: { [key: string]: FormControl } = {};
    this.primaryEmployeeFormControls.forEach((controlConfig: DisplayFormControlConfig) => {
      controls[controlConfig.key] = controlConfig.control;
    });
    this.primaryEmployeeForm = new FormGroup(controls);
    this.showCollectPrimaryForm = true;
    this.showErrorModal = false;
    this.processing = false;
  }

  showMaxRetriesModal() {
    this.bsCacheService.setItem('uniqueIdRetriesExceeded', true, { expires: moment().add(6000, 'seconds').valueOf() });
    this.displayError = this.canContinue ? this.clientConfirmationService.errors['maxSoft'] : this.clientConfirmationService.errors['max'];
    this.disableSubmit = true;
    this.showErrorModal = true;
  }

  showProcessErrorMessage() {
    this.canContinue = false;
    this.processing = false;
    this.displayError = this.processError;
    this.showErrorModal = true;
    this.disableSubmit = false;
  }

  stateClicked(stateString: string, controlRef: DisplayFormControlConfig) {
    controlRef.hasFocus = false;
    const workingStateControl = this.eligibilityForm.get('workState');
    if (workingStateControl) {
      workingStateControl.setValue(stateString);
      this.selectedState = stateString;
    }
  }

  productRadioChanged(event: boolean, value: string) {
    const products: FormControl = this.electiveProductsForm.get('selectedElectiveProduct') as FormControl;
    this.selectedProducts = products.value.toString();
  }

  programContinue() {
    this.showElectiveProducts = false;
  }

  backClicked() {
    if (this.electiveProductChoices.length > 0 && !this.showElectiveProducts) {
      this.showElectiveProducts = true;
    } else {
      this.backEmitted.emit();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }



}
