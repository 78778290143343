import { IsRunningAndroid, MessageBusPlatformAndroidMessageHandler } from './platform/platform-android.service';
import { IsRunningIOS, MessageBusPlatformIosMessageHandler } from './platform/platform-ios.service';
import { MessageBusPlatformBrowserMessageHandler } from './platform/platform-browser.service';

const getServiceByPlatform = () => {
  if (IsRunningAndroid()) {
    return MessageBusPlatformAndroidMessageHandler;
  } else if (IsRunningIOS()) {
    return MessageBusPlatformIosMessageHandler;
  } else {
    return MessageBusPlatformBrowserMessageHandler;
  }
};

const MixinClass = (superclass: any) => class extends superclass {};
const extendsPlatformClass = (AnyClass: any) => class extends MixinClass(AnyClass) {};

const MessageBusBaseService = extendsPlatformClass(getServiceByPlatform());

export { MessageBusBaseService };
