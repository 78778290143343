import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bw-card-lt',
  templateUrl: './card-lt.component.html',
  styleUrls: ['./card-lt.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardLtComponent implements OnInit {
  isElevated = true;
  hasFooter = false;
  _footerIconColor: string;
  _footerColor: string;

  @Input() elevated = true;
  @Input() cardTitle: string;
  @Input() footerText: string;
  @Input() footerRightIcon: string;
  @Input() footerLeftIcon: string;
  @Input() footerType: 'default'|'warning'|'danger';

  @Output() headerClickAction = new EventEmitter();
  _isHeaderClickable = false;

  @Output() footerClickAction = new EventEmitter();
  _isFooterClickable = false;
  
  ngOnInit() {
    if (!this.elevated) {
      this.isElevated = false;
    }
    if (this.footerText || this.footerLeftIcon) {
      this.hasFooter = true;
    }
    this._isHeaderClickable = this.headerClickAction.observers.length > 0;
    this._isFooterClickable = this.footerClickAction.observers.length > 0;
    this.setFooterStyle();
  }

  handleHeaderClick(event: Event) {
    this.headerClickAction.emit(event);
  }

  handleFooterClick(event: Event) {
    this.footerClickAction.emit(event);
  }

  private setFooterStyle() {
    if (this.footerType === 'warning') {
      console.log('hit footer type warning');
      this._footerIconColor = 'var(--primary-text)';
      this._footerColor = 'var(--yellow-20)';
    } else if (this.footerType === 'danger') {
      this._footerIconColor = 'var(--white)';
      this._footerColor = 'var(--red-50)';
    } else {
      this._footerIconColor = 'var(--white)';
      this._footerColor = 'var(--brand)';
    }
  }
}
