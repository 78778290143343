import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifFeatureFlag]',
})
export class FeatureFlagDirective {

  @Input() ifFeatureFlag!: string;
  @Input() ifFeatureFlagElse?: TemplateRef<unknown>;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private featureFlagSvc: FeatureFlagService
  ) {
  }

  ngOnInit() {
    this.featureFlagSvc.getFlag<boolean>(this.ifFeatureFlag).subscribe(
      resp => {
        if (resp) {
          this.onIf();
        } else {
          this.onElse();
        }
      }
    )
  }

  onIf() {
    this.createView(this.templateRef);
  }

  onElse() {
    if (!this.ifFeatureFlagElse) return;
    this.createView(this.ifFeatureFlagElse);
  }

  private createView(templateRef: TemplateRef<unknown>): void {
    this.viewContainerRef.createEmbeddedView(templateRef);
  }
}
