import { Injectable } from '@angular/core';
import {
  FinancialAccountTransaction,
  FinancialAccountTransactionsPayload,
  FinancialAccountTransactionsRequestConfig,
  FinancialAccountTypes, TransactionPagination
} from "@brightside-web/desktop/feature/financial-accounts-core";
import {
  BsApiService,
  GenericResponseCode,
  GenericResponseResult
} from "@brightside-web/desktop/data-access/core-services";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class FinancialAccountsHistoryService {
  apiName = 'api-mobile';
  transactionHistoryPage = 0;
  transactionsPerPage = 25;

  private _recentTransactions = new BehaviorSubject<FinancialAccountTransaction[] | undefined>(undefined);
  public readonly recentTransactions: Observable<FinancialAccountTransaction[] | undefined> = this._recentTransactions.asObservable();

  private _transactionHistory = new BehaviorSubject<TransactionPagination | undefined>(undefined);
  public readonly transactionHistory: Observable<TransactionPagination|undefined> = this._transactionHistory.asObservable();

  private _hasMoreTransactions = new BehaviorSubject<boolean>(true);
  public readonly hasMoreTransactions: Observable<boolean> = this._hasMoreTransactions.asObservable();

  constructor(
    private bsApi: BsApiService
  ) { }

  getTransactions(
    accountType: FinancialAccountTypes,
    accountId: number,
    callParams?: FinancialAccountTransactionsRequestConfig) {
    this.callGetTransactions(accountType,accountId,callParams);
  }

  getNextTransactionsPage(
    accountType: FinancialAccountTypes,
    accountId: number
  ) {
    const callParams: FinancialAccountTransactionsRequestConfig = {
      offset: 0,
      limit: this.transactionsPerPage
    };
    if (this._transactionHistory.value) {
      this.transactionHistoryPage++
      callParams.offset = this.transactionHistoryPage * this.transactionsPerPage;
    }
    this.getTransactions(accountType,accountId,callParams);
  }


  private callGetTransactions(
    accountType: FinancialAccountTypes,
    accountId: number,
    callParams?:FinancialAccountTransactionsRequestConfig) {
      this.getAccountTransactions(
        accountType,
        accountId,
        callParams)
        .subscribe(
          transactionResp => {
            if (transactionResp.result.code === GenericResponseCode.SERVICE_OK) {
              if (!callParams) {
                this._recentTransactions.next(transactionResp.payload.transactions);
              } else {
                this.handleHistoryResponse(transactionResp);
              }
            }
          }
        )
    }

    handleHistoryResponse(transactionResp: FinancialAccountTransactionsPayload & GenericResponseResult) {
      const currentHistory = this._transactionHistory.value;
      if (transactionResp.payload.resultSet.allFetched) {
        this._hasMoreTransactions.next(false);
      }
      if (currentHistory) {
        currentHistory.pages.push({
          page: this.transactionHistoryPage,
          transactions: transactionResp.payload.transactions
        });
        this._transactionHistory.next(currentHistory)
      } else {
        this._transactionHistory.next({pages: [{
            page: 0,
            transactions: transactionResp.payload.transactions
          }]})
      }
    }

  getAccountTransactions(
    accountType: FinancialAccountTypes, accountId: number,
    callParams?: FinancialAccountTransactionsRequestConfig
  ): Observable<FinancialAccountTransactionsPayload & GenericResponseResult> {
    callParams = callParams ?? {limit: 3,offset: 0};
    if (callParams.limit <= 0) {
      throw Error('transactions limit must be greater than 0');
    }

    const apiParam = callParams.lastTransactionId ?
      `?offset=${callParams.offset}&limit=${callParams.limit}&lastTransactionId=${callParams.lastTransactionId}` :
      `?offset=${callParams.offset}&limit=${callParams.limit}`;

    return this.bsApi.get<FinancialAccountTransactionsPayload>(
      this.apiName,
      `/accounts/${accountType}/${accountId}/transactions${apiParam}`);
  }
}


