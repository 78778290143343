<div class="list-container">
  <form [formGroup]="searchForm">
    <bw-input
      label="Employer"
      type="text"
      placeholder="Search"
      formControlName="inputText"
      [disabled]="showProcessSpinner"
    ></bw-input>
  </form>

  <bw-spinner *ngIf="showProcessSpinner; else notLoading"></bw-spinner>

  <ng-template #notLoading>
    <div *ngIf="!hideBeforeTyping || (hideBeforeTyping && !isEmptySearchText)" class="list-display-container">
      <ng-container *ngIf="displayedListItems.length > 0; else noRecordsFound">
        <bw-cell
          *ngFor="let displayItem of displayedListItems"
          [contentLeft]="true"
          [iconNameRight]="'chevron_right'"
          [separatorType]="'full'"
          (clicked)="onCompanyClicked(displayItem.code)"
        >
          <ng-container content-left>
            <typography text-medium-regular>
              <p>{{ displayItem.displayName }}</p>
            </typography>
          </ng-container></bw-cell
        >
      </ng-container>
      <ng-template #noRecordsFound>
        <p class="body bs-light-gray" style="margin-top: 16px; margin-left: 16px; margin-right: 16px;">Sorry no matches</p>
      </ng-template>
    </div>
  </ng-template>
</div>
