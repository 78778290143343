import { Injectable } from '@angular/core';
import { ConsoleLogger } from 'aws-amplify/utils'

@Injectable({
  providedIn: 'root'
})
export class BsLoggerService {
  constructor() {}
  log = ConsoleLogger;
}
