import {
  BRIGHTSIDE_SAVINGS_ACCOUNT_ID,
  BRIGHTSIDE_SAVINGS_ICON,
  BRIGHTSIDE_SAVINGS_INSTITUTION_NAME,
  ExternalFinicityAccount,
  ExternalFinicityTransaction,
  FinicityAccountType,
  FinicityTransactionStatus,
  InternalOrExternalLinkedAccount,
  InternalOrExternalSavingsAccount,
  InternalOrExternalSavingsTransaction,
  LinkedAccountGroup,
  LinkedAccountStatus,
  LinkedAccountType,
  SavingsAccountType,
  SavingsTransactionStatus,
} from './external-savings.service';
import {
  LinkedBank,
  LinkedBankStatus,
  SavingsAccount,
  SavingsAch,
  SavingsScheduleType,
  SavingsSource,
  SavingsTransaction,
} from '@brightside-web/desktop/data-access/shared';

const getExternalFinicityAccount = (params: Record<string, any>): ExternalFinicityAccount => ({
  id: params.id ?? '12',
  name: params.name ?? '360 Savings',
  institutionName: params.institutionName ?? 'Institution',
  number: params.number ?? '12345',
  balance: params.balance ?? 123.45,
  institutionId: params.institutionId ?? '34',
  type: params.type ?? FinicityAccountType.SAVINGS,
  branding: params.branding ?? { icon: 'https://prod-carpintero-branding.s3.us-west-2.amazonaws.com/101732/icon.svg' },
});
const getExternalFinicityTransaction = (params: Record<string, any>): ExternalFinicityTransaction => ({
  transactionId: params.transactionId ?? '22',
  amount: params.amount ?? 15.75,
  merchant: params.merchant ?? 'merchant',
  transactionDateStr: params.transactionDateStr ?? '2021-11-23T12:00:00.000Z',
  status: params.status ?? FinicityTransactionStatus.ACTIVE,
});
const getInternalOrExternalSavingsAccount = (params: Record<string, any>): InternalOrExternalSavingsAccount => ({
  type: params.type ?? SavingsAccountType.BRIGHTSIDE,
  id: params.id ?? BRIGHTSIDE_SAVINGS_ACCOUNT_ID,
  accountName: params.accountName ?? 'Brightside Savings Account',
  institutionName: params.institutionName ?? BRIGHTSIDE_SAVINGS_INSTITUTION_NAME,
  balance: params.balance ?? 123.45,
  lastFourAccountNumber: params.lastFourAccountNumber ?? '1234',
  icon: params.icon ?? BRIGHTSIDE_SAVINGS_ICON,
});
const getInternalOrExternalSavingsTransaction = (params: Record<string, any>): InternalOrExternalSavingsTransaction => ({
  label: params.label ?? 'Savings Reward',
  id: params.id ?? '18251',
  amount: params.amount ?? 0.12,
  createdDate: params.createdDate ?? '2021-05-02',
  status: params.status ?? SavingsTransactionStatus.POSTED,
});
const getInternalOrExternalLinkedAccount = (params: Record<string, any>): InternalOrExternalLinkedAccount => ({
  accountName: params.accountName ?? 'linked account name',
  accountType: params.accountType ?? 'checking',
  id: params.id ?? '123',
  institutionLogo: params.institutionLogo ?? 'goofy.svg',
  institutionName: params.institutionName ?? 'linked institution name',
  lastFourAccountNumber: params.lastFourAccountNumber ?? '5678',
  type: params.linkedAccountType ?? LinkedAccountType.TRACK_BALANCE,
  status: params.status ?? LinkedAccountStatus.ACTIVE,
});
const getLinkedAccountGroup = (params: Record<string, any>): LinkedAccountGroup => ({
  type: params.linkedAccountType ?? LinkedAccountType.TRANSFER_FUNDS,
  banks: params.banks ?? [getInternalOrExternalLinkedAccount(params)],
});
const getLinkedBank = (params: Record<string, any>): LinkedBank => ({
  id: params.id ?? 162,
  status: params.status ?? LinkedBankStatus.ACTIVE,
  type: params.type ?? 'routing',
  bank_name: params.bank_name ?? 'CAPITAL ONE',
  last_four: params.last_four ?? '8901',
  account_class: params.account_class ?? 'CHECKING',
  create_ts: params.create_ts ?? '2021-12-01T22:03:33Z',
  bank_code: params.bank_code ?? '121fcu',
  balance_verifiable: params.balance_verifiable ?? true,
  bank_logo: params.bank_logo ?? 'https://cdn.synapsepay.com/bank_logos_v3p1/CapitalOne360_v.png',
  micro_meta: params.micro_meta ?? null,
});
const getSavingsAccount = (params: Record<string, any>): SavingsAccount => ({
  balance: params.balance ?? 135.57,
  available_balance: params.available_balance ?? 135.57,
  banks: params.banks ?? [getLinkedBank(params)],
  total_rewards_balance: params.total_rewards_balance ?? 0.57,
  autoSaveMatchReward: params.autoSaveMatchReward ?? {},
  name: params.name ?? 'HD Savings Account',
  next_deposit_amount: params.next_deposit_amount ?? 5,
  next_deposit_date: params.next_deposit_date ?? '2021-03-26',
  schedule_type: params.schedule_type ?? SavingsScheduleType.biweekly,
  pay_frequency: params.pay_frequency ?? 'every day',
  schedule_paused: params.schedule_paused ?? null,
  is_first_schedule: params.is_first_schedule ?? true,
  active_status: params.active_status ?? 'active-account',
  status: params.status ?? 'active-account',
  linked_account_status: params.linked_account_status ?? 'active',
  source: params.source ?? SavingsSource.ach,
  monthly_withdrawals_remaining: params.monthly_withdrawals_remaining ?? 0,
  last_four_accnum: params.last_four_accnum ?? "2323"
});
const getSavingsAch = (params: Record<string, any>): SavingsAch => ({
  balance: params.balance ?? 123,
  available_balance: params.available_balance ?? 456,
  status: params.status ?? 'active-account',
  source: SavingsSource.payroll,
  monthly_withdrawals_remaining: 0,
  routing_number: '051000017',
  account_number: '12345',
});
const getSavingsTransaction = (params: Record<string, any>): SavingsTransaction => ({
  label: params.label ?? 'One-Time Savings',
  id: params.id ?? 65470,
  amount: params.amount ?? 20,
  is_credit: params.is_credit ?? true,
  is_skipped: params.is_skipped ?? false,
  source: params.source ?? {
    type: 'ACH',
    synapse_id: '611eadd7039b9519f9a3d8bf',
    bank_name: 'BANK OF AMERICA  N A ',
    bank_logo: 'https://cdn.synapsepay.com/bank_logos_v3p1/bankofamerica_v.png',
    account_class: 'CHECKING',
    last_four_accnum: '3456',
  },
  estimated_arrival_date: params.estimated_arrival_date ?? null,
  transaction_date: params.transaction_date ?? '2021-09-24',
  status: params.status ?? 'COMPLETED',
  type: params.type ?? 'Manually Saved',
  client_display: params.client_display ?? 'One-Time Savings',
  category: params.category ?? 'deposit',
});
const factory: Record<string, any> = {
  ExternalFinicityAccount: getExternalFinicityAccount,
  ExternalFinicityTransaction: getExternalFinicityTransaction,
  InternalOrExternalSavingsAccount: getInternalOrExternalSavingsAccount,
  InternalOrExternalSavingsTransaction: getInternalOrExternalSavingsTransaction,
  InternalOrExternalLinkedAccount: getInternalOrExternalLinkedAccount,
  LinkedAccountGroup: getLinkedAccountGroup,
  LinkedBank: getLinkedBank,
  SavingsAccount: getSavingsAccount,
  SavingsAch: getSavingsAch,
  SavingsTransaction: getSavingsTransaction,
};

export class SavingsTestData {
  static generateTestObject = <T>(objType: string, params?: Record<string, any>): T => {
    const obj = factory[objType](params ?? {});
    //this will remove the empty fields (but not Date/NaN/functions)
    return JSON.parse(JSON.stringify(obj, (kk, vv) => vv ?? undefined));
  };
}
