import { Injectable } from '@angular/core';
import { Hub } from 'aws-amplify/utils';


@Injectable({
  providedIn: 'root'
})
export class BsHubService {

  static listenStatic = Hub.listen.bind(Hub);
  static dispatchStatic = Hub.dispatch.bind(Hub);

  constructor() { }

  listen = Hub.listen.bind(Hub);
  dispatch = Hub.dispatch.bind(Hub);

}
