<bw-action-article [brightsideWebFb]="displayReviewAnalytics.shown" minHeight="0">
  <div header-actions class="flex-row pointer">
    <bw-icon
      *ngIf="config.pageHideBackButton; else showBackButton"
      color="var(--secondary-text)"
      iconName="close"
      [brightsideWebFb]="displayReviewAnalytics.dismiss"
      (click)="handleCloseAction()"
    ></bw-icon>

    <ng-template #showBackButton>
      <ui-nav-exit-back
        *ngIf="!pageIsFullScreen"
        back-action-top
        [trackingDetail]="displayReviewAnalytics.dismiss"
        [skipActionEmit]="!config.pageOnBackCtaPath"
        (exitActionHandler)="handleCloseAction()"
      ></ui-nav-exit-back>
      <ui-nav-exit-back
        *ngIf="pageIsFullScreen"
        back-action-bottom
        [trackingDetail]="displayReviewAnalytics.dismiss"
        [skipActionEmit]="!config.pageOnBackCtaPath"
        (exitActionHandler)="handleCloseAction()"
      ></ui-nav-exit-back>
    </ng-template>
  </div>

  <div *ngIf="config.pageTitle" header-title class="flex-row space-between flex-end">
    <typography title-medium class="mb-sm">{{ config.pageTitle | translate }}</typography>
  </div>

  <div content-section>
    <p *ngIf="config.pageSubTitle" text-large-regular class="mb-sm">{{ config.pageSubTitle | translate }}</p>

    <a
      *ngIf="config.pageExtraModal"
      bw-anchor
      anchorStyle="text-large-regular"
      class="mr-xxs"
      mwTextColor="var(--secondary-text)"
      style="text-decoration: underline"
      (click)="pageExtraModalIsShowing = true"
    >
      {{ config.pageExtraModal.triggerText | translate }}
    </a>

    <section class="mt-md mb-xs">
      <ng-container *ngIf="!config?.pageDisplayCells?.length; else cellDisplaySection">
        <p text-large-semibold>{{ config.pageEmptyText | translate }}</p></ng-container
      >

      <ng-template #cellDisplaySection>
        <ng-container *ngFor="let cellInfo of config.pageDisplayCells">
          <bw-cell
            *ngIf="!cellInfo?.value; else multiLineDisplay"
            separatorType="full"
            [singleLeftLabel]="cellInfo.label | translate"
            iconNameRight="chevron_right"
            (clicked)="handleCellClick(cellInfo)"
          ></bw-cell>

          <ng-template #multiLineDisplay>
            <bw-cell
              *ngIf="!config.pageShowAsFlipped"
              [labelLeft]="cellInfo.label | translate"
              [labelRight]="cellInfo.value | translate"
              separatorType="full"
            >
            </bw-cell>

            <bw-cell
              *ngIf="config.pageShowAsFlipped"
              [labelLeft]="cellInfo.label | translate"
              [labelSubLeft]="cellInfo.value | translate"
              [flipLeftLabels]="true"
              separatorType="full"
            >
            </bw-cell>
          </ng-template>
        </ng-container>
      </ng-template>

      <div *ngFor="let questionAnswer of config.pageDisplayQuestionAnswer" class="mt-md mb-sm">
        <bw-paragraph textStyle="Body2"><span [innerHTML]="questionAnswer.label | translate"></span></bw-paragraph>
        <p text-large-semibold [innerHTML]="questionAnswer.value | translate"></p>
      </div>

      <div *ngIf="config.pageAddActionText" class="flex-row mt-md mr-md" (click)="handleAddAction()">
        <bw-icon iconName="add" color="var(--brand)"></bw-icon>
        <p text-large-regular class="brand ml-sm">{{ config.pageAddActionText | translate }}</p>
      </div>
    </section>
  </div>
  <div footer-top>
    <div class="mt-md" *ngIf="config.pageExtraInfoListDetail">
      <bw-list-item-details
        [avatarIconName]="config.pageExtraInfoListDetail.avatarTitle"
        [avatarIconColor]="config.pageExtraInfoListDetail.avatarBg"
        [blockStyle]="true"
        [bgColor]="config.pageExtraInfoListDetail.bgColor"
        [listTitle]="config.pageExtraInfoListDetail.title | translate">
      </bw-list-item-details>
    </div>
  </div>
  <div footer-actions class="flex-row row-reverse">
    <button
      *ngIf="config.pageCtaLabel || config.pageCtaLabelKey"
      bw-button
      [processing]="processing"
      [brightsideWebFb]="displayReviewAnalytics.submit"
      (click)="handleCtaClick()"
    >
      {{ config.pageCtaLabelKey || config.pageCtaLabel | translate }}
    </button>

    <typography text-large-semibold>
      <a
        *ngIf="config.pageSecondaryCtaLabel"
        bw-anchor
        class="bs-blue mr-sm"
        [brightsideWebFb]="{ event_name: 'display_review_secondary' }"
        (click)="handleSecondaryCtaClick()"
        >{{ config.pageSecondaryCtaLabel | translate }}</a
      >
    </typography>
  </div>
</bw-action-article>

<bw-modal
  *ngIf="pageExtraModalIsShowing"
  (closeModal)="pageExtraModalIsShowing = false"
  [brightsideWebFb]="{ event_name: config.fbEventName + 'more_info' }"
>
  <typography title-medium color="var(--gray-70)">{{ config.pageExtraModal.title | translate }}</typography>
  <section class="body">
    <bw-paragraph *ngFor="let bodyText of config.pageExtraModal.body" [textStyle]="'Body2'" color="var(--gray-50)">
      <span [innerHTML]="bodyText | translate"></span>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        (click)="pageExtraModalIsShowing = false"
        [brightsideWebFb]="{ event_name: config.fbEventName + 'more_info' }"
      >
        {{ config.pageExtraModal.cta | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
