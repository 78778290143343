import { Component, OnInit, ElementRef, Input, OnChanges } from '@angular/core';
@Component({
  selector: 'button[bw-button], button[bw-outlined-button], button[bw-link-button], button[bw-small-button], button[bw-small-outlined-button], button[bw-icon-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() processing: boolean;
  @Input() fluidWidth: boolean;

  /** Whether the button is fluid and can stretch to it's container. */
  readonly isFluidButton: boolean = this._getHostElement().hasAttribute('fluidWidth');

  /** Whether the button is a small button. */
  readonly isSmallButton: boolean =
    this._hasHostAttributes('bw-small-outlined-button') || this._hasHostAttributes('bw-small-button');

  /** Whether the button is an outlined button. */
  readonly isOutlined: boolean = this._hasHostAttributes('bw-small-outlined-button') || this._hasHostAttributes('bw-outlined-button');

  /** Whether the button is an outlined button. */
  readonly isLink: boolean = this._hasHostAttributes('bw-link-button');

  /** Whether the button is an icon button. */
  readonly isIconButton: boolean = this._hasHostAttributes('bw-icon-button');

  constructor(public _elementRef: ElementRef) {
    if (this.isSmallButton) {
      (this._getHostElement() as HTMLElement).classList.add('small');
    }

    if (this.isOutlined) {
      (this._getHostElement() as HTMLElement).classList.add('outlined');
    }

    if (this.isLink) {
      (this._getHostElement() as HTMLElement).classList.add('link-button');
    }

    if (this.isFluidButton) {
      (this._getHostElement() as HTMLElement).classList.add('fluid-width');
    }

    if (this.isIconButton) {
      (this._getHostElement() as HTMLElement).classList.add('icon-button');
    }
  }

  ngOnInit() {
    this.setDisabled(this.processing || this.disabled);
  }

  private setDisabled(isDisabled: boolean = false) {
    if (isDisabled) {
      (this._getHostElement() as HTMLElement).setAttribute('disabled', '');
    } else {
      (this._getHostElement() as HTMLElement).removeAttribute('disabled');
    }
  }

  _getHostElement() {
    return this._elementRef.nativeElement;
  }

  _hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) => this._getHostElement().hasAttribute(attribute));
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.setDisabled(changes['processing']?.currentValue === true || changes['disabled']?.currentValue === true || this.disabled === true);
  }
}
