import { Injectable } from '@angular/core';
import { ApiCacheService, SavingsAccount } from '@brightside-web/desktop/data-access/shared';

import { Observable, Subscription, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { InitiatingSource, SavingsAccountService, SavingsScheduleRequest } from '../savings-account.service';
import { SavingsPayrollService } from '../payroll/savings-payroll.service';

export interface SavingsCompositeAccount {
  account: SavingsAccount | null;
  payrollSchedule: string[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class SavingsCompositeService {
  private sub = new Subscription();

  constructor(
    private apiCache: ApiCacheService,
    private savingsService: SavingsAccountService,
    private payrollService: SavingsPayrollService
  ) {}

  getAccountWithPayroll(): Observable<SavingsCompositeAccount> {
    return this.payrollService.getSchedule().pipe(
      map((scheduleDetails) => scheduleDetails.schedule || []),
      switchMap(async (scheduleList) => {
        const savingsAccount = await this.savingsService.getSavingsAccount().toPromise();

        return { account: savingsAccount || null, payrollSchedule: scheduleList };
      }),
      catchError((_: any) =>
         of({ account: null, payrollSchedule: [] })
      )
    );
  }

  /**
   * This method should be utilized when we want to check if an account creation is pending already before creating
   * @returns boolean if new account was started
   */
  createNewAccount(source: InitiatingSource, scheduleDetails?: SavingsScheduleRequest): Observable<boolean> {
    return this.savingsService.hasPendingSavingsAccount().pipe(
      switchMap(async (isPending) => {
        let createAccountResult;

        //If we are already in pending state return that account was NOT newly created
        if (isPending) {
          return false;
        }

        //Normal create should be utilized if no schedule details are shared
        if (!scheduleDetails) {
          createAccountResult = await this.savingsService.createAccount().toPromise();
        } else {
          if (!scheduleDetails.source) {
            scheduleDetails.source = 'payroll';
          }

          createAccountResult = await this.savingsService.createAccountWithSchedule(scheduleDetails, source).toPromise();
        }
        // @ts-ignore
        return !createAccountResult.statusCode || createAccountResult.statusCode < 300;
      }),
      catchError((error: any) => of(false))
    );
  }
}
