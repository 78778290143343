import { Component, Input, OnInit, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';

import { CellSeparatorType } from '@brightside/brightside-ui';
import { MicroUtilDebugLogger } from '@micro-core/utility';

import { UiTemplateContentType, UiTemplateContentFormType } from '../../shared/model/content-template.enum';
import { UiTemplateContent, UiTemplateContentCustomComponent } from '../../shared/model/content-template.interface';

type ContentType = UiTemplateContentType | UiTemplateContentFormType;

@Component({
  selector: 'ui-content-display-item',
  templateUrl: './display-content-item.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiContentDisplayItemComponent extends MicroUtilDebugLogger implements OnInit {
  @Input() contentControl: UiTemplateContent;

  //Make sure the content types can be used in template
  contentType = UiTemplateContentType;
  contentFormType = UiTemplateContentFormType;
  separatorType = CellSeparatorType;

  constructor(public viewContainerRef: ViewContainerRef) {
    super();

    this.allowVerboseDebugMode = true;
    this.logPrefix = 'UiContentDisplayItemComponent';
  }

  ngOnInit(): void {
    this.getMethodOrNoop(this.contentControl.type)();
  }

  private getMethodOrNoop(contentTypeName: ContentType) {
    this.logForDebugging(`Trying to load method for type of ${contentTypeName}`);

    if (UiContentDisplayItemComponent.prototype[contentTypeName]) {
      this.logForDebugging(`Found method for type`);

      return this[contentTypeName].bind(this);
    }

    this.logForDebugging(`No method found for type`);

    return () => false;
  }

  private [UiTemplateContentType.CDN_CONTENT]() {}
  private [UiTemplateContentType.CUSTOM_COMPONENT]() {
    const data: UiTemplateContentCustomComponent = this.contentControl.data as UiTemplateContentCustomComponent;

    if (data.component) {
      const componentRef = this.viewContainerRef.createComponent(data.component);

      data.afterComponentCreated(componentRef);
    }
  }
  private [UiTemplateContentType.GROUP_CELL]() {}
  private [UiTemplateContentType.LIST_ITEM]() {}
  private [UiTemplateContentType.TITLE_TEXT]() {}
  private [UiTemplateContentFormType.SELECT_AMOUNT]() {}
  private [UiTemplateContentFormType.GROUP_RADIO]() {}
}
