import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RoutingStateService } from '@brightside/brightside-ui-services';
import { AnalyticsAttributesV4, isTemplateFullScreen, Settings } from '@brightside-web/desktop/data-access/shared';
import { MessageBusInternalService, MessageBusInternalHubEvent } from '@brightside-web/micro/core/message-bus';

export interface DisplayReviewExtra {
  triggerText: string;
  title: string;
  body: string[];
  cta: string;
}

export interface SharedDisplayReviewCell {
  label: string;
  value?: string | string[];
  onClickPath?: string[];
}

interface SharedDisplayExtraInfoInterface {
  title: string;
  bgColor: string;
  avatarTitle: string;
  avatarBg: string;
}

export class DisplayReviewSettings extends Settings {
  pageExtraInfo: any;

  constructor(config: any = {}) {
    super(config);

    this.pageAddActionText = config.pageAddActionText || null;
    this.pageDisplayCells = config.pageDisplayCells || [];
    this.pageDisplayQuestionAnswer = config.pageDisplayQuestionAnswer || [];
    this.pageEmptyText = config.pageEmptyText || '';
    this.pageExtraModal = config.pageExtraModal;
    this.pageOnAddActionCtaPath = config.pageOnAddActionCtaPath || [];
    this.pageSecondaryCtaEvent = config.pageSecondaryCtaEvent || null;
    this.pageSecondaryCtaPath = config.pageSecondaryCtaPath || null;
    this.pageShowAsFlipped = Boolean(config.pageShowAsFlipped);
    this.pageExtraInfoListDetail = config.pageExtraInfoListDetail || null;
  }

  pageAddActionText?: string;
  pageDisplayCells: SharedDisplayReviewCell[];
  pageDisplayQuestionAnswer?: SharedDisplayReviewCell[];
  pageEmptyText: string;
  pageExtraModal?: DisplayReviewExtra;
  pageOnAddActionCtaPath: string[];
  pageSecondaryCtaEvent: MessageBusInternalHubEvent;
  pageSecondaryCtaPath: string[];
  pageShowAsFlipped?: boolean;
  pageExtraInfoListDetail?: SharedDisplayExtraInfoInterface;
}

@Component({
  selector: 'brightside-web-display-review',
  templateUrl: './display-review.component.html',
  styleUrls: ['./display-review.component.scss'],
})
export class SharedDisplayReviewComponent implements OnInit {
  config: DisplayReviewSettings = new DisplayReviewSettings({
    fbPage: 'page',
    fbCategory: 'consents',

    pageTitle: '',
    pageSubTitle: '',
    pageCtaLabel: 'BUTTON_CONTINUE',
    pageSecondaryCtaLabel: '',
    pageSecondaryCtaEvent: null,

    pageOnForwardCtaPath: [],
  });

  displayReviewAnalytics: {
    shown: AnalyticsAttributesV4;
    dismiss: AnalyticsAttributesV4;
    submit: AnalyticsAttributesV4;
  } = {
    shown: { event_name: '' },
    dismiss: { event_name: '' },
    submit: { event_name: '' },
  };

  pageIsFullScreen: boolean;
  pageExtraModalIsShowing = false;
  processing = false;

  constructor(private routingState: RoutingStateService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.checkForConfigViaData();
  }

  private checkForConfigViaData() {
    if (isTemplateFullScreen(this.activatedRoute)) {
      this.pageIsFullScreen = true;
    }

    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new DisplayReviewSettings(this.activatedRoute.snapshot.data.pageConfig);
      this.setUpAnalytics();
    }

    this.activatedRoute.data.subscribe(() => {
      this.config = new DisplayReviewSettings(this.activatedRoute.snapshot.data.pageConfig);
      this.setUpAnalytics();
    });
  }

  private setUpAnalytics() {
    if (this.config.analyticsAttributes) {
      this.displayReviewAnalytics.shown = this.config.analyticsAttributes('shown');
      this.displayReviewAnalytics.dismiss = this.config.analyticsAttributes('dismiss');
      this.displayReviewAnalytics.submit = this.config.analyticsAttributes('tapped');
    }
  }

  handleCellClick(cell: SharedDisplayReviewCell) {
    if (cell.onClickPath) {
      this.routingState.navigateTo(cell.onClickPath);
    }
  }

  handleCloseAction() {
    if (this.config.pageSecondaryCtaEvent) {
      MessageBusInternalService.sendOutgoingHubEvent(this.config.pageSecondaryCtaEvent);
    } else {
      this.routingState.navigateTo(this.config.pageOnBackCtaPath || ['home']);
    }
  }

  handleAddAction() {
    this.routingState.navigateTo(this.config.pageOnAddActionCtaPath, {});
  }

  handleCtaClick() {
    const options = { queryParams: { ...this.activatedRoute?.snapshot?.queryParams } };

    if (this.config.pageOnForwardCtaData && Object.keys(this.config.pageOnForwardCtaData || {}).length > 0) {
      options.queryParams = this.config.pageOnForwardCtaData;
    }

    this.routingState.navigateTo(this.config.pageOnForwardCtaPath, options);
  }

  handleSecondaryCtaClick() {
    this.routingState.navigateTo(this.config.pageSecondaryCtaPath, {});
  }
}
