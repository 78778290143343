<ng-container *ngFor="let contentControl of data">
  <ng-container *ngTemplateOutlet="contentGroupRadio; context: { $implicit: contentControl }"></ng-container>
  <ng-container *ngTemplateOutlet="contentSelectAmount; context: { $implicit: contentControl }"></ng-container>
</ng-container>

<ng-template #contentGroupRadio let-contentControl>
  <ui-content-group-radio
    *ngIf="contentControl.type === contentType.GROUP_RADIO"
    [data]="contentControl.data"
    (formValuesChanged)="formValuesChanged.emit($event)"
  ></ui-content-group-radio>
</ng-template>

<ng-template #contentSelectAmount let-contentControl>
  <ui-content-select-amount
    *ngIf="contentControl.type === contentType.SELECT_AMOUNT"
    [data]="contentControl.data"
    (formValuesChanged)="formValuesChanged.emit($event)"
  ></ui-content-select-amount>
</ng-template>
