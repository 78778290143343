import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { MenuComponent } from '@brightside/brightside-ui';

import { UiHeaderType } from '../header.enum';
import { UiHeaderMenu, UiHeaderMenuInputs, UiHeaderMenuItem } from '../header.interface';

@Component({
  selector: 'ui-header-menu',
  templateUrl: './header-menu.component.html',
  template: '',
  styleUrls: ['./header-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiHeaderMenuComponent implements UiHeaderMenuInputs {
  type: UiHeaderType = UiHeaderType.MENU;

  @Input() title: string;
  @Input() menu: UiHeaderMenu;
  @Input() trackingDetail: { event_name: string };
  @Input() menuState: { hide: string[]; last: UiHeaderMenuItem | null | undefined[] } = { hide: [], last: null };

  @Output() menuItemUpdateStateWithHandler = new EventEmitter();
  @Output() menuItemActionHandler = new EventEmitter();

  @ViewChild('actionMenu') actionMenu: MenuComponent;

  handleMenuItemSelect(menuItem: UiHeaderMenuItem) {
    this.menuItemUpdateStateWithHandler.emit({ state_menu: { last: [menuItem] } });
    this.menuItemActionHandler.emit();
  }

  handleMenuToggleOpenClose(e: MouseEvent) {
    if (this.actionMenu.visibility === 'visible') {
      this.actionMenu.close(e);
    } else {
      this.actionMenu.open(e);
    }
  }
}
