import { Component, OnInit, Input, Output, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

export type TabsType = [
  {
    label: string,
    route: any
  }
]

@Component({
  selector: 'bw-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
  
})
export class TabsComponent implements OnInit {
  activeTab: string;

  @Input() tabs: TabsType;

  @Input() initialTab: string;

  @Output() clicked = new EventEmitter();

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  onKey(tab: any) {
    this.activeTab = tab.route;
    this.router.navigate([tab.route], {relativeTo: this.route});
  }

  onClick(tab: any) {
    this.clicked.emit(tab);
    this.activeTab = tab.route;
  }

  ngOnInit() {
    this.activeTab = this.initialTab;
  }
}
