import { Component } from '@angular/core';
import {MessageBusEventUtil, MessageBusInternalService} from "@micro-core/message-bus";
import {MobileStateService} from "@brightside-web/desktop/data-access/shared";
import {take} from "rxjs/operators";

@Component({
  selector: 'brightside-web-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent {

  constructor(private msService: MobileStateService) { }

  closeEvent($event: boolean) {
    this.leaveTermsUpdate();
  }

  termsAccepted($event: boolean) {
    if ($event) {
      this.leaveTermsUpdate();
    }
  }

  leaveTermsUpdate() {
    this.msService.put( {
      "redirectCompleted": true
    }).pipe(take(1)).subscribe(
      resp => {
        MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.exitFeature);
      }
    )
  }
}
