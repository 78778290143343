import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'bw-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnChanges {

  imageSrc: string;
  iconSize = 32;
  isSmallAvatar: boolean;
  isMediumAvatar: boolean;
  isLargeAvatar: boolean;
  @Input() noBackground: boolean;
  @Input() bgColor: string;
  @Input() iconName: string;
  @Input() iconColor = "var(--blue-70)";
  @Input() imageURL: string;

  @Input() avatarStyle: 'primary' | 'secondary' | 'tertiary' | 'image';

  /**
   * This is to size the avatar. Only the following strings will affect the size: "small", "medium", or "large" - all other values will be ignored.
   */
  @Input() avatarSize: 'small' | 'medium' | 'large' = 'medium';

  constructor() { }

  ngOnChanges(): void {
    this.imageSrc = 'url(' + this.imageURL + ')';

    if (this.avatarSize === 'small') {
      this.isSmallAvatar = true;
      this.iconSize = 24;
    }
    
    if (this.avatarSize === 'medium') {
      this.isMediumAvatar = true;
      this.iconSize = 32;
    } 
    
    if (this.avatarSize === 'large') {
      this.isLargeAvatar = true;
      this.iconSize = 48;
    }

    if (this.avatarStyle === 'primary') {
      this.noBackground = false;
      this.bgColor = 'var(--bg-blue)';
      this.iconColor = 'var(--blue-70)';
    }

    if (this.avatarStyle === 'secondary') {
      this.noBackground = false;
      this.bgColor = 'var(--blue-70)';
      this.iconColor = 'var(--bg-blue)';
    }

    if (this.avatarStyle === 'tertiary') {
      this.noBackground = true;
      this.bgColor = 'transparent';
      this.iconColor = 'var(--blue-70)';
    }

    if (this.avatarStyle === 'image') {
      this.noBackground = true;
      this.bgColor = 'transparent';
      this.iconColor = 'transparent';
    }
  }

}
