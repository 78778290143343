import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

import { FirebaseService, AnalyticsAttributesV4 } from './firebase-abstract.service';

@Directive({
  selector: '[coreTracking]',
})
export class MicroCoreUtilTrackingDirective implements AfterViewInit {
  @Input('coreTracking') attributes: AnalyticsAttributesV4;
  @Input() secondaryCta = false;
  @Input() dismissedCta = false;
  @Input() error_view = false;

  tagName: string;
  dontLog: boolean;

  constructor(private analytics: FirebaseService, private el: ElementRef) {}

  @HostListener('click', ['$event']) @HostListener('keyup.enter', ['$event']) onClick(event: KeyboardEvent) {
    if (!this.dontLog) {
      if (this.isDismissButton()) {
        this.analytics.logEvent(`${this.attributes.event_name} dismissed`, this.attributes.custom);
      } else if (this.isClickable()) {
        const eventAction = this.secondaryCta ? 'secondary tapped' : 'tapped';
        this.analytics.logEvent(`${this.attributes.event_name} ${eventAction}`, this.attributes.custom);
      }
    }
  }

  cleanEventName() {
    const eventNameArray = this.attributes.event_name.toLowerCase().split('_');
    for (let i = eventNameArray.length - 1; i >= 0; i--) {
      if (
        eventNameArray[i] === 'dismissed' ||
        eventNameArray[i] === 'shown' ||
        eventNameArray[i] === 'tapped' ||
        eventNameArray[i] === 'dismiss'
      ) {
        eventNameArray.splice(i, 1);
      }
    }
    this.attributes.event_name = eventNameArray.join(' ');
  }

  cleanCustomParams() {
    try {
      const customParams = JSON.parse(JSON.stringify(this.attributes.custom).toLowerCase().split('_').join(' '));
      this.attributes.custom = customParams;
    } catch (e) {
      // parsing probably failed, so do nothing and pass the params as-is
    }
  }

  ngAfterViewInit(): void {
    if (!this.dontLog) {
      this.tagName = this.el.nativeElement.tagName.toLowerCase();

      this.cleanEventName();
      if (this.attributes.custom) {
        this.cleanCustomParams();
      }
      if (this.error_view || this.attributes.event_name === 'error shown') {
        // pass error shown event_name as-is
        this.analytics.logEvent(`${this.attributes.event_name}`, this.attributes.custom);
      } else if (!this.isClickable() && !this.isDismissButton()) {
        this.analytics.logEvent(`${this.attributes.event_name} shown`, this.attributes.custom);
      }

      // if (!this.env.production) {
      //   console.groupCollapsed('FbDirective');
      //   console.log(this.attributes);
      //   console.log('is clickable: ', this.isClickable());
      //   console.log('is dismiss button: ', this.isDismissButton());
      //   console.log(this.el);
      //   console.groupEnd();
      // }
    }
  }

  isClickable(): boolean {
    return this.tagName === 'button' || this.tagName === 'a' || this.tagName === 'bw-icon';
  }

  isDismissButton(): boolean {
    return this.tagName === 'bw-back' || this.tagName === 'ui-nav-exit-back' || this.dismissedCta;
  }

  isInputElement(tagName: string): boolean {
    return tagName === 'INPUT' || tagName === 'BW-INPUT' || tagName === 'BW-CREATE-PASSWORD' || tagName === 'BW-PHONE-FORM';
  }
}
