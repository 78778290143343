import { Injectable } from '@angular/core';
import { MappingDetail, RouteInfoInterface } from '@brightside-web/desktop/data-access/shared';

@Injectable({
  providedIn: 'root',
})
export class FindMoneyRoutingService {
  constructor() {}

  navigateToSurvey(surveyLink: string): RouteInfoInterface | undefined {
    const breakRoute = surveyLink.replace('gobrightside://', '').split('/');
    const partnerKey: string = breakRoute[2] || '';
    let path: string = '';
    if (partnerKey) {
      path = `find-money/intro/${partnerKey}`;
    } else {
      path = 'find-money/view';
    }
    

    const route: MappingDetail = {
      path,
    };
    return { mappingDetail: route, queryString: '' };
  }
}
