import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";

export interface GlobalModalInterface {
  titleKey?: string;
  bodyKey?: string;
  ctaKey?: string;
  logEvent?: string;
  type: string;
  displayMe: boolean;
  id: string;
  bannerId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService {

  defaultModal: GlobalModalInterface = {
    type: 'default',
    displayMe : false,
    id: ''
  }

  modalMemory: string[] = [];

  private _globalModal: BehaviorSubject<GlobalModalInterface> = new BehaviorSubject<GlobalModalInterface>(this.defaultModal);
  private sub = new Subscription();

  public readonly getModal: Observable<GlobalModalInterface> = this._globalModal.asObservable();
  constructor() { }

  setModal(modal: GlobalModalInterface) {
    this._globalModal.next(modal);
  }

  hideModal() {
    this._globalModal.next(this.defaultModal);
  }
}
