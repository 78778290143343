<typography text-small-semibold>
  <div class="mb-sm" [innerHTML]="controlConfig.label | translate"></div>
</typography>

<div *ngFor="let question of questions; let i = index" class="mb-sm">
  <typography>
    <p class="secondary-text mb-sm" [innerHTML]="question.text | translate"></p>
  </typography>
  <typography>
    <p class="hint mb-sm" [innerHTML]="question.questionHintText | translate"></p>
  </typography>
  <ng-container [ngSwitch]="question.style">
    <ng-container *ngSwitchCase="'checkbox'">
      <ng-container *ngTemplateOutlet="checkboxStyle; context: { $implicit: controlConfig, question: question }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="radioStyle; context: { $implicit: controlConfig, question: question }"></ng-container>
    </ng-container>
  </ng-container>


</div>

<ng-template #checkboxStyle let-controlConfig let-question=question>
  <div class="mb-xs" *ngFor="let choice of question.choices">
    <bw-checkbox
      [name]="controlConfig.key"
      [value]="choice"
      [label]="choice | translate"
      [checked]="question.selectedChoices[choice]"
      (checkboxChanged)="updateFormOnChange(question, choice)"
    ></bw-checkbox>
  </div>
</ng-template>

<ng-template #radioStyle let-controlConfig let-question=question>
  <ng-container *ngFor="let choice of question.choices">
    <bw-radio
      [name]="controlConfig.key"
      [value]="choice"
      [label]="choice | translate"
      [startSelected]="
      controlConfig.inputOptions?.initialValue === question.choiceValueMapping[choice]
      || controlConfig.inputOptions?.initialValue === choice"
      (radioClick)="updateFormOnChange(question, choice)"
    ></bw-radio>
  </ng-container>

</ng-template>
