import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AnalyticsAttributesV4, FirebaseService } from '@brightside-web/desktop/data-access/shared';

import { SharedDisplayCampaignComponent } from '../display-campaign/display-campaign.component';

@Component({
  selector: 'brightside-web-display-complete',
  templateUrl: './display-complete.component.html',
  styleUrls: ['./display-complete.component.scss'],
})
export class SharedDisplayCompleteComponent extends SharedDisplayCampaignComponent implements OnInit {
  displayFormAnalytics: {
    shown: AnalyticsAttributesV4;
    dismiss: AnalyticsAttributesV4;
    submit: AnalyticsAttributesV4;
  } = { shown: { event_name: '' }, dismiss: { event_name: '' }, submit: { event_name: '' } };
  eventName = '';

  constructor(protected activatedRoute: ActivatedRoute, protected analytics: FirebaseService, protected router: Router) {
    super(activatedRoute, analytics, router);
  }

  ngOnInit(): void {
    if (this.analyticsAttributes && this.analyticsAttributes.event_name) {
      this.eventName = this.analyticsAttributes.event_name;
    }
  }
}
