import { animate, query, style } from '@angular/animations';

const ANIMATION_QUERY_OPTIONS = {
  optional: true,
};

const ANIMATION_SLIDE_TIME = '500ms';
const ANIMATION_SLIDE_EASING = 'ease-out';
const ANIMATION_SLIDE_META = `${ANIMATION_SLIDE_TIME} ${ANIMATION_SLIDE_EASING}`;

const ANIMATION_STAY_TIME = '2s';
const ANIMATION_STAY_EASING = 'ease-in';
const ANIMATION_STAY_META = `${ANIMATION_STAY_TIME} ${ANIMATION_STAY_EASING}`;

const ANIMATION_TRANSFORM_OFF_SCREEN = { transform: 'translateX(100vw)' };
const ANIMATION_TRANSFORM_ON_SCREEN = { transform: 'translateX(0)' };

const ANIMATION_OPACITY_HIDE = { opacity: 0 };
const ANIMATION_OPACITY_SHOW = { opacity: 1 };

const ANIMATION_ZINDEX_BELOW = { 'z-index': 1 };
const ANIMATION_ZINDEX_ABOVE = { 'z-index': 2 };

const ANIMATION_FROM_RIGHT = (useSelector: string) =>
  query(
    useSelector,
    [style(ANIMATION_TRANSFORM_OFF_SCREEN), animate(ANIMATION_SLIDE_META, style(ANIMATION_TRANSFORM_ON_SCREEN))],
    ANIMATION_QUERY_OPTIONS
  );

const ANIMATION_TO_RIGHT = (useSelector: string) =>
  query(
    useSelector,
    [
      style(ANIMATION_ZINDEX_ABOVE),
      animate(ANIMATION_SLIDE_META, style(ANIMATION_TRANSFORM_OFF_SCREEN)),
      style(ANIMATION_OPACITY_HIDE),
    ],
    ANIMATION_QUERY_OPTIONS
  );

const ANIMATION_STAY_ENTER = (useSelector: string) =>
  query(
    useSelector,
    [
      style({ ...ANIMATION_TRANSFORM_ON_SCREEN, ...ANIMATION_ZINDEX_BELOW }),
      animate(ANIMATION_STAY_META, style(ANIMATION_OPACITY_HIDE)),
    ],
    ANIMATION_QUERY_OPTIONS
  );

const ANIMATION_STAY_EXIT = (useSelector: string) =>
  query(
    useSelector,
    [
      style({ ...ANIMATION_TRANSFORM_ON_SCREEN, ...ANIMATION_ZINDEX_BELOW }),
      animate(ANIMATION_STAY_META, style(ANIMATION_OPACITY_SHOW)),
    ],
    ANIMATION_QUERY_OPTIONS
  );

export {
  ANIMATION_FROM_RIGHT,
  ANIMATION_STAY_ENTER,
  ANIMATION_STAY_EXIT,
  ANIMATION_TO_RIGHT,
  ANIMATION_TRANSFORM_OFF_SCREEN,
  ANIMATION_TRANSFORM_ON_SCREEN,
};
