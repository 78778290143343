import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bw-bui-row',
  templateUrl: './bui-row.component.html',
  styleUrls: ['./bui-row.component.scss']
})
export class BuiRowComponent implements OnInit {

  @Input() leftIconName: string;
  @Input() leftIconColor: string;
  @Input() leftIconSize: number = 32;
  @Input() leftIconBackgroundColor: string;
  @Input() rightIconName: string;
  @Input() rightIconColor: string;
  @Input() rightIconBackgroundColor: string;

  constructor() { }

  ngOnInit(): void {
  }

}
