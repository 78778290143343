import { Injectable } from '@angular/core';
import {
  AwsApiWrapperService
} from '@brightside-web/desktop/data-access/core-services';
import { from, Observable } from 'rxjs';
import {
  ApiCacheService,
  ApiResponse,
  CreditScore,
  CreditSetupStatus,
  CreditSubscription,
  CreditSummary,
  DesktopCard, DesktopCardFooter, DesktopCardSegment,
  DesktopSegmentType,
} from '@brightside-web/desktop/data-access/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  async groomCardData(card: DesktopCard, isCreditScoreEnabled?: boolean): Promise<DesktopCard> {
    const defaultSegment = card.segments ? card.segments.find((seg: DesktopCardSegment) => seg.type === DesktopSegmentType.DEFAULT) : undefined;
    if (defaultSegment) {
      try {
        if (isCreditScoreEnabled) {
          const resp = await this.getCreditScore().toPromise();
          defaultSegment.dynamicValue = resp ? resp.score : '--';
          card.ctaPath = '/credit/dashboard';
        } else {
          card.ctaPath = await this.getToastNotificationByCreditStatus();
        }
      } catch (e) {
        defaultSegment.dynamicValue = '--';
        card.ctaPath = await this.getToastNotificationByCreditStatus();
        card.footer = <DesktopCardFooter>{
          leftImage: "alert",
          style: "Error",
          title: "An error has occurred"
        };
      }
    }

    return card;
  }

  constructor(
    private apiSvc: ApiCacheService,
    private awsApiWrapperService: AwsApiWrapperService,
    ) {}

  disconnectCreditReport(): Observable<ApiResponse> {
    this.apiSvc.refreshItem('/credit/subscription');
    this.apiSvc.refreshItem('/credit/?filter=summary');
    this.apiSvc.refreshItem('/client/mobilestate');
    return from(this.awsApiWrapperService.del('api-mobile', '/credit/subscription', { headers: { 'Content-Type': 'application/json' } }));
  }

  getCreditScore(): Observable<CreditScore> {
    return this.getCreditSummary().pipe(map((resp) => resp.creditScore));
  }

  getCreditSummary(): Observable<CreditSummary> {
    return this.apiSvc.get<CreditSummary>('api-mobile', '/credit/?filter=summary');
  }

  getCreditReport(): Observable<Blob> {

    return from(
      this.awsApiWrapperService.get('api-mobile', '/credit/report/v2', {}, 'pdf')
    ).pipe(
      map((data) => {
        const byteString = atob(data);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'application/pdf' });
      })
    );
  }

  openBase64Pdf(base64String: string, filename: string = 'document.pdf') {
    const byteString = atob(base64String);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'application/pdf' });

    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
  }

  getCreditSubscription(): Observable<CreditSubscription> {
    return this.apiSvc.get('api-mobile', '/credit/subscription');
  }

  private async getToastNotificationByCreditStatus(): Promise<string> {
    const sub = await this.getCreditSubscription().toPromise();
    if (sub?.status === CreditSetupStatus.FULFILLED) {
      return 'toast:noCreditData';
    } else {
      return 'toast:creditSyncing';
    }
  }


}
