<bw-modal>
  <typography title-medium [innerHTML]="title | translate"></typography>
  <div class="body">
    <bw-spinner brightsideWebTimeout *ngIf="loading"></bw-spinner>
    <div *ngIf="phoneNumber">
      {{prompt | translate}}:
      {{ phoneNumber|phone }}
    </div>
  </div>
  <div class="footer">
    <button bw-button (click)="emitClick()">{{ ctaText | translate }}</button>
  </div>
</bw-modal>
