<div [ngClass]="{'steps':true,'readonly':readonly}" [ngStyle]="style" [class]="styleClass">
  <ul role="tablist">
      <li *ngFor="let item of model; let i = index" class="steps-item" #menuitem [ngStyle]="item.style" [class]="item.styleClass" role="tab" [attr.aria-selected]="i === activeIndex" [attr.aria-expanded]="i === activeIndex"
          [ngClass]="{'highlight steps-current': isActive(item, i), 'steps-item-complete': isComplete(item, i), 'disabled': item.disabled || (readonly && !isActive(item, i))}">
          <a *ngIf="isClickableRouterLink(item); else elseBlock" [routerLink]="item.routerLink" [queryParams]="item.queryParams" role="presentation" [routerLinkActive]="'menuitem-link-active'" [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}" class="menuitem-link"
              (click)="itemClick($event, item, i)" (keydown.enter)="itemClick($event, item, i)" [attr.target]="item.target" [attr.id]="item.id" [attr.tabindex]="item.disabled || readonly ? null : (item.tabindex ? item.tabindex : '0')"
              [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment" [skipLocationChange]="item.skipLocationChange" [replaceUrl]="item.replaceUrl" [state]="item.state">
              <span class="steps-number">{{i + 1}}</span>
              <span class="steps-title" *ngIf="item.escape !== false; else htmlLabel">{{item.label}}</span>
              <ng-template #htmlLabel><span class="steps-title" [innerHTML]="item.label"></span></ng-template>
          </a>
          <ng-template #elseBlock>
              <a [attr.href]="item.url" class="menuitem-link" role="presentation" (click)="itemClick($event, item, i)" (keydown.enter)="itemClick($event, item, i)" [attr.target]="item.target" [attr.id]="item.id"
                  [attr.tabindex]="item.disabled||(i !== activeIndex && readonly) ? null : (item.tabindex ? item.tabindex : '0')">
                  <span class="steps-number">{{i + 1}}</span>
                  <span class="steps-title" *ngIf="item.escape !== false; else htmlRouteLabel">{{item.label}}</span>
                  <ng-template #htmlRouteLabel><span class="steps-title" [innerHTML]="item.label"></span></ng-template>
              </a>
          </ng-template>
      </li>
  </ul>
</div>