import { Component, EventEmitter, Output, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MessageBusInternalService } from '@brightside-web/micro/core/message-bus';

import { SharedDisplayFormControlConfig } from '../shared-display-form.modal';

@Component({
  selector: 'bs-form-file',
  templateUrl: './form-file.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SharedDisplayFormFileComponent implements OnInit {
  @Input() controlConfig: SharedDisplayFormControlConfig;
  @Input() formGroup: FormGroup;

  @Output() cellClick: EventEmitter<SharedDisplayFormControlConfig> = new EventEmitter();

  inputSupportMultiple = false;
  inputSupportedFiles = 'image/*, heif';

  ngOnInit() {
    this.inputSupportMultiple = this.controlConfig?.cellDetails?.action?.supportMultiple || false;
    this.inputSupportedFiles = this.controlConfig?.cellDetails?.action?.supportedFiles || 'image/*, heif';
  }

  private noopFunction = () => null;

  get actionClickResponder() {
    return this.controlConfig?.cellDetails?.action?.clickResponder || this.noopFunction;
  }

  handleActionCellClick() {
    //Things like file upload can be supported with a responder like SharedDisplayFormFileActionResponder
    this.actionClickResponder(this.formGroup, this.controlConfig);

    //If this action has a special event defined, something like displaying a half-sheet on click
    if (this.controlConfig?.cellDetails?.action?.event) {
      MessageBusInternalService.sendOutgoingHubEvent(this.controlConfig.cellDetails.action.event);
      return; //Return is to not prevent any routing the user may have on emit of click
    }

    //Now let the caller handle anything they need to
    this.cellClick.emit(this.controlConfig);
  }
}
