<ng-container *ngIf="displayForm">
<form [formGroup]="displayForm">
  <bw-action-article minHeight="0" [brightsideWebFb]="displayFormAnalytics.shown">
    <div id="displayFormHeaderAction" header-actions class="flex-row pointer">
      <bw-icon
        *ngIf="config.pageHideBackButton; else showBackButton"
        color="var(--secondary-text)"
        iconName="close"
        (click)="handleBackClick()"
      ></bw-icon>

      <ng-template #showBackButton>
        <ui-nav-exit-back
          *ngIf="!pageIsFullScreen"
          back-action-top
          [trackingDetail]="displayFormAnalytics.dismiss"
          [skipActionEmit]="!config.pageOnBackCtaPath"
          (exitActionHandler)="handleBackClick()"
        ></ui-nav-exit-back>
        <ui-nav-exit-back
          *ngIf="pageIsFullScreen"
          back-action-bottom
          [trackingDetail]="displayFormAnalytics.dismiss"
          [skipActionEmit]="!config.pageOnBackCtaPath"
          (exitActionHandler)="handleBackClick()"
        ></ui-nav-exit-back>
      </ng-template>
    </div>
    <div id="displayFormHeaderTitle" header-title>
      <typography title-medium class="mt-xs">{{ config.pageTitleKey || config.pageTitle | translate }}</typography>
      <bw-icon
        *ngIf="config.pageHeaderIconName"
        size="41.6"
        [iconName]="config.pageHeaderIconName"
        color="var(--brand)"
        backgroundColor="var(--bg-blue)"
      ></bw-icon>
    </div>
    <div id="displayFormContentSection" content-section>
      <bw-paragraph [textStyle]="config.pageSubTitleStyle" [color]="config.pageSubTitleColor">
        <span [innerHTML]="config.pageSubTitleKey || config.pageSubTitle | translate"></span>
      </bw-paragraph>

      <div *ngIf="config.pageCalloutInformation" class="flex-row mb-md">
        <bw-icon [iconName]="config.pageCalloutInformation.iconName"></bw-icon>
        <p class="secondary-text mr-xs">{{ config.pageCalloutInformation.text | translate }}</p>
      </div>

      <typography text-large-semibold class="mt-xs mb-sm">{{
        config.pageFormControlsTitleKey || config.pageFormControlsTitle | translate
      }}</typography>

      <div class="form-grid mb-lg" [ngClass]="{ 'full-width': config.pageFormControlFullWidth }">
        <bw-spinner *ngIf="!sharedFormControls || !sharedFormControls.length; else templateShowForm"></bw-spinner>
        <ng-template #templateShowForm>
          <ng-container *ngFor="let controlConfig of sharedFormControls">
            <span [ngClass]="{ hidden: displayRuleMapping.hide.includes(controlConfig.key) }">
              <ng-container *ngTemplateOutlet="buildControlTemplate; context: { $implicit: controlConfig }"></ng-container
            ></span>
          </ng-container>
        </ng-template>
      </div>

      <div *ngIf="addKeyboardSpacer" class="hidden-desktop flex-column flex-start flex-wrap keyboard-filler-space"></div>
    </div>

    <div footer-top>
      <bw-checkbox
        *ngIf="config.pageConfirmCheckText"
        required
        formControlName="controlConfirmCheckbox"
        [label]="config.pageConfirmCheckText | translate"
        [centerAlign]="true"
        (checkboxChanged)="displayForm.markAllAsTouched()"
      ></bw-checkbox>
      <div *ngIf="config.pageAdditionalInformation" class="additional-information-wrapper">
        <typography text-small-regular class="mr-xxs">{{ config.pageAdditionalInformation.linkPreText | translate }}</typography>
        <a bw-anchor anchorStyle="text-small-regular" class="bs-blue mr-xxs" (click)="showAdditionalInformation = true">
          {{ config.pageAdditionalInformation.linkText | translate }}
        </a>
        <typography text-small-regular>{{ config.pageAdditionalInformation.linkPostText | translate }}</typography>
      </div>
    </div>
    <div footer-actions class="flex-row row-reverse">

      <button
        *ngIf="config.pageCtaLabel || config.pageCtaLabelKey"
        bw-button
        [brightsideWebFb]="displayFormAnalytics.submit"
        [disabled]="!isFormValid"
        [processing]="processing"
        (click)="handleCtaClick()"
      >
        {{ config.pageCtaLabelKey || config.pageCtaLabel | translate }}
      </button>

      <typography text-large-semibold>
        <a *ngIf="config.pageSecondaryCtaLabel" bw-anchor class="bs-blue mr-sm" (click)="handleSecondaryCtaClick()">{{
          config.pageSecondaryCtaLabelKey || config.pageSecondaryCtaLabel | translate
        }}</a>
      </typography>
    </div>
  </bw-action-article>

  <!-- These templates should be broken out to their own files -->
  <ng-template #buildControlTemplate let-controlConfig>
    <!-- START - If you add anything here... make sure you are wrapping template content with IF block -->
    <ng-container *ngIf="controlConfig.cellDetails; else formFieldDisplay">
      <ng-container *ngTemplateOutlet="cellFormField; context: { $implicit: controlConfig }"></ng-container>
      <ng-container *ngTemplateOutlet="cellActionFormField; context: { $implicit: controlConfig }"></ng-container>
    </ng-container>

    <ng-template #formFieldDisplay>
      <ng-container *ngTemplateOutlet="basicFormField; context: { $implicit: controlConfig }"></ng-container>
      <ng-container *ngTemplateOutlet="checkboxFormField; context: { $implicit: controlConfig }"></ng-container>
      <ng-container *ngTemplateOutlet="choiceFormField; context: { $implicit: controlConfig }"></ng-container>
      <ng-container *ngTemplateOutlet="currencyFormField; context: { $implicit: controlConfig }"></ng-container>
      <ng-container *ngTemplateOutlet="phoneFormField; context: { $implicit: controlConfig }"></ng-container>
      <ng-container *ngTemplateOutlet="selectFormField; context: { $implicit: controlConfig }"></ng-container>
    </ng-template>

    <!-- END - Form field displays -->

    <a
      *ngIf="controlConfig.inputOptions?.link"
      bw-anchor
      anchorStyle="text-small-regular"
      color="var(--brand)"
      (click)="handleLinkClick(controlConfig.inputOptions?.link.path)"
      [brightsideWebFb]="displayFormAnalytics.submit"
      >{{ controlConfig.inputOptions?.link.text || '' | translate }}</a
    >
  </ng-template>

  <ng-template #basicFormField let-controlConfig>
    <bw-input
      *ngIf="
        !controlConfig.type ||
        (!controlConfig.choice &&
          controlConfig.type !== 'currency' &&
          controlConfig.type !== 'phone' &&
          controlConfig.type !== 'checkbox' &&
          controlConfig.type !== 'select')
      "
      [formControlName]="controlConfig.key"
      [type]="controlConfig.type"
      [label]="controlConfig.labelKey || controlConfig.label | translate"
      [attr.disabled]="processing"
      [inputError]="doesControlHaveError(controlConfig.key) && (controlConfig.errorText || controlConfig.errorTextKey)"
      [inputErrorText]="controlConfig.errorTextKey || controlConfig.errorText | translate"
      [inputMask]="controlConfig.inputOptions?.mask"
      [minlength]="controlConfig.inputOptions?.minlength"
      [maxlength]="controlConfig.inputOptions?.maxlength"
      [pattern]="controlConfig.inputOptions?.pattern"
      [placeholder]="controlConfig.inputOptions?.placeholder || '' | translate"
      [togglePassword]="controlConfig.inputOptions?.togglePassword || false"
      [suffixIconName]="controlConfig.inputOptions?.suffixIconName || ''"
      [showSuffixIcon]="false"
      [inputHintText]="controlConfig.inputOptions?.inputHintText || '' | translate"
    >
    </bw-input>
  </ng-template>

  <ng-template #phoneFormField let-controlConfig>
    <bw-phone-form
      *ngIf="controlConfig.type === 'phone'"
      [label]="controlConfig.labelKey || controlConfig.label | translate"
      [fGroup]="displayForm"
      [fcName]="controlConfig.key"
      [inputError]="doesControlHaveError(controlConfig.key) && (controlConfig.errorText || controlConfig.errorTextKey)"
      [inputErrorText]="controlConfig.errorTextKey || controlConfig.errorText | translate"
      [attr.disabled]="processing"
    ></bw-phone-form>
  </ng-template>

  <ng-template #currencyFormField let-controlConfig>
    <bw-currency-input
      *ngIf="controlConfig.type === 'currency'"
      verticalSpace="mb-sm"
      class="form-grid"
      value="{{ controlConfig.inputOptions?.initialValue || '' }}"
      [label]="controlConfig.labelKey || controlConfig.label | translate"
      [onChange]="generateWrappedFunctionValueUpdate(controlConfig.key)"
      [inputHintText]="controlConfig.inputOptions?.inputHintText || '' | translate"
    ></bw-currency-input>
  </ng-template>

  <ng-template #selectFormField let-controlConfig>
    <span *ngIf="controlConfig.type === 'select'">
      <label [for]="controlConfig.key" class="mb-xs"
        ><typography text-small-semibold>{{ controlConfig.labelKey || controlConfig.label | translate }}</typography></label
      >
      <ngx-select-dropdown
        [formControlName]="controlConfig.key"
        [options]="controlConfig.inputOptions?.selectOptions"
        [config]="controlConfig.inputOptions?.selectConfig"
      ></ngx-select-dropdown>
    </span>
  </ng-template>

  <ng-template #checkboxFormField let-controlConfig>
    <bw-checkbox *ngIf="controlConfig.type === 'checkbox'" [formControlName]="controlConfig.key"
      ><typography text-medium-regular color="var(--secondary-text)">{{
        controlConfig.label | translate
      }}</typography></bw-checkbox
    >
  </ng-template>

  <ng-template #cellFormField let-controlConfig>
    <bw-cell
      *ngIf="!controlConfig.cellDetails.action"
      (clicked)="handleFormCellClick(controlConfig.key)"
      [labelLeft]="controlConfig.cellDetails.labelLeft || '' | translate"
      [labelSubLeft]="controlConfig.cellDetails.labelSubLeft || '' | translate"
      [flipLeftLabels]="controlConfig.cellDetails.flipLeftLabels || false"
      [iconNameRight]="controlConfig.cellDetails.iconNameRight || 'chevron_right'"
      class="full-width"
    ></bw-cell>
  </ng-template>

  <ng-template #cellActionFormField let-controlConfig>
    <bs-form-file
      *ngIf="controlConfig.cellDetails.action"
      [formGroup]="displayForm"
      [controlConfig]="controlConfig"
      (cellClick)="handleFormActionCellClick(controlConfig)"
    ></bs-form-file>
  </ng-template>

  <ng-template #choiceFormField let-controlConfig>
    <bs-form-choice *ngIf="controlConfig.choice" [formGroup]="displayForm" [controlConfig]="controlConfig"></bs-form-choice>
  </ng-template>
</form>
</ng-container>
<bw-modal
  *ngIf="showAdditionalInformation"
  modalWidth="large"
  [showCloseButton]="true"
  (closeModal)="showAdditionalInformation = false"
  [brightsideWebFb]="{ event_name: 'additional_info' }"
>
  <typography title-medium class="mb-md">{{ config.pageAdditionalInformation.title | translate }}</typography>
  <div class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)" style="white-space: pre-wrap">{{
      config.pageAdditionalInformation.bodyText | translate
    }}</bw-paragraph>
  </div>
  <div class="footer">
    <div class="flex-row row-reverse hidden-mobile">
      <button
        bw-button
        dismissedCta="true"
        (click)="showAdditionalInformation = false"
        [brightsideWebFb]="{ event_name: 'additional_info' }"
      >
        {{ config.pageAdditionalInformation.cta | translate }}
      </button>
    </div>
  </div>
</bw-modal>

<bw-modal *ngIf="showProcessError" (closeModal)="showProcessError = false">
  <typography title-medium color="var(--gray-70)">{{ processErrorMessageTitle | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">{{ processErrorMessage | translate }}</bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="showProcessError = false" [brightsideWebFb]="{ event_name: 'error_modal' }">{{"OK" |translate}}</button>
    </div>
  </footer>
</bw-modal>

<brightside-web-display-halfsheet
  [eventName]="this.config.fbEventName"
  [internalHubEvent]="halfSheetEventData"
></brightside-web-display-halfsheet>


