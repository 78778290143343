import {Injectable} from '@angular/core';
import {Cache} from 'aws-amplify/utils'
import { CacheItemOptions } from '@aws-amplify/core/src/Cache/types/cache';
import {Environment} from "@micro-core/environment";

@Injectable({
  providedIn: 'root'
})
export class BsCacheService {

  static setItemStatic(key: string, value: any, options?: Record<string, any> | undefined) : Promise<void> {
    return Cache.setItem(key, value, options);
  }

  static getItemStatic(key: string) : Promise<any>
  static getItemStatic(key: string, options?: CacheItemOptions | undefined) : Promise<any> {
    if (options) return Cache.getItem(key, options);
    return Cache.getItem(key);
  }

  static removeItemStatic(key: string) : Promise<void> {
    return Cache.removeItem(key);
  }

  constructor(
    private env: Environment
  ) { }

  async getItem(key: string) : Promise<any>
  async getItem(key: string, options?: CacheItemOptions | undefined) : Promise<any> {
    if (options) {
      return Cache.getItem(key, options).catch(
        (error) => {
          if (!this.env.production) console.warn(`Error getting cache item, ${error}`);
          return Promise.reject(error);
        }
      );
    }
    return await Cache.getItem(key).catch(
      (error) => {
        if (!this.env.production) console.warn(`Error getting cache item, ${error}`);
        return Promise.reject(error);
      }
    );
  }

  setItem(key: string, value: any, options?: Record<string, any>): Promise<void> {
    if (!value) return Promise.resolve(); // Resolve immediately if value is falsy
    return Cache.setItem(key, value, options).catch((error) => {
      if (!this.env.production) {
        console.warn(`Error setting cache item: ${error}`);
      }
      return Promise.reject(error);
    });
  }


  removeItem(key: string) : Promise<void> {
    return Cache.removeItem(key).catch(
      (error) => {
        if (!this.env.production) console.warn(`Error removing cache item, ${error}`);
        return Promise.reject(error);
      }
    );
  }

  clear(): Promise<void> {
    return Cache.clear();
  }

}
