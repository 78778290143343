<section class="content-layout">
  <bw-action-article [brightsideWebFb]="{ event_name: this.analyticsAttributes.event_name }">
    <bw-icon
      *ngIf="campaign.closeable && campaign.secondaryCtaPath"
      iconName="close"
      size="24"
      [brightsideWebFb]="{ event_name: this.analyticsAttributes.event_name }"
      (click)="handleClick(campaign.secondaryCtaPath, CampaignEventStatus.FAILED)"
    ></bw-icon>

    <!-- We need to sort out these buttons... needed to add this one to support micro app (mobile) -->
    <div header-actions class="flex-row pointer" *ngIf="campaign.closeable">
      <bw-icon
        class="hidden-desktop"
        iconName="close"
        size="24"
        (click)="handleCloseClick()"
      ></bw-icon>
    </div>

    <ui-nav-exit-back
      *ngIf="!campaign.closeable && showBackArrow"
      back-action-bottom
      [trackingDetail]="{ event_name: this.analyticsAttributes.event_name }"
      (exitActionHandler)="handleClick(campaign.secondaryCtaPath, CampaignEventStatus.FAILED)"
    ></ui-nav-exit-back>

    <div content-section>
      <div class="flex-column space-center mb-sm">
        <div class="align-left">
          <img src="../assets/{{ imageName }}.svg" [alt]="imageName" class="mb-md" />
          <p title-medium class="mb-sm">{{ titleText || campaign.titleKey | translate }}</p>
          <typography text-large-regular color="var(--secondary-text)">
            <p [ngClass]="bodyTextAlign" [style.whiteSpace]="bodyTextNoWrap" style="max-width: 432px">
              {{ bodyText || campaign.textBodyKey | translate }}
            </p>
          </typography>
        </div>
      </div>
    </div>

    <div content-section *ngIf="campaign.isCreditIntro">
      <div class="flex-column space-center mb-xl">
        <div class="align-left">
          <typography text-large-regular
            ><strong>{{ 'CREDIT_INTRO_SUBHEADER' | translate }}</strong></typography
          >
          <div class="mt-sm mb-sm">
            <div *ngFor="let item of creditIntroItems" class="flex-row space-between">
              <div class="align-left mr-sm">
                <bw-icon [iconName]="'checkmark'" size="24"></bw-icon>
              </div>
              <div class="flex-column flex-item-flexible">
                <typography text-medium-regular>{{ item | translate }}</typography>
              </div>
            </div>
          </div>
          <div class="mb-sm">
            <typography text-large-regular color="var(--secondary-text)">
              <p class="left" style="max-width: 432px">
                {{ 'CREDIT_INTRO_HALFSHEET_DESC' | translate }}
              </p>
            </typography>
          </div>
        </div>
      </div>
    </div>

    <div footer-actions [ngClass]="{ 'flex-column': campaign.showFullScreenCta }">
      <button
        *ngIf="campaign.ctaKey || primaryCtaText"
        bw-button
        type="submit"
        [brightsideWebFb]="{ event_name: this.analyticsAttributes.event_name }"
        [processing]="processing"
        (click)="handleClick(campaign.ctaPath, CampaignEventStatus.SUCCESS)"
      >
        {{ primaryCtaText || campaign.ctaKey | translate }}
      </button>

      <button
        *ngIf="campaign.secondaryCta"
        bw-link-button
        [brightsideWebFb]="{ event_name: this.analyticsAttributes.event_name }"
        [dismissedCta]="true"
        (click)="handleClick(campaign.secondaryCtaPath, CampaignEventStatus.FAILED)"
      >
        {{ secondaryCtaText || campaign.secondaryCtaKey | translate }}
      </button>
    </div>
  </bw-action-article>
</section>
