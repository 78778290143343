import { ChangeDetectorRef, Component, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import {
  UiTemplateAction,
  UiTemplateActionFunctionReturnRouter,
  UiTemplateBaseActionFunction,
  UiTemplateLifecycleHook,
} from './model/page-template.enum';
import { UiTemplateBaseActionRoute, UiTemplateBaseInterface } from './model/page-template.interface';

export interface OnTemplateDataChanged {
  data: UiTemplateBaseInterface;

  /**
   * This method is called (and should always call super) when data input values change.
   */
  templateDataChanged(): void;

  /**
   * This method is called (and should always call super) when route query params change
   */
  queryParamsChanged(): void;
}

@Component({
  template: '',
})
export class UiTemplateBaseComponent implements OnDestroy, OnInit, OnChanges, OnTemplateDataChanged {
  public hasRoutingLocked = false;
  public hasActionLocked = false;

  public shouldReverseExitAnimation = false;

  private _sub = new Subscription();

  /**
   * If you wish to have developer logging turn this on
   *
   * Default: true
   */
  protected allowVerboseDebugMode = true;

  /**
   * Override this if you want your template to log
   * with certain prefix string
   *
   * Default: 'UI Template Log - '
   */
  protected logPrefix = 'UI Template Log - ';

  /**
   * This can be set to true when query params should be subscribed to
   *
   * Default: false
   */
  protected shouldWatchQueryParams = false;

  /**
   * Allow developer to pass in data with an object
   *
   * Interface UiTemplateBaseInterface
   */
  data: UiTemplateBaseInterface;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected changeDetectorRef: ChangeDetectorRef,
    protected injector: Injector,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.checkAndValidateDataFromActivatedRoute();
    this.checkForFlagsBasedOnData();

    this.startWatchingQueryParams();
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //If changes happened
    if (!changes['data'].isFirstChange()) {
      this.templateDataChanged();
    }
  }

  private get hasDataDefined(): boolean {
    return this.data !== undefined && Object.keys(this.data).length > 0;
  }

  private get hasHooksDefined(): boolean {
    return Boolean(this.data?.hooks) && Object.keys(this.data.hooks || {}).length > 0;
  }

  private startWatchingQueryParams() {
    if (this.shouldWatchQueryParams) {
      this._sub.add(
        this.activatedRoute.queryParams.pipe(throttleTime(1000)).subscribe(() => {
          this.logForDebugging('startWatchingQueryParams - Query Param Changed');

          this.queryParamsChanged();
        })
      );
    }
  }

  private checkForFlagsBasedOnData() {
    if ((this.data as any)['modals']) {
      this.shouldWatchQueryParams = true;
    }
  }

  private checkAndValidateDataFromActivatedRoute() {
    this.logForDebugging('checkAndValidateDataFromActivatedRoute', this.activatedRoute);

    //Only load data if we don't have data defined yet. This helps us avoid the modals have route defined data
    if (!this.hasDataDefined && this.activatedRoute.snapshot?.data && this.activatedRoute.snapshot.data['templateData']) {
      const templatedData: UiTemplateBaseInterface = this.activatedRoute.snapshot.data['templateData'];

      this.logForDebugging('Template Data', templatedData);

      this.data = templatedData;
    }

    //Check if we have a data object yet.
    //Should be true when one is present with at least 1 key/value pair
    if (this.hasDataDefined) {
      this.templateDataChanged();

      this.runLifeCycleEvent(UiTemplateLifecycleHook.templateDataInitLoaded);
    }
  }

  private checkForDeveloperSettingsFromData() {
    if (this.data.developer) {
      //Should maybe not make this hard coded later... but not sure about allowing anything to be overwritten
      this.allowVerboseDebugMode =
        this.data.developer.allowVerboseDebugMode === undefined
          ? this.allowVerboseDebugMode
          : this.data.developer.allowVerboseDebugMode;
      this.logPrefix = this.data.developer.logPrefix === undefined ? this.logPrefix : this.data.developer.logPrefix;
    }
  }

  protected get sub(): Subscription {
    return this._sub;
  }

  public queryParamsChanged(): void {
    this.logForDebugging('queryParamsChanged (core)', this.activatedRoute.snapshot.queryParams);
  }

  public templateDataChanged(): void {
    this.logForDebugging('templateDataChanged (core)', this.data);

    this.checkForDeveloperSettingsFromData();
  }

  protected logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode) console.log(`main:${this.logPrefix || '<notSetUp>'}`, message, optionalParams);
  }

  /**
   *
   *
   * @param action <UiTemplateLifecycleHook>
   * @returns void
   */
  protected runLifeCycleEvent(lifeCycle: UiTemplateLifecycleHook): void {
    //If there are no hooks, exit
    if (!this.hasHooksDefined) {
      return;
    }

    /**
     * Make sure we have a hook in the template for this given
     * life cycle hook to make sure we have something needing
     * to be called.
     */
    const hookFn = (this.data?.hooks || {})[lifeCycle];

    if (hookFn) {
      hookFn(this.injector);
    }
  }

  /**
   * This should be used to understand what the name of
   * the template component is. Which is used for logging
   * in console.
   */
  public get templateDisplayName() {
    return this.logPrefix || '<notPresent>';
  }

  public routeTo(routeInfo: UiTemplateBaseActionRoute, actionKey?: UiTemplateAction) {
    if (this.hasRoutingLocked) {
      console.warn('UITemplate: Trying to route when another route event is in progress');
      return;
    }

    this.logForDebugging('routing to', routeInfo.route);
    this.logForDebugging('routing with options', routeInfo.options);
    this.logForDebugging('routing from', this.activatedRoute);

    if (actionKey === UiTemplateAction.navLeftIcon) {
      this.shouldReverseExitAnimation = true;
      this.changeDetectorRef.detectChanges();
    }

    //this.hasRoutingLocked = true;
    this.router
      .navigate(routeInfo.route, {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
        ...routeInfo.options,
        queryParams: {
          entryAt: new Date().getTime(),
          ...routeInfo.options?.queryParams,
        },
      })
      .then((routeWorked) => {
        //this.hasRoutingLocked = false;
        this.logForDebugging('routing result', routeWorked);
      })
      .catch((err) => {
        this.logForDebugging('routing had error', err);
      })
      .finally(() => {
        this.logForDebugging('routing hit finally');
      });
    this.changeDetectorRef.markForCheck();
  }

  public executeAction(action: UiTemplateActionFunctionReturnRouter | UiTemplateBaseActionFunction) {
    if (this.hasActionLocked) {
      console.warn('UITemplate: Trying to trigger action when another action is in progress');
      return;
    }

    this.hasActionLocked = true;
    const actionFnResponse = action();
    this.hasActionLocked = false;

    //Check if the response has a route
    if (actionFnResponse && actionFnResponse.route) {
      this.routeTo(actionFnResponse);
      return;
    }
  }
}
