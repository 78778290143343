<ng-container *ngIf="size === 'medium'">
  <div class="copy_cell" [ngClass]="{'copy_cell--show-border':border}">
    <div class="flex-row space-between">
      <typography *ngIf="color === 'primary'" text-medium-regular>{{label}}</typography>
      <typography *ngIf="color === 'secondary'" text-medium-regular color="var(--secondary-text)">{{label}}</typography>
      <div class="copy-cell__details flex-row" (click)="cellClicked()">
        <typography text-medium-regular><div [innerHTML]="detail"></div></typography>
        <bw-icon *ngIf="iconName" [iconName]="iconName"></bw-icon>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="size === 'small'">
  <div class="copy_cell" [ngClass]="{'copy_cell--show-border':border}">
    <div class="flex-row space-between">
      <typography *ngIf="color === 'primary'" text-small-regular>{{label}}</typography>
      <typography *ngIf="color === 'secondary'" text-small-regular color="var(--secondary-text)">{{label}}</typography>
      <div class="copy-cell__details flex-row" (click)="cellClicked()">
        <typography text-small-regular><div [innerHTML]="detail"></div></typography>
        <bw-icon *ngIf="iconName" [iconName]="iconName"></bw-icon>
      </div>
    </div>
  </div>
</ng-container>

