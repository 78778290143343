enum UiTemplateStateKey {
  CHILD_MODAL = 'state_child_modal',
  CTA_1 = 'state_cta1',
  CTA_2 = 'state_cta2',
  FOOTER_CHECKED = 'state_footer_checked',
  FORM = 'state_form',
  HEADER_MENU = 'state_menu',
}

export { UiTemplateStateKey };
