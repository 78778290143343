import { Injectable, Injector } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Data } from '@angular/router';
import { Observable, from } from 'rxjs';
import { BsCacheService } from "@brightside-web/desktop/data-access/core-services";

export enum SequentialGuardMode {
  OR = 'OR',
  AND = 'AND',
}

/**
 * https://github.com/AlexWalkerson/ng-master-guard/blob/master/master-guard.service.ts
 * Guard that makes it possible to use sequential chain of async guards
 * Example:
 *
 *     import { Guard1, Guard2, Guard3 } from '@appRoot/guards';
 *     ...
 *     Single route
 *          {
 *              path: 'one',
 *              canActivate: [MasterGuard],
 *              data: { guards: [Guard1, Guard2, Guard3], guardsRelation: 'AND' },
 *          },
 *      Child Routes:
 *          {
 *              path: 'parent',
 *              canActivateChild: [MasterGuard],
 *              data: {guards: [Guard1, Guard2, Guard3]},
 *              children: [
 *                  { path: 'child1', component: ChildComponent },
 *                  //override guards and their relation
 *                  { path: 'child2', component: ChildComponent, data: {guards: [Guard1, Guard2], guardsRelation: 'OR'} },
 *              ]
 *          },
 */
@Injectable({
  providedIn: 'root',
})
export class SequentialGuard implements CanActivate, CanActivateChild {
  private route: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;
  private executor: 'canActivate' | 'canActivateChild';
  private relation: SequentialGuardMode = SequentialGuardMode.AND;

  constructor(
    private injector: Injector,
    private bsCacheService: BsCacheService
    ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.executor = 'canActivate';
    this.route = route;
    this.state = state;

    return this.middleware();
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.executor = 'canActivateChild';
    this.route = route;
    this.state = state;
    return this.middleware();
  }

  private middleware(): Promise<boolean> {
    const data = this.findDataWithGuards(this.route);

    if (!data.guards || !data.guards.length) {
      return Promise.resolve(true);
    }

    this.relation = this.route.data.guardsRelation as SequentialGuardMode;

    return this.executeGuards(data.guards);
  }

  private findDataWithGuards(route: ActivatedRouteSnapshot | null): Data {
    if (route) {
      if (route.data.guards) {
        return route.data;
      }

      if (
        (route.routeConfig?.canActivateChild &&
          // eslint-disable-next-line no-bitwise
          ~route.routeConfig.canActivateChild.findIndex((guard) => this instanceof guard)) ||
        // eslint-disable-next-line no-bitwise
        (route.routeConfig?.canActivate && ~route.routeConfig.canActivate.findIndex((guard) => this instanceof guard))
      ) {
        return route.data;
      }

      return this.findDataWithGuards(route.parent);
    } else {
      return {};
    }
  }

  //Execute the guards sent in the route data
  private executeGuards(guards: Function[], guardIndex: number = 0): Promise<boolean> {
    this.logStart(guardIndex);

    return this.activateGuard(guards[guardIndex])
      .then((result) => {
        this.logStop(guardIndex);

        if (this.relation === SequentialGuardMode.AND && !result) return Promise.resolve(false);

        if (this.relation === SequentialGuardMode.OR && result) return Promise.resolve(true);

        if (guardIndex < guards.length - 1) {
          return this.executeGuards(guards, guardIndex + 1);
        } else {
          return Promise.resolve(result);
        }
      })
      .catch(() =>
         Promise.reject(false)
      );
  }

  private async activateGuard(token: Function): Promise<boolean> {
    const guard = this.injector.get(token);
    const onboardingFailed = await this.bsCacheService.getItem('onboardingError');

    let result: Observable<boolean> | Promise<boolean> | boolean;

    if (onboardingFailed) {
      result = true;
    } else if (['canActivate', 'canActivateChild'].includes(this.executor)) {
      result = guard[this.executor](this.route, this.state);
    } else {
      //Default to canActivate
      result = guard.canActivate(this.route, this.state);
    }

    if (typeof result === 'boolean') return Promise.resolve(result);

    return from(result).toPromise() as Promise<boolean>;
  }

  private logStart(index: number) {
    console.log('SequentialGuard - start guard', { guardIndex: index });
  }

  private logStop(index: number) {
    console.log('SequentialGuard - stop guard', { guardIndex: index });
  }
}
