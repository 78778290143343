import { MessageBusInternalService } from '../internal/message-bus-internal.service';

import {
  StandardEvent_EnterExternalAccountConnect as enterExternalAccountConnect,
  StandardEvent_ExitFeature as exitFeature,
  StandardEvent_ExitFeatureToChat as exitFeatureToChat,
  StandardEvent_HideLoader as hideLoadingSpinner,
  StandardEvent_PageLoaded as pageLoaded,
  StandardEvent_ShowLoader as showLoadingSpinner,
} from '../event/standard-events';

import { BuildForPathAndReturnStandardEvent as forPathAndReturnStandardEvent } from '../event/builder-events';
import { BuildHalfsheetConfigAndReturnStandardEvent as halfsheetConfigAndReturnStandardEvent } from '../event/builder/halfsheet-builder.event';

/**
 * Use this to trigger a routing event that will be handled in this order:
 * Angular routing service /routing.service.ts
 * - This service will match the path against the remote config domain mapping
 * - This service will use angular router to navigate
 * Will fallback to bridge routing if routing found but no domain included
 *
 * @param path string value for the entire path you wish to go to
 * @returns void
 */
const routeToPath = (path: string) => {
  console.log('debug: routeToPath triggered', path);

  MessageBusInternalService.sendOutgoingHubEvent(
    forPathAndReturnStandardEvent({
      path: path,
      route: path,
    })
  );
};

const standard = {
  enterExternalAccountConnect,
  exitFeature,
  exitFeatureToChat,
  exitFeatureToSavingsHome: forPathAndReturnStandardEvent({
    path: 'accounts/savings',
    route: 'accounts/savings',
  }),
  hideLoadingSpinner,
  pageLoaded,
  showLoadingSpinner,
};
const builder = { forPathAndReturnStandardEvent, halfsheetConfigAndReturnStandardEvent };


/**
 * MessageBusEventUtil contains pre-built events, builders for events, and reference to
 * the outgoing sender.
 *
 * event.standard - Pre-built events like exitFeature
 * events.builder - Builders like path navigation forPathAndReturnStandardEvent
 *
 * sendOutgoing(<MessageBusInternalHubEvent>) - Sends an event to the outgoing message bus
 */
const MessageBusEventUtil = {
  event: { standard, builder },
  sendOutgoing: MessageBusInternalService.sendOutgoingHubEvent,
  routeToPath,
};

export { MessageBusEventUtil };
