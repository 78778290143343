import {Injectable, Injector} from '@angular/core';
import {Observable, of, ReplaySubject, shareReplay, catchError, switchMap, take} from 'rxjs';
import { ApiCacheService } from './api-cache.service';
import {tap} from "rxjs/operators";
import {BsApiService, BsAuthService, UserAttributesInterface} from "@brightside-web/desktop/data-access/core-services";

export interface ChatTokenResponse {
  chatToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  // private profileSubject$: ReplaySubject<UserAttributesInterface> | null = null;

  private profileSubject$: ReplaySubject<UserAttributesInterface> | null = null;
  private lastFetchTimestamp: number | null = null;
  private readonly cacheExpiration = 30 * 1000; // 30 seconds



  private bsAuthService: BsAuthService;
  private bsApiService: BsApiService;

  private readonly _defaultProfile: ProfileResponse = {
    guid: '',
    intercom_external_id: '',
    lastName: '',
    firstName: '',
    phoneNumber: '',
    email: '',
    locale: '',
    company: '',
    sfContactId: ''
  };

  constructor(
    private apiCache: ApiCacheService,
    private injector: Injector
  ) {}

  private getBsAuthService(): BsAuthService {
    if (!this.bsAuthService) {
      this.bsAuthService = this.injector.get(BsAuthService);
    }
    return this.bsAuthService;
  }

  private getBsApiService(): BsApiService {
    if (!this.bsApiService) {
      this.bsApiService = this.injector.get(BsApiService);
    }
    return this.bsApiService;
  }

  getProfile(force = false): Observable<UserAttributesInterface> {
    const authService = this.getBsAuthService();
    const apiService = this.getBsApiService();
    return authService.isAuthenticated().pipe(
      switchMap(authenticated => {
        if (!authenticated) {
          return of(this._convertAttributes({...this._defaultProfile}));
        } else {
          if (!this.profileSubject$ || force) {
            this.profileSubject$ = new ReplaySubject<UserAttributesInterface>(1);

            apiService.get<ProfilePayload>('api-mobile', '/client/profile-summary').pipe(
              tap(profile => {
                if (this.profileSubject$) {
                  if (profile.payload) {
                    this.profileSubject$.next(this._convertAttributes({...profile.payload}));
                  } else {
                    this.profileSubject$.next(this._convertAttributes({...this._defaultProfile}));
                  }
                  this.profileSubject$.complete();
                }
              }),
              catchError(error => {
                console.error('Error fetching profile:', error);
                if (this.profileSubject$) {
                  this.profileSubject$.error(error);
                }
                return of(this._convertAttributes({...this._defaultProfile}));
              }),
              shareReplay({ bufferSize: 1, refCount: true })
            ).subscribe();
          }
          return this.profileSubject$.asObservable();
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      take(1)
    );
  }

  getChatToken(): Observable<ChatTokenResponse> {
    return this.apiCache.get<ChatTokenResponse>('api-mobile','/chat/token');
  }

  private _convertAttributes(allResults: ProfileResponse): UserAttributesInterface {
    return {
      email: allResults.email ?? '',
      guid: allResults.guid ?? '',
      intercom_external_id: allResults.guid ?? '',
      last_name: allResults.lastName ?? '',
      first_name: allResults.firstName ?? '',
      phone_number: allResults.phoneNumber ?? '',
      locale: allResults.locale ?? '',
      company: allResults.company ?? '',
      sf_contact_id: allResults.sfContactId ?? ''
    };
  }
}

export interface ProfilePayload {
  payload: ProfileResponse;
}

export interface ProfileResponse {
  guid: string;
  intercom_external_id: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  email: string;
  locale: string;
  company: string;
  sfContactId: string;
}
