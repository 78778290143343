<ng-container *ngIf="data && data.inputs">
  <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: cardTitle | translate }"></ng-container>
</ng-container>

<ng-template #headerTemplate let-cardTitle>
  <bw-card cardType="active-product" [title]="cardTitle | translate" (cardAction)="cardTitleActionHandler.emit()">
    <bw-card-body>
      <hr class="separator" />
      <div class="flex-row space-between mt-sm pt-xs">
        <div class="pt-sm">
          <typography title-medium>{{ title | translate }}</typography>
          <p text-large-regular class="mb-smd" color="var(--secondary-text)">{{ subTitle | translate }}</p>
          <typography headline-semibold>{{ promoTitle | translate }}</typography>

          <a bw-anchor [anchorUnderline]="true" class="mt-sm" (click)="handleMoreInfoAction($event)"><typography text-small-regular>{{ promoMoreInfoTitle | translate }}</typography></a>
        </div>
        <div>
          <ui-media [data]="[promoMedia]"></ui-media>
        </div>
      </div>
    </bw-card-body>
  </bw-card>
</ng-template>
