import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'bw-header',
  template: `
  <div class="flex-column" [ngClass]="{'column-reverse': eyebrow}">
    <div class="flex-row space-between mb-smd">
      <div *ngIf="hasTitle" class="header-title">
        <h1 class="type-xl">{{title}}</h1>
      </div>
      <div *ngIf="hasAvatar && !eyebrow" class="header-avatar ml-sm">
        <bw-avatar [iconName]="avatarIconName" [imageURL]="avatarImageURL" [avatarSize]="'large'"></bw-avatar>
      </div>
    </div>
    <p class="type-md secondary-text mb-sm" *ngIf="hasDescription">{{description}}</p>
  </div>
  `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() eyebrow: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() avatarIconName: string;
  @Input() avatarImageURL: string;

  hasTitle: boolean;
  hasDescription: boolean;
  hasAvatar: boolean;

  ngOnInit(): void {
    if (this.title) {
      this.hasTitle = true;
    } else {
      this.hasTitle = false;
    }
    if (this.description) {
      this.hasDescription = true;
    } else {
      this.hasDescription = false;
    }
    if (this.avatarIconName || this.avatarImageURL) {
      this.hasAvatar = true;
    } else {
      this.hasAvatar = false;
    }
  }

}
