import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";

enum TextStyle {
  Display = "display",
  TitleLarge = "title-large",
  TitleMedium = "title-medium",
  HeadlineSemibold = "headline-semibold",
  HeadlineRegular = "headline-regular",
  TextXXLargeSemibold = "text-xxlarge-semibold",
  TextLargeSemibold = "text-large-semibold",
  TextMediumSemibold = "text-medium-semibold",
  TextSmallSemibold = "text-small-semibold",
  TextXSmallSemibold = "text-xsmall-semibold",
  TextLargeRegular = "text-large-regular",
  TextMediumRegular = "text-medium-regular",
  TextSmallRegular = "text-small-regular",
  TextXSmallRegular = "text-xsmall-regular"
}

/**
 * Typography component:
 *  `<typography title-large>Title large</typography>`
 *  or
 * `<h1 title-large>Title large</h1>`
 *  or
 * `<p text-medium-semibold>Text medium semibold</p>`
 */

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `typography, typography[display], *[display], typography[title-large],
  *[title-large], typography[title-medium], *[title-medium], typography[headline-semibold],
  *[headline-semibold], typography[headline-regular],  *[headline-regular],
  typography[text-large-semibold], p[text-large-semibold], typography[text-medium-semibold],
  p[text-medium-semibold], typography[text-small-semibold], p[text-small-semibold],
  typography[text-xxlarge-semibold], typography[text-large-regular], p[text-large-regular], typography[text-medium-regular],
  p[text-medium-regular], typography[text-small-regular], p[text-small-regular]],
  typography[text-xsmall-regular], typography[text-xsmall-semibold]`,
  templateUrl: "./typography.component.html",
  styleUrls: ["./typography.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TypographyComponent implements OnInit {
  @Input() color: string;

  constructor(public _elementRef: ElementRef) {}

  ngOnInit() {
    const hostEl = this._getHostElement() as HTMLElement;
    const hostElAttrs = hostEl.getAttributeNames();

    for (const value of this._styleKeys(TextStyle)) {
      if (hostElAttrs.includes(TextStyle[value])) {
        hostEl.classList.add(TextStyle[value]);
      }
    }

    if (this.color) {
      hostEl.style.setProperty("color", this.color);
    }
  }

  private _getHostElement() {
    return this._elementRef.nativeElement;
  }

  // private _hasHostAttributes(...attributes: string[]) {
  //   return attributes.some(attribute => this._getHostElement().hasAttribute(attribute));
  // }

  private _styleKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
  }
}
