import { Injectable } from '@angular/core';
import {from, Observable, switchMap} from 'rxjs';
import {
  AwsApiWrapperService,
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';

import { CompanyService } from "@brightside-web/desktop/data-access/shared";

export interface InviteHouseholdCache {
  fName: string;
  lName: string;
  dateOfBirth: string;
  lastFourSSN: string;
  email?: string;
  employeeAlias?: string;
  homeAddressLine1?: string;
  homeAddressLine2?: string;
  homeAddressCity?: string;
  homeAddressState?: string;
  homeAddressZip?: string | number;
}

export interface InviteHouseholdEmployeeCache {
  fName: string;
  lName: string;
  dateOfBirth: string;
  zipCode: string;
}

export interface InviteRequest {
  referredClientName: string;
  type: InviteType;
  referralType: InviteReferralType;
}

export interface InviteEmailRequest extends InviteRequest {
  email: string;
}

export interface InvitePhoneRequest extends InviteRequest {
  sms: string;
}

export enum InviteType {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum InviteReferralType {
  HOUSEHOLD = 'household',
  CLIENT = 'client',
}

const HOUSEHOLD_CACHE_KEY = 'HOUSEHOLD_CACHE_KEY';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(
    private companyService: CompanyService,
    private awsApiWrapperService: AwsApiWrapperService,
    private bsCacheService: BsCacheService
  ) {}

  static clearCacheHousehold() {
    BsCacheService.removeItemStatic(HOUSEHOLD_CACHE_KEY);
  }

  static preCacheHousehold(details: InviteHouseholdCache) {
    InviteService.clearCacheHousehold();
    BsCacheService.setItemStatic(HOUSEHOLD_CACHE_KEY, details);
  }

  static getPreCacheHousehold(): Promise<InviteHouseholdCache> {
    return BsCacheService.getItemStatic(HOUSEHOLD_CACHE_KEY);
  }

  saveDependent(details: InviteHouseholdCache): Observable<any> {
    InviteService.preCacheHousehold(details);

    return from(
      this.awsApiWrapperService.post('api-mobile', '/household?type=dependent', {
        body: {
          first_name: details.fName,
          last_name: details.lName,
          last_four_ssn: details.lastFourSSN,
          date_of_birth: details.dateOfBirth,
        },
      })
    );
  }

  verifyDependentEmployee(employeeDetails: InviteHouseholdEmployeeCache): Observable<any> {
    const company = this.companyService.getCompany();

    return from(InviteService.getPreCacheHousehold()).pipe(
      switchMap(dependentCache => {
        const dependent = {
          first_name: dependentCache.fName,
          last_name: dependentCache.lName,
          last_four_ssn: dependentCache.lastFourSSN,
          date_of_birth: dependentCache.dateOfBirth,
          company,
        };
        const employee = {
          primary_info: {
            first_name: employeeDetails.fName,
            last_name: employeeDetails.lName,
            zip_code: employeeDetails.zipCode,
            date_of_birth: employeeDetails.dateOfBirth,
          },
        };
        return from(
          this.awsApiWrapperService.post('api-mobile-noauth', '/client?source=self&type=dependent', {
            body: {
              ...dependent,
              ...employee,
            },
          })
        );
      })
    )
  }

  sendSMS(body: InvitePhoneRequest): Observable<any> {
    return from(this.awsApiWrapperService.post('api-mobile', '/client/invite', { body }));
  }

  sendEmail(body: InviteEmailRequest): Observable<any> {
    return from(this.awsApiWrapperService.post('api-mobile', '/client/invite', { body }));
  }
}
