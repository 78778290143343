import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RoutingStateService } from '@brightside/brightside-ui-services';
import { isTemplateFullScreen, Settings } from '@brightside-web/desktop/data-access/shared';
import {
  MessageBusInternalService,
  MessageBusInternalHubEvent,
} from '@brightside-web/micro/core/message-bus';

import { Observable } from 'rxjs';

export class SharedDisplayWaitingSettings extends Settings {
  constructor(config: any = {}) {
    super(config);

    this.pageSecondaryCtaEvent = config.pageSecondaryCtaEvent || null;
    this.triggerWaitingStatusCheck = config.triggerWaitingStatusCheck || null;
  }

  pageSecondaryCtaEvent: MessageBusInternalHubEvent;

  triggerWaitingStatusCheck?: (injector: Injector, routingStateService: RoutingStateService) => Observable<boolean>;
}

@Component({
  selector: 'brightside-web-display-waiting',
  templateUrl: './display-waiting.component.html',
})
export class SharedDisplayWaitingComponent implements OnInit {
  config: SharedDisplayWaitingSettings = new SharedDisplayWaitingSettings({
    fbPage: 'page',
    fbCategory: 'consents',

    pageTitle: '',
    pageSubTitle: '',
    pageCtaLabel: 'BUTTON_CONTINUE',
    pageSecondaryCtaLabel: '',
    pageSecondaryCtaEvent: null,

    pageOnForwardCtaPath: [],
  });

  pageIsFullScreen: boolean;

  constructor(
    private injector: Injector,
    private routingState: RoutingStateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.checkForConfigViaData();

    //If we have a method to trigger for status/routing updates, call it. Otherwise set a timer and move forward
    if (this.config.triggerWaitingStatusCheck && typeof this.config.triggerWaitingStatusCheck === 'function') {
      this.config.triggerWaitingStatusCheck(this.injector, this.routingState);
    } else {
      setTimeout(() => this.handleMoveForward(), 500);
    }
  }

  private checkForConfigViaData() {
    if (isTemplateFullScreen(this.activatedRoute)) {
      this.pageIsFullScreen = true;
    }

    if (this.activatedRoute?.snapshot?.data?.config) {
      this.config = new SharedDisplayWaitingSettings(this.activatedRoute.snapshot.data.config);
    }
  }

  handleCloseAction() {
    if (this.config.pageSecondaryCtaEvent) {
      MessageBusInternalService.sendOutgoingHubEvent(this.config.pageSecondaryCtaEvent);
    } else {
      this.routingState.navigateTo(['home']);
    }
  }

  handleMoveForward() {
    const options = { queryParams: { ...this.activatedRoute?.snapshot?.queryParams } };

    if (this.config.pageOnForwardCtaData && Object.keys(this.config.pageOnForwardCtaData || {}).length > 0) {
      options.queryParams = this.config.pageOnForwardCtaData;
    }

    this.routingState.navigateTo(this.config.pageOnForwardCtaPath, options);
  }
}
