<label class="input-group" [ngClass]="[verticalSpace ? verticalSpace : '', horizontalSpace ? horizontalSpace : '']">
  <typography text-small-semibold class="primary-text mb-xs flex-row">
    <span [ngClass]="{'has-error' : inputError}">{{ label }}</span>
    <a *ngIf="hasInfo" (click)="infoClicked()" tabindex="0">
      <typography text-small-regular color="var(--gray-50)" class="ml-xs">
        <ng-content select="[infoCopy]"></ng-content>
      </typography>
    </a>
  </typography>

  <div class="input-field" [ngClass]="{ focused: isFocused }">
    <div *ngIf="prefixSymbol" class="prefix-symbol">
      <typography text-large-regular>{{prefixSymbol}}</typography>
    </div>
    <ng-container *ngIf="isCustomControl; else preformattedControl">
      <ng-content></ng-content>
    </ng-container>
    <!-- NGX-Mask only works with type text, also this would be better handled in the ts file, but blocked by https://github.com/JsDaddy/ngx-mask/issues/365 -->
    <ng-template #preformattedControl>
      <input #elementRef
        *ngIf="(type === 'text' || type === 'password') && inputMask; else noMask"
        [type]="type"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="disabled"
        [mask]="inputMask"
        [attr.required]="required"
        (input)="onChange($event.target.value)"
        [attr.pattern]="pattern"
        [attr.maxlength]="maxlength"
        [attr.minlength]="minlength"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)"
        [bsNumbersOnly]="allowOnlyNumbers"
        autocomplete="new-password"
        class="body"
      />
      <ng-template #noMask>
      <input #elementRef
        [type]="type"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="disabled"
        [attr.required]="required"
        (input)="onChange($event.target.value)"
        [attr.pattern]="pattern"
        [attr.maxlength]="maxlength"
        [attr.minlength]="minlength"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)"
        [bsNumbersOnly]="allowOnlyNumbers"
        autocomplete="new-password"
        class="body"
      />
      </ng-template>
    </ng-template>
    <div class="suffix-icon" (click)="showHidePassword()" *ngIf="isPassword && !showSuffixIcon" [ngClass]="{ revealed: hidePass }">
      <bw-icon [iconName]="suffixIconName" size="24"></bw-icon>
    </div>
    <div class="suffix-icon" *ngIf="showSuffixIcon">
      <bw-icon [iconName]="suffixIconName" size="24"></bw-icon>
    </div>
  </div>

  <div [@enterAnimation]="inputError ? 'show' : 'hide'" class="error">
    <ng-container *ngIf="inputError">{{ inputErrorText }}</ng-container>
  </div>
  <div *ngIf="hint" class="hint">{{ inputHintText }}</div>
</label>
