import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string, mask?:boolean, ...args: any[]): any {
    if (value && value.length >= 10) {
      const normalized= value.replace(/[^0-9]/gi, '');
      const pt1 = normalized.slice(0,3);
      const pt2 = normalized.slice(3,6);
      const pt3 = normalized.slice(6,10);
      if (mask){
        const regex = /[0-9]/gi;
        return `${pt1.replace(regex, 'X')}-${pt2.replace(regex, 'X')}-${pt3}`;
      } else {
        return `${pt1}-${pt2}-${pt3}`;
      }
    }
    return '';
  }

}
