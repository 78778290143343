import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ApiCacheService } from './api-cache.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private apiCache:ApiCacheService) { }

  support(company:string):Observable<string>{
    return from(this.apiCache.get<SupportResponse>('api-mobile-noauth',`/support/v2`)).pipe(
      map((response)=> {
        if (response[company]){
          return response[company];
        } else {
          return response['default'];
        }
      })
    );
  }

}

export interface SupportResponse {
  [key:string]:string;
}
