import { Injectable } from '@angular/core';
import {BsApiService, GenericResponseResult} from "@brightside-web/desktop/data-access/core-services";
import {Observable} from "rxjs";
import {
  FinancialAccountDetails, FinancialAccountDetailsPayload,
  FinancialAccountPayload,
  FinancialAccountTypes,
} from "../models/financial-account-models";



@Injectable({
  providedIn: 'root'
})
export class FinancialAccountsCoreService {

  apiName = 'api-mobile';

  constructor(private bsApi: BsApiService) {}

  getAccountsByType<T>(accountType: FinancialAccountTypes): Observable<(FinancialAccountPayload<T>) & GenericResponseResult> {
    return this.bsApi.get<(FinancialAccountPayload<T>)>(this.apiName, `/accounts/${accountType}`);
  }

  getAccountById<T>(accountType: FinancialAccountTypes, accountId: number): Observable<(FinancialAccountPayload<T>) & GenericResponseResult> {
    return this.bsApi.get<(FinancialAccountPayload<T>)>(this.apiName, `/accounts/${accountType}/${accountId}`);
  }

  getAccountDetails(accountType: FinancialAccountTypes, accountId: number) : Observable<FinancialAccountDetailsPayload & GenericResponseResult> {
    return this.bsApi.get<FinancialAccountDetailsPayload>(this.apiName, `/accounts/${accountType}/${accountId}/details`);
  }

}
