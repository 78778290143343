import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { UiTemplateContent } from '../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-display',
  templateUrl: './display-content.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiContentDisplayComponent {
  @Input() data: UiTemplateContent[];
}
