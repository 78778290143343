import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bw-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent {

  @Input() secondaryStyle = false;
  @Input() showPointer = true;
  @Output() blockAction = new EventEmitter();

  handleBlockAction(event: Event) {
    this.blockAction.emit(event);
  }
}
