<ui-page-transition [reverseExitAnimation]="shouldReverseExitAnimation">
  <bw-action-article page-template [coreTracking]="fromDataTrackingDetail" [minHeight]="0" [edgeToEdge]="true">
    <div id="templateFlyerHeaderAction" header-actions class="flex-row pointer">
      <!--START Navigation Template-->
      <ui-nav-exit-back
        back-action-top
        *ngIf="data.inputs.navType === navType.BACK"
        [data]="fromDataNavigation"
        [skipActionEmit]="fromDataActions[action.navLeftIcon] === undefined"
        (exitActionHandler)="fromDataAction.executeAction(action.navLeftIcon)"
      ></ui-nav-exit-back>

      <ui-nav-exit-close
        back-action-top
        *ngIf="data.inputs.navType === navType.CLOSE"
        [data]="fromDataNavigation"
        [skipActionEmit]="fromDataActions[action.navLeftIcon] === undefined"
        (exitActionHandler)="fromDataAction.executeAction(action.navLeftIcon)"
      ></ui-nav-exit-close>
      <!--END Navigation Template-->
    </div>

    <div *ngIf="fromDataMedia" id="templateFlyerHeroImage" pre-title>
      <ui-media [data]="fromDataMedia"></ui-media>
    </div>

    <!--START Header Template-->
    <ui-header-default
      header-title
      *ngIf="fromDataHeader && fromDataHeader.type === 'default'"
      id="templateFlyerHeaderTitle"
      [title]="fromDataHeader.title"
      [subTitle]="fromDataHeader.subTitle || ''"
    ></ui-header-default>

    <ui-header-eyebrow
      header-title
      *ngIf="fromDataHeader && fromDataHeader.type === 'eyeBrow'"
      id="templateFlyerHeaderTitle"
      [title]="fromDataHeader.title"
      [subTitle]="fromDataHeader.subTitle || ''"
    ></ui-header-eyebrow>

    <ui-header-menu
      header-title
      *ngIf="fromDataHeader && fromDataHeader.type === 'menu'"
      id="templateFlyerHeaderTitle"
      [title]="fromDataHeader.title"
      [menu]="fromDataHeader.menu || {}"
      [menuState]="state.withKey$(stateKeys.HEADER_MENU) | async"
      (menuItemUpdateStateWithHandler)="setUpdateStateWith($event)"
      (menuItemActionHandler)="fromDataAction.executeAction(action.headerMenuItem)"
    ></ui-header-menu>
    <!--END Header Template-->

    <ui-content-display id="templateFlyerContentSection" content-section [data]="fromDataContent">
      <ng-content custom-content-section select="[custom-content-section]"></ng-content>
    </ui-content-display>

    <!--START Footer Top Template-->
    <div id="templateFlyerFooterTop" footer-top>
      <a
        *ngIf="fromDataFooter.inputs.link"
        bw-anchor
        color="var(--secondary-text)"
        anchorStyle="text-medium-regular"
        [coreTracking]="{ event_name: fromDataTrackingDetail.event_name + '_footer_link' }"
        [anchorUnderline]="true"
        (click)="fromDataAction.executeAction(action.footerLink)"
      >
        {{ fromDataFooter.inputs.link | translate }}
      </a>

      <bw-checkbox
        *ngIf="fromDataFooter.inputs.confirmBox"
        (checkboxChanged)="setUpdateStateForKey(stateKeys.FOOTER_CHECKED, $event)"
      >
        <typography text-medium color="var(--primary-text)">
          {{ fromDataFooter.inputs.confirmBox | translate }}
        </typography>
      </bw-checkbox>
    </div>
    <!--END Footer Top Template-->

    <ui-footer-default
      *ngIf="fromDataFooter"
      footer-actions
      id="templateFlyerFooterActions"
      class="flex-row row-reverse"
      [data]="fromDataFooter"
      [cta1State]="state.withKey$(stateKeys.CTA_1) | async"
      [cta2State]="state.withKey$(stateKeys.CTA_2) | async"
      (cta1ActionHandler)="fromDataAction.executeAction(action.cta1)"
      (cta2ActionHandler)="fromDataAction.executeAction(action.cta2)"
    ></ui-footer-default>
  </bw-action-article>
</ui-page-transition>

<ng-container *ngIf="fromDataActiveModal && fromDataActiveModal.data">
  <ng-container
    *ngTemplateOutlet="
      contentModal;
      context: { modalDetails: fromDataActiveModal, $implicit: logPrefix, $implicit: allowVerboseDebugMode }
    "
  ></ng-container>
</ng-container>

<!--START Modal Template-->
<ng-template #contentModal let-modalDetails="modalDetails" let-logPrefix let-allowVerboseDebugMode>
  <bw-modal class="flyer-modal">
    <section class="body">
      <ui-page-half-sheet
        [data]="modalDetails.data"
        [state]="state"
        [logPrefix]="'modal:' + modalDetails.modalId"
        [allowVerboseDebugMode]="allowVerboseDebugMode"
      ></ui-page-half-sheet>
    </section>
  </bw-modal>
</ng-template>
<!--END Modal Template-->
