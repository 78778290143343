import { Component, OnInit, OnDestroy, Injector, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

import { RoutingStateService, ToastService } from '@brightside/brightside-ui-services';

import { DisplayFormComponent } from '@brightside-web/shared/desktop';
import {
  HubPayload, IsRunningAndroid, IsRunningIOS,
  MessageBusEventChannel, MessageBusEventUtil,
  MessageBusHubListener,
  MessageBusInternalEventKey,
  MessageBusInternalHubEvent,
  MessageBusInternalService,
  MessageBusOutgoingEventKey,
} from '@brightside-web/micro/core/message-bus';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  SharedDisplayFormControlConfig,
  SharedDisplayFormControlDisplayRule,
  SharedDisplayFormSettings,
} from './shared-display-form.modal';

/**
 * @description
 * Component should be used to display a data configured form to the client. Support for
 * basic form usage (like DisplayFormComponent) but also supports cell displays.
 *
 * @extends DisplayFormComponent
 *
 * @usageNotes
 * To utilize this component inside a route you need to be providing a class SharedDisplayFormSettings
 * under the data part of the route. Key for this data should be: pageConfig
 *
 * @example
 * {
 *   path: 'testRoutePath',
 *   component: SharedDisplayFormComponent,
 *   data: {
 *     breadcrumb: { skip: true },
 *     pageConfig: {} //new SharedDisplayFormSettings()
 *   },
 * }
 */
@Component({
  selector: 'brightside-web-display-form',
  styleUrls: ['./shared-display-form.component.scss'],
  templateUrl: './shared-display-form.component.html',
})
export class SharedDisplayFormComponent extends DisplayFormComponent implements OnInit, OnDestroy {
  private hubWatchers: MessageBusHubListener[] = [];
  private sub = new Subscription();

  //Overriding here because it has a different type
  override config: SharedDisplayFormSettings;
  sharedFormControls: SharedDisplayFormControlConfig[];

  addKeyboardSpacer = false;

  showAdditionalInformation = false;
  //Is driven by events through the bridge to show it from whatever app needs it
  showLanguageSelect = false;

  displayRuleControlConfigs: SharedDisplayFormControlConfig[];
  displayRuleMapping: { show: string[]; hide: string[] } = { show: [], hide: [] };

  displayRuleCheckDebounces: number;

  //This is used to show a modal in place of the half sheet bridge event native uses
  halfSheetEventData: MessageBusInternalHubEvent;

  isFormValid = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected injector: Injector,
    protected routingState: RoutingStateService,
    protected toastService: ToastService,
    protected translatePipe: TranslatePipe,
    protected translateService: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(activatedRoute, injector, routingState, toastService, translatePipe, translateService, changeDetectorRef);

    this.formAutomaticCreateMode = false;

    this.setUpHalfSheetListener();
  }

  ngOnInit(): void {
    super.ngOnInit();

    //Specific config update for this component
    this.updateSharedConfig();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.hubWatchers.forEach((hubWatcher: MessageBusHubListener) => hubWatcher?.destroy());
    this.sub.unsubscribe();
  }

  private extractControlsWithDisplayRules() {
    this.displayRuleControlConfigs = this.sharedFormControls.filter((config: SharedDisplayFormControlConfig) => config.rules);
  }

  private setUpFormWatcher() {
    this.sub.add(
      this.displayForm.valueChanges.subscribe((latestValues: { [key: string]: string | number }) => {
        this.isFormValid = false;

        const formReadyToCheck = this.displayForm.valid;

        if (formReadyToCheck && this.config.checkPageFormForceAsValid) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const forceValid = this.config.checkPageFormForceAsValid();
          this.isFormValid = (this.displayForm.valid && forceValid);
        } else {
          this.isFormValid = formReadyToCheck;
        }
        //If there are no rules to check... leave
        if (!this.displayRuleControlConfigs) {
          console.log('No form config rules to validate');
          return;
        }

        clearTimeout(this.displayRuleCheckDebounces);
        this.displayRuleCheckDebounces = window.setTimeout(() => {
          this.checkControlsAgainstDisplayRules(latestValues);
          this.cleanControlsAgainstDisplayRules();
        }, 100);
      })
    );
  }

  /**
   * This watcher will wait for events to show halfsheet
   * and display a modal as needed
   */
  private setUpHalfSheetListener() {
    this.hubWatchers.push(
      MessageBusInternalService.addHubListenerWithEventFilter({
        channel: MessageBusEventChannel.INTERNAL,
        filterByEvents: [MessageBusOutgoingEventKey.SHOW_HALFSHEET],
        callbackListener: (payload: HubPayload) => {
          console.log('Halfsheet', payload);

          this.halfSheetEventData = payload as MessageBusInternalHubEvent;
        },
      })
    );
  }

  private setupStaticFormControls(addCheckboxControl: boolean) {
    this.sharedFormControls = this.config.pageFormControls;

    this.buildFormFromConfig();
    this.clearForm();

    this.setUpFormWatcher();
    this.extractControlsWithDisplayRules();
    this.checkControlsAgainstDisplayRules({});
    this.cleanControlsAgainstDisplayRules();

    //Check to see if we should be adding keyboard spacer (more than 2 editable form elements)
    this.addKeyboardSpacer =
      this.displayRuleControlConfigs.filter((formControlConfig: SharedDisplayFormControlConfig) => !formControlConfig.cellDetails)
        .length > 2;

    if (addCheckboxControl) this.insertCheckboxControl();
  }

  private setupAsyncFormControls(addCheckboxControl: boolean) {
    this.config.pageFormControlsObservable?.pipe(take(1)).subscribe(
      (formControls: SharedDisplayFormControlConfig[]) => {
        this.config.pageFormControls = formControls;

        this.setupStaticFormControls(addCheckboxControl);
      },
      () => {
        //ToDo: handle fatal errors
      }
    );
  }

  private insertCheckboxControl() {
    this.displayForm.addControl('controlConfirmCheckbox', new FormControl(false, [Validators.required, Validators.requiredTrue]));
  }

  private updateSharedConfig() {
    if (this.activatedRoute?.snapshot?.data?.pageConfig) {
      this.config = new SharedDisplayFormSettings(this.activatedRoute.snapshot.data.pageConfig);
      if (this.config.pageFormForceAsValid) {
        this.isFormValid = true;
      }
      if (this.config.pageFormControlsObservable) {
        this.setupAsyncFormControls(Boolean(this.config.pageConfirmCheckText));
      } else {
        this.setupStaticFormControls(Boolean(this.config.pageConfirmCheckText));
      }
    }
  }

  /**
   * Build the display rules for the input keys showIfTrue or hideIfTrue inside the config.rules
   * Example would be something like:
   *
   * rules: {
   *  display: {
   *    showIfTrue: [{ formKey: 'is_earning_income', values: [true] }],
   *  },
   * },
   */
  private checkControlsAgainstDisplayRules(latestValues: { [key: string]: string | number }) {
    //Reset the values so we can populate them after processing
    this.displayRuleMapping.show = [];
    this.displayRuleMapping.hide = [];

    this.displayRuleControlConfigs.forEach((config: SharedDisplayFormControlConfig) => {
      config.rules?.display.showIfTrue?.forEach((rule: SharedDisplayFormControlDisplayRule) => {
        const latestVal = latestValues[rule.formKey];
        if (typeof latestVal === 'object' && rule.objKey) {
          if (latestVal && latestVal[rule.objKey] && rule.values.includes(latestVal[rule.objKey])) {
            this.displayRuleMapping.show.push(config.key);
          } else {
            this.displayRuleMapping.hide.push(config.key);
          }
        } else if (latestVal && rule.values.includes(latestVal)) {
          this.displayRuleMapping.show.push(config.key);
        } else {
          this.displayRuleMapping.hide.push(config.key);
        }
      });

      config.rules?.display.hideIfTrue?.forEach((rule: SharedDisplayFormControlDisplayRule) => {
        const latestVal = latestValues[rule.formKey];
        if (typeof latestVal === 'object' && rule.objKey) {
          if (latestVal && latestVal[rule.objKey] && rule.values.includes(latestVal[rule.objKey])) {
            this.displayRuleMapping.hide.push(config.key);
          } else {
            this.displayRuleMapping.show.push(config.key);
          }
        } else if (latestValues[rule.formKey] && rule.values.includes(latestValues[rule.formKey])) {
          this.displayRuleMapping.hide.push(config.key);
        } else {
          this.displayRuleMapping.show.push(config.key);
        }
      });
    });
  }

  /**
   * This method will remove any entered values on inputs that are hidden based on the
   * config.rules provided. Array for process is displayRuleMapping.hide
   */
  private cleanControlsAgainstDisplayRules() {
    this.displayRuleMapping.hide.forEach((controlKey: string) => {
      const control = this.displayForm.get(controlKey);

      if (control) {
        if (control.value !== undefined && control.value !== '') {
          control.patchValue('', { emitEvent: true });
        }
      }
    });
  }

  protected override handleMoveBackward(): void {
    if (this.config.pageOnBackCtaEvent) {
      MessageBusInternalService.sendOutgoingHubEvent(this.config.pageOnBackCtaEvent);
    } else {
      this.routingState.popAndNavigateTo(this.config.pageOnBackCtaPath || [], { relativeTo: this.activatedRoute });
    }
  }

  protected override handleMoveForward() {
    if (this.config.bridgeExitOverride && (IsRunningIOS() || IsRunningAndroid())) {
      MessageBusInternalService.sendOutgoingHubEvent(
        {
          event: MessageBusOutgoingEventKey.ROUTE,
            data: {
              path: this.config.bridgeExitOverride
            },
        }, 500
      );
    } else {
      this.routingState.popAndNavigateTo(this.config.pageOnForwardCtaPath || [], {relativeTo: this.activatedRoute});
    }
  }

  /**
   * This method is used to correctly update the category value from the onChange callback
   * @returns Function for the onChange, only argument updatedTotal: number
   */
  generateWrappedFunctionValueUpdate(controlKey: string): Function {
    return (updatedTotal: number) => {
      this.displayForm.controls[controlKey].setValue(updatedTotal);
    };
  }

  override handleSecondaryCtaClick() {
    if (this.config.processSecondaryEvent) {
      this.config
        .processSecondaryEvent()
        .pipe(take(1))
        .subscribe((route: string[]) => {
          if (route && route.length > 0) {
            this.routingState.popAndNavigateTo(route, { relativeTo: this.activatedRoute });
          }
        });
    } else if (this.config.pageSecondaryCtaEvent) {
      MessageBusInternalService.sendInternalHubEvent(this.config.pageSecondaryCtaEvent);
    }
  }

  handleFormCellClick(controlKey: string) {
    if (this.config.cellClicked) {
      this.config.cellClicked(this.displayForm);
    }

    this.routingState.navigateTo(this.config.getCellRedirectRoute(controlKey), { relativeTo: this.activatedRoute });
  }

  handleFormActionCellClick(control: SharedDisplayFormControlConfig) {
    if (this.config.cellClicked) {
      //Note: We don't access the form file input directly here to keep the control generic
      //and with the caller.
      this.config.cellClicked(this.displayForm, control);
    }

    //If the cell config has an action event attached, trigger it
    if (control.cellDetails?.action) {
      if (control.cellDetails.action.event) {
        MessageBusInternalService.sendOutgoingHubEvent(control.cellDetails?.action.event);
        return;
      } else if (control.cellDetails.action.redirectPath) {
        //If the control itself has a redirect, use this
        this.routingState.navigateTo(control.cellDetails.action.redirectPath.split('/'), { relativeTo: this.activatedRoute });
        return;
      }
    }

    //If the config has a general pageCellRedirectPath redirect to basic handler
    if (this.config.pageCellRedirectPath && this.config.pageCellRedirectPath.length > 0) {
      this.handleFormCellClick(control.key);
      return;
    }

    if (!this.config.cellClicked && !control.cellDetails?.action?.event && !this.config.pageCellRedirectPath) {
      console.warn(
        'You have not provided a way for this cell to react to a client interaction event... please add a cellClicked method or cellDetails?.action.event to do something with this action.'
      );
    }
  }

  handleLanguageSelectChange(option: { [key: string]: string }) {
    MessageBusInternalService.sendInternalHubEvent({
      event: MessageBusInternalEventKey.CHANGED_LANGUAGE,
      data: option,
    });

    this.showLanguageSelect = false;
  }

}
