import {AfterViewInit, Directive, EventEmitter, OnDestroy, Output} from '@angular/core';
import { FirebaseService } from "@brightside-web/desktop/data-access/shared";

@Directive({
  selector: '[brightsideWebTimeout]',
})
export class TimeoutDirective implements AfterViewInit, OnDestroy {
  @Output() timedOut: EventEmitter<any> = new EventEmitter<any>();

  timeout: number;
  remoteConfigAngularTimeout: string;
  timeoutInMS: number;

  constructor(private analytics: FirebaseService) {
    this.remoteConfigAngularTimeout = this.analytics.getValueAsString('apiTimeout');
    this.timeoutInMS = Number(this.remoteConfigAngularTimeout) ? Number(this.remoteConfigAngularTimeout) * 1000 : 0 ;
  }

  ngAfterViewInit() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.analytics.logEvent(`loading_timed_out`);
      this.timedOut.emit({timedOut: true});
    }, this.timeoutInMS);
  };

  ngOnDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  }

}

