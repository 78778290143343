import { UiTemplateFlyerInterface } from '../flyer/flyer-page-template.component';
import { UiTemplateFormFlyerInterface } from '../form-flyer/form-flyer-page-template.component';

/**
 * Use this when utilizing a flyer template to ensure you are defining
 * the data structure correctly and able to see what's available
 *
 * @param data <UiTemplateFlyerInterface>
 * @returns data UiTemplateFlyerInterface
 */
const UiTemplateFlyerDataBuilder = (data: UiTemplateFlyerInterface): UiTemplateFlyerInterface => data;

/**
 * Use this when utilizing a flyer template to ensure you are defining
 * the data structure correctly and able to see what's available
 *
 * @param data <UiTemplateFormFlyerInterface>
 * @returns data UiTemplateFormFlyerInterface
 */
const UiTemplateFormFlyerDataBuilder = (data: UiTemplateFormFlyerInterface): UiTemplateFormFlyerInterface => data;

export { UiTemplateFlyerDataBuilder, UiTemplateFormFlyerDataBuilder };
