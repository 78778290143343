import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[bsNumbersOnly]',
})
export class NumbersOnlyDirective {
  @Input() bsNumbersOnly: boolean;
  @Input() allowDecimals: boolean;

  private readonly regexNumbers = /^[0-9]*$/;
  private readonly regexDecimalNumbers = /^\d*\.?\d+$/;

  constructor() {}

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (!this.bsNumbersOnly) {
      return;
    }
    // Allow only to paste numeric values
    event.preventDefault();
    const pastedInput: string = event.clipboardData?.getData('text/plain') || '';
    const regex = this.allowDecimals ? this.regexDecimalNumbers : this.regexNumbers;

    if (regex.test(pastedInput)) {
      document.execCommand('insertText', false, pastedInput);
    }
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    if (!this.bsNumbersOnly) {
      return;
    }
    // Allow only to drop numeric values
    event.preventDefault();
    const textData: string = event.dataTransfer?.getData('text') || '';
    const regex = this.allowDecimals ? this.regexDecimalNumbers : this.regexNumbers;
    if (regex.test(textData)) {
      (event.target as HTMLInputElement).focus();
      document.execCommand('insertText', false, textData);
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (!this.bsNumbersOnly) {
      return;
    }

    const keyPressed = event.key.toLowerCase();
    const regex = this.allowDecimals ? this.regexDecimalNumbers : this.regexNumbers;
    // Allow: Delete, Backspace, Tab, Escape, Enter
    if (
      ['delete', 'backspace', 'tab', 'escape', 'enter', 'arrowleft', 'arrowright'].indexOf(keyPressed) !== -1 ||
      // Allow: Ctrl+A
      (keyPressed === 'a' && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (keyPressed === 'c' && event.ctrlKey === true) ||
      // Allow: Ctrl+V
      (keyPressed === 'v' && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (keyPressed === 'x' && event.ctrlKey === true)
    ) {
      // let it happen, don't do anything
      return;
    }

    if (this.allowDecimals) {
      if (['decimal point', 'period', 'comma', '.', ','].indexOf(keyPressed) !== -1) {
        return;
      }
    }
    if (regex.test(event.key)) {
      return;
    }

    event.preventDefault();
  }
}
