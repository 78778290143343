import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import {
  FirebaseService,
  AnalyticsAttributesV4
} from '@brightside-web/desktop/data-access/shared';
import { Environment } from '@brightside-web/micro/core/environment';

const isV4 = (object:any): object is AnalyticsAttributesV4 => object && 'event_name' in object;

@Directive({
  selector: '[brightsideWebFb]',
})
export class FbDirective implements AfterViewInit {

  @Input('brightsideWebFb') attributes: AnalyticsAttributesV4;
  @Input() secondaryCta = false;
  @Input() dismissedCta = false;
  @Input() error_view = false;

  tagName: string;
  dontLog: boolean;

  constructor(
    private analytics: FirebaseService,
    private el: ElementRef,
    private env: Environment
  ) {

  }

  @HostListener('click', ['$event']) @HostListener('keyup.enter', ['$event']) onClick(event: KeyboardEvent) {
    if (!this.dontLog) {
      if (this.isDismissButton()) {
        this.analytics.logEvent(`${this.attributes.event_name} dismissed`, this.attributes.custom);
      } else if (this.isClickable()) {
        const eventAction = this.secondaryCta ? 'secondary tapped' : 'tapped'
        this.analytics.logEvent(`${this.attributes.event_name} ${eventAction}`, this.attributes.custom);
      }
    }
  }

  cleanEventName() {
    const eventNameArray = this.attributes.event_name.toLowerCase().split('_');
    for (let i = eventNameArray.length - 1; i >= 0; i--) {
      if (eventNameArray[i] === "dismissed" || eventNameArray[i] === "shown" || eventNameArray[i] === "tapped" || eventNameArray[i] === "dismiss") {
        eventNameArray.splice(i, 1);
      }
    }
    this.attributes.event_name = eventNameArray.join(' ');
  }

  cleanCustomParams() {
    try {
      const customParams = JSON.parse(JSON.stringify(this.attributes.custom).toLowerCase().split('_').join(' '));
      this.attributes.custom = customParams;
    } catch (e) {
      // parsing probably failed, so do nothing and pass the params as-is
    }
  }

  ngOnInit() {
    if (
      !this.attributes.event_name
      || !this.attributes.event_name.trim()
      || !this.el.nativeElement
      || !this.el.nativeElement.tagName) {
      this.dontLog = true;
      // if (!this.env.production) {
      //   console.groupCollapsed('FbDirective don\'t log')
      //   console.log(this.attributes);
      //   console.log(this.el);
      //   console.groupEnd();
      // }
    }
  }

  ngAfterViewInit(): void {
    if (!this.dontLog) {
      this.tagName = this.el.nativeElement.tagName.toLowerCase();

      if (this.attributes.custom) {
        this.cleanCustomParams();
      }

      if (this.isErrorView()) {
        this.analytics.logEvent(`${this.attributes.event_name}`, this.attributes.custom);
      } else if (!this.isClickable() && !this.isDismissButton()) {
        this.cleanEventName();
        this.analytics.logEvent(`${this.attributes.event_name} shown`, this.attributes.custom);
      } else {
        this.cleanEventName();
      }
    }
  }

  isErrorView(): boolean {
    return this.error_view || this.attributes.event_name === 'error shown' || this.attributes.event_name === 'error_shown';
  }

  isClickable(): boolean {
    return this.tagName === 'button' || this.tagName === 'a';
  }

  isDismissButton(): boolean {
    return this.tagName === 'bw-back' || this.dismissedCta;
  }

  isInputElement(tagName: string): boolean {
    return tagName === 'INPUT' || tagName === 'BW-INPUT' || tagName === 'BW-CREATE-PASSWORD' || tagName === 'BW-PHONE-FORM';
  }
}
