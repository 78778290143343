import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[bwScrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterViewInit {
  @Input() shouldScroll = false;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.shouldScroll) this.el?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
  }
}
