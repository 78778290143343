import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of} from 'rxjs';
import { FirebaseService } from '@brightside-web/desktop/data-access/shared';
import {DeviceDetectorService, DeviceInfo, OS} from "ngx-device-detector";


@Injectable({
  providedIn: 'root',
})
export class ReviewAppGuard implements CanActivate {
  deviceInfo: DeviceInfo;

  constructor(
    private analytics: FirebaseService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    setTimeout(()=>{
      if (this.deviceInfo.os === OS.ANDROID) {
        document.location.href = 'https://play.google.com/store/apps/details?id=com.androidapp.gobrightside.mobile';
      } else if (this.deviceInfo.os === OS.IOS) {
        document.location.href = 'https://apps.apple.com/app/id1250624924?action=write-review';
      } else {
        this.router.navigate(['/']);
      }
    }, 250);
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.analytics.logEvent('review app redirect shown');
    return of(true);
  }
}
