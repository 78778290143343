import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Subscription} from "rxjs";
import {
  EligibilityFieldsObject,
  FirebaseService, ProdEligReqFieldsInterface,
  ProductEligibilityService,
  nonRequiredProductFields
} from "@brightside-web/desktop/data-access/shared";
import {ActivatedRoute, Router} from "@angular/router";
import {
  CONTROL_ADDRESS_CITY,
  CONTROL_ADDRESS_STATE,
  CONTROL_DOB,
  CONTROL_FNAME,
  CONTROL_LNAME,
  CONTROL_MNAME,
  CONTROL_SALARY,
  CONTROL_SSN,
  CONTROL_STREET_ADDRESS_LINE_ONE,
  CONTROL_STREET_ADDRESS_LINE_TWO,
  CONTROL_ZIP,
  DisplayFormControlConfig,
} from "@brightside-web/shared/desktop";
import {FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import {RegistrationService} from "@brightside-web/desktop/data-access/onboarding";
import {RoutingStateService} from "@brightside/brightside-ui-services";
import {ngxMaskOptions} from "@brightside/brightside-ui";
import {CurrencyMaskInputMode} from "ngx-currency";

interface RequiredFormsInterface {
  name: string;
  controlConfig: ConfigEligType[];
  itemOrder: FormItemOrderEnum;
  completed?: boolean;
  submitted?: boolean;
  titleKey?: string;
  display?: boolean;
}

interface PageCopyInterface {
  full_ssn: PageCopyContentInterface;
  full_name: PageCopyContentInterface;
  date_of_birth: PageCopyContentInterface;
  auto_address: PageCopyContentInterface;
  self_reported_salary: PageCopyContentInterface;
}

interface PageCopyContentInterface {
  titleKey: string;
  bodyKey: string;
  eventName: string;
}

enum FormItemOrderEnum {
  full_ssn = 1,
  full_name = 2,
  date_of_birth = 3,
  auto_address = 4,
  self_reported_salary = 5
}

enum TitleKeyEnum {
  full_ssn = 'SOCIAL_SECURITY_NUMBER',
  full_name = 'FULL_NAME',
  date_of_birth = 'DATE_OF_BIRTH',
  auto_address = 'HOME_ADDRESS',
  self_reported_salary = 'ANNUAL_INCOME'
}

export type ConfigEligType = DisplayFormControlConfig & EligibilityFieldsObject;
@Component({
  selector: 'brightside-web-product-eligibility',
  templateUrl: './product-eligibility.component.html',
  styleUrls: ['./product-eligibility.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ProductEligibilityComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ProductEligibilityComponent
    }
  ]
})
export class ProductEligibilityComponent implements OnInit {

  @Input() productCode: string;
  @Input() showBackbutton = false;
  @Input() ignoreFields: string[] = [];
  @Input() hideDefaults = false;
  @Input() minHeight = 576;
  @Output() successfulSubmit: EventEmitter<string> = new EventEmitter<string>();
  currencyOptions = {
    ...ngxMaskOptions,
  }
  loading = true;

  sub = new Subscription();

  eligibilityFields: unknown[] = [];

  EligibilityToControls : { [key: string]: DisplayFormControlConfig }= {
    date_of_birth: CONTROL_DOB,
    full_ssn: CONTROL_SSN,
    address1: CONTROL_STREET_ADDRESS_LINE_ONE,
    address2: CONTROL_STREET_ADDRESS_LINE_TWO,
    city: CONTROL_ADDRESS_CITY,
    state: CONTROL_ADDRESS_STATE,
    zip_code: CONTROL_ZIP,
    first_name: CONTROL_FNAME,
    middle_name: CONTROL_MNAME,
    last_name: CONTROL_LNAME,
    self_reported_salary: CONTROL_SALARY
  };

  activeFieldName: string;

  pageCopy: PageCopyInterface = {
    full_ssn: {
      titleKey: 'SOCIAL_SECURITY_NUMBER',
      bodyKey: 'VERIFY_IDENTITY_DESC',
      eventName: 'social security collect'
    },
    full_name: {
      titleKey: 'FULL_NAME',
      bodyKey: 'FULL_NAME_COLLECT_DESC',
      eventName: 'full name collect'
    },
    date_of_birth: {
      titleKey: 'DATE_OF_BIRTH',
      bodyKey: 'DATE_OF_BIRTH_DESC',
      eventName: 'date of birth collect'
    },
    auto_address: {
      titleKey: 'HOME_ADDRESS',
      bodyKey: 'ADDRESS_SELECT_DESC',
      eventName: 'home address collect'
    },
    self_reported_salary: {
      titleKey: 'VERIFY_IDENTITY_SALARY_TITLE',
      bodyKey: '',
      eventName: 'self reported salary collect'
    }
  }

  requiredForms : RequiredFormsInterface[] = [];
  fieldsForDisplay : RequiredFormsInterface[];
  formControls: ConfigEligType[] = [];
  eligibilityFormControls: FormControl[] = [];
  eligibilityForm: FormGroup;

  showErrorModal: boolean;
  disableSubmit: boolean;
  formIsReady: boolean;
  processing = false;
  showIntro = false;
  showSummary: boolean;
  goingBackCalled = false;
  activeFormIndex = -1;
  numberOfFormsToDisplay = 0;
  hasAlreadyCompleted: boolean;

  processError =  {
    errorId: 'identity',
    errorTitleKey: 'PROCESS_ERROR_TITLE',
    errorDescKey: 'ERROR_GENERAL_DESC',
    ctaKey: 'OK'
  }

  ssnMismatchError = {
    errorId: 'ssn mismatch',
    errorTitleKey: 'ERROR_SSN_MISMATCH_TITLE',
    errorDescKey: 'ERROR_SSN_MISMATCH_DESC',
    ctaKey: 'TRY_AGAIN'
  }

  displayedError = this.processError;

  confirmDataForm: FormGroup = new FormGroup({
    confirmCheckbox: new FormControl('', Validators.requiredTrue)
  });

  submissionObject: { [key: string]: string } = {}
  private displayedMissingForm: boolean;

  constructor(
    private prodElgSvc: ProductEligibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private analytics: FirebaseService,
    private clientSvc: RegistrationService,
    private routingState:RoutingStateService) {}

  ngOnInit(): void {
    if (!this.productCode) {
      // we're assuming this is being used directly in a route
      this.sub.add(
        this.route.params.subscribe(
          params => {
            this.title.setTitle('Brightside | Identity Verification');
            this.productCode = params['product'];
            if (this.productCode) this.getProductEligibilityRequirements();
          }
        )
      );
    } else {
      this.getProductEligibilityRequirements();
    }

    this.currencyOptions = {
      ...ngxMaskOptions,
      precision: 2,
      prefix: '$',
      allowNegative: false,
      align: 'left',
      nullable: true,
      inputMode: CurrencyMaskInputMode.NATURAL, //Use '.FINANCIAL' for cash register type entry
    }

  }

  getProductEligibilityRequirements() {
    this.sub.add(
      this.prodElgSvc.getProductEligibility().subscribe(
        response => {
          if (response.products) {
            const productFields = response.products.find(product => product.name.toLowerCase() === this.productCode.toLowerCase());
            if (productFields) {
              const reqFields = productFields.required_fields;
              reqFields?.forEach(field => {
                this.handleRequiredFormCreation(field);
              });

              this.requiredForms = this.requiredForms.sort((a, b) => {
                if (a.itemOrder < b.itemOrder) {
                  return -1;
                }
                if (a.itemOrder > b.itemOrder) {
                  return 1;
                }
                return 0;
              });
              // summary is still reliant on the above finishing before display
              if (this.numberOfFormsToDisplay === 0){
                this.hasAlreadyCompleted = true;
                this.displaySummary();
              }
              this.setUpSubmissionForm();
            } else {
              this.router.navigate(['/']);
            }
          }
        }
      )
    )
  }

  handleRequiredFormCreation(field: ProdEligReqFieldsInterface) : void {
    const configArray: ConfigEligType[] = [];
    let tallyOfFieldsToDisplay = 0;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const itemOrderEnum = FormItemOrderEnum[field.name];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const titleKey = TitleKeyEnum[field.name];
    const reqFieldObj = {
      name: field.name,
      controlConfig: configArray,
      itemOrder: itemOrderEnum,
      titleKey: titleKey
    };

    if (field.sub_fields) {
      field.sub_fields.forEach(subField => {
        const subFieldControl = this.EligibilityToControls[subField.name];
        if (subFieldControl && !subField.not_replayable) {
          reqFieldObj.controlConfig.push(Object.assign(subFieldControl, subField));
          if (this.prodElgSvc.testReqValueForEmpty(subField)) {
            tallyOfFieldsToDisplay++;
          }
        }
      })
    } else if (field.api_parameter_name) {
      const control = this.EligibilityToControls[field.name];
      if (control && !field.not_replayable) {
        reqFieldObj.controlConfig.push(Object.assign(control, field));
        if (this.prodElgSvc.testReqValueForEmpty(field)) {
          tallyOfFieldsToDisplay++;
        }
      }
    }

    if (reqFieldObj.controlConfig.length !== 0) {
      const newReqField = reqFieldObj as RequiredFormsInterface;
      if (!this.ignoreFields.includes(newReqField.name)) {
        newReqField['display'] = tallyOfFieldsToDisplay > 0 ? true : false;
        this.numberOfFormsToDisplay = tallyOfFieldsToDisplay > 0 ? this.numberOfFormsToDisplay + 1 : this.numberOfFormsToDisplay;
        this.requiredForms.push(newReqField);
      }
    }
  }

  showForm(field: RequiredFormsInterface, isSummary = false) {
    if (isSummary) {
      this.activeFormIndex = this.requiredForms.length + 1;
      this.showSummary = false;
    }
    this.activeFieldName = field.name;
    this.formControls = field.controlConfig;
    this.setUpForm();
  }

  setUpSubmissionForm() {
    this.requiredForms.forEach( (control, index) => {
      control.controlConfig.forEach( controlConfig => {
        const startingValue = controlConfig.value ? controlConfig.value : '';
        this.confirmDataForm.addControl(controlConfig.key, controlConfig.control);
        this.confirmDataForm.get(controlConfig.key)?.setValue(startingValue);
      });
      if (this.numberOfFormsToDisplay > 0 && control.display && !this.displayedMissingForm) {
        // display the first missing fields form
        this.displayedMissingForm = true;
        this.activeFormIndex = index;
        this.showForm(this.requiredForms[index]);
      }
    });
  }

  setUpForm() {

    const controls: { [key: string]: FormControl } = {};

    if (this.formControls) {
      const formCopy = [...this.formControls];
      formCopy.forEach((controlConfig: DisplayFormControlConfig, index) => {
        controls[controlConfig.key] = controlConfig.control;
        if (formCopy[index].value !== 'middle') {
          controls[controlConfig.key].setValue(formCopy[index].value);
        } else {
          controls[controlConfig.key].setValue('');
        }
      });

      this.eligibilityForm = new FormGroup(controls);

      this.formIsReady = true;
      this.loading = false;
    }
  }

  // todo: add support for info icon
  displayInfo(controlConfig: DisplayFormControlConfig) {
    if (controlConfig.inputInfo) {
      // this.displayModal = controlConfig.inputInfo;
      // this.showInfoModal = true;
    }
  }

  updateInfo() {
    for (const field of this.formControls.values()) {
        field.value = field.control.value;
        const subFormControl = this.confirmDataForm.get(field.key);
        if (subFormControl) {
          subFormControl.setValue(field.value);
        }
    }
    this.stepThroughForms(1);
  }

  submitInfo() {
    this.analytics.logEvent('verify identity tapped')
    this.submissionObject = {};
    this.processing = true;

    this.requiredForms.forEach( field => {
      field.controlConfig.forEach( control => {
        if (control.api_parameter_name === 'self_reported_salary' && control.value) {
          this.submissionObject[control.api_parameter_name] = control.value.toString();
        } else {
          this.submissionObject[control.api_parameter_name] = control.value ? control.value : '';
        }
      });
    });

    this.sub.add(
      this.clientSvc.submitClientUpdate(this.submissionObject).subscribe(
        response => {
          if (response.statusCode === 200) {
            this.successfulSubmit.emit(this.prodElgSvc.getProductCodeRedirect(this.productCode));
          } else {
            this.displayedError = this.processError;
            this.showErrorModal = true;
          }
        },
        error => {
          if (error.response.data.error.code === 'SSN_MISMATCH') {
            this.displayedError = this.ssnMismatchError;
          } else {
            this.displayedError = this.processError;
          }
          this.showErrorModal = true;
        }
      )
    )
  }

  closeErrorModal() {
    this.showErrorModal = false;
    this.processing = false;
  }

  stepThroughForms(direction: -1 | 1) {
    this.activeFormIndex = this.activeFormIndex + direction;
    if (this.activeFormIndex === -1) {
      this.routingState.goBack();
    } else if (this.activeFormIndex >= 0 && this.activeFormIndex < this.requiredForms.length) {
      const form = this.requiredForms[this.activeFormIndex];
      if (form.display) {
        this.showIntro = false;
        this.showSummary = false;
        this.showForm(this.requiredForms[this.activeFormIndex]);
      } else {
        this.stepThroughForms(direction);
      }
    } else if (this.activeFormIndex >= this.requiredForms.length) {
      this.displaySummary();
    }
  }

  displaySummary() {
    this.activeFormIndex = this.requiredForms.length;
    this.showIntro = false;
    this.formIsReady = false;
    this.showSummary = true;
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  handleMainBack() {
    if (this.routingState.history.includes('/onboarding')) {
      this.router.navigate(['/']);
    } else {
      this.routingState.goBack();
    }
  }
}
