import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnalyticsAttributesV4, FirebaseService} from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-credit-modal',
  templateUrl: './credit-modal.component.html',
})
export class CreditModalComponent {
  @Input() visible = false;
  @Input() analyticsData: AnalyticsAttributesV4;

  @Output() results: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private analytics: FirebaseService) {
  }

  handleClick(boolToSend: boolean) {
    if (!boolToSend) this.analytics.logEvent('credit_details_dismissed');
    this.results.emit(boolToSend);
  }
  getFBInfo(extra: { [key: string]: string } = {}) {
    return {
      ...this.analyticsData,
      ...extra,
    };
  }
}
