// TODO: KEN CHECK THIS
/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import {
  ActivationEnd,
  ActivationStart,
  ChildActivationEnd,
  ChildActivationStart,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RoutesRecognized,
  Scroll,
  Event
} from '@angular/router';

import { BsHubService } from '@brightside-web/desktop/data-access/core-services';
import { HubCapsule } from 'aws-amplify/utils';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  MessageBusEventChannel,
  MessageBusEventUtil,
  MessageBusInternalEventKey,
  MessageBusInternalHubEvent,
  MessageBusInternalService,
  UseThisForEmbeddedOrThat,
} from '@micro-core/message-bus';
import { MicroUtilDebugLogger, RemoteConfigDomain } from '@micro-core/utility';
import {EventDataMap} from "@aws-amplify/core/src/Hub/types/HubTypes";

declare global {
  interface Window {
    gTag: Function;
  }
}

interface TempRoutingDetail {
  routeURL: string;
  skipThesePaths: (string | null)[];
}

interface LayoutDetail {
  style: LayoutStyle;
  lastPathKey: string;
  mapping: { [key: string]: LayoutStyle };
}

export type RouteEvent =
  | NavigationStart
  | NavigationEnd
  | NavigationCancel
  | RouteConfigLoadStart
  | RouteConfigLoadEnd
  | RoutesRecognized
  | GuardsCheckStart
  | GuardsCheckEnd
  | ActivationStart
  | ActivationEnd
  | ChildActivationStart
  | ChildActivationEnd
  | ResolveStart
  | ResolveEnd
  | Scroll;

export enum EVENT_METHOD_NAME {
  UnKnown = 'onUnknown',
  NavigationStart = 'onNavigationStart',
  NavigationEnd = 'onNavigationEnd',
  NavigationCancel = 'onNavigationCancel',
  RouteConfigLoadStart = 'onRouteConfigLoadStart',
  RouteConfigLoadEnd = 'onRouteConfigLoadEnd',
  RoutesRecognized = 'onRoutesRecognized',
  GuardsCheckStart = 'onGuardsCheckStart',
  GuardsCheckEnd = 'onGuardsCheckEnd',
  ActivationStart = 'onActivationStart',
  ActivationEnd = 'onActivationEnd',
  ChildActivationStart = 'onChildActivationStart',
  ChildActivationEnd = 'onChildActivationEnd',
  ResolveStart = 'onResolveStart',
  ResolveEnd = 'onResolveEnd',
  Scroll = 'onScroll',
}

export enum LayoutStyle {
  DEFAULT = 'default',
  FULL_SCREEN = 'fullScreen',
  PUBLIC_ACCESS = 'publicAccess',
}

export enum RoutingServiceDispatchEvent {
  EXIT_PAGE = 'EXIT_PAGE',
  LAYOUT_CHANGE = 'LAYOUT_CHANGE',
  ROUTE_BY_URL = 'ROUTE_BY_URL',
}

class RemoteConfigDomainRuleHandler extends MicroUtilDebugLogger {
  private _remoteConfigDomainRules: RemoteConfigDomain[] = [];
  private _ignoreRemoteDomainRules: boolean = UseThisForEmbeddedOrThat(true, false);

  constructor(
    private router: Router,
    private remoteConfigDomainRules: RemoteConfigDomain[]) {
    super();

    this._remoteConfigDomainRules = remoteConfigDomainRules;

    this.allowVerboseDebugMode = true;
    this.logPrefix = 'remoteDomainHandler';
    this.logForDebugging('Current remote domain rules', this._remoteConfigDomainRules);
  }

  private sendOriginalEvent(originalEvent: MessageBusInternalHubEvent) {
    //If there was an original event, we know we can handle. If there is
    //nothing then we just need to assume exiting is needed.
    if (originalEvent && originalEvent?.data) {
      originalEvent.data.forceBridgeFire = true;

      //Doing this allows the native applications to pick it up as needed
      MessageBusInternalService.sendOutgoingHubEvent(originalEvent);
    } else {
      this.routeInPlace('');
    }
  }

  private routeInPlace(routeToPath: string) {
    this.logForDebugging('Used angular routing navigateByUrl', { with: routeToPath });
    this.router.navigateByUrl(routeToPath);
  }

  private replaceFullHref(replaceHref: string) {
    this.logForDebugging('Replaced full location', { with: replaceHref });

    window.location.replace(replaceHref);
  }

  /**
   * Right now this method will do a simple check for exact matches or wild card
   * matches.
   *
   * Iterating the array will be stopped when a match is found, so first match will be sent back to caller
   * even if there are other matches.
   *
   * @param routeToPath <string> - The value of the route you are trying to go to
   * @returns
   * 1 - null = Means there was no matching information in the remote config.
   * 2 - RemoteConfigDomain = Means this is the first thing that matches your path.
   */
  private checkForMatchingRule(routeToPath: string): null | RemoteConfigDomain {
    let matchingRule: null | RemoteConfigDomain = null;

    const noMatchesFound = this._remoteConfigDomainRules.every((rule: RemoteConfigDomain) => {
      matchingRule = rule;

      //If there is not a DOMAIN - return true by default
      if (!rule.domain) {
        return true;
      }

      if (rule.regex.includes('.*') && routeToPath.startsWith(rule.regex.replace('.*', ''))) {
        return false;
      }

      return true;
    });

    //If we don't have matches return null
    if (noMatchesFound) {
      return null;
    }

    return matchingRule;
  }

  /**
   * This method should be called when you want to navigate checking for matching route paths
   * in the remote config file. Outcomes:
   *
   * 1 - Angular route done with navigateByUrl - Means the route was internal to the current application
   * 2 - window location replace used - Means the route was matched in remote config and needs to change base domain/app
   * 3 - Angular route sent to empty path - Means no rule was found and no original event was sent
   * 4 - Outgoing message sent to message bus - Means no rule was found BUT there is an original event. This usual means we are trying to navigate
   * to a route that is fully native and outside webviews
   *
   * @param routeToPath  <string> - The value of the route you are trying to go to
   * @param originalEvent <MessageBusInternalHubEvent> - This should be a value if the message bus converted to internal route before sending to native
   * @returns void
   */
  public handleRouteByUrlEvent(routeToPath: string, originalEvent: MessageBusInternalHubEvent) {
    if (this._ignoreRemoteDomainRules) {
      this.logForDebugging('The flag told us to ignore remote domain rules, so we are navigating in-place');
      this.routeInPlace(routeToPath);
      return;
    }

    const ruleToUse = this.checkForMatchingRule(routeToPath);
    const isMatchingRuleToUse = ruleToUse && ruleToUse.domain.startsWith(window.location.origin);
    const isMatchingCurrentBasePath = !ruleToUse && window.location.href.split('/')[0] === routeToPath.split('/')[0];

    this.logForDebugging('Rule Located', { ruleToUse, isMatchingRuleToUse, isMatchingCurrentBasePath });
    /**
     * If we have match with current route or rule make sure we route in-place.
     * isMatchingRuleToUse or isMatchingCurrentBasePath
     */
    if (isMatchingRuleToUse || isMatchingCurrentBasePath) {
      this.routeInPlace(routeToPath);
    } else if (!ruleToUse) {
      this.sendOriginalEvent(originalEvent);
    } else {
      this.replaceFullHref(`${ruleToUse.domain}${routeToPath}`);
    }
  }
}

class NavigationHelperHandler extends MicroUtilDebugLogger {
  private _historyStack: string[] = [];
  private _lastNavigationDetail = { fromUrl: '', toUrl: '', startTime: '', completeTime: '' };
  private _lastNavigationEventLog: { eventName: EVENT_METHOD_NAME; detail: Event }[] = [];

  private _StartTrackingEvents: EVENT_METHOD_NAME[] = [EVENT_METHOD_NAME.NavigationStart];
  private _StopTrackingEvents: EVENT_METHOD_NAME[] = [EVENT_METHOD_NAME.NavigationEnd, EVENT_METHOD_NAME.NavigationCancel];

  constructor(private _router: Router) {
    super();

    this.allowVerboseDebugMode = true;
    this.logPrefix = 'Navigation History';
  }

  /**
   * This will return the last url in the stack or "" meaning nothing is present
   */
  private get historyCurrentUrl() {
    return this._historyStack[this._historyStack.length - 1] || '';
  }

  /**
   * Be aware this WILL adjust the navigation stack. Please don't use it for logging
   * or any non-action function.
   *
   * Will pop the last two entries to get current page and the previous
   */
  private get historyPreviousPath() {
    const currentUrl: string = this._historyStack.pop() || '';
    const previousUrl: string = this._historyStack.pop() || '';

    this.logForDebugging('getPreviousPath', { currentUrl, previousUrl });

    return previousUrl.replace(window.location.origin, '');
  }
  //TODO is there a cleaner way to identify paths related to modals/iframes that should not go on the stack?
  private pathsExcludedFromHistory = ["external-accounts/savings/launch", "modal:"];
  private addHistory(url: string) {
    if (url !== this.historyCurrentUrl && !this.pathsExcludedFromHistory.some((path)=>url.includes(path))) {
      this._historyStack.push(url);
      this.logForDebugging(`Adding to history stack: ${url}`, this._historyStack);
    }
  }

  private routeInPlace(routeToPath: string) {
    this.logForDebugging('Used angular routing navigateByUrl', { with: routeToPath });
    this._router.navigateByUrl(routeToPath);
  }

  private replaceFullHref(replaceHref: string) {
    this.logForDebugging('Replaced full location', { with: replaceHref });

    window.location.replace(replaceHref);
  }

  private startSingleNavigationTrack() {
    this._lastNavigationDetail = {
      fromUrl: window.location.href,
      toUrl: '',
      startTime: new Date().toISOString(),
      completeTime: '',
    };
    this._lastNavigationEventLog = [];
  }

  /**
   * Stops the single navigation and finishes the details for debugging.
   * It will also normalize the history stack in-case navigation happened
   * outside the default handlers
   */
  private stopSingleNavigationTrack() {
    this.addHistory(window.location.href);

    this._lastNavigationDetail.toUrl = window.location.href;
    this._lastNavigationDetail.completeTime = new Date().toISOString();

    //This part is to normalize the back history in-case routing was done outside hub
    if (this._lastNavigationDetail.toUrl === this._historyStack[this._historyStack.length - 2]) {
      this._historyStack.pop();
      this.logForDebugging(`Adjusted history stack to pop off one`);
    }

    this.logForDebugging('navigation logs', { details: this._lastNavigationDetail, eventLog: this._lastNavigationEventLog });
  }

  /**
   * Logging this information to help debug if you are seeing issues.
   *
   * This will also look to start/stop tracking based on certain events
   *
   * @param eventName <EVENT_METHOD_NAME>
   * @param detail <RouteEvent>
   */
  public addEvent(eventName: EVENT_METHOD_NAME, detail: Event) {
    if (this._StartTrackingEvents.includes(eventName)) {
      this.startSingleNavigationTrack();
    } else if (this._StopTrackingEvents.includes(eventName)) {
      this.stopSingleNavigationTrack();
    }

    this._lastNavigationEventLog.push({ eventName, detail });
  }

  public routeBackwardOnce() {
    this.routeInPlace(this.historyPreviousPath);
  }
}

@Injectable()
export class RoutingService extends MicroUtilDebugLogger {
  static readonly DISPATCH_KEY = 'RoutingServiceChannel';

  protected googleTrackingId = '';
  protected isProduction = false;

  protected executeAllOnEvent: Function[] = [];

  protected lastEvent: Event;
  protected lastLayout: LayoutDetail = {
    style: LayoutStyle.DEFAULT,
    lastPathKey: '',
    mapping: {},
  };

  // This is used to make sure we don't send unneeded layout events
  protected lastBroadcastedLayoutStyle: LayoutStyle | null = null;

  protected remoteConfigDomainRuleHandler: RemoteConfigDomainRuleHandler;
  protected navigationHelperHandler: NavigationHelperHandler;

  protected tempRouteDetails: TempRoutingDetail = {
    routeURL: '',
    skipThesePaths: [null, '', 'registration'],
  };

  protected layoutLifeCycleEvents = {
    [EVENT_METHOD_NAME.ActivationStart]: (event: ActivationStart) => {
      const path = this.tempRouteDetails.routeURL;

      this.consoleLog('Path target at activation start', path);

      if (this.tempRouteDetails.skipThesePaths.includes(path)) {
        return;
      }

      if (event.snapshot?.data?.appDisplayStyle) {
        this.lastLayout.mapping[path] = event.snapshot?.data?.appDisplayStyle || LayoutStyle.DEFAULT;

        this.runLayoutCheck(this.lastLayout.mapping[path]);
      }
    },
    [EVENT_METHOD_NAME.ChildActivationStart]: (event: ChildActivationStart) => {
      const path = this.tempRouteDetails.routeURL;

      if (this.tempRouteDetails.skipThesePaths.includes(path)) {
        return;
      }

      //First check snapshot has data fallback towards array lookup
      if (event.snapshot.data?.appDisplayStyle) {
        this.lastLayout.mapping[path] = event.snapshot.data.appDisplayStyle;
        this.runLayoutCheck(this.lastLayout.mapping[path]);
      } else {
        this.lastLayout.lastPathKey = path;
        this.lastLayout.style =
          this.lastLayout.mapping[this.lastLayout.lastPathKey] || this.lastLayout.style || LayoutStyle.DEFAULT;
      }
    },
    [EVENT_METHOD_NAME.ResolveEnd]: (event: ResolveEnd) => {
      this.checkAndDispatchLayoutChange();
    },
    [EVENT_METHOD_NAME.ActivationEnd]: (
      event: ActivationEnd | ChildActivationEnd,
      styleFromSnapshotOverride?: LayoutStyle | null
    ) => {
      const styleFromSnapshot = styleFromSnapshotOverride || event.snapshot?.firstChild?.data?.appDisplayStyle;
      const styleFromPath = this.lastLayout.mapping[this.tempRouteDetails.routeURL];

      const styleArrays = [styleFromSnapshot, styleFromPath, this.lastLayout.style];
      const theOneTrueStyle = styleArrays.filter((style) => style && style !== 'default')[0] || 'default';

      //If we have anything left in the styles we should use it
      if (theOneTrueStyle) {
        this.lastLayout.style = theOneTrueStyle;
      }

      this.checkAndDispatchLayoutChange();
    },
    // Child activation should just flow to activation end with styleOverride from snapshot
    [EVENT_METHOD_NAME.ChildActivationEnd]: (event: ChildActivationEnd) =>
      this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ActivationEnd](event, event.snapshot.data?.appDisplayStyle || null),
  };

  constructor(private router: Router, isProduction: boolean = false, googleTrackingId: string = '') {
    super();

    this.allowVerboseDebugMode = false;
    this.logPrefix = 'RoutingService - ';

    this.isProduction = isProduction;
    this.googleTrackingId = googleTrackingId;

    this.navigationHelperHandler = new NavigationHelperHandler(router);

    this.watchForRoutingEvents();
    this.watchForRemoteConfigLoadedEvent();
    this.listenForRouteByUrl();
  }

  protected consoleLog(...rest: any) {
    if (!this.isProduction) {
      this.logForDebugging({ ...rest });
    }
  }

  protected getMethodOrNoop(methodName: EVENT_METHOD_NAME = EVENT_METHOD_NAME.UnKnown) {
    if (RoutingService.prototype[methodName]) {
      return this[methodName].bind(this);
    }

    return () => false;
  }

  protected getEventMethod(event: Event): EVENT_METHOD_NAME {
    this.lastEvent = event;

    switch (true) {
      case event instanceof NavigationStart:
        return EVENT_METHOD_NAME.NavigationStart;
      case event instanceof NavigationEnd:
        return EVENT_METHOD_NAME.NavigationEnd;
      case event instanceof NavigationCancel:
        return EVENT_METHOD_NAME.NavigationCancel;
      case event instanceof RouteConfigLoadStart:
        return EVENT_METHOD_NAME.RouteConfigLoadStart;
      case event instanceof RouteConfigLoadEnd:
        return EVENT_METHOD_NAME.RouteConfigLoadEnd;
      case event instanceof RoutesRecognized:
        return EVENT_METHOD_NAME.RoutesRecognized;
      case event instanceof GuardsCheckStart:
        return EVENT_METHOD_NAME.GuardsCheckStart;
      case event instanceof GuardsCheckEnd:
        return EVENT_METHOD_NAME.GuardsCheckEnd;
      case event instanceof ActivationStart:
        return EVENT_METHOD_NAME.ActivationStart;
      case event instanceof ActivationEnd:
        return EVENT_METHOD_NAME.ActivationEnd;
      case event instanceof ChildActivationStart:
        return EVENT_METHOD_NAME.ChildActivationStart;
      case event instanceof ChildActivationEnd:
        return EVENT_METHOD_NAME.ChildActivationEnd;
      case event instanceof ResolveStart:
        return EVENT_METHOD_NAME.ResolveStart;
      case event instanceof ResolveEnd:
        return EVENT_METHOD_NAME.ResolveEnd;
      case event instanceof Scroll:
        return EVENT_METHOD_NAME.Scroll;

      default:
        return EVENT_METHOD_NAME.UnKnown;
    }
  }

  protected runAllOnEvents() {
    if (!this.executeAllOnEvent) {
      return;
    }

    this.executeAllOnEvent.forEach((fn) => fn());
  }

  protected runLayoutCheck(checkThisPath: string) {}

  protected watchForRemoteConfigLoadedEvent() {
    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INTERNAL,
      filterByEvents: [MessageBusInternalEventKey.DOMAIN_MAPPING_LOADED],
      take: 1,
      callbackListener: (payload: MessageBusInternalHubEvent | EventDataMap) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (payload.data?.rules && !this.remoteConfigDomainRuleHandler) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.remoteConfigDomainRuleHandler = new RemoteConfigDomainRuleHandler(this.router, payload.data.rules);
        }
      },
    });
  }

  protected watchForRoutingEvents() {
    this.router.events.subscribe((event) => {
      const routeMethod = this.getEventMethod(event);

      this.navigationHelperHandler.addEvent(routeMethod, event);

      this.getMethodOrNoop(routeMethod)(this.lastEvent as any);
    });
  }

  protected checkAndDispatchLayoutChange() {
    this.dispatchLayoutChange();
  }

  protected resetLastLayout() {
    //We need to reset the layout stuff after dispatch
    this.lastLayout.style = LayoutStyle.DEFAULT;
    this.lastLayout.lastPathKey = '';
  }

  protected updateLastBroadcastedAndTimer() {
    this.lastBroadcastedLayoutStyle = this.lastLayout.style;

    //Clear this.lastBroadcastedLayoutStyle so events that happen on further events still trigger
    setTimeout(() => {
      this.lastBroadcastedLayoutStyle = null;
    }, 500);
  }

  protected listenForRouteByUrl() {
    BsHubService.listenStatic(RoutingService.DISPATCH_KEY, (data: HubCapsule<any, any>) => {
      this.consoleLog(`event on channel ${RoutingService.DISPATCH_KEY} with payload of`, data.payload);

      if (data.payload.event === RoutingServiceDispatchEvent.ROUTE_BY_URL && data.payload.data) {
        this.routeByUrl(data);
      } else if (data.payload.event === RoutingServiceDispatchEvent.EXIT_PAGE) {
        this.routeExitPage();
      }
    });
  }

  protected routeExitPage() {
    this.navigationHelperHandler.routeBackwardOnce();
  }

  protected routeByUrl(data: HubCapsule<any, any>, retryAttempt = 0) {
    //We need to have the remote config loaded to move forward. If we
    //have also retried twice already we should allow default navigation
    if (!this.remoteConfigDomainRuleHandler) {
      this.consoleLog('Missing remoteConfigDomainRuleHandler retry attempt ' + retryAttempt);

      if (retryAttempt >= 2) {
        this.router.navigateByUrl(data.payload.data.routeToUrl || '');
      } else {
        setTimeout(() => {
          this.routeByUrl(data, retryAttempt + 1);
        }, 250);
      }

      return;
    }

    this.consoleLog('We have remoteConfigDomainRuleHandler', data);

    //If we have remote domain rules, handle it here
    this.remoteConfigDomainRuleHandler.handleRouteByUrlEvent(
      data.payload.data.routeToUrl || '',
      data.payload.data?.originalEvent
    );
  }

  protected dispatchLayoutChange() {
    if (this.lastBroadcastedLayoutStyle === this.lastLayout.style) {
      return;
    }

    BsHubService.dispatchStatic(RoutingService.DISPATCH_KEY, {
      event: RoutingServiceDispatchEvent.LAYOUT_CHANGE,
      message: this.lastLayout.style || LayoutStyle.DEFAULT,
    });

    this.consoleLog('Layout Change', this.lastLayout.style);
    this.updateLastBroadcastedAndTimer();
    this.resetLastLayout();
  }

  addOnEventFunction(fn: Function) {
    this.executeAllOnEvent.push(fn);
  }

  [EVENT_METHOD_NAME.UnKnown](event: RouteEvent) {
    this.consoleLog('Unknown routing event occurred', this.lastEvent, event);
  }

  [EVENT_METHOD_NAME.NavigationStart](event: NavigationStart) {
    this.runAllOnEvents();
  }
  [EVENT_METHOD_NAME.NavigationEnd](event: NavigationEnd) {
    if ((window as any).gTag) {
      window.gTag('config', this.googleTrackingId, {
        page_path: event.urlAfterRedirects,
      });
    }
    console.log(`FS ROUTING EVENT: ${EVENT_METHOD_NAME.NavigationEnd} called with ${JSON.stringify(event)}`);
    MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.hideLoadingSpinner, 100);
    MessageBusInternalService.sendInternalHubEvent({
      event: MessageBusInternalEventKey.NAVIGATION_END,
      data: {},
    });
  }
  [EVENT_METHOD_NAME.NavigationCancel](event: NavigationCancel) {
    this.dispatchLayoutChange();
  }
  [EVENT_METHOD_NAME.RouteConfigLoadStart](event: RouteConfigLoadStart) {
    this.tempRouteDetails.routeURL = event.route.path || '';
  }
  [EVENT_METHOD_NAME.RouteConfigLoadEnd](event: RouteConfigLoadEnd) {}
  [EVENT_METHOD_NAME.RoutesRecognized](event: RoutesRecognized) {}
  [EVENT_METHOD_NAME.GuardsCheckStart](event: GuardsCheckStart) {}
  [EVENT_METHOD_NAME.GuardsCheckEnd](event: GuardsCheckEnd) {
    this.consoleLog('GuardCheckEnd', event);
  }
  [EVENT_METHOD_NAME.ActivationStart](event: ActivationStart) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ActivationStart](event);
  }
  [EVENT_METHOD_NAME.ActivationEnd](event: ActivationEnd) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ActivationEnd](event);
  }
  [EVENT_METHOD_NAME.ChildActivationStart](event: ChildActivationStart) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ChildActivationStart](event);
  }
  [EVENT_METHOD_NAME.ChildActivationEnd](event: ChildActivationEnd) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ChildActivationEnd](event);
  }
  [EVENT_METHOD_NAME.ResolveStart](event: ResolveStart) {}
  [EVENT_METHOD_NAME.ResolveEnd](event: ResolveEnd) {
    this.layoutLifeCycleEvents[EVENT_METHOD_NAME.ResolveEnd](event);
  }
  [EVENT_METHOD_NAME.Scroll](event: Scroll) {}
}
