<form [formGroup]="!fGroup ? phoneForm : fGroup">
  <bw-input
    *ngIf="isProd"
    [autoFocus]="autoFocus"
    [label]="label"
    [placeholder]="placeholder"
    [formControlName]="fcName"
    [disabled]='disabled'
    type="text"
    inputMask="000-000-0000"
    pattern="\d{3}-\d{3}-\d{4}"
    minlength="12"
    maxlength="12"
    [inputError]="inputError"
    [inputErrorText]="inputErrorText"
  >
  </bw-input>
  <bw-input
    *ngIf="!isProd"
    [autoFocus]="autoFocus"
    [label]="label"
    [placeholder]="placeholder"
    [formControlName]="fcName"
    [disabled]='disabled'
    type="text"
    inputMask="000-000-0000"
    minlength="3"
    maxlength="12"
    [inputError]="inputError"
    [inputErrorText]="inputErrorText"
  >
  </bw-input>
</form>
