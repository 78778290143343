import * as deepEqual from 'fast-deep-equal';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { DeepMerge } from '@micro-core/utility';

import { StateSubject } from './state-subject';

import { DeepPartial, UiTemplatePageStateCondition } from './state.interface';

export class Store<T extends object> {
  private unsubscriber: Subject<void>;
  private state: StateSubject<T>;

  get isReady() {
    return Boolean(this.state);
  }

  initialize(state: T, unsubscriber: Subject<void>): void {
    this.state = new StateSubject(state);
    this.unsubscriber = unsubscriber;
  }

  isStateEqualWith(checkWithState: DeepPartial<T>) {
    if (!this.isReady) {
      console.warn('State <isStateEqualWith> NOT READY');
      return false;
    }

    const currentState = this.getState();
    const newPossibleState = DeepMerge(checkWithState, currentState);

    return deepEqual(currentState, newPossibleState);
  }

  setState(value: DeepPartial<T>, conditions?: UiTemplatePageStateCondition[]): void {
    if (!this.isReady) {
      console.warn('State <setState> NOT READY');
      return;
    }

    let preparedNewState = DeepMerge(this.state.value, value);

    if (conditions) {
      const pendingUpdatedKeys = Object.keys(value);

      conditions.forEach((conditionCheck: UiTemplatePageStateCondition) => {
        const hasTriggerMatch = conditionCheck.triggeringKeys.filter((key) => pendingUpdatedKeys.includes(key)).length > 0;

        if (hasTriggerMatch) {
          preparedNewState = conditionCheck.manualConditionRunner(preparedNewState);
        }
      });
    }

    this.state.next(preparedNewState);
  }

  getState(): T | Record<string, never> {
    if (!this.isReady) {
      console.warn('State <getState> NOT READY');
      return {};
    }

    return this.state.value;
  }

  withKey$<K extends keyof T>(key: K): Observable<T[K]> | undefined {
    if (this.isReady) {
      return this.state.value$.pipe(
        map((state) => state[key]),
        distinctUntilChanged((a, b) => deepEqual(a, b)),
        takeUntil(this.unsubscriber)
      );
    }

    return undefined;
  }

  reset(): void {
    if (!this.isReady) {
      console.warn('State <reset> NOT READY');
      return;
    }

    this.state.reset();
  }
}
