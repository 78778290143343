import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Toast, ToastType, ToastDelay } from './toast.model';

interface Debounce {
  message: string;
  toasted: number;
}

// slightly longer than the actual delay.
const toastFadeDelay = ToastDelay * 1.1;

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private subject = new Subject<Toast>();
  private defaultId = 'default-toast';
  private toasts: Toast[] = [];

  private debouncing: Debounce[] = [];

  onShowToast(id = this.defaultId): Observable<Toast> {
    return this.subject.asObservable();
  }

  // green
  success(message: string, options?: any) {
    this.toast(new Toast({ ...options, type: ToastType.Success, message }));
  }

  // black
  info(message: string, options?: any) {
    this.toast(new Toast({ ...options, type: ToastType.Info, message }));
  }

  // blue
  infoBlue(message: string, options?: any) {
    this.toast(new Toast({ ...options, type: ToastType.InfoBlue, message }));
  }

  // yellow
  warn(message: string, options?: any) {
    this.toast(new Toast({ ...options, type: ToastType.Warning, message }));
  }

  // red
  error(message: string, options?: any) {
    this.toast(new Toast({ ...options, type: ToastType.Error, message }));
  }

  delete(id: string) {
    this.toasts = this.toasts.filter((oldtoast) => oldtoast.id !== id);
    this.subject.next({ id, message: 'delete', type: ToastType.Success });
  }

  deleteAll() {
    this.toasts.forEach((toast: Toast) => {
      this.subject.next({ id: toast.id, message: 'delete', type: ToastType.Success });
    });
    this.toasts = [];
  }

  /**
   * is there another transient still showing, if so, don't show a new one.
   * @param msg the string to show.
   */
  shouldDebounce(msg: string): boolean {
    const found = this.debouncing.find(({ message, toasted }) =>
       message === msg && toasted > Date.now() - toastFadeDelay
    );

    return found !== undefined;
  }

  /**
   * remove old toasts that are left over.
   */
  pruneDebouncing(): void {
    const removePriorTo = Date.now() - toastFadeDelay;
    this.debouncing = this.debouncing.filter(({ toasted }) => toasted > removePriorTo);
  }

  toast(toast: Toast) {
    toast.id = toast.id || this.defaultId;
    this.pruneDebouncing();

    //these fade after 3 seconds, see ToastDelay constant.
    if (toast.transient) {
      if (this.shouldDebounce(toast.message)) {
        return;
      }
      this.debouncing.push({ message: toast.message, toasted: Date.now() });

      this.subject.next(toast);
    } else {
      //these stick around till the client taps the "X" or does something else to remove them.
      if (!this.toasts.find((oldtoast) => oldtoast.id === toast.id)) {
        this.toasts.push(toast);
        this.subject.next(toast);
      }
    }
  }
}
