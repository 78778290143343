<bw-action-article [brightsideWebFb]="{ event_name: 'financial_account_creation' }">
  <div content-section>
    <div class="flex-column space-center">
      <bw-spinner></bw-spinner>
      <typography text-medium-semibold>
        {{ 'SENDING_INFORMATION' | translate }}
      </typography>
    </div>
  </div>
</bw-action-article>
