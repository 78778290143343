import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'safeStyle'
})
export class SafeStylePipe implements PipeTransform {
//see https://stackoverflow.com/a/52282888/763648
//we can use this if we want to bind css variable strings to ngStyle expressions
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }

}
