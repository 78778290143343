import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagPipe } from './FeatureFlag/feature-flag.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FeatureFlagPipe,
  ],
  exports: [

  ]
})
export class DesktopDataAccessCoreServicesModule {}
