import { Injectable } from '@angular/core';


const sizmekPixelMap:{[key: string]: string} = {
  "legrand": "1073746533",
  "fivebelow": "1073746534"
};

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  /**
   * determine which company to get a pixel from.
   * @param hostname - just the hostname, no https garbage.
   */
  getSizmekPixelId(hostname:string) : string {
    if ( hostname.match(/.*.gobrightside.com/)) {
      return sizmekPixelMap[hostname.split(".")[0]]
    }
    return "";
  }

  addPixels(document:Document, hostname: string) {
    //just one for now.
    const pixelId = this.getSizmekPixelId(hostname);
    if ( pixelId ) {
      this.addSizmekPixel(document, pixelId);
    }
  }

  addSizmekPixel(document:Document,pixelId:string) {
      console.log(`Adding Pixel for Sizmek...`);
      (function(e, s, t) {
        // @ts-ignore
        // tslint:disable-next-line:no-shadowed-variable
        const versaTagObj = { $: [], onready: function(e) { this.$.push(e) } };
        // @ts-ignore
        // tslint:disable-next-line:no-shadowed-variables
        const n = s.getElementsByTagName("script")[0], r = s.createElement("script"); r.options = t, r.async = !0, r.src = "https://secure-ds.serving-sys.com/SemiCachedScripts/ebOneTag.js?id=" + e, r.options = t, n.parentNode.insertBefore(r, n) })(pixelId, document, { dataLayer:"SizmekDataLayer", id: pixelId });
  }
}
