<div class="tracker">
  <div *ngFor="let step of trackerSteps; index as i; first as isFirst; last as isLast" class="step"
       [ngClass]="{'failed': step.status === 'failed', 'complete': step.status === 'complete', 'current': step.status === 'current', 'first': isFirst, 'last': isLast}">
    <div class="step-icon">
      <bw-icon size="12" iconName="checkmark" color="var(--white)" *ngIf="step.status === 'complete'"></bw-icon>
      <bw-icon size="24" iconName="alert" color="var(--brand)" *ngIf="step.status === 'failed'"></bw-icon>
    </div>
    <div class="step-label text-large-semibold">{{step.label}}</div>
    <div class="step-details secondary-text" *ngIf="step.status === 'current' || step.status === 'failed'">{{step.details}}</div>
  </div>
</div>
