import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UiTemplateContentGroupRadioDisplayStyle } from '../../../shared/model/content-template.enum';
import { UiTemplateContentGroupRadio, UiTemplateFormContentValueChanged } from '../../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-group-radio',
  templateUrl: './group-radio-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTemplateContentGroupRadioComponent {
  @Input() data: UiTemplateContentGroupRadio;

  @Output() formValuesChanged: EventEmitter<UiTemplateFormContentValueChanged> = new EventEmitter();

  DISPLAY_STYLE = UiTemplateContentGroupRadioDisplayStyle;
}
