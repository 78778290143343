import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import {BrightsideUiModule} from "@brightside/brightside-ui";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, BrightsideUiModule, TranslateModule],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
})
export class LanguageSelectionModule {}
