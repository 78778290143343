import { Component, Input } from '@angular/core';

@Component({
  selector: 'brightside-web-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent {
  @Input() items: any;
  @Input() waiting = true;
  @Input() failed = false;
  @Input() actionHeader: any; // expects a translate key
  @Input() actionDescription1: any; // expects a translate key
  @Input() actionDescription2: any; // expects a translate key
  @Input() fbPage: any;
  @Input() fbCategory: any;
  @Input() stepIndex = 0;

  constructor() { }

}
