import { Component, Input } from '@angular/core';

@Component({
  selector: 'bw-bui-tile',
  templateUrl: './bui-tile.component.html',
  styleUrls: ['./bui-tile.component.scss']
})
export class BuiTileComponent {
  @Input() tileWidth: string;
  @Input() tileHeight: string;
  @Input() tileMargin: string;
  @Input() tileBorder: string;
}

@Component({
  selector: 'bui-tile-card',
  template: `
    <div class="tile-card-wrapper">
      <div class="tile-card" [ngStyle]="{'backgroundColor': cardBackground}">
        <ng-content></ng-content>
      </div>
    </div>
   `,
  styleUrls: ['bui-tile.component.scss']
})
export class TileCardComponent {
  @Input() cardBackground: string;
}

@Component({
  selector: 'bui-tile-list-item',
  template: `
    <div class="tile-list-item-wrapper">
      <div class="tile-list-item">
        <ng-content></ng-content>
      </div>
    </div>
   `,
  styleUrls: ['bui-tile.component.scss']
})
export class TileListItemComponent {}
