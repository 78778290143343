import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  DesktopCard,
  DesktopSegmentType,
  MobileStateService,
} from '@brightside-web/desktop/data-access/shared';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';
import { LinkedAccountService } from '@brightside-web/desktop/data-access/account-linking';
import { ToastService } from '@brightside/brightside-ui-services';
import { ExternalSavingsService } from '@brightside-web/desktop/data-access/external-savings'
import { forkJoin } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {BsApiService, FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import { ArrayOfSavingsAccounts } from "@brightside-web/desktop/feature/savings-account";
import {ArrayOfSpendingAccounts} from "../../../../feature/spending-account/src/lib/new-spending-account.service";
import { FinancialAccountPayload } from "@brightside-web/desktop/feature/financial-accounts-core";

@Injectable({
  providedIn: 'root',
})
export class SavingsCardDataService {

  constructor(
    private toastService: ToastService,
    private savingsService: SavingsAccountService,
    private mobileStateService: MobileStateService,
    private currencyPipe: CurrencyPipe,
    private extSavingsService: ExternalSavingsService,
    private translateService: TranslateService,
    private featureFlagSvc: FeatureFlagService,
    private bsApi: BsApiService
  ) {}

  private handleBannerSideAffect(message: string, options?: any) {
    this.toastService.infoBlue(message, options);
  }

  async groomCardData(startingData: DesktopCard): Promise<DesktopCard> {
    return this.helixAccount(startingData);
  }

  async helixAccount(startingData: DesktopCard): Promise<DesktopCard> {
    const savingsAccount = await this.bsApi.get<FinancialAccountPayload<ArrayOfSavingsAccounts>>('api-mobile', `/accounts/savings`).toPromise();
    // @ts-ignore
    if (savingsAccount?.payload?.accounts.length > 0) {
      startingData.segments?.forEach((segment) => {
        if (segment.type === DesktopSegmentType.DEFAULT && savingsAccount) {
            segment.dynamicValue = this.currencyPipe.transform(savingsAccount.payload.accounts[0].availableBalance) || '$0.00';
        }
      });
    }
    return startingData;
  }


  async groomSpendingCard(startingData: DesktopCard): Promise<DesktopCard> {
    return this.helixSpendingAccount(startingData);
  }

  async helixSpendingAccount(startingData: DesktopCard): Promise<DesktopCard> {
    const spendingAccount = await this.bsApi.get<FinancialAccountPayload<ArrayOfSpendingAccounts>>('api-mobile', `/accounts/spending`).toPromise();
    // @ts-ignore
    if (spendingAccount?.payload?.accounts.length > 0) {
      startingData.segments?.forEach((segment) => {
        if (segment.type === DesktopSegmentType.DEFAULT && spendingAccount) {
          segment.dynamicValue = this.currencyPipe.transform(spendingAccount.payload.accounts[0].availableBalance) || '$0.00';

        }
      });
    }
    return startingData;
  }
}
