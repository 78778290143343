import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService {
  history: string[] = [];
  constructor(private router: Router) {}

  public loadRouting(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e: any) => {
      this.history.push(e.urlAfterRedirects);
    });
  }

  public navigateTo(route: string[], options: any = {}) {
    this.router.navigate(route, options);
  };

  public popAndNavigateTo(route: string[], options: any = {}) {
    if (this.history.length > 1) {
      this.history.pop(); // the current page
    }

    this.navigateTo(route, options);
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.history.pop(); // the current page
      const prevPage = this.history.pop() as string;
      this.router.navigateByUrl(prevPage);
    } else {
      this.navigateTo(['/']);
    }
  }
}
