/**
 * example usage in route
 * canActivate: [HasLegalDocsGuard],
 * data: {
 *   pageConfig: {
 *     documentsKey: DocumentsKeyEnum.SAVINGS_ACCOUNT,
 *       legalRoute: ['accounts','savings','legal']
 *   }
 * }
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {from, Observable} from 'rxjs';
import {DocumentsKeyEnum, LegalDocumentsService} from "@brightside-web/shared/legal-documents";
import {map} from "rxjs/operators";

@Injectable()
export class HasLegalDocsGuard implements CanActivate {
  constructor(
    protected legalDocumentsService: LegalDocumentsService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const documentsKey = route.data?.pageConfig.documentsKey;
    const legalRoute = route.data?.pageConfig.legalRoute;
    if (!documentsKey || !legalRoute) {
      console.warn('this guard requires a documentsKey and legalRoute in the data snapshot');
      this.router.navigate(['home']);
      return false;
    } else {
      return this.getDocsForDocKey(documentsKey, legalRoute);
    }
  }

  getDocsForDocKey(docKey: DocumentsKeyEnum, legalRoute: string[]): Observable<boolean> {
    return this.legalDocumentsService.getDocsByKey(docKey).pipe(
      map(
        docs => {
          if (docs.payload?.legalDocuments && docs.payload.legalDocuments.length > 0) {
            from(this.router.navigate( [{outlets: {modal: null}}]))
              .subscribe(()=>{
                this.router.navigate(legalRoute);
              });
            return false;
          } else {
            return true;
          }
        }
      )
    );
  }
}
