export interface SurveySelectorData {
  value: string;
  label?: string;
  title: string;
  description?: string;
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export enum SurveySelectorType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Instant = 'instant',
}