import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { UiMoleculePromoCardData } from './product-promo-card.interface';
import { UiMoleculeMedia } from '../media/media.interface';

import { UiBaseDataMoleculeComponent, UiBaseDataMoleculeSchema } from '../shared/base-data/base-data-molecule.component';

@Component({
  selector: 'ui-product-promo-card',
  templateUrl: './product-promo-card.component.html',
  styleUrls: ['./product-promo-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiProductPromoCardComponent extends UiBaseDataMoleculeComponent implements UiBaseDataMoleculeSchema, OnInit {
  /**
   * Allow developer to pass in data with an object
   *
   * Interface UiMoleculePromoCardData
   */
  @Input() override data: UiMoleculePromoCardData;

  /**
   * Base path can be changed if you know your images are stored someplace else.
   *
   * DEFAULT ../assets/
   */
  @Input() basePath = '../assets/';

  /**
   * Allow developer to pass in data single fields at a time
   */
  @Input() cardTitle: string;
  @Input() title: string;
  @Input() subTitle: string;

  @Input() promoTitle: string;
  @Input() promoMoreInfoTitle: string;
  @Input() promoMedia: UiMoleculeMedia;
  @Input() trackingDetail: { event_name: string };

  @Output() cardTitleActionHandler = new EventEmitter();
  @Output() moreInfoActionHandler = new EventEmitter();

  ngOnInit(): void {
    this.schemaValidationConfig = {
      directInputValues: [],
    };

    super.ngOnInit();
  }

  public override uiOnDirectInputsFromDataObject(): void {
    this.cardTitle = this.data?.inputs?.cardTitle || this.cardTitle || '';
    this.title = this.data?.inputs?.title || this.title || '';
    this.subTitle = this.data?.inputs?.subTitle || this.subTitle || '';

    this.promoTitle = this.data?.inputs?.promo.title || this.promoTitle || '';
    this.promoMoreInfoTitle = this.data?.inputs?.promo.moreInfoTitle || this.promoMoreInfoTitle || '';

    this.trackingDetail = this.data?.inputs?.trackingDetail || this.trackingDetail || { event_name: 'missing_event_name_footer' };

    if (this.data?.inputs?.promo?.media) {
      this.promoMedia = this.data.inputs.promo.media;
    }
  }

  public handleMoreInfoAction(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.moreInfoActionHandler.emit();
  }
}
