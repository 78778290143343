import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoutingStateService } from '@brightside/brightside-ui-services';

@Component({
  selector: 'bw-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent {
  @Output() back = new EventEmitter();
  @Input() emit = false;

  constructor(private routingState:RoutingStateService) { }



  goBack() {
    if (this.emit){
      this.back.emit();
    } else {
      // this allows for directive events to fire
      setTimeout(()=>{
        this.routingState.goBack();
      },10);
    }
  }
}
