import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UiTemplateContentFormType } from '../../shared/model/content-template.enum';
import { UiTemplateContent, UiTemplateFormContentValueChanged } from '../../shared/model/content-template.interface';

@Component({
  selector: 'ui-content-display-form',
  templateUrl: './display-form-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiContentDisplayFormComponent {
  @Input() data: UiTemplateContent[];

  @Output() formValuesChanged: EventEmitter<UiTemplateFormContentValueChanged> = new EventEmitter();

  //Make sure the content types can be used in template
  contentType = UiTemplateContentFormType;
}
