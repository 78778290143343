import { Component, Input } from '@angular/core';

@Component({
  selector: 'bw-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() iconName: string;
  @Input() size = 24;
  @Input() horizontalSpace = 'mr-null';
  @Input() color: string;
  @Input() backgroundColor: string;
  iconBgSize: number;

  ngOnInit() {
    this.iconBgSize = this.backgroundColor ? Number(this.size) + 8 : this.size;
  }

}
