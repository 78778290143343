import {Injectable, OnDestroy} from '@angular/core';
import { Subscription } from "rxjs";

import {
  BsAuthService,
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';
import { CacheItemOptions } from '@aws-amplify/core/src/Cache/types/cache';

@Injectable({
  providedIn: 'root'
})
export class CacheStorageService implements OnDestroy {

  private sub = new Subscription();
  private _guid = '';

  constructor(
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService
  ) {
    this.getGuid();
  }

  getItem(key: string, withoutGuid = false): Promise<any> {
    const cacheKey = withoutGuid ? key : this.buildKey(key);
    return this.bsCacheService.getItem(cacheKey);
  }

  async setItem(key: string, value: any, options?: CacheItemOptions): Promise<void> {
    await this.bsCacheService.setItem(this.buildKey(key), value, options);
  }

  async removeItem(key: string): Promise<void> {
    await this.bsCacheService.removeItem(this.buildKey(key));
  }

  clear(): void {
    this._guid = '';
    this.bsCacheService.clear();
  }

  buildKey(key: string) {
    if (!this._guid) this.getGuid();
    const newKey = !this._guid ? key: `${key}_${this._guid}`;
    return newKey;
  }

  async getGuid(): Promise<void> {
    this._guid = await this.bsCacheService.getItem('GUID');
    if (!this._guid) {
      this.sub.add(
        this.bsAuthService.fetchUserAttributes().subscribe(
          attributes => {
            if (attributes.guid) {
              this._guid = attributes.guid;
              this.bsCacheService.setItem('GUID', this._guid);
            }
          }
        )
      );
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
