import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {MessageBusInternalService} from '@brightside-web/micro/core/message-bus';

import {
  SharedDisplayCellChoiceQuestion,
  SharedDisplayChoiceStyle,
  SharedDisplayFormControlConfig
} from '../shared-display-form.modal';

@Component({
  selector: 'bs-form-choice',
  templateUrl: './form-choice.component.html',
  styleUrls: ['./form-choice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SharedDisplayFormChoiceComponent implements OnInit {
  @Input() controlConfig: SharedDisplayFormControlConfig;
  @Input() formGroup: FormGroup;

  @Output() click: EventEmitter<SharedDisplayFormControlConfig> = new EventEmitter();

  private changesDebounces: number;

  questions: SharedDisplayCellChoiceQuestion[] = [];

  ngOnInit() {
    this.questions = this.controlConfig?.choice?.questions || [];

    //If we have an initial value we need to set it
    if (this.controlConfig?.choice?.questions && this.controlConfig?.inputOptions?.initialValue) {
      clearTimeout(this.changesDebounces);

      this.changesDebounces = window.setTimeout(() => {
        //Make sure we have choice information
        if (this.controlConfig?.choice) {
          this.updateFormOnChange(this.controlConfig.choice.questions[0], `${this.controlConfig?.inputOptions?.initialValue}`);
        }
      }, 10);
    }
  }

  private noopFunction = () => null;

  get actionClickResponder() {
    return this.controlConfig?.choice?.clickResponder || this.noopFunction;
  }

  updateFormOnChange(question: SharedDisplayCellChoiceQuestion, choice: string) {
    let updateWithValue: string | boolean | number = choice;
    let foundMapping = false;
    if (question?.choiceValueMapping && Object.keys(question.choiceValueMapping).includes(choice)) {
      updateWithValue = question.choiceValueMapping[choice];
      foundMapping = true;
    }

    if (question.style && question.style === SharedDisplayChoiceStyle.CHECKBOX ) {
      if (foundMapping) {
        let currentVal = this.formGroup.controls[this.controlConfig.key].value ?? [];
        if (typeof currentVal === 'string') currentVal = currentVal.split(',');
        let storeValue = [];
        if (currentVal.length === 0) {
          storeValue.push(updateWithValue);
        } else {
          storeValue = currentVal;
          const hasValueAlready = storeValue.indexOf(updateWithValue);
          if (hasValueAlready > -1) {
            storeValue.splice(hasValueAlready, 1);
          } else {
            storeValue.push(updateWithValue)
          }
        }
        this.formGroup.controls[this.controlConfig.key].setValue(storeValue, {emitEvent: true});
      } else {
        // below handles preselecting checkbox elements based on the value of the mapping
        // this occurs on refresh/load of the page
        if (question.choiceValueMapping && typeof choice === 'string') {
          Object.keys(question.choiceValueMapping).forEach(item => {
            const choiceVal = question.choiceValueMapping ? question.choiceValueMapping[item].toString() : undefined;
            if (choiceVal && choice.includes(choiceVal)) {
              question.selectedChoices = {...question.selectedChoices, [item]:'selected'};
            }
          })
        }
      }
    } else {
      this.formGroup.controls[this.controlConfig.key].patchValue(updateWithValue, {emitEvent: true});
    }
  }

  handleActionCellClick() {
    this.actionClickResponder(this.formGroup, this.controlConfig);

    //If this action has a special event defined, something like displaying a half-sheet on click
    if (this.controlConfig?.choice?.event) {
      MessageBusInternalService.sendOutgoingHubEvent(this.controlConfig.choice?.event);
      return; //Return is to not prevent any routing the user may have on emit of click
    }

    //Now let the caller handle anything they need to
    this.click.emit(this.controlConfig);
  }
}
