import { Input, Output, EventEmitter, Directive, TemplateRef, ContentChild } from '@angular/core';

@Directive({
  selector: "[accordionContent]"
})
export class AccordionContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'bw-accordion-item'
})
export class AccordionItemDirective {

  @Input() title = "";
  @Input() disabled = false;
  @Output() collapsed = new EventEmitter<boolean>();
  @ContentChild(AccordionContentDirective) content: AccordionContentDirective;

}
