import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { from, map, Observable, of } from 'rxjs';
import {FinancialAccountsCreationService} from "./services/financial-accounts-creation.service";
import { VerificationStatusEnum, VerificationStatusService } from '@brightside-web/desktop/data-access/shared';

@Injectable()
export class FinancialAccountCreationGuard implements CanActivate {

  constructor(
    private faCreateSvc: FinancialAccountsCreationService,
    private router: Router,
    private verificationStatusSvc: VerificationStatusService
    ) {
  }

  /**
   * Don't allow users to access creation steps ahead of their current step
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.verificationStatusSvc.getEmploymentVerificationStatus().pipe(
      map(response=>{
        if (response.employment_data_verification_status.status !== VerificationStatusEnum.VERIFIED) {
          this.router.navigate(['/verification/status']);
          return false;
        } else if (route.data['creationStep']) {
          if (this.faCreateSvc.isStepBeforeCurrent(route.data['creationStep'])) {
            return true;
          }
        }
        this.router.navigate(['home']);
        return false;
      })
    )
  }

}
