import { Component, OnInit, Output, Input, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface ListData {
  displayName: string;
  code: string;
}

@Component({
  selector: 'bw-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit {
  @Input() dataList: ListData[];
  @Input() hideBeforeTyping = true;
  @Input() showProcessSpinner = false;

  @Output() listSelected = new EventEmitter();

  searchForm: FormGroup;
  displayedListItems: ListData[];

  isEmptySearchText = true;

  ngOnInit() {
    this.searchForm = new FormGroup({ inputText: new FormControl('', [Validators.required]) });
    this.displayedListItems = this.dataList;

    this.inputText?.valueChanges.subscribe((newValue) => {
      const fixedSearchValue = newValue.toLowerCase();
      const checkForMatch = (name: string) => name.toLowerCase().indexOf(fixedSearchValue) === 0;

      this.isEmptySearchText = !newValue;
      this.displayedListItems = this.dataList.filter((item) => checkForMatch(item.displayName));
    });
  }

  get inputText() {
    return this.searchForm.get('inputText');
  }

  onCompanyClicked(code: string) {
    console.log(`Company ${code}`);

    this.listSelected.emit(code);
  }
}
