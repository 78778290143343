import { Injectable } from '@angular/core';
import {forkJoin, from, Observable} from 'rxjs';
import { ApiResponse } from './api-contracts';
import {
  AwsApiWrapperService,
  BsCacheService,
} from '@brightside-web/desktop/data-access/core-services';
import { HttpClient } from '@angular/common/http';
import {concatMap, map} from 'rxjs/operators';
import * as moment from "moment";
import { CompanyService } from "@brightside-web/desktop/data-access/shared";

export enum NotificationMessageTypeEnum {
  CHANGE_EMAIL = "account-change-password-notify"
}

export enum NotificationMessageChannelEnum {
  EMAIL = 'email'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private awsApiWrapperService: AwsApiWrapperService,
    private bsCacheService: BsCacheService,) {  }

  sendMessage(messageType: NotificationMessageTypeEnum, channel: NotificationMessageChannelEnum) : void {
    this.awsApiWrapperService.post('api-mobile', '/notification', {body:{
     messageType,
     channel
    }});
  }

  generateDynamicLink(route:string,cardName:string):Observable<ApiResponse>{
    return from(this.awsApiWrapperService.post('api-mobile','/notification',{body:{
      messageType:'desktopCardToMobile',
        channel:'sms',
        dynamicLink:route,
        cardName
      }}));
  }
// TODO: ken check this
  sendGetTheAppMessage(phone_number:string, user_agent:string):Observable<ApiResponse>{

    return this.http.get<{ip:string}>('https://api.ipify.org/?format=json').pipe(
      concatMap( (ip) => from(this.bsCacheService.getItem('referrer')).pipe(
        map(referrer => {
          if(!referrer) {
            this.bsCacheService.setItem('referrer', this.companyService.getCompany(), { expires: moment().add(6000, 'seconds').valueOf() });
            referrer = this.companyService.getCompany()
          }
          return {ipInfo: ip, referrer}
        })
      )),
      concatMap( (resp: {ipInfo: {ip:string}, referrer:string}) => from(this.bsCacheService.getItem('referrerType')).pipe(
        map(referrerType => {
          if ( !referrerType ) {
            this.bsCacheService.setItem('referrerType', 'desktop', { expires: moment().add(6000, 'seconds').valueOf() });
            referrerType = 'desktop';
          }
          return {...resp, referrerType}
        })
      )),
      concatMap((final: {ipInfo: {ip:string}, referrer:string, referrerType:string}) => from(this.awsApiWrapperService.post('api-mobile-noauth','/landingpage',{body:{
          "referrer":final.referrer,
          "referrerType":final.referrerType,
          "company":'app',
          phone_number,
          user_agent,
          "ip_address":final.ipInfo.ip}})))
    )
  }
}

