import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const AgeValidator = (): ValidatorFn =>
   (control: AbstractControl): { [key: string]: any } | null => {
    const currentYear = new Date().getFullYear();
    if (control.value) {
      const inputDate = control.value;
      const inputYear = inputDate.split('-')[0];
      const age = currentYear - inputYear;

      if (age < 18 || age > 120) {
        return { invalidAge: true };
      }
    }
    return null;
  };

