import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bw-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmptyStateComponent {

  @Input() iconName = '';
  @Input() primaryCopy: string;
  @Input() secondaryCopy: string;

}
