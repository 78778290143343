import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanLoad,
  Route,
  Router, RouterStateSnapshot,
  UrlSegment, UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {MobileStateService} from "@brightside-web/desktop/data-access/shared";
import {map} from "rxjs/operators";
import {MessageBusEventUtil, MessageBusInternalService} from "@micro-core/message-bus";

@Injectable({
  providedIn: 'root'
})
export class HasTermsUpdateGuard implements CanLoad {

  constructor(
    private mobileStateService: MobileStateService,
    private router: Router
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.mobileStateService.get().pipe(
      map( (value => {
        if (value.redirect !== "terms-update") {
          MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.exitFeature);
          return false;
        }
        return true;
      }))
    );
  }

  // use to block routes and force them to go to the terms-update
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.mobileStateService.get().pipe(
      map( (value => {
        if (value.redirect === "terms-update") {
          this.router.navigate(['terms-update']);
          return false;
        }
        return true;
      }))
    );
  }

}
