import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SurveySelectorData, SurveySelectorType } from './survey-selector.model';
import { CheckboxComponent, RadioComponent } from '@brightside/brightside-ui';

@Component({
  selector: 'bw-survey-selector',
  templateUrl: './survey-selector.component.html',
  styleUrls: ['./survey-selector.component.scss'],
})
export class SurveySelectorComponent implements OnInit, OnDestroy {
  @Input() selectorType: SurveySelectorType = SurveySelectorType.Instant;
  @Input() data: SurveySelectorData[];
  @Input() selectorName: string;
  @Input() disabled: boolean = false;
  @Input() surveyGroupForm: FormGroup = new FormGroup({});
  @Input() surveyGroupFormKey: string;
  @Output() instantCtaClicked = new EventEmitter<any>();

  @ViewChildren('checkboxElem') checkboxElem: QueryList<CheckboxComponent>;
  @ViewChildren('radioElem') radioElem: QueryList<RadioComponent>;

  SurveySelectorType = SurveySelectorType;
  valueKey: string = 'value';
  private _unsub$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    const selectionControl = this.surveyGroupForm.get(this.surveyGroupFormKey);
    this.setUpAdditionalInfoOnData(selectionControl);

    if (selectionControl) {
      selectionControl.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((valueSelected) => {
        if (this.selectorType === SurveySelectorType.Radio) {
          this.setRadioActive(valueSelected);
        }
        if (this.selectorType === SurveySelectorType.Checkbox) {
          this.setCheckboxActive(valueSelected);
        }
      });
    }
  }

  handleInstantClick(value: SurveySelectorData): void {
    const selectionControl = this.surveyGroupForm.get(this.surveyGroupFormKey);
    if (selectionControl) {
      selectionControl.setValue(value);
      this.setInstantActive(value);
    }

    this.instantCtaClicked.emit();
  }

  onSurveySelectorClick($event: MouseEvent, dataItem: SurveySelectorData, value: any) {
    if (dataItem.disabled || this.disabled) return;

    if (this.selectorType === SurveySelectorType.Radio) {
      this.radioElem.forEach((elem) => {
        if (elem.value === value) elem.handleClick($event, { focus: () => {} }, true);
        return;
      });
    }
    if (this.selectorType === SurveySelectorType.Checkbox) {
      this.checkboxElem.forEach((elem) => {
        if (elem.value === value) elem.onClick($event, null, true);
        return;
      });
    }
    if (this.selectorType === SurveySelectorType.Instant) {
      this.handleInstantClick(value);
    }
  }

  ngOnDestroy(): void {
    this._unsub$.next();
  }

  private setUpAdditionalInfoOnData(selectionControl: AbstractControl | null): void {
    if (selectionControl) {
      let additionalData: any;

      this.data = this.data.map((dataItem: any) => {
        if (this.selectorType === SurveySelectorType.Checkbox) {
          const selectionControlArray: FormGroup = selectionControl as FormGroup;
          const formControlItem = new FormControl(dataItem.checked);
          selectionControlArray.addControl(dataItem[this.valueKey], formControlItem);

          additionalData = {
            formControl: formControlItem,
          };
        }

        if ([SurveySelectorType.Radio, SurveySelectorType.Instant].includes(this.selectorType)) {
          additionalData = {
            checked: dataItem.checked,
          };
        }

        return {
          ...dataItem,
          ...additionalData,
        };
      });
    }
  }

  private setRadioActive(valueSelected: any): void {
    this.data.forEach((dataItem: SurveySelectorData) => {
      dataItem.checked = dataItem[this.valueKey as keyof SurveySelectorData] === valueSelected;
    });
  }

  private setCheckboxActive(valueSelected: any): void {
    this.data.forEach((dataItem: SurveySelectorData) => {
      const matchingKeyValue =
        Object.keys(valueSelected).find((keyValue) => keyValue === dataItem[this.valueKey as keyof SurveySelectorData]) || '';
      dataItem.checked = valueSelected[matchingKeyValue];
    });
  }

  private setInstantActive(valueSelected: any): void {
    this.data.forEach((dataItem: SurveySelectorData) => {
      dataItem.checked = dataItem[this.valueKey as keyof SurveySelectorData] === valueSelected;
    });
  }
}
