import { Injectable } from '@angular/core';

import {
  MessageBusEventChannel,
  MessageBusIncomingEventKey,
  MessageBusIncomingEventKeyList,
  MessageBusIncomingEventKeyByValue,
} from '../models/message-bus.enum';
import { MessageBusBaseService } from '../service/message-bus-base.service';

interface SimpleUnknownObject {
  [key: string]: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class MessageBusIncomingService extends MessageBusBaseService {
  private readonly skipConvertingToObject = [MessageBusIncomingEventKey.CAMERA_RESPONSE];

  constructor() {
    super();
  }

  private extractEventKeys(rawMessageBusIncomingEventKey: string): MessageBusIncomingEventKey[] {
    return (
      MessageBusIncomingEventKeyList.filter(
        (eventKeyDetails: { key: string; value: string }) => rawMessageBusIncomingEventKey.indexOf(eventKeyDetails.value) === 0
      ).map((eventKeyDetails: { key: string; value: string }) => MessageBusIncomingEventKeyByValue(eventKeyDetails.key)) || [
        MessageBusIncomingEventKey.UNKNOWN,
      ]
    );
  }

  private extractEventBody(
    keys: MessageBusIncomingEventKey[] = [],
    rawMessageBusIncomingEventKey: string = ''
  ): SimpleUnknownObject {
    let cleanedMessageBody: SimpleUnknownObject = {};
    let cleanedMessageString = rawMessageBusIncomingEventKey;

    //Remove the values of the keys from the rawString
    keys.forEach((eventKey: MessageBusIncomingEventKey) => {
      cleanedMessageString = cleanedMessageString.replace(eventKey, '');
    });

    if (this.skipConvertingToObject.filter((skipKey) => keys.includes(skipKey)).length > 0) {
      cleanedMessageBody = { rawMessage: rawMessageBusIncomingEventKey }; //This should be the raw message that's sent into us and not the cleansed version
    } else if (cleanedMessageString.indexOf('{') > -1) {
      cleanedMessageString = cleanedMessageString.slice(cleanedMessageString.indexOf('{'));
      cleanedMessageBody = this.convertIncomingBodyToObject(cleanedMessageString);
    } else {
      cleanedMessageBody = { value: cleanedMessageString };
    }

    return cleanedMessageBody;
  }

  private convertIncomingBodyToObject(cleanedMessageString: string, convertToSingleQuotes = false): SimpleUnknownObject {
    //Let's try to convert to an object now
    try {
      return JSON.parse(convertToSingleQuotes ? cleanedMessageString.replace(/'/g, '"') : cleanedMessageString);
    } catch (_) {
      if (!convertToSingleQuotes) return this.convertIncomingBodyToObject(cleanedMessageString, true);

      return { unknown: cleanedMessageString };
    }
  }

  private sendIncomingEvents(eventKeys: MessageBusIncomingEventKey[], eventBody: SimpleUnknownObject) {
    eventKeys.forEach((eventKey) => {
      this.sendInternalHubEvent(MessageBusEventChannel.INCOMING, {
        event: eventKey,
        data: { body: eventBody },
      });
    });
  }

  /**
   * ToDo: Do we need to have better checks here to make sure bad data can't be injected
   * @param rawMessageBusIncomingEventKey
   */
  handleIncoming(rawMessageBusIncomingEventKey: string) {
    this.logForDebugging('Incoming message bus event processing...');

    const extractedEventKeys = this.extractEventKeys(rawMessageBusIncomingEventKey);
    const extractedEventBody = this.extractEventBody(extractedEventKeys, rawMessageBusIncomingEventKey);

    this.logForDebugging('Event keys and body extracted', extractedEventKeys, extractedEventBody);

    this.sendIncomingEvents(extractedEventKeys, extractedEventBody);
  }
}
