import { Injectable } from '@angular/core';

import { DeepPartial, UiTemplateState, UiTemplatePageStateCondition } from './state.interface';
import { Store } from './state.service';

import { UiTemplateStateInterface } from '../../shared/state-page-template.component';

//ToDo: maybe this should be { providedIn: 'root', }

@Injectable({ providedIn: 'root' })
export class UiTemplateStateHandlerService extends Store<UiTemplateState> {
  /**
   * We are keeping track of the initial state and last updates. Don't look to these
   * for information for the client. Should be used as validation/debugging tool only.
   *
   * Default: undefined
   */
  private _initial: UiTemplateState | undefined;
  private _lastUpdateWith: DeepPartial<UiTemplateState> | undefined;

  /**
   * Keep direct reference to the conditions in-case a child is updating state
   * without direct access to the conditions.
   *
   * Default: undefined
   */
  private _conditions: UiTemplatePageStateCondition[] | undefined;

  /**
   * Tracking change log for validation and debugging
   *
   * Default: []
   */
  private _changeLog: {
    updatedAt: string;
    with: DeepPartial<UiTemplateState> | undefined;
  }[] = [];

  /**
   * If you wish to have developer logging turn this on
   *
   * Default: true
   */
  protected allowVerboseDebugMode = true;
  protected logPrefix = 'state-handler:<sharedService>';

  protected logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode) console.log(`${this.logPrefix} - `, message, optionalParams);
  }

  private addLastChange() {
    this._changeLog.push({ updatedAt: new Date().toTimeString(), with: this._lastUpdateWith });
  }

  /**
   * Very important to clean this stuff up when destroyed.
   */
  public destroy() {
    this.reset();

    this._initial = undefined;
    this._lastUpdateWith = undefined;
    this._conditions = undefined;

    this._changeLog = [];
  }

  public isNotEmpty(checkObj: DeepPartial<UiTemplateState> | undefined) {
    return !checkObj || Object.keys(checkObj).length > 0;
  }

  public updateWithInterface(details: UiTemplateStateInterface) {
    if (!details.state) {
      this.logForDebugging('Eh, called without any state details. If you only have partial state update, just pass that');
      return;
    }

    this.logForDebugging(
      'Condition Checks (updateWithInterface)',
      this.isNotEmpty(details.state.initial),
      this.isNotEmpty(details.state.updateWith)
    );

    if (details && this.isNotEmpty(details.state.initial) && this.isNotEmpty(details.state.updateWith)) {
      this._initial = details.state.initial;
      this._lastUpdateWith = details.state.updateWith;

      this.setState(details.state.updateWith || {}, details.stateConditions);
      this.addLastChange();

      this.logForDebugging('Updated State', this.getState());
    }
  }

  public updateWithPartial(partialState: DeepPartial<UiTemplateState>) {
    this.logForDebugging('Condition Checks (updateWithPartial)', this.isNotEmpty(partialState));

    if (this.isNotEmpty(partialState)) {
      this._lastUpdateWith = partialState;

      this.setState(partialState, this._conditions);
      this.addLastChange();
    }
  }
}
